import { publicApi } from '..';

import { Beacon, BeaconEventResponse } from '@/interfaces/Beacons.interface';

export const postBeaconEvent = async (beacons: Beacon[]) => {
  const currentTime = new Date();
  const { data } = await publicApi.post<BeaconEventResponse>(
    '/beacon/event',
    {
      'current-time': currentTime,
      beacons
    },
    {
      headers: {
        'X-Beacon-Secret': 'J!bmMph&7aZJ'
      }
    }
  );

  return data;
};
