import React, { useMemo } from 'react';
import { ImageSourcePropType } from 'react-native';

import FastImage from '@/components/fast-image/FastImage';

interface Props {
  color: string;
  focused: boolean;
  src: ImageSourcePropType;
  srcOnFocused: ImageSourcePropType;
  size: number;
}

export const ForcusableTabBarIcon: React.FC<Props> = ({
  color,
  focused,
  size,
  src,
  srcOnFocused
}) => {
  const style = {
    width: size,
    height: size,
    tintColor: color
  };
  const actualSrc = useMemo(() => (focused ? srcOnFocused : src), [focused]);

  return <FastImage resizeMode="contain" source={actualSrc} style={style} />;
};
