import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import * as RNDeviceInfo from 'react-native-device-info';

export const useDeviceUniqueId = () => {
  const [deviceUniqueId, setDeviceUniqueId] = useState<string>('');

  useEffect(() => {
    (async () => {
      if (Platform.OS === 'android') {
        const id = await RNDeviceInfo.getUniqueIdSync();
        setDeviceUniqueId(id);
      } else if (Platform.OS === 'ios') {
        const id = await RNDeviceInfo.syncUniqueId();
        setDeviceUniqueId(id);
      }
    })();
  }, []);

  return {
    deviceUniqueId
  };
};
