import React, { useEffect, useRef } from 'react';
import { Animated, TouchableHighlight, View } from 'react-native';

import { useDesign } from '@/hooks/useDesign';
import { createStyles, isMobile, platformSpecificStyle } from '@/utils';

interface Props {
  isOn: boolean;
  toggle: () => void;
  isDisabled?: boolean;
}

const { getStyle } = createStyles(({ pixel, colors }) => ({
  outer: {
    backgroundColor: colors.icon.primaryInvertedDisabled,
    overflow: 'hidden',
    position: 'relative',
    ...platformSpecificStyle({
      native: {
        width: pixel(75),
        height: pixel(40),
        borderRadius: pixel(40)
      },
      default: {
        width: pixel(38),
        height: pixel(20),
        borderRadius: pixel(20)
      }
    })
  },
  bgOn: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: colors.primary
  },
  bgOff: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: colors.icon.primaryDisabled
  },
  icon: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  inner: {
    backgroundColor: colors.icon.primary,
    justifyContent: 'center',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {
        top: pixel(4),
        left: pixel(4),
        borderRadius: pixel(32),
        width: pixel(32),
        height: pixel(32)
      },
      default: {
        top: pixel(2),
        left: pixel(2),
        borderRadius: pixel(16),
        width: pixel(16),
        height: pixel(16)
      }
    })
  }
}));

export const Toggle: React.FC<Props> = ({ isOn, toggle, isDisabled }) => {
  const design = useDesign();
  const { pixel } = design;
  const styles = getStyle(design);

  const togglerAnimation = useRef(new Animated.Value(isOn ? 1 : 0)).current;

  useEffect(() => {
    Animated.timing(togglerAnimation, {
      useNativeDriver: true,
      toValue: isOn ? 1 : 0,
      duration: 200
    }).start();
  }, [isOn, togglerAnimation]);

  const transformInterpolation = togglerAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [0, isMobile ? pixel(34) : pixel(18)]
  });

  return (
    <TouchableHighlight
      activeOpacity={1}
      underlayColor="transparent"
      onPress={() => {
        if (!isDisabled) {
          toggle();
        }
      }}
    >
      <View style={{ ...styles.outer }}>
        <View style={styles.bgOff} />
        <Animated.View style={{ ...styles.bgOn, opacity: togglerAnimation }} />
        <Animated.View
          style={{
            ...styles.inner,
            transform: [{ translateX: transformInterpolation }]
          }}
        />
      </View>
    </TouchableHighlight>
  );
};
