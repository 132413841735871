import axios, { AxiosError, AxiosRequestHeaders } from 'axios';
import { format } from 'date-fns';
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import * as RNDeviceInfo from 'react-native-device-info';

import { AuthTokens } from '@/interfaces/Auth.interface';
import { setTokens, store } from '@/store';
import { errorAction } from '@/store/reducers/error/error.slice';
import { ErrorCodes } from '@/utils';
import { ACCESS_TOKEN, REFRESH_TOKEN, getStorage } from '@/utils/storage.util';

const API_URL: string = Constants.expoConfig?.extra?.API_URL;

const CRITICAL_PATHS = ['/account/me', '/configuration'];
const ALERT_EXCLUDE_PATHS = [
  /^\/account\/me\/profileImage$/,
  /^\/qr-api\/code$/,
  /^\/qr-api\/read$/,
  /^\/account\/info$/,
  /^\/account\/info\/.+\/profileImage$/
];
const ALERT_EXCLUDE_PATHS_WHEN_GATEWAY_TIMEOUT = ['/attendance/status'];
export const ERROR_RESPONSE_MESSAGES = {
  NetworkError: 'Network Error'
};

const config = {
  baseURL: API_URL
};

export const api = axios.create(config);
export const publicApi = axios.create(config);
export const isAlertExcludedPath = (error: AxiosError) => {
  // URLからクエリパラメータを除去するヘルパー関数
  const removeQueryParameters = (url: string) => url.split('?')[0];

  // エラーのURLが存在するか確認し、存在する場合はクエリパラメータを除去
  const errorUrl = error?.config?.url
    ? removeQueryParameters(error.config.url)
    : null;

  return (
    errorUrl &&
    (ALERT_EXCLUDE_PATHS.find((pathRegExp) => pathRegExp.test(errorUrl)) ||
      (ALERT_EXCLUDE_PATHS_WHEN_GATEWAY_TIMEOUT.find(
        (path) => errorUrl === path
      ) &&
        String(error?.response?.status) === ErrorCodes.GatewayTimeout))
  );
};
export const isNetworkError = (error: Error) =>
  (error as AxiosError).code === ErrorCodes.NetworkError ||
  error?.message === ERROR_RESPONSE_MESSAGES.NetworkError;

api.interceptors.request.use(async (config) => {
  const accessToken = await getStorage(ACCESS_TOKEN);
  let id;
  if (Platform.OS === 'android') {
    id = await RNDeviceInfo.getUniqueIdSync();
  } else if (Platform.OS === 'ios') {
    id = await RNDeviceInfo.syncUniqueId();
  }
  const versionStr = await RNDeviceInfo.getVersion();

  const nativeSpecificHeaders =
    Platform.OS !== 'web'
      ? { 'User-Agent': `Version=${versionStr} Device=${id}` }
      : {};

  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${accessToken}`,
    ...nativeSpecificHeaders
  } as AxiosRequestHeaders;

  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    // ------------------------------------------------------------------------
    // Token refresh logic

    if (
      String(error?.response?.status) === ErrorCodes.Unauthorized &&
      !config?.sent
    ) {
      config.sent = true;

      const refreshTokenFromStorage = await getStorage(REFRESH_TOKEN);

      try {
        const {
          data: { accessToken, refreshToken }
        } = await publicApi.post<AuthTokens>('/auth/refresh', {
          refreshToken: refreshTokenFromStorage
        });

        store.dispatch(setTokens({ accessToken, refreshToken }));

        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${accessToken}`
        };

        return api(config);
      } catch (error) {
        console.log(error);
        await store.dispatch(setTokens({}));
        return Promise.reject(error);
      }
    }

    // ------------------------------------------------------------------------
    // Handling critical backend errors

    if (
      error?.response?.status &&
      String(error.response.status) !== ErrorCodes.Unauthorized &&
      String(error.response.status) !== ErrorCodes.NotFound &&
      String(error.response.status) !== ErrorCodes.GatewayTimeout &&
      error?.config?.url &&
      CRITICAL_PATHS.includes(error.config.url)
    ) {
      // Navigate to error page
      store.dispatch(
        errorAction.setRedirect({
          label: `サーバーに問題が発生しています: ${error.config.url}`,
          message: `原因: ${error.message} (${format(
            new Date(),
            'yyyy-MM-dd HH:mm:ss'
          )} )`
        })
      );
    } else if (isAlertExcludedPath(error) || isNetworkError(error)) {
      // Do nothing
    } else {
      // If any other error occurs -> display error modal
      store.dispatch(
        errorAction.push({
          label: `エラーが発生しました: ${error.config.url}`,
          message: `原因: ${error?.message} (${format(
            new Date(),
            'yyyy-MM-dd HH:mm:ss'
          )} )`
        })
      );
    }
    return Promise.reject(error);
  }
);
