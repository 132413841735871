import * as React from 'react';
import { View } from 'react-native';
import { Circle, G } from 'react-native-svg';

import { SvgProps, SvgWrapper } from '@/wrappers/svg';

interface Props extends SvgProps {
  progress: number;
  timelimitAlerting: boolean;
}
export const QrCountdownCircle: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  progress,
  timelimitAlerting,
  ...props
}) => {
  return (
    <View
      style={{
        position: 'relative',
        width: 52,
        height: 52,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <SvgWrapper
        style={{ position: 'absolute', top: 1.5, left: 1.5 }}
        viewBox={{ width: 52, height: 52 }}
        {...props}
      >
        <G
          transform="scale(-1, 1) translate(-52, 0) rotate(-90 26 26)"
          id="circle_gr"
        >
          <G id="circle" fill="none" stroke="#e6e6e6" strokeWidth="4">
            <Circle cx="26" cy="26" r="26" stroke="none" />
            <Circle cx="26" cy="26" r="24" fill="none" />
          </G>
          <G
            id="indicator"
            fill="none"
            stroke={timelimitAlerting ? '#C54134' : '#3376d0'}
            strokeWidth="4"
            strokeDasharray={`${progress * 151} 151`}
          >
            <Circle cx="26" cy="26" r="26" stroke="none" />
            <Circle cx="26" cy="26" r="24" fill="none" />
          </G>
        </G>
      </SvgWrapper>
      {children}
    </View>
  );
};
