type BeaconRegion = {
  identifier: string;
  uuid?: string | undefined;
};

export default {
  stopRangingBeaconsInRegion: async (_region: BeaconRegion) => {},
  startRangingBeaconsInRegion: async (_region: BeaconRegion) => {},
  startUpdatingLocation: () => {},
  detectIBeacons: () => {}
};
