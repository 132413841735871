import Constants from 'expo-constants';
import { createURL, parse as parseUrl, useURL } from 'expo-linking';
import {
  dismissAuthSession,
  maybeCompleteAuthSession,
  openAuthSessionAsync
} from 'expo-web-browser';
import { useCallback, useEffect } from 'react';
import { Platform } from 'react-native';

import { usePreload } from './usePreload';

import { AuthTokens } from '@/interfaces/Auth.interface';
import { setTokens, useAppDispatch } from '@/store';
import { getErrorMessage } from '@/utils';

const API_URL: string = Constants.expoConfig?.extra?.API_URL;

export const useAuthentication = () => {
  const openingUrl = useURL();
  const dispatch = useAppDispatch();
  const { preload } = usePreload();

  useEffect(() => {
    if (Platform.OS === 'web') {
      maybeCompleteAuthSession();
    }
  });

  const updateTokens = useCallback(
    (tokens: AuthTokens) => {
      dispatch(
        setTokens({
          ...tokens
        })
      ).then(() => {
        preload();
      });
    },
    [dispatch]
  );

  const getTokensFromUrl = useCallback(
    (url: string | null) => {
      if (url) {
        const parsedUrl = parseUrl(url);
        const queryParams = parsedUrl.queryParams;

        if (queryParams) {
          if (queryParams.error) return queryParams.error;

          try {
            dismissAuthSession();
          } catch (e) {}

          const { accessToken, refreshToken } = queryParams;
          if (
            typeof accessToken === 'string' &&
            typeof refreshToken === 'string'
          ) {
            updateTokens({
              accessToken,
              refreshToken
            });
          }
        }
      }
    },
    [updateTokens]
  );

  const login = async () => {
    const callbackUrl = createURL('login');
    const result = await openAuthSessionAsync(
      `${API_URL.replace(/\/$/, '')}/auth/login?RelayState=${encodeURIComponent(
        callbackUrl
      )}`,
      callbackUrl,
      {
        createTask: false
      }
    );

    if (result.type === 'success') {
      const error = getTokensFromUrl(result.url);
      if (error && typeof error === 'string') {
        return getErrorMessage(error);
      }
    }
  };

  useEffect(() => {
    getTokensFromUrl(openingUrl);
  }, [openingUrl]);

  return {
    login
  };
};
