import { useIsFocused } from '@react-navigation/native';
import Constants from 'expo-constants';
import { useEffect, useMemo } from 'react';
import { ScrollView, View } from 'react-native';

import { SubNaviHeader } from './components/SubNaviHeader.component';
import { SubNaviPage } from './components/SubNaviPage.component';

import { List } from '@/components/atomic/list';
import { Row } from '@/components/atomic/list/ListRow.component';
import { Screen } from '@/components/atomic/screen';
import { TextM } from '@/components/atomic/text';
import { NAVIGATION_MENU_ITEM_TYPE_MAPPING } from '@/constants/Navi';
import { useDesign } from '@/hooks/useDesign';
import { useLoading } from '@/hooks/useLoading';
import { NavigationMenuItem, NavigationMenuItemType } from '@/interfaces';
import { NaviStackParamList, NaviStackScreenProps } from '@/navigation/types';
import {
  getNaviContent,
  selectActivePageContent,
  selectNavigationMenuRootItems,
  selectNode,
  useAppDispatch,
  useAppSelector
} from '@/store';
import { createStyles, isMobile, isWeb, openBrowser } from '@/utils';

const APP_NAME = Constants.expoConfig?.extra?.APP_NAME;

const { getStyle } = createStyles(({ pixel, colors }) => ({
  titleContainer: {
    paddingBottom: pixel(19)
  },
  titleContainerPage: {
    paddingTop: pixel(42),
    borderTopWidth: pixel(1),
    borderTopColor: colors.button.borderSecondary
  },
  contentsContainer: {
    marginBottom: pixel(24)
  }
}));

export const SubNaviScreen = ({
  navigation,
  route: { params }
}: NaviStackScreenProps<keyof NaviStackParamList>) => {
  const design = useDesign();
  const styles = getStyle(design);
  const navigationMenuRootItems = useAppSelector(selectNavigationMenuRootItems);
  const activeNode = useAppSelector((state) => {
    return selectNode(
      state,
      navigationMenuRootItems,
      params?.rootId,
      params?.subId
    );
  });
  const activePageContent = useAppSelector((state) =>
    selectActivePageContent(state, activeNode?.item.id)
  );
  const isFocused = useIsFocused();
  const dispatch = useAppDispatch();

  const { isLoading, refresh, isPtrLoading, pullToRefresh } = useLoading(
    (currentItem?: NavigationMenuItem) => {
      if (!currentItem || currentItem.object !== NavigationMenuItemType.PAGE) {
        return [];
      }

      return [dispatch(getNaviContent(currentItem.id))];
    }
  );

  useEffect(() => {
    refresh(activeNode?.item);

    if (activeNode) {
      navigation.setOptions({
        title: `${APP_NAME} 大学ナビ - ${activeNode.path.join(' - ')}`
      });
    }
  }, [activeNode?.item.id]);

  useEffect(() => {
    if (!activeNode && navigationMenuRootItems.length && isFocused) {
      navigation.navigate('Navi', { rootId: params?.rootId });
    }
  }, [activeNode, navigationMenuRootItems, isFocused]);

  const listElements = useMemo<Row[] | undefined>(() => {
    if (!activeNode?.item || !activeNode.item.children?.length) {
      return;
    }

    return activeNode.item.children.map<Row>((child) => {
      return {
        key: child.title,
        type: NAVIGATION_MENU_ITEM_TYPE_MAPPING[
          child.object ?? NavigationMenuItemType.CUSTOM
        ],
        ...(child.object === NavigationMenuItemType.CUSTOM && {
          value: child.url
        }),
        onPress: () => {
          if (child.object === NavigationMenuItemType.CUSTOM) {
            openBrowser({
              url: child.url,
              openInSameBrowser: true
            });
            return;
          }

          if (isMobile) {
            navigation.push('SubNavi', {
              rootId: params.rootId,
              subId: child.id
            });
          } else {
            navigation.setParams({
              subId: child.id
            });
          }
        }
      };
    });
  }, [activeNode, isMobile]);

  const isContent = !listElements;

  return (
    <Screen
      isTransparent={!isMobile}
      isLoading={isLoading}
      refreshing={isPtrLoading}
      onRefresh={!isContent ? () => pullToRefresh(activeNode?.item) : undefined}
    >
      {activeNode && (
        <>
          {(activeNode.item.object === NavigationMenuItemType.PAGE ||
            isMobile) && (
            <SubNaviHeader
              menuItem={activeNode}
              onBackPress={() => {
                navigation.navigate(
                  activeNode.item.parentId !== undefined && isMobile
                    ? 'SubNavi'
                    : 'Navi',
                  {
                    ...(activeNode.item.parentId !== undefined && {
                      rootId: params?.rootId,
                      ...(params.rootId !== activeNode.item.parentId &&
                        activeNode.item.parentId !== 0 && {
                          subId: activeNode.item.parentId
                        })
                    })
                  }
                );
              }}
              isContent={isContent}
            />
          )}
          {!isMobile && (
            <View
              style={{
                ...styles.titleContainer,
                ...(activeNode.item.object === NavigationMenuItemType.PAGE &&
                  styles.titleContainerPage)
              }}
            >
              <TextM>{activeNode.item.title}</TextM>
            </View>
          )}
        </>
      )}
      {listElements ? (
        <List rows={listElements} />
      ) : (
        <>
          {isMobile && !isWeb ? (
            <SubNaviPage html={activePageContent?.content.rendered} />
          ) : (
            <ScrollView
              style={{
                ...styles.contentsContainer
              }}
            >
              <SubNaviPage html={activePageContent?.content.rendered} />
            </ScrollView>
          )}
        </>
      )}
    </Screen>
  );
};
