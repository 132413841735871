import QrError from 'assets/images/icons/id-card/qr-error.png';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ImageSourcePropType } from 'react-native';

import { isNetworkError } from '@/api';
import { fetchNewQr } from '@/api/calls/qr.api';
import { ErrorCodes } from '@/utils';

type UseQrCodeProps = {
  enabled: boolean;
};

type UseQrCodeState = {
  isLoading: boolean;
  qrImageData?: ImageSourcePropType;
  secondsRemaining: number;
  secondsRemainingForDisplay: number;
  errorCode?: string | undefined;
  onRetryCallback: () => Promise<void>;
  timelimitAlerting: boolean;
};

export const useQrCode = ({ enabled }: UseQrCodeProps): UseQrCodeState => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [qrImageData, setQrImageData] = useState<ImageSourcePropType>();
  const [secondsRemaining, setSecondsRemaining] = useState<number>(0);
  const [expiresAtDate, setExpiresAttDate] = useState<Date>();
  const [errorOnFetch, setErrorOnFetch] = useState<AxiosError>();

  const fetchNewQrCode = useCallback(async () => {
    if (!enabled) return;
    setIsLoading(true);
    try {
      const { qrImageData, expiresAt } = await fetchNewQr();
      const expiresAtDateObject = new Date(expiresAt);
      setExpiresAttDate(expiresAtDateObject);
      const now = new Date();
      setSecondsRemaining(expiresAtDateObject.getTime() - now.getTime());

      setQrImageData(
        qrImageData
          ? {
              uri: qrImageData.startsWith('data:')
                ? qrImageData
                : `data:image/jpeg;base64,${qrImageData}`
            }
          : QrError
      );
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      if (e instanceof AxiosError) {
        console.error('QRコードの取得に失敗しました', e.response);
        setErrorOnFetch(e);
      }
      setQrImageData(undefined);
      setIsLoading(false);
    }
  }, [enabled]);

  const retry = useCallback(async () => {
    setErrorOnFetch(undefined);
    await fetchNewQrCode();
  }, []);

  const secondsRemainingForDisplay = useMemo(() => {
    return Math.floor(secondsRemaining / 1000);
  }, [secondsRemaining]);
  const timelimitAlerting = useMemo(() => {
    return secondsRemainingForDisplay <= 10;
  }, [secondsRemainingForDisplay]);

  const errorCode = useMemo(() => {
    if (!errorOnFetch) return undefined;
    if (errorOnFetch?.response?.status) {
      return errorOnFetch?.response?.status;
    }
    if (errorOnFetch?.response?.data) {
      return (errorOnFetch?.response?.data as any)?.statusCode;
    }
    if (!errorOnFetch?.response && isNetworkError(errorOnFetch)) {
      return ErrorCodes.NetworkError;
    }
    return ErrorCodes.ServerError;
  }, [errorOnFetch]);

  const timerInterval = 200;
  const timerMax = timerInterval * 100;
  const [timer, setTimer] = useState<number>(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => (prev < timerMax ? prev + timerInterval : 0));
    }, timerInterval);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (timer % timerInterval === 0) {
      expiresAtDate &&
        setSecondsRemaining(expiresAtDate?.getTime() - new Date().getTime());
    }
  }, [expiresAtDate, timer]);

  useEffect(() => {
    (async () => {
      if (
        enabled &&
        !errorOnFetch &&
        (!secondsRemaining || secondsRemaining <= 0)
      ) {
        setTimeout(() => {
          fetchNewQrCode();
        }, 1000);
      }
    })();
  }, [enabled, errorOnFetch, secondsRemaining]);

  return {
    isLoading,
    qrImageData,
    secondsRemaining,
    secondsRemainingForDisplay,
    onRetryCallback: retry,
    errorCode: errorCode && String(errorCode),
    timelimitAlerting
  };
};
