import { isBefore, isWithinInterval } from 'date-fns';
import { useMemo } from 'react';

import { CAFETERIA_OPEN_TIME } from '@/constants/Home';
import { getDateTimeObjectFromDateAndTimeString } from '@/utils';

interface Props {
  currentTime: Date;
  selectedDate: Date;
}

export const useCafeteriaStatus = ({ currentTime, selectedDate }: Props) => {
  const isActiveCafeteria = useMemo(() => {
    return isWithinInterval(currentTime || new Date(), {
      start: getDateTimeObjectFromDateAndTimeString(
        selectedDate,
        CAFETERIA_OPEN_TIME.from
      ),
      end: getDateTimeObjectFromDateAndTimeString(
        selectedDate,
        CAFETERIA_OPEN_TIME.to
      )
    });
  }, [currentTime, selectedDate]);
  const isExpiredCafeteria = useMemo(() => {
    return isBefore(
      getDateTimeObjectFromDateAndTimeString(
        selectedDate,
        CAFETERIA_OPEN_TIME.to
      ),
      currentTime || new Date()
    );
  }, [currentTime, selectedDate]);

  return {
    isActiveCafeteria,
    isExpiredCafeteria
  };
};
