import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { useEffect, useState } from 'react';

import { usePreload } from './usePreload';

import { getTokensFromStorage, useAppDispatch } from '@/store';

export default function useCachedResources() {
  const dispatch = useAppDispatch();
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const { preload } = usePreload();

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        await dispatch(getTokensFromStorage());
        await preload();

        // Load fonts
        await Font.loadAsync({
          'space-mono': require('assets/fonts/SpaceMono-Regular.ttf')
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
        await SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}
