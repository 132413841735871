import { useFocusEffect } from '@react-navigation/native';
import { addBusinessDays, format } from 'date-fns';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { View } from 'react-native';

import { DateSelector } from './components/DateSelector.component';
import { EventList } from './components/EventList.component';
import { EventListAllDay } from './components/EventListAllDay.component';
import { EventListContainer } from './components/EventListContainer.component';
import { HeaderHome } from './components/HeaderHome.component';

import { Loader } from '@/components/atomic/loader';
import { Screen } from '@/components/atomic/screen';
import { Separator } from '@/components/atomic/separator';
import { IdCardOpenFloatingButtonAndDialog } from '@/components/id-card/IdCardOpenFloatingButtonAndDialog.component';
import { useDesign } from '@/hooks/useDesign';
import { useFocus } from '@/hooks/useFocus';
import { useLoading } from '@/hooks/useLoading';
import { CalendarClass } from '@/interfaces';
import {
  checkIfCanShowStudentIdCard,
  getCalendarList,
  getUnreadCount,
  selectCalendarCafeteriaMenus,
  selectCalendarEvent,
  selectCalendarSchedule,
  selectPeriods,
  store,
  useAppDispatch,
  useAppSelector
} from '@/store';
import {
  createStyles,
  getTime,
  isMobile,
  platformSpecificStyle
} from '@/utils';

const { getStyle } = createStyles(({ pixel }) => ({
  eventsWithDateSelector: {
    flex: 1,
    width: '100%'
  },
  events: {
    flex: 1,
    justifyContent: 'center',
    ...platformSpecificStyle({
      native: {},
      default: {
        flexDirection: 'row-reverse',
        gap: pixel(30)
      }
    })
  },
  loader: {
    flex: 1
  },
  buttonArea: {
    justifyContent: 'center',
    flexDirection: 'row'
  }
}));

export const HomeScreen = () => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const dispatch = useAppDispatch();

  const canShowStudentIdCard = useAppSelector(checkIfCanShowStudentIdCard);
  const events = useAppSelector((state) =>
    selectCalendarEvent(state, selectedDate)
  );
  const schedule = useAppSelector((state) =>
    selectCalendarSchedule(state, selectedDate)
  );
  const cafeteriaMenus = useAppSelector((state) =>
    selectCalendarCafeteriaMenus(state, selectedDate)
  );
  const periods = useAppSelector((state) => selectPeriods(state));
  const emptySchedule: CalendarClass[] = useMemo(
    () =>
      Object.values(periods).map((period) => ({ period, name: '', room: [] })),
    [periods]
  );

  const mergedSchedule = useMemo(() => {
    if (!schedule.length) {
      return emptySchedule;
    }

    let scheduleIndex = 0;
    return emptySchedule.map((emptyItem) => {
      if (
        schedule[scheduleIndex] &&
        getTime(emptyItem.period.from) ===
          getTime(schedule[scheduleIndex]?.period.from)
      ) {
        const scheduleItem = schedule[scheduleIndex];
        scheduleIndex += 1;

        return scheduleItem;
      }

      return emptyItem;
    });
  }, [emptySchedule, schedule]);

  const { isFocused, subscribe, unsubscribe } = useFocus();

  const sections = [
    <EventListAllDay
      selectedDate={selectedDate}
      events={events}
      cafeteriaMenus={!isMobile ? cafeteriaMenus : []}
    />,
    <EventList
      selectedDate={selectedDate}
      schedule={mergedSchedule}
      cafeteriaMenus={isMobile ? cafeteriaMenus : []}
    />
  ];

  const design = useDesign();
  const styles = getStyle(design);
  const { pixel, colors } = design;

  const { isLoading, refresh, isPtrLoading, pullToRefresh } = useLoading(
    (currentDate: Date) => {
      if (!store.getState().notifications.loadingState.unreadCount) {
        dispatch(getUnreadCount());
      }

      // If we have already fetched calendar list today, do nothing
      const calendarList = store.getState().calendar.list;
      const calendarListLoading = store.getState().calendar.isLoading;
      const days = Object.keys(calendarList);
      if (
        (days.length && days[0] === format(currentDate, 'yyyyMMdd')) ||
        calendarListLoading
      ) {
        return [];
      }

      return [
        dispatch(
          getCalendarList({
            from: currentDate,
            to: addBusinessDays(currentDate, 6)
          })
        )
      ];
    }
  );

  // fetch resource when tab is focused
  useFocusEffect(
    useCallback(() => {
      subscribe();
      refresh(new Date());

      return () => {
        unsubscribe();
      };
    }, [])
  );

  // fetch resource browser window or app is focused
  useEffect(() => {
    if (isFocused) {
      refresh(new Date());
    }
  }, [isFocused]);

  return (
    <Screen
      isLoading={isLoading}
      refreshing={isPtrLoading}
      onRefresh={() => pullToRefresh(selectedDate)}
      pageFixedLayerChildren={
        canShowStudentIdCard && <IdCardOpenFloatingButtonAndDialog />
      }
    >
      <View style={{ ...styles.eventsWithDateSelector }}>
        <HeaderHome />
        <DateSelector
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
        <Separator size={pixel(30)} />
        {isLoading ? (
          <Loader
            style={{ ...styles.loader }}
            color={colors.secondary}
            size="large"
          />
        ) : (
          <View style={{ ...styles.events }}>
            {isMobile ? (
              <EventListContainer sections={sections} />
            ) : (
              sections.map((section, index) => (
                <Fragment key={index}>
                  <EventListContainer sections={[section]} />
                </Fragment>
              ))
            )}
          </View>
        )}
      </View>
    </Screen>
  );
};
