import React, { PropsWithChildren } from 'react';
import { View } from 'react-native';

import { ListRow, Row } from './ListRow.component';

import { useDesign } from '@/hooks/useDesign';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  rows: Row[];
  isActive?: boolean;
}

const { getStyle } = createStyles(({ pixel, colors }) => ({
  container: {
    borderRadius: pixel(20),
    borderColor: 'transparent',
    overflow: 'hidden'
  },
  containerActive: {
    borderColor: colors.button.borderSecondary,
    borderTopEndRadius: 0,
    borderBottomEndRadius: 0,
    borderEndWidth: 0,
    ...platformSpecificStyle({
      native: {
        borderWidth: pixel(2)
      },
      default: {
        borderWidth: pixel(1)
      }
    })
  },
  separatorWrapper: {
    backgroundColor: colors.icon.primary
  },
  separator: {
    backgroundColor: colors.icon.primaryInvertedDisabled,
    ...platformSpecificStyle({
      native: {
        height: pixel(2)
      },
      default: {
        height: pixel(1)
      }
    })
  }
}));

export const List: React.FC<PropsWithChildren<Props>> = ({
  rows,
  isActive,
  children
}) => {
  const design = useDesign();
  const styles = getStyle(design);
  return (
    <View
      style={{ ...styles.container, ...(isActive && styles.containerActive) }}
    >
      {rows.map((row, index) => (
        <React.Fragment key={row.key}>
          <ListRow row={row} isActive={isActive} children={children} />
          {index !== rows.length - 1 && (
            <View style={{ ...styles.separatorWrapper }}>
              <View style={{ ...styles.separator }} />
            </View>
          )}
        </React.Fragment>
      ))}
    </View>
  );
};
