import IdCardOpenButton from 'assets/images/icons/id-card/id-card-open-button.png';
import React, { useState } from 'react';
import { TouchableOpacity, View } from 'react-native';

import { IdCardPageDialog } from './IdCardPageDialog.component';

import { Image } from '@/components/atomic/Image/Image.atomic';
import { useDesign } from '@/hooks/useDesign';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {}

const { getStyle } = createStyles(({ pixel }) => ({
  fixedBottomRight: {
    zIndex: 100,
    position: 'absolute',
    bottom: pixel(32),
    right: pixel(32)
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  idCardOpenButton: {
    ...platformSpecificStyle({
      native: {
        width: pixel(142)
      },
      tablet: {
        width: (pixel(142) * 2) / 3
      },
      default: {
        width: (pixel(142) * 2) / 3
      }
    })
  }
}));

export const IdCardOpenFloatingButtonAndDialog: React.FC<Props> = () => {
  const design = useDesign();
  const styles = getStyle(design);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onPressButton = () => setIsModalOpen(true);
  const onPressClose = () => setIsModalOpen(false);
  return (
    <>
      <View style={{ ...styles.fixedBottomRight }}>
        <TouchableOpacity
          style={{ ...styles.buttonWrapper }}
          onPress={onPressButton}
        >
          <Image
            size={{ width: styles.idCardOpenButton.width }}
            src={IdCardOpenButton}
          />
        </TouchableOpacity>
      </View>
      <IdCardPageDialog isModalOpen={isModalOpen} onPressClose={onPressClose} />
    </>
  );
};
