import * as React from 'react';
import { Circle, G, Mask, Rect } from 'react-native-svg';

import { SvgProps, SvgWrapper, usePrimaryIconColor } from '@/wrappers/svg';

export interface CafeteriaMenusCardSvgProps extends SvgProps {
  opacity?: number;
}

export const CafeteriaMenusCardSvg: React.FC<CafeteriaMenusCardSvgProps> = ({
  color,
  opacity,
  ...props
}) => {
  const primaryIconColor = usePrimaryIconColor();

  return (
    <SvgWrapper viewBox={{ width: 398, height: 45 }} {...props}>
      <Mask id="round-mask_CafeteriaMenusCardSvg">
        <Rect x="0" y="0" width="398" height="45" fill="white" />
        <Rect x="378" width="40" height="45" fill="black" />
        <Rect x="358" width="40" height="45" rx="20" fill="white" />
        <Circle cx="-5" cy="22.5" r="12" fill="#000" />
      </Mask>
      <G
        mask="url(#round-mask_CafeteriaMenusCardSvg)"
        fill={color || primaryIconColor}
        opacity={opacity}
      >
        <Rect x="0" width="398" height="45" rx="4" />
      </G>
    </SvgWrapper>
  );
};
