import React from 'react';
import { View, ViewStyle } from 'react-native';

interface Props {
  size: number | string;
  vertical?: boolean;
  styleObject?: ViewStyle;
}

export const Separator: React.FC<Props> = ({ size, vertical, styleObject }) => {
  return (
    <View
      style={{
        ...styleObject,
        ...(vertical ? { width: size } : { height: size })
      }}
    />
  );
};
