import Constants from 'expo-constants';
import React from 'react';
import { View } from 'react-native';

import { Separator } from '@/components/atomic/separator';
import { TextL, TextM, TextXL } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { useAppSelector } from '@/store';
import { createStyles, isMobile, platformSpecificStyle } from '@/utils';

const { getStyle } = createStyles(({ pixel }) => ({
  container: {
    alignSelf: 'flex-start',
    ...platformSpecificStyle({
      native: {
        width: '100%'
      },
      default: {
        marginBottom: 90,
        width: pixel(300)
      }
    })
  },
  appName: {
    fontWeight: 'bold',
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(52)
      },
      default: {}
    })
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end'
  },
  nameCol: {
    fontWeight: 'bold',
    maxWidth: '90%'
  },
  sanCol: {
    marginLeft: pixel(10),
    maxWidth: '10%'
  },
  gakusekiNo: {
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(28)
      },
      tablet: {
        fontSize: pixel(20)
      },
      default: {
        fontSize: pixel(20)
      }
    })
  }
}));

const APP_NAME = Constants.expoConfig?.extra?.APP_NAME;

export const HeaderHome: React.FC = () => {
  const design = useDesign();
  const styles = getStyle(design);
  const { pixel } = design;
  const profile = useAppSelector((state) => state.account.profile);
  return (
    <View style={{ ...styles.container }}>
      {isMobile ? (
        <>
          <TextXL style={{ ...styles.appName }}>{APP_NAME}</TextXL>
          <Separator size={pixel(40)} />
          <TextM style={{ ...styles.gakusekiNo }}>{profile?.gakusekiNo}</TextM>
          <Separator size={pixel(30)} />
          <View style={{ ...styles.row }}>
            <TextL style={{ ...styles.nameCol }} numberOfLines={3}>
              {profile?.name}
            </TextL>
            <TextM style={{ ...styles.sanCol }}>さん</TextM>
          </View>
          <Separator size={pixel(30)} />
        </>
      ) : (
        <>
          <Separator size={pixel(20)} />
          <TextM style={{ ...styles.gakusekiNo }}>{profile?.gakusekiNo}</TextM>
          <Separator size={pixel(15)} />
          <View style={{ ...styles.row }}>
            <TextL style={{ ...styles.nameCol }} numberOfLines={3}>
              {profile?.name}
            </TextL>
            <TextM style={{ ...styles.sanCol }}>さん</TextM>
          </View>
        </>
      )}
      <Separator size={pixel(40)} />
    </View>
  );
};
