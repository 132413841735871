import {
  addDays,
  compareAsc,
  compareDesc,
  differenceInYears,
  format,
  parse,
  set,
  startOfWeek
} from 'date-fns';
import ja from 'date-fns/locale/ja';

export const localizedFormat: typeof format = (date, dateFormat, options) => {
  const d = format(date, dateFormat, {
    locale: ja,
    ...options
  });

  return d;
};

export const getYear = (date?: Date) => {
  if (!date) {
    return;
  }

  return localizedFormat(date, 'Yo');
};

export const getDateWithoutYear = (
  date: Date,
  options?: { showDayOfWeek: boolean }
) => {
  const format = `MMMdo${options?.showDayOfWeek ? '(EEEEE)' : ''}`;
  const result = localizedFormat(date, format);

  return result;
};

export const convertTimeStringToDate = ({
  timeString,
  selectedDate = new Date()
}: {
  timeString: string;
  selectedDate?: Date;
}) => {
  const timeParts = timeString.split(':');

  // return in milliseconds
  const ms =
    timeParts.reduce((acc, curr, index) => {
      const currentNumber = Number(curr);
      if (isNaN(currentNumber)) return acc;

      return (acc += currentNumber * Math.pow(60, timeParts.length - index));
    }, 0) * 1000;

  // set to midnight
  selectedDate.setHours(0, 0, 0, 0);

  return new Date(Number(selectedDate) + ms);
};

export const getTime = (date: Date) => {
  return localizedFormat(date, 'HH:mm');
};

export const compareDatesDesc = (date1: Date, date2: Date) => {
  const date1WithoutTime = new Date(date1).setHours(0, 0, 0, 0);
  const date2WithoutTime = new Date(date2).setHours(0, 0, 0, 0);

  const result = compareDesc(date1WithoutTime, date2WithoutTime);

  return result;
};

export const shortWeekDaysArray = () => {
  const firstDOW = startOfWeek(new Date(), {
    weekStartsOn: 1
  });

  return Array.from(Array(5)).map((_e, i) => ({
    day: format(addDays(firstDOW, i), 'EEE'),
    localizedDay: localizedFormat(addDays(firstDOW, i), 'EEE')
  }));
};

export const setTime = (date: Date, hours: number, minutes: number) => {
  return set(date, {
    hours,
    minutes
  });
};

export const getDateTimeObjectFromDateAndTimeString = (
  date: Date,
  time: string
) => {
  const copiedDate = new Date(date.getTime());
  const timeOffsetInMS = copiedDate.getTimezoneOffset() * 60000;
  copiedDate.setTime(copiedDate.getTime() - timeOffsetInMS);
  const dateString = format(copiedDate, 'yyyy-MM-dd', { locale: ja });
  const _date = parse(
    `${dateString} ${time}:00`,
    'yyyy-MM-dd HH:mm:ss',
    new Date(),
    { locale: ja }
  );
  return _date;
};

type Era = {
  name: string;
  start: Date;
};

const eras: Era[] = [
  { name: '令和', start: parse('2019-05-01', 'yyyy-MM-dd', new Date()) },
  { name: '平成', start: parse('1989-01-08', 'yyyy-MM-dd', new Date()) },
  { name: '昭和', start: parse('1926-12-25', 'yyyy-MM-dd', new Date()) },
  { name: '大正', start: parse('1912-07-30', 'yyyy-MM-dd', new Date()) },
  { name: '明治', start: parse('1868-01-25', 'yyyy-MM-dd', new Date()) }
];

export const convertToJapaneseEra = (date: Date): string => {
  for (const era of eras) {
    if (compareAsc(date, era.start) >= 0) {
      const year = date.getFullYear() - era.start.getFullYear() + 1;
      const eraYear = year === 1 ? '元' : year.toString();
      return `${era.name}${eraYear}年${
        date.getMonth() + 1
      }月${date.getDate()}日`;
    }
  }
  throw new Error('和暦の範囲外の日付です。');
};

export const calculateAge = (birthDate: Date): number | string => {
  // 現在の日付を取得（ローカルタイムゾーンを使用）
  const now = new Date();

  // 不正な日付（未来の日付）が入力された場合のエラー処理
  if (birthDate > now) {
    return '不正な日付が入力されました。';
  }

  /**
   * 生まれた日付の1日前を取得
   * - 参議院法制局 「４月１日生まれの子どもは早生まれ？」 https://houseikyoku.sangiin.go.jp/column/column011.htm (2024.4.3時点)
   * > 生まれた日と同じ月の同じ日に誕生日を祝う日常生活の感覚からは、令和２（2020）年の４月１日に生まれた子どもは、令和８（2026）年の４月１日に満６歳になるようにも思われます。
   * > しかし、年齢計算ニ関スル法律という法律があって、その第１項には「年齢ハ出生ノ日ヨリ之ヲ起算ス」と規定されています。つまり、生まれた時刻が何時かを問わず、
   * > その生まれた日を第１日目として年齢を計算することになっているのです。そうすると、令和２（2020）年４月１日生まれの子どもは、
   * > 令和８（2026）年３月31日限りをもって満６歳になり、翌日の４月１日から小学校に入学するため、「早生まれ」ということになります。
   */
  const birthDateMinusOneDay = new Date(
    birthDate.getTime() - 1000 * 60 * 60 * 24
  );

  // 現在の年から生まれた年を引く
  const age = differenceInYears(now, birthDateMinusOneDay);
  return age;
};

export const getStartOfWeek = (date: Date) => {
  return startOfWeek(date, { weekStartsOn: 1 });
};
