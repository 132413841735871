import { useIsFocused } from '@react-navigation/native';
import React, { useEffect, useMemo } from 'react';
import { FlatList, View } from 'react-native';

import { SubNaviScreen } from './SubNavi.screen';
import { NaviMenuItem } from './components/NaviMenuItem.component';

import { Header } from '@/components/atomic/header';
import { List } from '@/components/atomic/list';
import { Loader } from '@/components/atomic/loader/Loader.atomic';
import { NavigationView } from '@/components/atomic/navigation-view';
import { Screen } from '@/components/atomic/screen';
import { Separator } from '@/components/atomic/separator';
import { useDesign } from '@/hooks/useDesign';
import { useLoading } from '@/hooks/useLoading';
import { NaviStackScreenProps } from '@/navigation/types';
import {
  getNaviMenu,
  selectNavigationMenuRootItems,
  useAppDispatch,
  useAppSelector
} from '@/store';
import { createStyles, isMobile, platformSpecificStyle } from '@/utils';

const { getStyle } = createStyles(({ pixel }) => ({
  body: {
    flex: 1,
    ...platformSpecificStyle({
      native: {
        alignItems: 'center'
      },
      default: {
        width: '100%',
        alignSelf: 'center',
        flexDirection: 'row'
      }
    })
  },
  activeNavigationView: {
    paddingLeft: pixel(40)
  },
  menu: {
    ...platformSpecificStyle({
      native: {},
      default: {
        width: pixel(200),
        flex: 1
      }
    })
  },
  menuColumnWrapper: {
    ...platformSpecificStyle({
      native: {
        marginBottom: pixel(30)
      },
      default: {}
    })
  },
  loader: {
    flex: 1
  }
}));

const COLUMNS = isMobile ? 2 : 1;

export const NaviScreen = ({
  navigation,
  route
}: NaviStackScreenProps<'Navi'>) => {
  const dispatch = useAppDispatch();
  const design = useDesign();
  const { pixel, colors } = design;
  const styles = getStyle(design);
  const isFocused = useIsFocused();

  const navigationMenuRootItems = useAppSelector(selectNavigationMenuRootItems);

  const activeNode = useMemo(() => {
    return navigationMenuRootItems.find(
      (node) => node.id === route.params?.rootId
    );
  }, [navigationMenuRootItems, route]);

  const { isLoading, refresh } = useLoading(() => {
    return [dispatch(getNaviMenu())];
  });

  // fetch resource when component did mount
  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    if (!activeNode && navigationMenuRootItems.length && isFocused) {
      navigation.navigate('Navi', { rootId: navigationMenuRootItems[0].id });
    }
  }, [activeNode, navigationMenuRootItems]);

  return (
    <Screen>
      <Header title="大学ナビ" />
      {isLoading ? (
        <Loader
          style={{ ...styles.loader }}
          color={colors.secondary}
          size="large"
        />
      ) : (
        <>
          {navigationMenuRootItems.length > 0 && (
            <View style={{ ...styles.body }}>
              <FlatList
                style={{ ...styles.menu }}
                keyExtractor={(node) => `${node.id}`}
                columnWrapperStyle={
                  COLUMNS > 1
                    ? {
                        ...styles.menuColumnWrapper
                      }
                    : undefined
                }
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                numColumns={COLUMNS}
                data={navigationMenuRootItems}
                renderItem={({ item: node, index }) => {
                  const isActive = !isMobile && activeNode?.id === node.id;

                  return (
                    <React.Fragment key={node.id}>
                      <NavigationView
                        isActive={isActive}
                        style={{
                          ...(isActive && styles.activeNavigationView)
                        }}
                      >
                        <List
                          isActive={isActive}
                          rows={[
                            {
                              key: node.title,
                              onPress: () => {
                                navigation.navigate(
                                  isMobile ? 'SubNavi' : 'Navi',
                                  {
                                    rootId: node.id
                                  }
                                );
                              }
                            }
                          ]}
                        >
                          <NaviMenuItem
                            isActive={isActive}
                            title={node.title}
                            iconSrc={{
                              uri: node.imageUrl
                            }}
                          />
                        </List>
                      </NavigationView>
                      {COLUMNS > 1 && index % COLUMNS !== COLUMNS - 1 && (
                        <Separator size={pixel(30)} vertical />
                      )}

                      {COLUMNS === 1 && (
                        <NavigationView>
                          <Separator
                            size={
                              index < navigationMenuRootItems.length - 1
                                ? pixel(20)
                                : pixel(80)
                            }
                          />
                        </NavigationView>
                      )}
                    </React.Fragment>
                  );
                }}
              />
              {!isMobile && (
                <SubNaviScreen {...{ navigation, route, isMobile }} />
              )}
            </View>
          )}
        </>
      )}
    </Screen>
  );
};
