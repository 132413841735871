import { addWeeks } from 'date-fns';
import { useCallback } from 'react';
import { TouchableOpacity, View } from 'react-native';

import { DateSelectorButton } from '@/components/atomic/button';
import { Separator } from '@/components/atomic/separator';
import { TextL, TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import {
  createStyles,
  getDateWithoutYear,
  getStartOfWeek,
  getYear,
  isMobile,
  platformSpecificStyle
} from '@/utils';

interface Props {
  selectedWeek: Date;
  setSelectedWeek: (date: Date) => void;
  onPressDate?: () => void;
}

const { getStyle } = createStyles(({ colors }) => ({
  container: {
    width: '100%',
    ...platformSpecificStyle({
      native: {},
      default: {
        borderBottomColor: colors.icon.primaryDisabled,
        borderBottomWidth: 1
      }
    })
  },
  row: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row'
  },
  firstRow: {
    justifyContent: 'center'
  },
  secondRow: {
    justifyContent: 'space-between'
  },
  selectedWeek: {
    fontWeight: 'bold'
  }
}));

export const PeriodSelector: React.FC<Props> = ({
  selectedWeek,
  setSelectedWeek,
  onPressDate
}) => {
  const design = useDesign();
  const styles = getStyle(design);
  const { pixel } = design;

  const addWeekToCurrentDate = useCallback(
    (signedStepSize: number) => {
      const newSelectedDate = addWeeks(
        getStartOfWeek(selectedWeek),
        signedStepSize
      );
      setSelectedWeek(newSelectedDate);
    },
    [selectedWeek, setSelectedWeek]
  );

  return (
    <View style={{ ...styles.container }}>
      <View style={{ ...styles.row, ...styles.firstRow }}>
        <TextM>{getYear(selectedWeek)}</TextM>
      </View>
      <View style={{ ...styles.row, ...styles.secondRow }}>
        <DateSelectorButton
          onPress={() => addWeekToCurrentDate(-1)}
          labelDate="前の週"
        />
        <TouchableOpacity onPress={onPressDate}>
          <TextL style={{ ...styles.selectedWeek }}>
            {getDateWithoutYear(getStartOfWeek(selectedWeek))}の週
          </TextL>
        </TouchableOpacity>
        <DateSelectorButton
          onPress={() => addWeekToCurrentDate(1)}
          labelDate="次の週"
          direction="right"
        />
      </View>
      {!isMobile && <Separator size={pixel(10)} />}
    </View>
  );
};
