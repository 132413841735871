import DeviceInfo from 'react-native-device-info';

import { api } from '..';

import {
  NotificationPreference,
  Profile
} from '@/interfaces/Account.interface';
import { UserProfile } from '@/interfaces/IdCard.interface';

export const fetchMyAccount = async () => {
  const { data } = await api.get<Profile>('/account/me');

  return data;
};

export const updateDeviceInfo = async ({
  deviceOs,
  deviceInfo,
  deviceId,
  bluetoothCapable,
  nfcCapable
}: {
  deviceOs: string;
  deviceInfo: string | null;
  deviceId: string;
  bluetoothCapable?: boolean;
  nfcCapable: boolean;
}) => {
  const versionStr = await DeviceInfo.getVersion();
  await api.post('/account/updateToken', {
    deviceOs,
    deviceInfo,
    deviceId,
    bluetoothCapable,
    appVersion: versionStr,
    nfcCapable
  });
};
export const updateDeviceToken = async ({
  fcmDeviceToken,
  deviceOs,
  deviceInfo,
  deviceId,
  bluetoothCapable,
  nfcCapable
}: {
  fcmDeviceToken: string;
  deviceOs: string;
  deviceInfo: string | null;
  deviceId?: string;
  bluetoothCapable?: boolean;
  nfcCapable?: boolean;
}) => {
  console.log('updateDeviceToken called');
  await api.post('/account/updateToken', {
    fcmDeviceToken,
    deviceOs,
    deviceInfo,
    deviceId,
    bluetoothCapable,
    nfcCapable
  });
};

export const fetchNotificationPreference = async () => {
  const { data } = await api.get<NotificationPreference>(
    'account/notificationPreference'
  );

  return data;
};

export const patchNotificationPreferences = async (
  preferences: NotificationPreference
) => {
  await api.patch('account/notificationPreference', preferences);
};

export const fetchMeInfo = async () => {
  const { data } = await api.get<UserProfile>('/account/me');

  return data;
};
export const fetchMeProfileImage = async () => {
  try {
    const { data } = await api.get('/account/me/profileImage');
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const fetchAccountInfo = async (federationId: string) => {
  const { data } = await api.get<UserProfile>(`/account/info/${federationId}`);

  return data;
};

export const fetchAccountProfileImage = async (federationId: string) => {
  try {
    const { data } = await api.get<string>(
      `/account/info/${federationId}/profileImage`
    );
    return `data:image/jpeg;base64,${data}`;
  } catch (e) {
    console.error(e);
  }
};
