import IdCardUniversityStamp from 'assets/images/icons/id-card/id-card-university-stamp.png';
import React from 'react';
import { View } from 'react-native';

import { Image } from '@/components/atomic/Image/Image.atomic';
import { TextL } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { UniversityInfo } from '@/interfaces';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  fixed?: boolean;
  universityInfo?: UniversityInfo;
}

const { getStyle } = createStyles(
  ({ pixel, safeArea, windowDimensions, isLandscape }) => ({
    idCardFooterContainer: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    idCardFooterContainerFixed: {
      position: 'absolute',
      ...platformSpecificStyle({
        ios: {
          bottom: safeArea.bottom + pixel(10),
          left: 0
        },
        android: {
          bottom:
            safeArea.bottom +
            (windowDimensions.height > 800 ? pixel(30) : pixel(15)),
          left: 0
        },
        tablet: {
          bottom:
            safeArea.bottom +
            (isLandscape
              ? windowDimensions.height < 768
                ? pixel(20) / 2
                : pixel(40) / 2
              : pixel(160)),
          left: 0
        },
        default: {
          bottom: pixel(50),
          left: 0
        }
      })
    },
    idCardFooterCertificationTextContainer: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      ...platformSpecificStyle({
        native: {
          marginBottom: pixel(20)
        },
        tablet: {
          marginBottom: pixel(20) / 2
        },
        default: {
          marginBottom: pixel(20) / 2
        }
      })
    },
    idCardFooterContents: {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      ...platformSpecificStyle({
        native: {
          marginTop: pixel(20),
          paddingHorizontal: pixel(40)
        },
        tablet: {
          marginTop: pixel(20) / 2,
          paddingHorizontal: pixel(40) / 2
        },
        default: {
          marginTop: pixel(20) / 2,
          paddingHorizontal: pixel(40) / 2
        }
      })
    },
    idCardContentsRow: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      ...platformSpecificStyle({
        native: {
          marginBottom: pixel(8),
          width: '100%'
        },
        tablet: {
          marginBottom: pixel(8) / 2,
          width: '50%'
        },
        default: {
          marginBottom: pixel(8) / 2,
          width: '50%'
        }
      })
    },
    idCardContentsRowBottomPresidentNameAndStamp: {
      position: 'relative',
      flexDirection: 'row',
      marginBottom: pixel(8),
      alignItems: 'center',
      justifyContent: 'center',
      ...platformSpecificStyle({
        native: {
          height: pixel(100)
        },
        tablet: {
          height: pixel(100) / 2
        },
        default: {
          height: pixel(100) / 2
        }
      })
    },
    idCardFooterStampAbsoluteContainer: {
      position: 'absolute',
      ...platformSpecificStyle({
        native: { right: -pixel(85) },
        tablet: {
          right: -pixel(85) / 2
        },
        default: { right: -pixel(85) / 2 }
      })
    },
    idCardContentsCenteringRow: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    },
    idCardCertificationText: {
      color: '#000',
      textAlign: 'center',
      ...platformSpecificStyle({
        native: {
          fontSize: pixel(24)
        },
        tablet: {
          fontSize: pixel(24) / 2
        },
        default: {
          fontSize: pixel(24) / 2
        }
      })
    },
    idCardContentsRowAddress: {
      color: '#000',
      textAlign: 'left',
      ...platformSpecificStyle({
        native: {
          fontSize: pixel(22)
        },
        tablet: {
          fontSize: pixel(22) / 2
        },
        default: {
          fontSize: pixel(22) / 2
        }
      })
    },
    idCardContentsRowPresidentTitle: {
      color: '#000',
      overflow: 'visible',
      ...platformSpecificStyle({
        native: {
          fontSize: pixel(30)
        },
        tablet: {
          fontSize: pixel(30) / 2
        },
        default: {
          fontSize: pixel(30) / 2
        }
      })
    },
    idCardContentsRowPresidentName: {
      color: '#000',
      fontWeight: 'bold',
      ...platformSpecificStyle({
        native: {
          fontSize: pixel(30)
        },
        tablet: {
          fontSize: pixel(30) / 2
        },
        default: {
          fontSize: pixel(30) / 2
        }
      })
    },
    certStamp: {
      ...platformSpecificStyle({
        native: { width: pixel(100) },
        tablet: {
          width: pixel(100) / 2
        },
        default: { width: pixel(100) / 2 }
      })
    },
    separator: {
      width: '90%',
      borderBottomWidth: 1,
      borderBottomColor: '#CCCCCC'
    }
  })
);

export const IdCardFooter: React.FC<Props> = ({
  fixed = true,
  universityInfo
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  /* eslint-disable no-irregular-whitespace */
  return (
    <View
      style={{
        ...styles.idCardFooterContainer,
        ...(fixed && { ...styles.idCardFooterContainerFixed })
      }}
    >
      <View style={{ ...styles.idCardFooterCertificationTextContainer }}>
        <View style={{ ...styles.idCardContentsCenteringRow }}>
          <TextL style={{ ...styles.idCardCertificationText }}>
            上記の者は、本学の学生であることを証明する。
          </TextL>
        </View>
      </View>
      <View style={{ ...styles.separator }} />
      <View style={{ ...styles.idCardFooterContents }}>
        <View style={{ ...styles.idCardContentsRow }}>
          <TextL
            style={{ ...styles.idCardContentsRowAddress }}
          >{`${universityInfo?.postNo}　${universityInfo?.address}`}</TextL>
        </View>
        <View style={{ ...styles.idCardContentsRow }}>
          <TextL
            style={{ ...styles.idCardContentsRowAddress }}
          >{`TEL：${universityInfo?.tel}`}</TextL>
        </View>
        <View
          style={{ ...styles.idCardContentsRowBottomPresidentNameAndStamp }}
        >
          <TextL
            numberOfLines={1}
            lineBreakMode="tail"
            style={{ ...styles.idCardContentsRowPresidentTitle }}
          >
            学長
          </TextL>
          <TextL style={{ ...styles.idCardContentsRowPresidentName }}>
            {`　${universityInfo?.president}`}
          </TextL>
          <View style={{ ...styles.idCardFooterStampAbsoluteContainer }}>
            <Image
              style={{ ...styles.certStamp }}
              size={{ width: styles.certStamp.width }}
              src={IdCardUniversityStamp}
            />
          </View>
        </View>
      </View>
    </View>
    /* eslint-enable no-irregular-whitespace */
  );
};
