import { useCallback, useEffect, useMemo, useState } from 'react';
import { FlatList, Platform, View } from 'react-native';

import { ReadProfileCard } from './components/qr-code-reader';

import { exportQrHistory } from '@/api/calls/qr.api';
import { OptionButton } from '@/components/atomic/button';
import { ConfirmDialog } from '@/components/atomic/dialog';
import { Loader } from '@/components/atomic/loader';
import { TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { QrCodeRecord } from '@/interfaces';
import {
  deleteAllQrHistory,
  getReadQrHistory,
  useAppDispatch,
  useAppSelector
} from '@/store';
import { CsvExportIcon, DeleteAllIcon } from '@/svgs';
import { createStyles } from '@/utils';

const { getStyle } = createStyles(({ pixel }) => ({
  contentContainer: {
    flex: 1,
    width: '100%',
    maxWidth: pixel(670),
    justifyContent: 'center',
    alignItems: 'center'
  },
  profileList: {
    flex: 1,
    width: '100%'
  },
  card: {
    marginBottom: 6
  },
  loaderBg: {
    width: 42,
    height: 42,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 21
  },
  buttonsWrapper: {
    marginBottom: pixel(10),
    marginTop: pixel(-10),
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end'
  },
  noDataMessage: {
    textAlign: 'center',
    fontSize: pixel(28),
    lineHeight: pixel(44),
    marginTop: pixel(90)
  }
}));

export const QrReadHistoryScreen: React.FC = () => {
  const design = useDesign();
  const styles = getStyle(design);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [reachedEndOfList, setReachedEndOfList] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isMailModalVisible, setIsMailModalVisible] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const { profiles, profileImages, lastReadPage } = useAppSelector(
    (state) => state.qrCode
  );
  const { email } = useAppSelector((state) => state.account.profile);

  const fetchProfiles = useCallback(
    async (page: number) => {
      const { payload } = await dispatch(getReadQrHistory({ page }));

      if (payload && !('error' in payload)) {
        if (!payload.histories.length) {
          setReachedEndOfList(true);
        }

        return { readCount: payload.histories.length };
      }
    },
    [profileImages]
  );

  const onEndReachedHandler = useCallback(() => {
    if (reachedEndOfList) return;

    fetchProfiles(lastReadPage + 1);
  }, [lastReadPage, reachedEndOfList]);

  const handleCsvExport = async () => {
    try {
      setIsModalLoading(true);

      const res = await exportQrHistory();

      if (res.status !== 'success') {
        throw new Error('CSV出力に失敗しました!');
      }
    } catch (error) {
      console.log('error');
    } finally {
      setIsModalLoading(false);
      setIsMailModalVisible(false);
    }
  };

  const handleDeleteAllHistory = async () => {
    try {
      setIsModalLoading(true);

      const { payload, meta } = await dispatch(deleteAllQrHistory());

      if (meta.requestStatus === 'rejected' || !payload) {
        throw new Error('CSV出力に失敗しました!');
      }

      if ('error' in payload) {
        throw new Error(String(payload.error));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsModalLoading(false);
      setIsDeleteModalVisible(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (!profiles.length || !lastReadPage) {
        setIsLoading(true);
        await fetchProfiles(lastReadPage);
        setIsLoading(false);
      }
    })();
  }, []);

  const LoaderIcon =
    Platform.OS === 'ios' ? (
      <Loader color="#444" size="large" />
    ) : (
      <View style={{ ...styles.loaderBg }}>
        <Loader color="#444" size={25} />
      </View>
    );

  const studentProfiles = useMemo(() => {
    return profiles.filter((item): item is Required<QrCodeRecord> =>
      Boolean(item.profile?.isStudent)
    );
  }, [profiles]);

  return (
    <View style={styles.contentContainer}>
      {isLoading && LoaderIcon}
      {!isLoading && (
        <>
          <View style={styles.buttonsWrapper}>
            <OptionButton
              text="CSV書き出し"
              icon={CsvExportIcon}
              disabled={!profiles || !profiles.length}
              onPress={() => {
                setIsMailModalVisible(true);
              }}
            />
            <OptionButton
              text="クリア"
              icon={DeleteAllIcon}
              disabled={!profiles || !profiles.length}
              onPress={() => {
                setIsDeleteModalVisible(true);
              }}
            />
          </View>
          {!profiles?.length && (
            <TextM style={styles.noDataMessage}>
              {`表示できるデータがありません。\n学生証読み取りタブをタップして、 \n学生証を読み取ってください。`}
            </TextM>
          )}
          <FlatList
            data={studentProfiles}
            renderItem={({ item }) => (
              <ReadProfileCard
                profile={item.profile}
                image={profileImages[item.profile.gakusekiNo]}
                federationId={item.profile.gakusekiNo}
                readAt={item.readAt}
                style={styles.card}
              />
            )}
            keyExtractor={(item, index) => `card-${item.id}-${index}`}
            onEndReached={onEndReachedHandler}
            onEndReachedThreshold={0.5}
            style={styles.profileList}
          />
        </>
      )}
      <ConfirmDialog
        isModalOpen={isDeleteModalVisible}
        isLoading={isModalLoading}
        title="学生証の読み取り一覧をクリアしますか？"
        description={`読み取った学生証の一覧をクリアします。よろしいですか？\n一度クリアすると元に戻せまん。\n\n※CSVに書き出してからクリアすることをお勧めします。`}
        confirmBtnText="すべて削除"
        onPressClose={() => {
          setIsDeleteModalVisible(false);
        }}
        onPressConfirm={handleDeleteAllHistory}
      />
      <ConfirmDialog
        isModalOpen={isMailModalVisible}
        isLoading={isModalLoading}
        title="学生証一覧（CSV）をメールで送信します"
        description={`CSVファイルに変換した学生証一覧を ”${email}”宛に送信します。\nよろしいですか？\n\n※書き出したCSVファイルは端末には保存されません。`}
        confirmBtnText="メール送信"
        confirmBtnStyle={{ color: '#0066CC' }}
        onPressClose={() => {
          setIsMailModalVisible(false);
        }}
        onPressConfirm={handleCsvExport}
      />
    </View>
  );
};
