import {
  AFTERNOON_END_HOUR,
  AFTERNOON_START_HOUR,
  MORNING_START_HOUR,
  PARTS_OF_DAY
} from '@/constants/WelcomeMessage';

export const getWelcomeMessage = (date: Date) => {
  const hour = date.getHours();

  if (hour >= MORNING_START_HOUR && hour < AFTERNOON_START_HOUR) {
    return PARTS_OF_DAY.MORNING;
  }
  if (hour >= AFTERNOON_START_HOUR && hour < AFTERNOON_END_HOUR) {
    return PARTS_OF_DAY.AFTERNOON;
  }
  return PARTS_OF_DAY.EVENING;
};
