import { View } from 'react-native';

import { TextL, TextM } from '../../text';

import { useDesign } from '@/hooks/useDesign';
import { useAppSelector } from '@/store';
import { createStyles, isMobile, platformSpecificStyle } from '@/utils';
import { getWelcomeMessage } from '@/utils/welcome-message.util';

const { getStyle } = createStyles(({ pixel, colors }) => ({
  horizontalTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {},
      default: {
        marginTop: pixel(5)
      }
    })
  },
  subtitleHighlight: {
    fontWeight: 'bold',
    ...platformSpecificStyle({
      native: {},
      default: {
        fontSize: pixel(20)
      }
    })
  },
  text: {
    ...platformSpecificStyle({
      native: {},
      default: {
        color: colors.textPrimaryInverted
      }
    })
  }
}));

export const WelcomeMessage: React.FC = () => {
  const design = useDesign();
  const styles = getStyle(design);
  const userName = useAppSelector((state) => state.account.profile.name);
  const welcomeMessage = getWelcomeMessage(new Date());

  const UserNameText = isMobile ? TextL : TextM;

  return (
    <>
      <TextM style={{ ...styles.text }}>{welcomeMessage}</TextM>
      <View style={{ ...styles.horizontalTextContainer }}>
        <UserNameText style={{ ...styles.text, ...styles.subtitleHighlight }}>
          {userName}
        </UserNameText>
        <TextM style={{ ...styles.text }}> さん</TextM>
      </View>
    </>
  );
};
