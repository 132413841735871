import { parse as parseUrl, useURL } from 'expo-linking';
import { useEffect } from 'react';

import { setTokens, useAppDispatch } from '@/store';

export const CallbackScreen: React.FC = () => {
  const url = useURL();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (url) {
      const parsedUrl = parseUrl(url);
      const queryParams = parsedUrl.queryParams;

      if (queryParams) {
        const { accessToken, refreshToken } = queryParams;

        if (
          typeof accessToken === 'string' &&
          typeof refreshToken === 'string'
        ) {
          dispatch(
            setTokens({
              accessToken,
              refreshToken
            })
          );
        }
      }
    }
  }, [url]);
  return null;
};
