import QrError from 'assets/images/icons/id-card/qr-error.png';
import React from 'react';
import { Modal, Platform, SafeAreaView, View } from 'react-native';

import { Loader } from '@/components/atomic/loader';
import { TextM } from '@/components/atomic/text';
import { QrPageBody } from '@/components/qr/QrPageBody.component';
import { QrPageFooter } from '@/components/qr/QrPageFooter.component';
import { QrPageHeader } from '@/components/qr/QrPageHeader.component';
import { useQrCode } from '@/hooks/qr/useQrCode';
import { useDesign } from '@/hooks/useDesign';
import { useAppSelector } from '@/store';
import { createStyles } from '@/utils';

const { getStyle } = createStyles(({ pixel }) => ({
  qrModalBackground: {
    height: '100%',
    backgroundColor: 'fff',
    flexDirection: 'column',
    alignItems: 'center'
  },
  qrModalContainer: {
    flex: 1,
    width: '100%',
    alignItems: 'center',
    marginTop: pixel(20)
  },
  userInfoWrapper: {
    width: '100%'
  },
  userInfo: {
    marginTop: pixel(40),
    marginHorizontal: pixel(70),
    paddingHorizontal: pixel(70),
    paddingVertical: pixel(20),
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#CCCCCC',
    flexDirection: 'row'
  },
  userInfoText: {
    color: '#000'
  },
  userName: {
    color: '#000',
    fontWeight: 'bold',
    fontSize: pixel(32)
  },
  alignRight: {
    textAlign: 'right',
    marginRight: pixel(20)
  },
  loaderBg: {
    width: 42,
    height: 42,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff'
  }
}));

interface Props {
  isModalOpen: boolean;
  onModalClose: () => void;
}

export const TeacherQrCodeModal: React.FC<Props> = ({
  isModalOpen,
  onModalClose
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  const profile = useAppSelector((state) => state.account.profile);

  const {
    qrImageData,
    secondsRemaining,
    secondsRemainingForDisplay,
    timelimitAlerting,
    errorCode,
    onRetryCallback,
    isLoading
  } = useQrCode({ enabled: true });

  return (
    <Modal animationType="fade" statusBarTranslucent visible={isModalOpen}>
      <View style={{ ...styles.qrModalBackground }}>
        <SafeAreaView style={{ ...styles.qrModalContainer }}>
          <QrPageHeader />
          <View style={styles.userInfoWrapper}>
            <View style={styles.userInfo}>
              <View>
                <TextM style={{ ...styles.userInfoText, ...styles.alignRight }}>
                  教員番号
                </TextM>
                <TextM style={{ ...styles.userInfoText, ...styles.alignRight }}>
                  氏名
                </TextM>
              </View>
              <View>
                <TextM style={styles.userInfoText}>{profile?.gakusekiNo}</TextM>
                <TextM style={styles.userName}>{profile?.name}</TextM>
              </View>
            </View>
          </View>
          {isLoading && !qrImageData && !errorCode && (
            <View style={Platform.OS === 'android' && styles.loaderBg}>
              <Loader color="#444" size="large" />
            </View>
          )}
          <QrPageBody
            errorCode={errorCode}
            qrImageData={qrImageData || QrError}
            onRetryCallback={onRetryCallback}
            secondsRemaining={secondsRemaining}
            secondsRemainingForDisplay={secondsRemainingForDisplay}
            timelimitAlerting={timelimitAlerting}
            forStudent
          />
          <QrPageFooter onPressClose={onModalClose} />
        </SafeAreaView>
      </View>
    </Modal>
  );
};
