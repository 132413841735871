import React, { ReactElement } from 'react';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { useDesign } from '@/hooks/useDesign';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props<T extends string | number | symbol> {
  selectedFilterType: T;
  selectFilterType: (filterType: T) => void;
  filterTypesIconsMapping: Record<T, ReactElement>;
}

const { getStyle } = createStyles(({ colors, pixel }) => ({
  filterContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    ...platformSpecificStyle({
      android: {
        marginBottom: pixel(24)
      },
      ios: {
        marginBottom: pixel(32)
      },
      tablet: {
        marginBottom: pixel(12)
      },
      default: {
        marginBottom: pixel(32)
      }
    })
  },
  filterButtonsBackground: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    backgroundColor: colors.messageBackgroundRead,
    padding: pixel(8),

    ...platformSpecificStyle({
      native: {
        height: pixel(80),
        borderRadius: pixel(80)
      },
      default: {
        height: 40,
        borderRadius: 40,
        maxWidth: pixel(500)
      }
    })
  },
  filterButtonContainer: {
    flex: 1,
    ...platformSpecificStyle({
      native: {},
      default: {
        maxHeight: 40
      }
    })
  },
  filterButton: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: pixel(15),
    width: '100%',

    ...platformSpecificStyle({
      native: {
        height: '100%',
        borderRadius: pixel(36)
      },
      default: {
        height: pixel(30),
        borderRadius: pixel(18)
      }
    })
  },
  filterButtonSelected: {
    backgroundColor: '#fff'
  }
}));

export const TabFilter = <T extends string | number | symbol>({
  selectFilterType,
  selectedFilterType,
  filterTypesIconsMapping
}: Props<T>) => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <View style={{ ...styles.filterContainer }}>
      <View style={{ ...styles.filterButtonsBackground }}>
        {Object.entries<ReactElement>(filterTypesIconsMapping).map(
          ([filterType, element], i) => {
            return (
              <View
                key={`filter${i}${filterType}`}
                style={{ ...styles.filterButtonContainer }}
              >
                <TouchableOpacity
                  style={{
                    ...styles.filterButton,
                    ...(selectedFilterType === filterType &&
                      styles.filterButtonSelected)
                  }}
                  onPress={() => selectFilterType(filterType as T)}
                >
                  {element}
                </TouchableOpacity>
              </View>
            );
          }
        )}
      </View>
    </View>
  );
};
