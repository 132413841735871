import React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';

interface Props extends SvgProps {
  strokeColor?: string;
}

export const QRCodeIcon: React.FC<Props> = ({ color = '#fff', ...props }) => {
  return (
    <Svg width={56} height={56} viewBox="0 0 56 56" {...props}>
      <G data-name="Group 359">
        <Path data-name="Rectangle 504" fill="none" d="M0 0H56V56H0z" />
      </G>
      <Path
        data-name="Path 389"
        d="M31 45h-4.667v-4.667H31zm-4.667-16.333h-4.666v11.666h4.667zM45 24h-4.667v9.333H45zm-4.667-4.667h-4.666V24h4.667zm-28 4.667H7.667v4.667h4.667zm-4.666-4.667H3V24h4.667zM24 7.667h4.667V3H24zM6.5 6.5v7h7v-7zM17 17H3V3h14zM6.5 34.5v7h7v-7zM17 45H3V31h14zM34.5 6.5v7h7v-7zM45 17H31V3h14zm-4.667 23.333v-7H31V38h4.667v7H45v-4.667zM35.667 24h-9.334v4.667h9.333zm-9.333-4.667h-14V24H17v4.667h4.667V24h4.667zM28.667 17v-4.667H24V7.667h-4.667V17zM11.75 8.25h-3.5v3.5h3.5zm0 28h-3.5v3.5h3.5zm28-28h-3.5v3.5h3.5z"
        transform="translate(4 4)"
        fill={color}
      />
    </Svg>
  );
};
