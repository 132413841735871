import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchTimeTableList } from '@/api/calls/timetable.api';
import { Timetable } from '@/interfaces';

interface TimetableState {
  semester: Timetable;
}

const initialState: TimetableState = {
  semester: {}
};

export const getTimeTableList = createAsyncThunk(
  'timetable/getTimeTableList',
  () => {
    return fetchTimeTableList();
  }
);

export const timetableSlice = createSlice({
  name: 'timetable',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      getTimeTableList.fulfilled,
      (state, { payload }: { payload: Timetable }) => {
        state.semester = payload;
      }
    );
  }
});

export const timetableReducer = timetableSlice.reducer;
export const timetableAction = timetableSlice.actions;
