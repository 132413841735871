import { getTime } from './date.util';

import { TeacherCalendarClassesAndPeriod } from '@/interfaces/Teacher.interface';

type Period = {
  from: Date;
  to: Date;
  key: string;
};

export const getClassPeriod = (
  classItem: TeacherCalendarClassesAndPeriod,
  periods: Period[]
) => {
  const period = periods.find((periodItem) => {
    return getTime(periodItem.from) === getTime(classItem.period.from);
  });
  return period?.key;
};
