import * as React from 'react';
import { Path } from 'react-native-svg';

import { SvgProps, SvgWrapper } from '@/wrappers/svg';

interface Props extends SvgProps {
  background?: string;
}

export const OpenIcon: React.FC<Props> = ({ ...props }) => {
  return (
    <SvgWrapper viewBox={{ width: 26.12, height: 15.182 }} {...props}>
      <Path
        id="icn_chev"
        d="M.354,24.352l12-12-12-12"
        transform="translate(25.413 0.707) rotate(90)"
        fill="none"
        stroke="#4c4c4c"
        strokeMiterlimit="10"
        strokeWidth="3"
      />
    </SvgWrapper>
  );
};
