import { addBusinessDays } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { View } from 'react-native';

import { DateSelectorButton } from '@/components/atomic/button';
import { Separator } from '@/components/atomic/separator';
import { TextL, TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import {
  compareDatesDesc,
  createStyles,
  getDateWithoutYear,
  getYear,
  isMobile,
  platformSpecificStyle
} from '@/utils';

interface Props {
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
}

const { getStyle } = createStyles(({ colors }) => ({
  container: {
    width: '100%',
    ...platformSpecificStyle({
      native: {},
      default: {
        borderBottomColor: colors.icon.primaryDisabled,
        borderBottomWidth: 1
      }
    })
  },
  row: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row'
  },
  firstRow: {
    justifyContent: 'center'
  },
  secondRow: {
    justifyContent: 'space-between'
  },
  selectedDate: {
    fontWeight: 'bold'
  }
}));

export const DateSelector: React.FC<Props> = ({
  selectedDate,
  setSelectedDate
}) => {
  const design = useDesign();
  const styles = getStyle(design);
  const { pixel } = design;

  const addDaysToCurrentDate = useCallback(
    (signedStepSize: number) => {
      const newSelectedDate = addBusinessDays(selectedDate, signedStepSize);
      setSelectedDate(newSelectedDate);
    },
    [selectedDate, setSelectedDate]
  );

  const getNeighbourOfCurrentDate = useCallback(
    (signedStepSize: number) => {
      return getDateWithoutYear(addBusinessDays(selectedDate, signedStepSize));
    },
    [selectedDate]
  );

  const isPrevDayDisabled = () => {
    return compareDatesDesc(new Date(), addBusinessDays(selectedDate, -1)) < 0;
  };
  const isNextDayDisabled = () => {
    return (
      compareDatesDesc(
        addBusinessDays(selectedDate, 1),
        addBusinessDays(new Date(), 6)
      ) < 0
    );
  };

  const labels = useMemo(
    () => ({
      prevDay: getNeighbourOfCurrentDate(-1),
      nextDay: getNeighbourOfCurrentDate(1),
      isPrevDayDisabled: isPrevDayDisabled(),
      isNextDayDisabled: isNextDayDisabled()
    }),
    [
      selectedDate,
      getNeighbourOfCurrentDate,
      isPrevDayDisabled,
      isNextDayDisabled
    ]
  );

  return (
    <View style={{ ...styles.container }}>
      <View style={{ ...styles.row, ...styles.firstRow }}>
        <TextM>{getYear(selectedDate)}</TextM>
      </View>
      <View style={{ ...styles.row, ...styles.secondRow }}>
        <DateSelectorButton
          disabled={labels.isPrevDayDisabled}
          onPress={() => addDaysToCurrentDate(-1)}
          labelDate={labels.prevDay}
        />
        <TextL style={{ ...styles.selectedDate }}>
          {getDateWithoutYear(selectedDate, {
            showDayOfWeek: true
          })}
        </TextL>
        <DateSelectorButton
          disabled={labels.isNextDayDisabled}
          onPress={() => addDaysToCurrentDate(1)}
          labelDate={labels.nextDay}
          direction="right"
        />
      </View>
      {!isMobile && <Separator size={pixel(10)} />}
    </View>
  );
};
