import { DeviceType, modelName, osName } from 'expo-device';
import { createContext } from 'react';
import { Platform } from 'react-native';

interface DeviceInfo {
  deviceType: DeviceType;
  deviceOS: DeviceOS;
  isBrowser: boolean;
  devicePixelDensity: number;
  modelName: string | null;
}

export enum DeviceOS {
  MacOS = 'Mac OS',
  Windows = 'Windows',
  Android = 'Android',
  IOS = 'iOS',
  IPadOS = 'iPadOS'
}

export const DeviceInfoContext = createContext<DeviceInfo>({
  deviceType: DeviceType.UNKNOWN,
  deviceOS: osName as DeviceOS,
  modelName,
  isBrowser: Platform.OS === 'web',
  devicePixelDensity: 1
});
