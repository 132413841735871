import { createURL } from 'expo-linking';
import { useLastNotificationResponse } from 'expo-notifications';
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { Linking } from 'react-native';

import { updateDeviceInfo, updateDeviceToken } from '@/api/calls/account.api';
import { useDeviceUniqueId } from '@/hooks/useDeviceUniqueId';
import { useHardwareAvailability } from '@/hooks/useHardwareAvailability';
import { hasToken, useAppSelector } from '@/store';
import {
  getExpoPushToken,
  getPushNotificationPermission,
  isWeb
} from '@/utils';
import { DeviceInfoContext } from '@/wrappers/device-info';

export const NotificationContext = createContext({ deviceToken: '' });

export const PushNotificationWrapper: React.FC<PropsWithChildren> = ({
  children
}) => {
  const { deviceOS, modelName } = useContext(DeviceInfoContext);

  const notification = useLastNotificationResponse();
  const firstRender = useRef(true);
  const isLoggedIn = useAppSelector(hasToken);
  const [deviceToken, setDeviceToken] = useState('');

  const { deviceUniqueId } = useDeviceUniqueId();
  const { isNfcAvailable, isBluetoothAvailable } = useHardwareAvailability();

  useEffect(() => {
    if (firstRender.current && !isWeb) {
      getPushNotificationPermission();
      firstRender.current = false;
    }

    if (notification) {
      const url = createURL('/');
      Linking.openURL(`${url}/notification`);
    }
  }, [notification]);

  useEffect(() => {
    if (isLoggedIn && deviceUniqueId && !isWeb) {
      try {
        updateDeviceInfo({
          deviceOs: deviceOS,
          deviceInfo: modelName,
          deviceId: deviceUniqueId,
          bluetoothCapable: isBluetoothAvailable,
          nfcCapable: isNfcAvailable
        });
        getExpoPushToken().then((token) => {
          setDeviceToken(token);
          updateDeviceToken({
            deviceOs: deviceOS,
            fcmDeviceToken: token,
            deviceInfo: modelName,
            deviceId: deviceUniqueId,
            bluetoothCapable: isBluetoothAvailable,
            nfcCapable: isNfcAvailable
          });
        });
      } catch (e) {
        console.warn(e);
      }
    }
  }, [isLoggedIn, deviceUniqueId, isNfcAvailable, isBluetoothAvailable]);

  return (
    <NotificationContext.Provider value={{ deviceToken }}>
      {children}
    </NotificationContext.Provider>
  );
};
