import { useFocusEffect } from '@react-navigation/native';
import { addDays, format, isSameDay } from 'date-fns';
import ja from 'date-fns/locale/ja';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Platform, View } from 'react-native';

import { TeacherQrCodeFab, TeacherQrCodeModal } from './components';
import { PeriodSelector } from './components/PeriodSelector.component';
import {
  ListType,
  TeacherScheduleList
} from './components/TeacherScheduleList.component';

import { Screen } from '@/components/atomic/screen';
import { useDesign } from '@/hooks/useDesign';
import { useFocus } from '@/hooks/useFocus';
import { useLoading } from '@/hooks/useLoading';
import {
  TeacherCalendarClass,
  TeacherCalendarClassesAndPeriod
} from '@/interfaces/Teacher.interface';
import {
  TeacherHomeStackParamList,
  TeacherHomeStackScreenProps
} from '@/navigation/types';
import {
  getTeacherCalendar,
  selectPeriods,
  selectTeacherSchedule,
  useAppDispatch,
  useAppSelector
} from '@/store';
import { createStyles, getClassPeriod, getStartOfWeek } from '@/utils';

const { getStyle } = createStyles(() => ({
  eventsWithDateSelector: {
    flex: 1,
    width: '100%'
  }
}));

export const TeacherHomeScreen = ({
  navigation
}: TeacherHomeStackScreenProps<keyof TeacherHomeStackParamList>) => {
  const design = useDesign();
  const styles = getStyle(design);
  const dispatch = useAppDispatch();

  const [selectedWeek, setSelectedWeek] = useState<Date>(
    getStartOfWeek(new Date())
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const shouldScrollToSection = useRef(false);

  const periods = useAppSelector((state) => selectPeriods(state));
  const teacherSchedule = useAppSelector((state) =>
    selectTeacherSchedule(state, selectedWeek)
  );
  const isFetchingClassList = useAppSelector(
    (state) => state.teacherData.isLoading
  );

  const { isFocused, subscribe, unsubscribe } = useFocus();

  const { isLoading, refresh, isPtrLoading, pullToRefresh } = useLoading(
    (currentDate: Date) => {
      if (isLoading || isFetchingClassList) return [];

      return [
        dispatch(
          getTeacherCalendar({
            date: currentDate
          })
        )
      ];
    }
  );

  const periodEntries = Object.entries(periods).map(([key, value]) => {
    return {
      key,
      ...value
    };
  });

  const formatDate = (date: Date) => {
    const isToday = isSameDay(date, new Date());
    return `${format(date, 'yyyy/MM/dd (EEEEEE)', { locale: ja })}${
      isToday ? ' - 本日' : ''
    }`;
  };

  const schedule = teacherSchedule.map((item) => {
    return {
      title: formatDate(item.date),
      data: item.classes
    };
  });

  const listRef = useRef<ListType>(null);

  const scrollToSection = () => {
    setTimeout(() => {
      const todayIndex = teacherSchedule.findIndex((item) =>
        isSameDay(item.date, new Date())
      );

      listRef?.current?.scrollToLocation({
        sectionIndex: todayIndex !== -1 ? todayIndex : 0,
        itemIndex: 0,
        animated: true
      });
    }, 100);
  };

  const openAttendanceDetails = (
    classItem: TeacherCalendarClass,
    schedule: TeacherCalendarClassesAndPeriod,
    index: number
  ) => {
    navigation.navigate('ClassAttendanceScreen', {
      classId: classItem.classId,
      className: classItem.name,
      period: getClassPeriod(schedule, periodEntries),
      date: addDays(selectedWeek, index).toISOString()
    });
  };

  const setToCurrentWeek = () => {
    const startOfCurrentWeek = getStartOfWeek(new Date());

    if (isSameDay(startOfCurrentWeek, getStartOfWeek(selectedWeek))) return;

    setSelectedWeek(startOfCurrentWeek);
    shouldScrollToSection.current = true;
  };

  // subscribe to app state change when app is focused
  useFocusEffect(
    useCallback(() => {
      subscribe();

      return () => {
        unsubscribe();
      };
    }, [])
  );

  // fetch resource browser window or app is focused
  useEffect(() => {
    if (isFocused) {
      refresh(new Date());
    }
  }, [isFocused]);

  useEffect(() => {
    if (isLoading || isPtrLoading) return;
    refresh(selectedWeek);
  }, [selectedWeek]);

  useEffect(() => {
    if (!isLoading && shouldScrollToSection) {
      shouldScrollToSection.current = false;
      scrollToSection();
    }
  }, [isLoading]);

  return (
    <Screen
      isLoading={isLoading}
      pageFixedLayerChildren={
        Platform.OS !== 'web' && (
          <TeacherQrCodeFab onPressButton={() => setIsModalVisible(true)} />
        )
      }
    >
      <View style={{ ...styles.eventsWithDateSelector }}>
        <PeriodSelector
          selectedWeek={selectedWeek}
          setSelectedWeek={(date: Date) => {
            setSelectedWeek(date);
            shouldScrollToSection.current = true;
          }}
          onPressDate={setToCurrentWeek}
        />
        <TeacherScheduleList
          ref={listRef}
          isLoading={isLoading}
          schedule={schedule}
          periods={periodEntries}
          refreshing={isPtrLoading}
          onRefresh={() => pullToRefresh(selectedWeek)}
          onPressItem={openAttendanceDetails}
          scrollToSection={scrollToSection}
        />
      </View>
      <TeacherQrCodeModal
        isModalOpen={isModalVisible}
        onModalClose={() => setIsModalVisible(false)}
      />
    </Screen>
  );
};
