import React from 'react';
import { Text, TextProps } from 'react-native';

import { useDesign } from '@/hooks/useDesign';
import { createStyles, platformSpecificStyle } from '@/utils';

const { getStyle } = createStyles(({ colors, pixel }) => ({
  l: {
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(42)
      },
      default: {
        fontSize: pixel(24)
      }
    }),
    color: colors.textPrimary
  }
}));

export const TextL: React.FC<TextProps> = ({ children, ...props }) => {
  const design = useDesign();
  const styles = getStyle(design);
  return (
    <Text {...props} style={{ ...styles.l, ...(props.style as any) }}>
      {children}
    </Text>
  );
};
