import * as Device from 'expo-device';
import { Platform } from 'react-native';

import { DeviceOS } from '@/wrappers/device-info/';

export const isMobile =
  Platform.OS === 'android' ||
  [
    // Always treat Android as 'mobile'
    DeviceOS.Android,
    DeviceOS.IOS
  ].includes(Device.osName as DeviceOS);

export const isWeb = Platform.OS === 'web';

export const isTablet = Device.osName === DeviceOS.IPadOS;

export const platformSpecificStyle = <T>(
  specifics: Parameters<typeof Platform.select<T>>[0] & { default: T } & {
    tablet?: T;
  }
): T => {
  if (isMobile && Platform.OS === 'web') {
    const { android, ios, native } = specifics;
    return Platform.select<any>({
      web: {
        ...native,
        ...(Device.osName === DeviceOS.Android && android),
        ...(Device.osName === DeviceOS.IOS && ios)
      }
    });
  }
  // iPadはWebと同じ表示とする
  if (isTablet) {
    const { native, default: _default, tablet } = specifics;
    return Platform.select<any>({
      native: {
        ...native,
        ..._default,
        ...tablet
      }
    });
  }
  return Platform.select<T>(specifics);
};
