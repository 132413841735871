import {
  ParamListBase,
  RouteProp,
  useNavigation,
  useRoute
} from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';

import type { AppDispatch, RootState } from './store';

import { RootTabParamList, RootTabScreenProps } from '@/navigation/types';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppNavigation = (...args: Parameters<typeof useNavigation>) => {
  return useNavigation<
    RootTabScreenProps<keyof RootTabParamList>['navigation']
  >(...args);
};
export const useAppRoute = (...args: Parameters<typeof useNavigation>) => {
  return useRoute<RouteProp<ParamListBase, keyof RootTabParamList>>(...args);
};
