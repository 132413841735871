const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

export default {
  light: {
    background: {
      color1: '#de2332',
      color2: '#d74f50',
      color3: '#d07a6e'
    },
    button: {
      text: '#000',
      background: '#fff',
      borderSecondary: '#ffffff80',
      borderModal: '#e0e0e0'
    },
    floatingButton: {
      background: {
        color1: '#e60012',
        color2: '#e63c2e',
        color3: '#e6774a'
      },
      border: '#f0cccc'
    },
    icon: {
      primary: '#fff',
      primaryDisabled: '#ffffff33',
      primaryInverted: '#000',
      primaryInvertedDisabled: '#00000033',
      secondary: '#d9ad3e'
    },
    navigation: {
      background: '#fff',
      backgroundInverted: '#e60012',
      iconBackgroundActive: '#e60012',
      iconColor: '#e60012',
      iconColorActive: '#fff'
    },
    cardBackground: {
      primary: '#ffffff14',
      secondary: '#0000001f'
    },
    list: {
      label: '#333333',
      chevron: '#888888',
      openInNew: '#00000080'
    },
    primary: '#de2432',
    secondary: '#fff',
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
    textPrimary: '#fff',
    textPrimaryInverted: '#000',
    tint: tintColorLight,
    messageBackground: {
      body: '#ffffff1f',
      header: '#ffffff52'
    },
    messageBackgroundRead: '#E57373'
  },
  dark: {
    background: {
      color1: '#de2332',
      color2: '#d74f50',
      color3: '#d07a6e'
    },
    button: {
      text: '#fff',
      background: '#000',
      borderSecondary: '#ffffff80'
    },
    floatingButton: {
      background: {
        color1: '#e60012',
        color2: '#e63c2e',
        color3: '#e6774a'
      },
      border: '#f0cccc'
    },
    icon: {
      primary: '#fff',
      primaryDisabled: '#ffffff33',
      primaryInverted: '#000',
      primaryInvertedDisabled: '#00000033',
      secondary: '#d9ad3e'
    },
    navigation: {
      background: '#fff',
      iconBackgroundActive: '#e60012',
      iconColor: '#e60012',
      iconColorActive: '#fff'
    },
    cardBackground: {
      primary: '#ffffff29',
      secondary: '#0000001f'
    },
    list: {
      label: '#333333',
      chevron: '#888888',
      openInNew: '#00000080'
    },
    primary: '#000',
    secondary: '#fff',
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
    textPrimary: '#fff',
    textPrimaryInverted: '#000',
    tint: tintColorDark,
    messageBackground: {
      body: '#ffffff1f',
      header: '#ffffff52'
    }
  }
};
