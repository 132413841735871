import { StyleSheet, TouchableOpacity } from 'react-native';

import { RootStackScreenProps } from '../navigation/types';

import { Text } from '@/components/Themed';
import { Screen } from '@/components/atomic/screen';

export const NotFoundScreen = ({
  navigation
}: RootStackScreenProps<'NotFound'>) => {
  return (
    <Screen style={styles.container}>
      <Text style={styles.title}>This screen doesn't exist.</Text>
      <TouchableOpacity
        onPress={() => navigation.navigate(navigation.getState().routeNames[0])}
        style={styles.link}
      >
        <Text style={styles.linkText}>Go to home screen!</Text>
      </TouchableOpacity>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  link: {
    marginTop: 15,
    paddingVertical: 15
  },
  linkText: {
    fontSize: 14,
    color: '#2e78b7'
  }
});
