import { useCallback, useState } from 'react';
import { InteractionManager } from 'react-native';

export const useLoading = <T extends unknown[]>(
  onRefresh: (...args: T) => Promise<unknown>[],
  onPullToRefresh?: (...args: T) => Promise<unknown>[],
  onBeforeRefresh?: () => void | Promise<void>
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPtrLoading, setIsPtrLoading] = useState(false);

  const makeRefreshCallback = useCallback(
    (
      setLoading: (loading: boolean) => void,
      refreshCallback: (...args: T) => Promise<unknown>[]
    ) => {
      return async (...args: T) => {
        if (onBeforeRefresh) {
          await onBeforeRefresh();
        }

        // InteractionManager is responsible for delaying fetching logic after navigation animations
        // have finished
        InteractionManager.runAfterInteractions(async () => {
          // wait a cycle in event loop just to make sure sequencing is right
          setTimeout(async () => {
            const promises = refreshCallback(...args);
            if (!promises.length) {
              return;
            }

            setLoading(true);
            await Promise.allSettled(promises);
            setLoading(false);
          }, 0);
        });
      };
    },
    [onBeforeRefresh]
  );

  const refresh = makeRefreshCallback(setIsLoading, onRefresh);
  const pullToRefresh = makeRefreshCallback(
    setIsPtrLoading,
    onPullToRefresh ?? onRefresh
  );

  return { isLoading, isPtrLoading, refresh, pullToRefresh };
};
