import * as React from 'react';
import { Path } from 'react-native-svg';

import { SvgProps, SvgWrapper } from '@/wrappers/svg';

export const FilterGmailLabelIcon: React.FC<SvgProps> = ({
  color,
  ...props
}) => {
  return (
    <SvgWrapper viewBox={{ width: 45.848, height: 16.654 }} {...props}>
      <Path
        id="パス_173"
        data-name="パス 173"
        d="M-12.441-8.448h-4.422v1.672h2.574v4.84a4.6,4.6,0,0,1-1.914.418c-3.344,0-4.928-2.618-4.928-6.578,0-3.74,2.288-5.742,4.8-5.742a8.235,8.235,0,0,1,3.586.946v-1.936a8.321,8.321,0,0,0-3.608-.682c-4,0-6.8,3.014-6.8,7.81,0,4.158,2.046,7.854,6.908,7.854a12.087,12.087,0,0,0,3.806-.792ZM-9.625,0h1.848V-6.93c0-2.024.748-2.86,2.112-2.86,1.1,0,1.716.66,1.716,2.09V0H-2.1V-6.93c0-2.024.748-2.86,2.112-2.86,1.1,0,1.716.66,1.716,2.09V0H3.575V-7.788c0-2.464-1.32-3.586-3.256-3.586a2.957,2.957,0,0,0-2.728,1.76,2.835,2.835,0,0,0-2.64-1.76A2.855,2.855,0,0,0-7.821-9.548h-.044l-.066-1.672H-9.713c.022.792.088,1.606.088,2.42Zm16.3-8.712A3.974,3.974,0,0,1,9.559-9.878a2.276,2.276,0,0,1,1.87.7,2.756,2.756,0,0,1,.418,1.8v.506H10.769c-3.85,0-5.28,1.606-5.28,3.784A2.992,2.992,0,0,0,8.723.154a3.387,3.387,0,0,0,3.146-1.672h.044L12.023,0H13.7a16.915,16.915,0,0,1-.088-1.716V-7.568a3.442,3.442,0,0,0-.968-2.75,4.256,4.256,0,0,0-2.992-1.056,5.976,5.976,0,0,0-3.168.924Zm5.17,4.62A2.651,2.651,0,0,1,9.229-1.254,1.744,1.744,0,0,1,7.337-3.212c0-1.3,1.034-2.332,3.212-2.332a11.154,11.154,0,0,1,1.3.044ZM16.181,0h1.848V-11.22H16.181Zm-.088-15.84v2.2h2.024v-2.2ZM20.845,0h1.848V-16.5H20.845Z"
        transform="translate(23.155 16.5)"
        fill={color || '#000'}
      />
    </SvgWrapper>
  );
};
