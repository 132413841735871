import React from 'react';
import { Circle, Path } from 'react-native-svg';

import { SvgProps, SvgWrapper } from '@/wrappers/svg';

interface Props extends SvgProps {
  checked?: boolean;
  strokeColor?: string;
  checkColor?: string;
}

export const CheckCircleIcon: React.FC<Props> = ({
  color = '#018a0f',
  checked = true,
  strokeColor = 'none',
  checkColor = '#fff',
  ...props
}) => {
  return (
    <SvgWrapper viewBox={{ width: 24, height: 24 }} {...props}>
      <Circle cx={12} cy={12} r={11} fill={color} stroke={strokeColor} />
      {checked && (
        <Path
          data-name="\u7DDA 1"
          d="M6 11.237l-.06.06L0 5.359l1.391-1.393L6 8.573 13.937.634l1.393 1.392L6.058 11.3z"
          fill={checkColor}
          transform="translate(4.666 6.666)"
        />
      )}
    </SvgWrapper>
  );
};
