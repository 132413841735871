import React, { useCallback, useState } from 'react';
import { Modal, TouchableOpacity, View } from 'react-native';

import { Button } from '@/components/atomic/button';
import { Loader } from '@/components/atomic/loader';
import { ModalBackgroundOverlay } from '@/components/atomic/modal/ModalBackgroundOverlay.atomic';
import { TextL, TextM, TextS } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { OpenAllMessageIcon } from '@/svgs';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  isLoading: boolean;
  disabled: boolean;
  callSetAllMessagesRead: () => Promise<void>;
  onCompleted: () => Promise<void>;
}

const { getStyle } = createStyles(({ pixel, colors }) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    ...platformSpecificStyle({
      android: {
        height: pixel(48),
        marginBottom: pixel(12)
      },
      ios: {
        paddingBottom: pixel(32)
      },
      tablet: {
        marginBottom: pixel(12)
      },
      default: {
        height: 26,
        marginBottom: 6
      }
    })
  },
  dialogOpenButtonTextWrapper: {
    gap: pixel(10),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  dialogOpenButtonText: {
    color: '#fff'
  },
  dialogOpenButtonTextDisabled: {
    color: colors.icon.primaryDisabled
  },
  // Dialog
  dialogContentArea: {
    width: '90%',
    height: '68%',
    maxWidth: 540,
    paddingHorizontal: pixel(80),
    paddingBottom: pixel(100),
    paddingTop: pixel(90),
    borderRadius: pixel(20),
    opacity: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    backgroundColor: '#fff',
    zIndex: 11
  },
  dialogContent: {
    width: '100%'
  },
  dialogHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logo: {
    backgroundColor: '#ff0000',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    flexDirection: 'row',
    zIndex: 100,
    width: pixel(64),
    ...platformSpecificStyle({
      default: {
        height: 60
      },
      native: {
        height: pixel(120)
      }
    })
  },
  dialogTitle: {
    color: '#000',
    fontWeight: 'bold',
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(38)
      },
      tablet: {
        fontSize: pixel(38) / 2
      },
      default: {
        fontSize: pixel(38) / 2
      }
    })
  },
  dialogBody: {
    marginTop: pixel(70)
  },
  dialogMessageText: {
    color: '#000',
    lineHeight: pixel(42),
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(28)
      },
      tablet: {
        fontSize: pixel(28) / 2
      },
      default: {
        fontSize: pixel(28) / 2
      }
    })
  },
  dialogButtonsWrapper: {
    width: '100%',
    display: 'flex',
    gap: pixel(20),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  dialogButton: {
    width: '45%'
  }
}));

export const SetAllMessagesReadButtonWithDialog: React.FC<Props> = ({
  isLoading,
  disabled,
  callSetAllMessagesRead,
  onCompleted
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onPressButton = useCallback(
    () => !disabled && setIsModalOpen(true),
    [disabled]
  );
  const onPressClose = () => setIsModalOpen(false);
  const onPressSetAllRead = useCallback(async () => {
    if (disabled) return;
    await callSetAllMessagesRead();
    setIsModalOpen(false);
    await onCompleted();
  }, [disabled]);

  return (
    <>
      <View
        style={{
          ...styles.container
        }}
      >
        <TouchableOpacity
          style={{
            ...styles.dialogOpenButtonTextWrapper
          }}
          onPress={onPressButton}
          disabled={disabled}
        >
          <OpenAllMessageIcon
            color={
              disabled ? styles.dialogOpenButtonTextDisabled.color : undefined
            }
            size={{ width: 18, height: 22 }}
          />
          <TextM
            style={{
              ...styles.dialogOpenButtonText,
              ...(disabled && { ...styles.dialogOpenButtonTextDisabled })
            }}
          >
            すべて既読
          </TextM>
        </TouchableOpacity>
      </View>
      <Modal visible={isModalOpen}>
        <ModalBackgroundOverlay>
          <View style={{ ...styles.dialogContentArea }}>
            <View style={{ ...styles.dialogContent }}>
              <View
                style={{
                  ...styles.dialogHeader
                }}
              >
                <TextL selectable style={{ ...styles.dialogTitle }}>
                  すべてのお知らせを既読にしますか？
                </TextL>
              </View>
              <View style={{ ...styles.dialogBody }}>
                <TextS selectable style={{ ...styles.dialogMessageText }}>
                  {`すべてのお知らせを既読にしてもよろしいですか？\n一度既読にすると元に戻せません。`}
                </TextS>
              </View>
              {isLoading && <Loader color="#444" size="large" />}
            </View>
            <View style={{ ...styles.dialogButtonsWrapper }}>
              <View style={{ ...styles.dialogButton }}>
                <Button
                  modal
                  fullWidth
                  labelStyle={{ fontWeight: 'normal' }}
                  label="キャンセル"
                  onPress={onPressClose}
                />
              </View>
              <View style={{ ...styles.dialogButton }}>
                <Button
                  modal
                  fullWidth
                  labelStyle={{ color: '#DE2634', fontWeight: 'normal' }}
                  label="すべて既読"
                  onPress={onPressSetAllRead}
                />
              </View>
            </View>
          </View>
        </ModalBackgroundOverlay>
      </Modal>
    </>
  );
};
