import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import { Animated, TouchableOpacity, View } from 'react-native';

import { BadgeContainer } from '../BadgeContainer.component';
import { OpacityAnimatedProps } from './types';

import { TextS } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { createStyles } from '@/utils';

interface Props extends BottomTabBarProps, OpacityAnimatedProps {}

const { getStyle } = createStyles(({ pixel }) => ({
  body: {
    alignItems: 'flex-end',
    width: '100%'
  },
  iconContainer: {
    borderTopLeftRadius: pixel(45),
    borderBottomLeftRadius: pixel(45),
    width: pixel(200),
    flexDirection: 'row',
    paddingVertical: pixel(15),
    paddingRight: pixel(25),
    marginBottom: pixel(27),
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  label: {
    marginRight: pixel(20)
  },
  labelActive: {
    fontWeight: 'bold'
  }
}));

export const Body: React.FC<Props> = ({
  state,
  descriptors,
  navigation,
  opacityAnimation
}) => {
  const design = useDesign();
  const styles = getStyle(design);
  const { pixel, colors } = design;

  return (
    <Animated.View style={{ ...styles.body }}>
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key];
        const label =
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name;

        const isFocused = state.index === index;

        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true
          });

          if (!event.defaultPrevented) {
            const merge = !isFocused;
            navigation.navigate({
              name: route.name,
              merge,
              params: {}
            });
          }
        };

        const onLongPress = () => {
          navigation.emit({
            type: 'tabLongPress',
            target: route.key
          });
        };

        const labelColor = isFocused
          ? colors.textPrimary
          : colors.textPrimaryInverted;
        const iconColor = isFocused
          ? colors.navigation.iconColorActive
          : colors.navigation.iconColor;
        const buttonBackgroundColor = isFocused
          ? colors.background.color1
          : colors.navigation.iconColorActive;

        return (
          <TouchableOpacity
            key={label as string}
            accessibilityRole="button"
            accessibilityState={isFocused ? { selected: true } : {}}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            testID={options.tabBarTestID}
            onPress={onPress}
            onLongPress={onLongPress}
            style={{
              ...styles.iconContainer,
              backgroundColor: buttonBackgroundColor
            }}
          >
            <Animated.View style={{ opacity: opacityAnimation }}>
              <TextS
                style={{
                  ...styles.label,
                  color: labelColor,
                  ...(isFocused && styles.labelActive)
                }}
              >
                {label as string}
              </TextS>
            </Animated.View>
            <View>
              {options?.tabBarIcon &&
                options.tabBarIcon({
                  focused: isFocused,
                  color: iconColor,
                  size: pixel(23)
                })}
              {options.tabBarBadge && (
                <BadgeContainer
                  badge={options.tabBarBadge}
                  isFocused={isFocused}
                />
              )}
            </View>
          </TouchableOpacity>
        );
      })}
    </Animated.View>
  );
};
