import React, { useMemo } from 'react';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { Loader } from '@/components/atomic/loader';
import { TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { AttendanceData } from '@/interfaces/Attendance.interface';
import { CalendarClass } from '@/interfaces/Calendar.interface';
import { AttendedMarkIcon, ChevronIcon } from '@/svgs';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  eventInfo: CalendarClass;
  attendanceInfo?: AttendanceData;
  isActive: boolean;
  isExpired: boolean;
  isLocationPermissionsRejected: boolean;
  isWithinAttendanceCheckingPeriod: boolean;
  isWithinBeforeAttendanceStartTime: boolean;
  onClickPermissionRejectedAlert: () => void;
}

const { getStyle } = createStyles(({ pixel, colors }) => ({
  container: {
    width: '100%',
    ...platformSpecificStyle({
      native: {
        height: '100%'
      },
      default: {
        height: pixel(90)
      }
    })
  },
  attendanceInfo: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {
        height: pixel(80)
      },
      default: {
        height: 40
      }
    })
  },
  attendanceText: {
    color: colors.textPrimaryInverted
  },
  attendanceIcon: {
    marginRight: 4
  },
  chevron: {
    ...platformSpecificStyle({
      native: {
        width: pixel(15),
        height: pixel(20)
      },
      default: {
        width: pixel(6.5),
        height: pixel(10)
      }
    })
  }
}));

export const AttendanceInfo: React.FC<Props> = ({
  eventInfo,
  attendanceInfo,
  isActive,
  isExpired,
  isLocationPermissionsRejected,
  isWithinAttendanceCheckingPeriod,
  isWithinBeforeAttendanceStartTime,
  onClickPermissionRejectedAlert
}) => {
  const design = useDesign();
  const styles = getStyle(design);
  const { pixel, colors } = design;

  const isLoading = useMemo(
    () => !isExpired && !attendanceInfo && isWithinAttendanceCheckingPeriod,
    [isExpired, attendanceInfo, isWithinAttendanceCheckingPeriod]
  );

  const attended = useMemo(
    () => attendanceInfo && attendanceInfo.status === 'attend',
    [attendanceInfo]
  );
  const attendedLate = useMemo(
    () => attendanceInfo && attendanceInfo.status === 'late',
    [attendanceInfo]
  );
  const attendanceText = useMemo(() => {
    if (isWithinBeforeAttendanceStartTime) {
      return '開始前です';
    }
    // '位置情報が取得できないため出席登録ができません。' を追加し、押下時に位置情報設定画面に遷移するようにする
    if (isLoading && isLocationPermissionsRejected) {
      return '位置情報がオフのため出席登録ができません。';
    }
    if (isLoading) {
      return '出席状況を確認しています';
    }
    if (attended) {
      return '出席登録を行いました';
    }
    if (attendedLate) {
      return '出席登録を行いました（遅刻）';
    }
    return '出席登録はされませんでした';
  }, [
    eventInfo,
    attendanceInfo,
    isActive,
    isExpired,
    isLocationPermissionsRejected,
    isLoading,
    attended,
    attendedLate,
    isWithinBeforeAttendanceStartTime
  ]);

  return (
    <>
      {isLoading && isLocationPermissionsRejected ? (
        <TouchableOpacity
          onPress={onClickPermissionRejectedAlert}
          style={{
            ...styles.attendanceInfo,
            gap: pixel(8)
          }}
        >
          <TextM
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              ...styles.attendanceText,
              fontSize: pixel(20),
              color: '#C42323'
            }}
          >
            {attendanceText}
          </TextM>
          <ChevronIcon
            direction="right"
            color="#C42323"
            size={{ ...styles.chevron }}
          />
        </TouchableOpacity>
      ) : (
        <View
          style={{
            ...styles.attendanceInfo
          }}
        >
          {isLoading && (
            <Loader
              style={{ ...styles.attendanceIcon }}
              color={colors.secondary}
              size="small"
            />
          )}
          {(attended || attendedLate) && (
            <AttendedMarkIcon
              style={{
                ...styles.attendanceIcon
              }}
              size={{ width: pixel(36), height: pixel(36) }}
              color={isExpired ? 'white' : 'red'}
            />
          )}
          <TextM
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              ...styles.attendanceText,
              color: isExpired
                ? 'rgba(255,255,255,0.6)'
                : isActive
                ? '#707070'
                : 'rgba(255,255,255,1)'
            }}
          >
            {attendanceText}
          </TextM>
        </View>
      )}
    </>
  );
};
