import { LinearGradient } from 'expo-linear-gradient';
import React, { useCallback, useEffect, useState } from 'react';
import {
  ImageSourcePropType,
  Modal,
  SafeAreaView,
  ScrollView,
  View
} from 'react-native';

import { IdCardBody } from './IdCardBody.component';
import { IdCardFooter } from './IdCardFooter.component';
import { IdCardHeader } from './IdCardHeader.component';

import { fetchMeInfo, fetchMeProfileImage } from '@/api/calls/account.api';
import { featchUniversityInfo } from '@/api/calls/configuration.api';
import { Loader } from '@/components/atomic/loader';
import { QrCodeTouchableMedium } from '@/components/qr/QrCodeTouchableMedium.component';
import { useDesign } from '@/hooks/useDesign';
import { UniversityInfo } from '@/interfaces';
import { UserProfile } from '@/interfaces/IdCard.interface';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  isModalOpen: boolean;
  onPressClose: () => void;
}

const { getStyle } = createStyles(({ pixel }) => ({
  fixedBottomRight: {
    zIndex: 100,
    position: 'absolute',
    bottom: pixel(32),
    right: pixel(32)
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  // Dialog
  idCardBackground: {
    width: '100%',
    height: '100%',
    backgroundColor: 'fff',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0
  },
  idCardContainer: {
    width: '100%',
    height: '100%',
    backgroundColor: 'fff',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    marginTop: pixel(20)
  },
  idCardBgHeaderColorBg: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {
        height: pixel(380)
      },
      tablet: {
        height: pixel(380) / 2
      },
      default: {
        height: pixel(380) / 2
      }
    })
  },
  idCardQrSection: {
    marginTop: pixel(30),
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative'
  },
  scrollMaxHeight: {
    width: '100%',
    overflow: 'hidden',
    ...platformSpecificStyle({
      native: {
        maxHeight: pixel(400)
      },
      tablet: {
        maxHeight: pixel(600)
      },
      default: {
        maxHeight: pixel(600)
      }
    })
  },
  scroll: {
    marginTop: pixel(20),
    width: '100%',
    overflow: 'hidden'
  },
  scrollContent: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden'
  },
  idCardOpenButton: {
    ...platformSpecificStyle({
      native: {
        width: pixel(142)
      },
      tablet: {
        width: (pixel(142) * 2) / 3
      },
      default: {
        width: (pixel(142) * 2) / 3
      }
    })
  }
}));

export const IdCardPageDialog: React.FC<Props> = ({
  isModalOpen,
  onPressClose
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userProfile, setUserProfile] = useState<UserProfile>();
  const [userProfileImg, setUserProfileImg] = useState<ImageSourcePropType>();
  const [universityInfo, setUniversityInfo] = useState<UniversityInfo>();

  const fetchUserProfile = useCallback(async () => {
    const meInfo = await fetchMeInfo();
    const meImage = await fetchMeProfileImage();
    const universityInfoResult = await featchUniversityInfo();
    setUserProfile(meInfo);
    setUserProfileImg(
      meImage ? { uri: `data:image/jpeg;base64,${meImage}` } : undefined
    );
    setUniversityInfo(universityInfoResult || {});

    setIsLoading(false);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fetchUserProfile();
    }, 1000);
  }, []);
  const content = (
    <>
      <IdCardBody userProfile={userProfile} />
      {/* QR */}
      <QrCodeTouchableMedium enabled={isModalOpen} />
    </>
  );
  return (
    <Modal animationType="fade" statusBarTranslucent visible={isModalOpen}>
      {isLoading ? (
        <Loader color="#444" size="large" />
      ) : (
        <View style={{ ...styles.idCardBackground }}>
          <LinearGradient
            colors={['#a8713b', '#e0da9c']}
            locations={[0, 1]}
            start={{ x: 0, y: 0.726 }}
            end={{ x: 1, y: 0.733 }}
            style={[
              {
                ...styles.idCardBgHeaderColorBg,
                height:
                  styles.idCardBgHeaderColorBg.height + design.safeArea.top
              }
            ]}
          />
          <SafeAreaView style={{ ...styles.idCardContainer }}>
            <IdCardHeader
              userProfileImg={userProfileImg}
              onPressClose={onPressClose}
            />
            {design.windowDimensions.height > 600 ? (
              <>{content}</>
            ) : (
              <View
                style={{
                  ...styles.scrollMaxHeight
                }}
              >
                <ScrollView
                  style={{
                    ...styles.scroll
                  }}
                  horizontal={false}
                  showsHorizontalScrollIndicator={false}
                  showsVerticalScrollIndicator
                  contentContainerStyle={{ ...styles.scrollContent }}
                >
                  {content}
                </ScrollView>
              </View>
            )}
            {/* Footer */}
            <IdCardFooter universityInfo={universityInfo} />
          </SafeAreaView>
        </View>
      )}
    </Modal>
  );
};
