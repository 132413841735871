import { useEffect, useMemo } from 'react';
import { View } from 'react-native';

import { Header } from '@/components/atomic/header';
import { List } from '@/components/atomic/list';
import { Row } from '@/components/atomic/list/ListRow.component';
import { Screen } from '@/components/atomic/screen';
import { TextL, TextM } from '@/components/atomic/text';
import { Toggle } from '@/components/atomic/toggle';
import { useDesign } from '@/hooks/useDesign';
import { NotificationPreference } from '@/interfaces/Account.interface';
import { AccountStackScreenProps } from '@/navigation/types';
import {
  updateNotificationPreferences,
  useAppDispatch,
  useAppSelector
} from '@/store';
import { createStyles, isMobile, platformSpecificStyle } from '@/utils';

const { getStyle } = createStyles(({ pixel }) => ({
  screenContainer: {
    ...platformSpecificStyle({
      native: {},
      default: {
        paddingHorizontal: 0
      }
    })
  },
  container: {
    alignSelf: 'center',
    width: '100%'
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {},
      default: {
        marginBottom: pixel(30)
      }
    })
  },
  headerText: {
    ...platformSpecificStyle({
      native: {},
      default: {
        fontSize: pixel(20)
      }
    })
  },
  subtitle: {
    alignSelf: 'flex-start',
    ...platformSpecificStyle({
      native: {
        marginBottom: pixel(30)
      },
      default: {
        marginBottom: pixel(15)
      }
    })
  },
  subtitleText: {
    ...platformSpecificStyle({
      native: {},
      default: {
        fontSize: pixel(13)
      }
    })
  }
}));

const TITLE = '通知設定';

export const NotificationSettingsScreen = ({
  navigation
}: Partial<AccountStackScreenProps<'NotificationSettings'>>) => {
  const dispatch = useAppDispatch();
  const design = useDesign();
  const styles = getStyle(design);
  const notificationPreference = useAppSelector(
    (state) => state.account.notificationPreference
  );

  useEffect(() => {
    const unsubscribe = navigation?.addListener('gestureEnd', () => {
      navigation?.goBack();
    });

    return unsubscribe;
  }, [navigation]);

  const updatePreferences = (preference: Partial<NotificationPreference>) => {
    dispatch(
      updateNotificationPreferences({
        ...notificationPreference,
        ...preference
      })
    );
  };

  const rows = useMemo<Row[]>(() => {
    return [
      {
        key: 'CampusPlanのお知らせ',
        type: 'custom',
        value: (
          <Toggle
            isOn={notificationPreference.campusPlan}
            toggle={() => {
              updatePreferences({
                campusPlan: !notificationPreference.campusPlan
              });
            }}
          />
        )
      },
      {
        key: 'Classroomの連絡事項',
        type: 'custom',
        value: (
          <Toggle
            isOn={notificationPreference.classroom}
            toggle={() =>
              updatePreferences({
                classroom: !notificationPreference.classroom
              })
            }
          />
        )
      },
      {
        key: 'メールの新着メッセージ',
        type: 'custom',
        value: (
          <Toggle
            isOn={notificationPreference.email}
            toggle={() => {
              updatePreferences({
                email: !notificationPreference.email
              });
            }}
          />
        )
      }
    ];
  }, [notificationPreference]);

  return (
    <Screen isTransparent={!isMobile} style={{ ...styles.screenContainer }}>
      <View style={{ ...styles.container }}>
        {isMobile ? (
          <Header
            title={TITLE}
            onBackPress={
              isMobile
                ? () => {
                    navigation?.goBack();
                  }
                : undefined
            }
          />
        ) : (
          <View style={{ ...styles.header }}>
            <TextL style={styles.headerText}>{TITLE}</TextL>
          </View>
        )}
        <View style={{ ...styles.subtitle }}>
          <TextM>プッシュ通知</TextM>
        </View>
        <View style={{ ...styles.subtitle }}>
          <TextM style={styles.subtitleText}>
            緊急のお知らせは、設定に関係なくプッシュ通知が行われます。
          </TextM>
        </View>
        <List rows={rows} />
      </View>
    </Screen>
  );
};
