import {
  Modal,
  TouchableHighlight,
  TouchableOpacity,
  View
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import { Button } from '@/components/atomic/button';
import { Screen } from '@/components/atomic/screen';
import { TextL, TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { TimetableClass } from '@/interfaces';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  event: TimetableClass;
  visible?: boolean;
  onClose: () => void;
}

const { getStyle } = createStyles(({ pixel, colors }) => ({
  overlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    backgroundColor: colors.icon.primary,
    borderRadius: pixel(16),
    alignSelf: 'center',
    justifyContent: 'space-between',
    margin: pixel(2),
    ...platformSpecificStyle({
      native: {
        gap: pixel(80),
        padding: pixel(80),
        width: pixel(670),
        maxHeight: pixel(905)
      },
      default: {
        gap: pixel(40),
        padding: pixel(40),
        width: pixel(540),
        maxHeight: pixel(453),
        cursor: 'default'
      }
    })
  },
  fieldsContainer: {
    ...platformSpecificStyle({
      native: {
        gap: pixel(80),
        marginTop: pixel(80)
      },
      default: {
        gap: pixel(40),
        marginTop: pixel(40)
      }
    })
  },
  fieldTitleContainer: {
    borderBottomColor: colors.button.borderModal,
    ...platformSpecificStyle({
      native: {
        borderBottomWidth: pixel(4),
        paddingBottom: pixel(16),
        marginBottom: pixel(32)
      },
      default: {
        borderBottomWidth: pixel(2),
        paddingBottom: pixel(8),
        marginBottom: pixel(16)
      }
    })
  },
  fieldTitle: {
    color: colors.textPrimaryInverted
  },
  fieldValues: {
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  text: {
    color: colors.textPrimaryInverted,
    fontWeight: 'bold'
  }
}));

export const TimetableModal: React.FC<Props> = ({
  onClose,
  event,
  visible
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  const fields = [
    {
      fieldName: '講師名',
      values: event.teacher
    },
    {
      fieldName: '教室名',
      values: event.room
    }
  ];

  return (
    <Modal
      statusBarTranslucent
      transparent
      animationType="fade"
      visible={visible}
    >
      <Screen isTransparent isModal>
        <TouchableOpacity
          style={styles.overlay}
          activeOpacity={1}
          onPress={onClose}
        >
          <TouchableHighlight>
            <View style={styles.container}>
              <ScrollView
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
              >
                <View onStartShouldSetResponder={() => true}>
                  <TextL style={styles.text}>{event.name}</TextL>
                  <View style={styles.fieldsContainer}>
                    {fields.map(({ fieldName, values }, i) => (
                      <View key={JSON.stringify(values) + i}>
                        <View style={styles.fieldTitleContainer}>
                          <TextM style={styles.fieldTitle}>{fieldName}</TextM>
                        </View>
                        <View style={styles.fieldValues}>
                          <TextM style={styles.text}>{values.join('、')}</TextM>
                        </View>
                      </View>
                    ))}
                  </View>
                </View>
              </ScrollView>
              <Button modal fullWidth label="閉じる" onPress={onClose} />
            </View>
          </TouchableHighlight>
        </TouchableOpacity>
      </Screen>
    </Modal>
  );
};
