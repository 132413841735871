import React from 'react';
import { ImageSourcePropType } from 'react-native';

import FastImage from '@/components/fast-image/FastImage';

interface Props {
  color: string;
  src: ImageSourcePropType;
  size: number;
}

export const TabBarIcon: React.FC<Props> = React.memo(
  ({ color, src, size }) => {
    const style = {
      width: size,
      height: size,
      tintColor: color
    };

    return <FastImage resizeMode="contain" source={src} style={style} />;
  },
  (prevProps, nextProps) => {
    // we can assume that keys are always in the same order
    const arePropsEqual =
      JSON.stringify(prevProps) === JSON.stringify(nextProps);

    return arePropsEqual;
  }
);
