import { HttpStatusCode } from 'axios';

export enum ErrorCodes {
  Unauthorized = '401',
  NotFound = '404',
  TimeoutError = '408',
  ServerError = '500',
  GatewayTimeout = '502',
  NetworkError = 'ERR_NETWORK'
}

const errorMessages = {
  [HttpStatusCode.Forbidden]:
    'ログイン中にエラーが発生しました。本システムは学生のみが利用できます。',
  [HttpStatusCode.InternalServerError]:
    'ログイン中にエラーが発生しました。このユーザーは利用を許可されていない可能性があります。',
  teacherLoginEnabledOnlyApp:
    'ログイン中にエラーが発生しました。教員機能はアプリのみで利用できます。'
};

export const getErrorMessage = (error: string) => {
  const statusCode = Number(error) as keyof typeof errorMessages;
  return (
    errorMessages[statusCode] ||
    errorMessages[HttpStatusCode.InternalServerError]
  );
};

export const getErrorMessageWithKey = (key: string) => {
  return (
    errorMessages[key] || errorMessages[HttpStatusCode.InternalServerError]
  );
};
