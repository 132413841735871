import { PropsWithChildren } from 'react';
import { View, ViewProps } from 'react-native';

import { useDesign } from '@/hooks/useDesign';
import { createStyles, isMobile, platformSpecificStyle } from '@/utils';

interface Props extends ViewProps {
  isActive?: boolean;
}

const { getStyle } = createStyles(({ pixel, colors }) => ({
  customView: {
    paddingRight: pixel(40),
    borderColor: colors.button.borderSecondary,
    ...platformSpecificStyle({
      native: {
        borderRightWidth: pixel(2)
      },
      default: {
        borderRightWidth: pixel(1)
      }
    })
  },
  customViewActive: {
    borderRightWidth: 0,
    paddingRight: 0
  }
}));

export const NavigationView: React.FC<PropsWithChildren<Props>> = ({
  children,
  isActive,
  style
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  return isMobile ? (
    <>{children}</>
  ) : (
    <View>
      <View
        style={[
          {
            ...styles.customView,
            ...(isActive && styles.customViewActive)
          },
          style
        ]}
      >
        {children}
      </View>
    </View>
  );
};
