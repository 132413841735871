import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { View } from 'react-native';

import { useDesign } from '@/hooks/useDesign';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  height: number;
  progress?: number;
}

const { getStyle } = createStyles(({ pixel }) => ({
  container: {
    ...platformSpecificStyle({
      native: {
        width: pixel(3)
      },
      default: {
        width: pixel(2)
      }
    })
  },
  progressBar: { flex: 1 }
}));

export const EventCardProgressBar: React.FC<Props> = ({ height, progress }) => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <View style={{ ...styles.container, height }}>
      {progress && progress > 0 && progress < 1 && (
        <LinearGradient
          colors={['rgba(255,255,255,1)', 'rgba(255,255,255,0)']}
          locations={[progress, Math.min(progress * 1.5)]}
          style={styles.progressBar}
        />
      )}
    </View>
  );
};
