import { useCallback, useMemo, useRef, useState } from 'react';

import {
  updateAllMessagesRead,
  updateNotificationReadStatus
} from '@/api/calls/notifiaction.api';
import { useLoading } from '@/hooks/useLoading';
import {
  NotificationReadTypes,
  NotificationTypes,
  NotificationsFilterTypes
} from '@/interfaces';
import {
  getNotificationList,
  selectReadMessagesDesc,
  selectUnreadMessagesDesc,
  useAppDispatch,
  useAppSelector
} from '@/store';

const getNotificationTypeFromFilterType: (
  notificationsFilterType: NotificationsFilterTypes
) => NotificationTypes | undefined = (notificationsFilterType) => {
  const mapping = {
    [NotificationsFilterTypes.CAMPUS_PLAN]: NotificationTypes.CAMPUS_PLAN,
    [NotificationsFilterTypes.CLASS_ROOM]: NotificationTypes.CLASS_ROOM,
    [NotificationsFilterTypes.GMAIL]: NotificationTypes.GMAIL,
    [NotificationsFilterTypes.ALL]: undefined,
    [NotificationsFilterTypes.UNREAD]: undefined
  };
  return mapping[notificationsFilterType];
};

export const useNotificationFilter = () => {
  const dispatch = useAppDispatch();

  const currentPage = useRef(1);
  const [isFilterChanging, setIsFilterChanging] = useState<boolean>(false);
  const [isNotificationsReadDirty, setIsNotificationsReadDirty] =
    useState<boolean>(false);
  const [selectedFilterType, setSelectedFilterType] =
    useState<NotificationsFilterTypes>(NotificationsFilterTypes.ALL);
  const readMessages = useAppSelector(selectReadMessagesDesc);
  const unreadMessages = useAppSelector(selectUnreadMessagesDesc);

  const isReadLoading = useAppSelector(
    (state) => state.notifications.loadingState.read
  );
  const isUnreadLoading = useAppSelector(
    (state) => state.notifications.loadingState.unread
  );
  const [isApiCalling, setIsApiCalling] = useState<boolean>(false);

  const onEndReached = () => {
    if (readMessages.total !== readMessages.current) {
      dispatch(
        getNotificationList({
          type: NotificationReadTypes.READ,
          page: currentPage.current + 1
        })
      );
      currentPage.current += 1;
    }
  };

  const {
    isLoading: isDispatchGetNotificationListLoading,
    refresh,
    isPtrLoading,
    pullToRefresh
  } = useLoading(() => {
    currentPage.current = 1;
    return [
      dispatch(
        getNotificationList({
          type: NotificationReadTypes.READ,
          page: currentPage.current,
          shouldReset: true
        })
      ),
      dispatch(
        getNotificationList({
          type: NotificationReadTypes.UNREAD,
          shouldReset: true
        })
      )
    ];
  });
  const selectFilterType = useCallback(
    async (notificationsFilterType: NotificationsFilterTypes) => {
      if (selectedFilterType === notificationsFilterType) {
        return;
      }
      if (isNotificationsReadDirty) {
        await refresh();
        setIsNotificationsReadDirty(false);
        setSelectedFilterType(notificationsFilterType);
        return;
      }
      setIsFilterChanging(true);
      setSelectedFilterType(notificationsFilterType);
      setTimeout(() => setIsFilterChanging(false), 200);
    },
    [isNotificationsReadDirty, refresh, selectedFilterType]
  );
  const isSetAllReadEnabledFilterTypeSelected = useMemo(() => {
    return [
      NotificationsFilterTypes.ALL,
      NotificationsFilterTypes.UNREAD
    ].includes(selectedFilterType);
  }, [selectedFilterType]);
  const canCallSetAllRead = useMemo(() => {
    return unreadMessages.length > 0;
  }, [unreadMessages]);
  const callSetAllMessagesRead = useCallback(async () => {
    setIsApiCalling(true);
    const result = await updateAllMessagesRead();
    setIsApiCalling(false);
    return result;
  }, []);
  const setNotificationRead = useCallback(
    async (id: string, type: NotificationTypes) => {
      !isNotificationsReadDirty && setIsNotificationsReadDirty(true);
      setIsApiCalling(true);
      const result = await updateNotificationReadStatus({ id, type });
      setIsApiCalling(false);
      return result;
    },
    [isNotificationsReadDirty]
  );

  const filteredNotifications = useMemo(() => {
    if (isReadLoading || isUnreadLoading || isFilterChanging) {
      return [];
    }
    const modifiedUnreadMessages = unreadMessages.map((n) => ({
      ...n,
      isRead: false
    }));
    if (selectedFilterType === NotificationsFilterTypes.UNREAD) {
      return modifiedUnreadMessages.sort(
        (a, b) => b.date.getTime() - a.date.getTime()
      );
    }
    const concatedNotifications = readMessages.notifications
      .map((n) => ({
        ...n,
        isRead: true
      }))
      .concat(modifiedUnreadMessages)
      .sort((a, b) => b.date.getTime() - a.date.getTime());

    if (selectedFilterType === NotificationsFilterTypes.ALL) {
      return concatedNotifications;
    } else {
      return concatedNotifications.filter(
        (notification) =>
          notification.type ===
          getNotificationTypeFromFilterType(selectedFilterType)
      );
    }
  }, [
    isReadLoading,
    isFilterChanging,
    isUnreadLoading,
    readMessages,
    unreadMessages,
    selectedFilterType
  ]);
  const isLoading = useMemo(() => {
    return (
      isDispatchGetNotificationListLoading || isReadLoading || isFilterChanging
    );
  }, [isDispatchGetNotificationListLoading, isReadLoading, isFilterChanging]);

  return {
    currentPage,
    onEndReached,
    isLoading,
    isSetAllReadEnabledFilterTypeSelected,
    canCallSetAllRead,
    refresh,
    isFilterChanging,
    isPtrLoading,
    pullToRefresh,
    filteredNotifications,
    selectFilterType,
    selectedFilterType,
    isApiCalling,
    callSetAllMessagesRead,
    setNotificationRead
  };
};
