import * as React from 'react';
import { Circle, G, Mask, Rect } from 'react-native-svg';

import { SvgProps, SvgWrapper, usePrimaryIconColor } from '@/wrappers/svg';

export interface Props extends SvgProps {
  opacity?: number;
}

export const EventCardSvg: React.FC<Props> = ({ color, opacity, ...props }) => {
  const primaryIconColor = usePrimaryIconColor();

  return (
    // <SvgWrapper viewBox={{ width: 595.999, height: 200 }} {...props}>
    //   <Path
    //     id="bg"
    //     d="M-8464-1015h-573a4.005,4.005,0,0,1-4-4v-57.831a39.8,39.8,0,0,0,11.222-5.6,40.156,40.156,0,0,0,8.864-8.684,39.835,39.835,0,0,0,5.822-11.086A39.938,39.938,0,0,0-9013-1115a39.944,39.944,0,0,0-2.093-12.8,39.836,39.836,0,0,0-5.822-11.087,40.139,40.139,0,0,0-8.864-8.684,39.8,39.8,0,0,0-11.222-5.6V-1211a4.005,4.005,0,0,1,4-4h573a18.874,18.874,0,0,1,7.395,1.493,18.934,18.934,0,0,1,6.04,4.072,18.939,18.939,0,0,1,4.07,6.04A18.864,18.864,0,0,1-8445-1196v162a18.863,18.863,0,0,1-1.493,7.4,18.93,18.93,0,0,1-4.07,6.04,18.944,18.944,0,0,1-6.04,4.072A18.869,18.869,0,0,1-8464-1015Z"
    //     transform="translate(9041 1215)"
    //     fill={color || primaryIconColor}
    //     opacity={opacity}
    //   />
    // </SvgWrapper>
    <SvgWrapper viewBox={{ width: 595.999, height: 200 }} {...props}>
      <Mask id="round-mask">
        <Rect x="0" y="0" width="595.999" height="200" fill="white" />
        <Rect x="575.999" width="40" height="200" fill="black" />
        <Rect x="555.999" width="40" height="200" rx="20" fill="white" />
        <Circle cx="-5" cy="95" r="30" fill="#000" />
      </Mask>
      <G
        mask="url(#round-mask)"
        fill={color || primaryIconColor}
        opacity={opacity}
      >
        <Rect x="0" width="595.999" height="200" rx="4" />
      </G>
    </SvgWrapper>
  );
};
