import { RenderHtml } from './RenderHtml.component';

import { Loader } from '@/components/atomic/loader';
import { useDesign } from '@/hooks/useDesign';
import { createStyles } from '@/utils';

interface Props {
  html?: string;
}

const { getStyle } = createStyles(({ colors, pixel }) => ({
  webViewContent: {
    backgroundColor: colors.secondary,
    borderRadius: pixel(20),
    padding: pixel(20),
    marginBottom: pixel(110)
  },
  loader: {
    flex: 1
  }
}));

export const SubNaviPage: React.FC<Props> = ({ html }) => {
  const design = useDesign();
  const styles = getStyle(design);
  const { colors } = design;
  const contentStyle = styles.webViewContent;

  const renderedHtml = `
  <div>
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <style type="text/css">
    body {
      font-family: sans-serif;
      font-size: 14px;
      line-height: 1.6;
    }
    .page-container {
      padding: ${contentStyle.padding}px;
      background-color: ${String(contentStyle.backgroundColor)};
      margin-bottom: ${contentStyle.marginBottom}px;
    }
    .content {
      overflow: auto;
    }
    figure {
      margin: 0 0 1em;
    }
    img {
     width: 100%;
     height: auto;
    }
    table {
      width: 100%;
      border-collapse: collapse;
    }
    table th,table td{
      font-size: 14px;
      border: solid 1px #aaa;
      padding: 5px;
    }
  </style>
  <div class="page-container">
    <div class="content">
      ${html}
    </div>
  <div>
</div>
`;

  return html !== undefined ? (
    <RenderHtml html={renderedHtml} />
  ) : (
    <Loader
      style={{ ...styles.loader }}
      color={colors.secondary}
      size="large"
    />
  );
};
