import * as React from 'react';
import { Path } from 'react-native-svg';

import { SvgProps, SvgWrapper, usePrimaryIconColor } from '@/wrappers/svg';

export const CheckMarkIcon: React.FC<SvgProps> = ({ color, ...props }) => {
  const primaryIconColor = usePrimaryIconColor();

  return (
    <SvgWrapper viewBox={{ width: 12, height: 9 }} {...props}>
      <Path
        fillRule="evenodd"
        fill={color || primaryIconColor}
        d="M11.499,1.803 L4.545,8.754 L4.500,8.709 L4.455,8.754 L0.001,4.302 L1.045,3.258 L4.500,6.711 L10.455,0.759 L11.499,1.803 Z"
      />
    </SvgWrapper>
  );
};
