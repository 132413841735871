import React, { useRef } from 'react';
import { RefreshControl, SectionList, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { Loader } from '@/components/atomic/loader';
import { TextL, TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import {
  TeacherCalendarClass,
  TeacherCalendarClassesAndPeriod
} from '@/interfaces/Teacher.interface';
import { createStyles, getClassPeriod, platformSpecificStyle } from '@/utils';

const { getStyle } = createStyles(({ pixel }) => ({
  listWrapper: {
    marginTop: pixel(20),
    ...platformSpecificStyle({
      native: {
        paddingBottom: pixel(90)
      },
      default: {
        flex: 1
      }
    })
  },
  sectionHeader: {
    textAlign: 'center',
    marginTop: pixel(45),
    marginBottom: pixel(25),
    fontWeight: 'bold'
  },
  listItem: {
    backgroundColor: '#fff',
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {
        padding: pixel(30),
        paddingRight: pixel(50),
        marginBottom: pixel(5)
      },
      default: {
        padding: pixel(30) / 2,
        paddingRight: pixel(40) / 2,
        marginBottom: pixel(5) / 2
      }
    })
  },
  listText: {
    color: '#000',
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(32)
      },
      default: {
        fontSize: pixel(32) / 2
      }
    })
  },
  period: {
    marginRight: pixel(15)
  },
  className: {
    fontWeight: 'bold',
    color: '#000'
  },
  roomName: {
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(26)
      },
      default: {
        fontSize: pixel(26) / 2
      }
    })
  },
  noDataMessage: {
    textAlign: 'center',
    marginTop: pixel(120)
  },
  loader: {
    flex: 1
  }
}));

export type ListType = SectionList<
  TeacherCalendarClassesAndPeriod,
  { title: string; data: TeacherCalendarClassesAndPeriod[] }
>;

interface Props {
  isLoading: boolean;
  schedule: {
    title: string;
    data: TeacherCalendarClassesAndPeriod[];
  }[];
  periods: {
    from: Date;
    to: Date;
    key: string;
  }[];
  // todayIndex: number;
  refreshing: boolean;
  onRefresh?: () => void;
  onPressItem: (
    item: TeacherCalendarClass,
    schedule: TeacherCalendarClassesAndPeriod,
    index: number
  ) => void;
  scrollToSection: () => void;
}

export const TeacherScheduleList = React.forwardRef<ListType, Props>(
  (
    {
      isLoading,
      schedule,
      periods,
      // todayIndex,
      refreshing,
      onRefresh,
      onPressItem,
      scrollToSection
    },
    listRef
  ) => {
    const design = useDesign();
    const styles = getStyle(design);
    const { colors } = design;

    const scrollRetry = useRef(10);

    const noData = schedule.every((item) => !item.data.length);

    if (isLoading && noData) {
      return (
        <Loader style={styles.loader} color={colors.secondary} size="large" />
      );
    }

    return (
      <View style={styles.listWrapper}>
        {noData && (
          <TextM style={styles.noDataMessage}>
            登録されている予定はありません
          </TextM>
        )}
        {!noData && (
          <SectionList
            ref={listRef}
            stickySectionHeadersEnabled={false}
            sections={schedule}
            keyExtractor={(item, index) => item.classes[0].classId + index}
            refreshControl={
              refreshing !== undefined && onRefresh ? (
                <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
              ) : undefined
            }
            renderSectionHeader={({ section: { title } }) => {
              return <TextL style={styles.sectionHeader}>{title}</TextL>;
            }}
            renderItem={({ item, section }) => {
              return (
                <>
                  {item.classes.map((classItem) => (
                    <TouchableOpacity
                      key={`${item.period}_${classItem.classId}`}
                      onPress={() => {
                        const index = schedule.indexOf(section);
                        onPressItem(classItem, item, index);
                      }}
                    >
                      <View style={styles.listItem}>
                        <View style={styles.period}>
                          <TextL style={styles.listText}>
                            {getClassPeriod(item, periods)}
                          </TextL>
                        </View>
                        <View>
                          <TextL
                            style={{ ...styles.listText, ...styles.className }}
                          >
                            {classItem.name}
                          </TextL>
                          <TextM
                            style={{ ...styles.listText, ...styles.roomName }}
                          >
                            {classItem.room.map((room) => room.name).join(', ')}
                          </TextM>
                        </View>
                      </View>
                    </TouchableOpacity>
                  ))}
                </>
              );
            }}
            onScrollToIndexFailed={() => {
              if (scrollRetry.current < 0) {
                setTimeout(() => {
                  scrollRetry.current = 10;
                }, 300);
                return;
              }

              scrollRetry.current -= 1;
              scrollToSection();
            }}
          />
        )}
      </View>
    );
  }
);
