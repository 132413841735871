import { startActivityAsync } from 'expo-intent-launcher';
import * as WebBrowser from 'expo-web-browser';
import { Linking, Platform } from 'react-native';

import {
  ACTION_MAIN,
  CATEGORY_APP_EMAIL,
  FLAG_ACTIVITY_NEW_TASK
} from '@/constants/Android';

export const openGmailApp = async (fallbackUrl?: string) => {
  try {
    if (Platform.OS === 'ios') {
      await Linking.openURL('googlegmail://');
    } else if (Platform.OS === 'android') {
      await startActivityAsync(ACTION_MAIN, {
        category: CATEGORY_APP_EMAIL,
        flags: FLAG_ACTIVITY_NEW_TASK
      });
    }
  } catch (_e) {
    openBrowser({ url: fallbackUrl });
  }
};

export const openBrowser = async ({
  url,
  openInSameBrowser,
  onBrowserClose
}: {
  url: string | undefined;
  openInSameBrowser?: boolean;
  onBrowserClose?: () => void;
}) => {
  if (url) {
    await WebBrowser.openBrowserAsync(url, {
      createTask: false,
      windowFeatures: {
        popup: !openInSameBrowser
      }
    }).finally(() => {
      if (Platform.OS === 'ios') {
        WebBrowser.dismissBrowser();
        if (onBrowserClose) {
          onBrowserClose();
        }
      }
    });
  }
};
