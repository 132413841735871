import { View } from 'react-native';

import { TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { CalendarEvent } from '@/interfaces/Calendar.interface';
import { CheckMarkIcon } from '@/svgs';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  eventInfo: CalendarEvent;
}

const { getStyle } = createStyles(({ pixel, colors }) => ({
  container: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: pixel(20),
    backgroundColor: colors.cardBackground.secondary,
    ...platformSpecificStyle({
      native: {
        height: pixel(90),
        paddingHorizontal: pixel(20)
      },
      default: {
        height: 65,
        paddingHorizontal: 19
      }
    })
  },
  checkMarkContainer: {
    ...platformSpecificStyle({
      native: {
        marginRight: pixel(20),
        width: pixel(23),
        height: pixel(16)
      },
      default: {
        marginRight: 14,
        width: 12,
        height: 8
      }
    })
  },
  checkMark: {
    width: '100%',
    height: '100%'
  }
}));

export const EventCardAllDay: React.FC<Props> = ({ eventInfo }) => {
  const design = useDesign();
  const styles = getStyle(design);
  const { colors } = design;
  return (
    <View style={{ ...styles.container }}>
      <View style={{ ...styles.checkMarkContainer }}>
        <CheckMarkIcon
          color={colors.icon.secondary}
          size={{ ...styles.checkMark }}
        />
      </View>
      <TextM numberOfLines={1}>{eventInfo.name}</TextM>
    </View>
  );
};
