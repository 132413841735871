import QrError from 'assets/images/icons/id-card/qr-error.png';
import React from 'react';
import { Platform, View } from 'react-native';

import { Loader } from '@/components/atomic/loader';
import { QrPageBody } from '@/components/qr/QrPageBody.component';
import { useQrCode } from '@/hooks/qr/useQrCode';
import { useDesign } from '@/hooks/useDesign';
import { createStyles } from '@/utils';

interface Props {}

const { getStyle } = createStyles(({ pixel }) => ({
  qrPageContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: pixel(20),
    flex: 3
  },
  loaderBg: {
    width: 42,
    height: 42,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 21
  }
}));

export const TeacherQrCodeLargePageContent: React.FC<Props> = () => {
  const design = useDesign();
  const styles = getStyle(design);

  const {
    qrImageData,
    secondsRemaining,
    secondsRemainingForDisplay,
    timelimitAlerting,
    errorCode,
    onRetryCallback,
    isLoading
  } = useQrCode({ enabled: true });

  return (
    <View style={{ ...styles.qrPageContainer }}>
      {isLoading && !qrImageData && (
        <View style={Platform.OS === 'android' && styles.loaderBg}>
          <Loader color="#444" size="large" />
        </View>
      )}
      {(!isLoading || qrImageData) && (
        <QrPageBody
          errorCode={errorCode}
          qrImageData={qrImageData || QrError}
          onRetryCallback={onRetryCallback}
          secondsRemaining={secondsRemaining}
          secondsRemainingForDisplay={secondsRemainingForDisplay}
          timelimitAlerting={timelimitAlerting}
          forStudent={false}
        />
      )}
    </View>
  );
};
