import { StyleSheet } from 'react-native';

import { GetStylesWithDesignArguments } from '@/hooks/useDesign';

export const createStyles = <
  T extends StyleSheet.NamedStyles<T> | StyleSheet.NamedStyles<any>
>(
  f: (design: GetStylesWithDesignArguments) => T
) => ({
  getStyle: (design: GetStylesWithDesignArguments) => {
    return StyleSheet.create(f(design)) as T;
  }
});
