import { TouchableOpacity } from 'react-native';

import { TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { ChevronIcon, Direction } from '@/svgs';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  onPress: () => void;
  disabled?: boolean;
  direction?: Direction;
  labelDate?: string;
}

const { getStyle } = createStyles(({ pixel }) => ({
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {
        gap: pixel(12)
      },
      default: {
        gap: 8
      }
    })
  },
  chevron: {
    ...platformSpecificStyle({
      native: {
        height: pixel(32)
      },
      default: {
        height: 16
      }
    })
  }
}));

export const DateSelectorButton: React.FC<Props> = ({
  onPress,
  direction,
  labelDate,
  disabled
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <TouchableOpacity
      disabled={disabled}
      style={{
        ...styles.buttonContainer,
        ...(direction === 'right' && { flexDirection: 'row-reverse' })
      }}
      onPress={onPress}
    >
      <ChevronIcon
        disabled={disabled}
        size={styles.chevron.height}
        direction={direction}
      />
      {!disabled && <TextM>{labelDate}</TextM>}
    </TouchableOpacity>
  );
};
