import IdCardCloseButton from 'assets/images/icons/id-card/id-card-close-button.png';
import HeaderTitle from 'assets/images/icons/id-card/id-card-header-title.png';
import React from 'react';
import { ImageSourcePropType, TouchableOpacity, View } from 'react-native';

import { Image } from '@/components/atomic/Image/Image.atomic';
import { useDesign } from '@/hooks/useDesign';
import { DummyIdCardProfile, IdCardUniversityLogo } from '@/svgs';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  userProfileImg?: ImageSourcePropType;
  onPressClose: () => void;
}

const { getStyle } = createStyles(({ pixel, safeArea }) => ({
  idCardHeaderContainer: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  idCardHeaderFixedWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    ...platformSpecificStyle({
      ios: {},
      android: {
        marginTop: safeArea.top
      },
      default: {
        marginTop: safeArea.top
      }
    })
  },
  idCardHeaderTop: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...platformSpecificStyle({
      native: {
        height: pixel(100)
      },
      tablet: {
        height: pixel(100) / 2
      },
      default: {
        height: pixel(100) / 2
      }
    })
  },
  idCardHeaderTopLeft: {
    flexBasis: '15%'
  },
  idCardHeaderTopCenter: {
    flexBasis: '70%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  univLogo: {
    marginLeft: -5,
    ...platformSpecificStyle({
      native: {
        marginTop: pixel(29),
        width: pixel(360),
        height: pixel(79.51)
      },
      tablet: {
        marginTop: pixel(29) / 2,
        width: pixel(360) / 2,
        height: pixel(79.51) / 2
      },
      default: {
        marginTop: pixel(29) / 2,
        width: pixel(360) / 2,
        height: pixel(79.51) / 2
      }
    })
  },
  headerTitleImg: {
    ...platformSpecificStyle({
      native: {
        width: pixel(301),
        height: pixel(37),
        marginTop: pixel(34),
        marginBottom: pixel(30)
      },
      tablet: {
        width: pixel(301) / 2,
        height: pixel(37) / 2,
        marginTop: pixel(34) / 2,
        marginBottom: pixel(30) / 2
      },
      default: {
        width: pixel(301) / 2,
        height: pixel(37) / 2,
        marginTop: pixel(34) / 2,
        marginBottom: pixel(30) / 2
      }
    })
  },
  idCardHeaderTopRight: {
    flexBasis: '15%',
    height: pixel(130)
  },
  closeIcon: {
    ...platformSpecificStyle({
      native: { width: pixel(88) },
      tablet: { width: pixel(88) / 2 },
      default: { width: pixel(88) / 2 }
    })
  },
  idCardHeaderText: {
    color: '#000',
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(40)
      },
      tablet: {
        fontSize: pixel(40) / 2,
        marginVertical: pixel(24) / 2
      },
      default: {
        fontSize: pixel(40) / 2,
        marginVertical: pixel(24) / 2
      }
    })
  },
  profileImage: {
    ...platformSpecificStyle({
      native: { width: pixel(350) },
      tablet: { width: pixel(350) / 2 },
      default: { width: pixel(350) / 2 }
    })
  },
  dummyImage: {
    ...platformSpecificStyle({
      native: { width: pixel(300), height: pixel(354) },
      tablet: { width: pixel(300) / 2, height: pixel((300 * 144) / (122 * 2)) },
      default: { width: pixel(300) / 2, height: pixel((300 * 144) / (122 * 2)) }
    })
  }
}));

export const IdCardHeader: React.FC<Props> = ({
  userProfileImg,
  onPressClose
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  const containerHeight =
    (styles.idCardHeaderFixedWrap.marginTop || 0) +
    styles.idCardHeaderTop.height +
    styles.idCardHeaderText.fontSize +
    styles.headerTitleImg.marginTop +
    styles.headerTitleImg.marginBottom +
    (userProfileImg ? styles.profileImage.width : styles.dummyImage.height);

  return (
    <View
      style={{
        ...styles.idCardHeaderContainer,
        height: containerHeight
      }}
    >
      <View
        style={{
          ...styles.idCardHeaderFixedWrap
        }}
      >
        <View style={{ ...styles.idCardHeaderTop }}>
          <View style={{ ...styles.idCardHeaderTopLeft }} />
          <View style={{ ...styles.idCardHeaderTopCenter }}>
            <IdCardUniversityLogo style={{ ...styles.univLogo }} />
          </View>
          <TouchableOpacity
            style={{ ...styles.idCardHeaderTopLeft }}
            onPress={onPressClose}
          >
            <Image
              size={{ width: styles.closeIcon.width }}
              src={IdCardCloseButton}
            />
          </TouchableOpacity>
        </View>
        <Image
          style={{ ...styles.headerTitleImg }}
          size={{
            width: styles.headerTitleImg.width,
            height: styles.headerTitleImg.height
          }}
          src={HeaderTitle}
        />
        {userProfileImg && (
          <Image
            size={{ width: styles.profileImage.width }}
            src={userProfileImg}
          />
        )}
        {!userProfileImg && (
          <DummyIdCardProfile
            width={styles.dummyImage.width}
            height={styles.dummyImage.height}
          />
        )}
      </View>
    </View>
  );
};
