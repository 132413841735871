import React from 'react';
import { View } from 'react-native';

import { AttendanceInfo } from './AttendanceInfo.component';

import { PeriodLabel } from '@/components/PeriodLabel.component';
import { TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { AttendanceData } from '@/interfaces/Attendance.interface';
import { CalendarClass } from '@/interfaces/Calendar.interface';
import { EventCardSvg } from '@/svgs';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  eventInfo: CalendarClass;
  attendanceInfo?: AttendanceData;
  isActive: boolean;
  isExpired: boolean;
  isLocationPermissionsRejected: boolean;
  displayAttendanceInfo: boolean;
  isWithinBeforeAttendanceStartTime: boolean;
  isWithinAttendanceCheckingPeriod: boolean;
  onClickPermissionRejectedAlert: () => void;
}

const { getStyle } = createStyles(({ pixel, colors }) => ({
  container: {
    width: '100%',
    ...platformSpecificStyle({
      native: {
        height: '100%'
      },
      default: {
        height: pixel(90)
      },
      tablet: {
        width: 398,
        height: 180
      }
    })
  },
  informationContainer: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    ...platformSpecificStyle({
      native: {
        paddingHorizontal: pixel(50)
      },
      default: {
        paddingHorizontal: pixel(25)
      },
      tablet: {
        paddingHorizontal: pixel(25)
      }
    })
  },
  information: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {
        height: pixel(136)
      },
      default: {
        height: 68
      }
    })
  },
  informationBody: {
    flex: 1,
    ...platformSpecificStyle({
      native: {
        marginLeft: pixel(30)
      },
      default: {
        marginLeft: pixel(15)
      },
      tablet: {
        marginLeft: 15
      }
    })
  },
  informationContainerPast: { opacity: 0.2 },
  eventName: {
    fontWeight: 'bold',
    marginBottom: pixel(5)
  },
  textActive: {
    color: colors.textPrimaryInverted
  },

  separator: {
    backgroundColor: '#fff',
    width: '100%',
    height: pixel(1)
  },
  attendanceInfo: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {
        height: pixel(80)
      },
      default: {
        height: 40
      }
    })
  },
  attendanceText: {
    color: colors.textPrimaryInverted
  }
}));

export const EventCard: React.FC<Props> = ({
  eventInfo,
  attendanceInfo,
  isActive,
  isExpired,
  isLocationPermissionsRejected,
  displayAttendanceInfo,
  isWithinBeforeAttendanceStartTime,
  isWithinAttendanceCheckingPeriod,
  onClickPermissionRejectedAlert
}) => {
  const design = useDesign();
  const styles = getStyle(design);
  const { colors } = design;

  return (
    <View>
      <EventCardSvg
        color={
          isActive
            ? undefined
            : isExpired
            ? colors.cardBackground.secondary
            : colors.cardBackground.primary
        }
        size={{
          ...styles.container
        }}
      />
      <View
        style={{
          ...styles.informationContainer,
          ...(isExpired && styles.informationContainerPast)
        }}
      >
        <View
          style={{
            ...styles.information
          }}
        >
          <PeriodLabel
            period={eventInfo.period}
            color={isActive ? colors.primary : undefined}
          />
          <View style={{ ...styles.informationBody }}>
            <TextM
              numberOfLines={1}
              style={{
                ...styles.eventName,
                ...(isActive && styles.textActive)
              }}
            >
              {eventInfo.name}
            </TextM>
            <TextM
              numberOfLines={1}
              style={{ ...(isActive && styles.textActive) }}
            >
              {eventInfo.room.join('、')}
            </TextM>
          </View>
        </View>
        {displayAttendanceInfo && (
          <>
            <View
              // eslint-disable-next-line react-native/no-inline-styles
              style={{
                ...styles.separator,
                backgroundColor: isExpired
                  ? 'rgba(255,255,255,0.6)'
                  : isActive
                  ? '#CCCCCC'
                  : 'rgba(255,255,255,0.3)'
              }}
            />
            <AttendanceInfo
              isActive={isActive}
              isExpired={isExpired}
              isLocationPermissionsRejected={isLocationPermissionsRejected}
              eventInfo={eventInfo}
              attendanceInfo={attendanceInfo}
              isWithinAttendanceCheckingPeriod={
                isWithinAttendanceCheckingPeriod
              }
              isWithinBeforeAttendanceStartTime={
                isWithinBeforeAttendanceStartTime
              }
              onClickPermissionRejectedAlert={onClickPermissionRejectedAlert}
            />
          </>
        )}
      </View>
    </View>
  );
};
