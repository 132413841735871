import * as React from 'react';
import { View } from 'react-native';
import { Circle, G } from 'react-native-svg';

import { SvgProps, SvgWrapper } from '@/wrappers/svg';

interface Props extends SvgProps {
  progress: number;
  timelimitAlerting: boolean;
}
export const QrCountdownCircleSmall: React.FC<
  React.PropsWithChildren<Props>
> = ({ children, progress, timelimitAlerting, ...props }) => {
  return (
    <View
      style={{
        position: 'relative',
        width: 35,
        height: 35,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <SvgWrapper
        style={{ position: 'absolute', top: 1, left: 1 }}
        viewBox={{ width: 35, height: 35 }}
        {...props}
      >
        <G
          transform="scale(-1, 1) translate(-35, 0) rotate(-90 17 17)"
          id="circle_gr"
        >
          <G id="circle" fill="none" stroke="#e6e6e6" strokeWidth="3">
            <Circle cx="17" cy="17" r="17" stroke="none" />
            <Circle cx="17" cy="17" r="15" fill="none" />
          </G>
          <G
            id="indicator"
            fill="none"
            stroke={timelimitAlerting ? '#C54134' : '#3376d0'}
            strokeWidth="3"
            strokeDasharray={`${progress * 15 * Math.PI * 2} ${
              15 * Math.PI * 2
            }`}
          >
            <Circle cx="17" cy="17" r="17" stroke="none" />
            <Circle cx="17" cy="17" r="15" fill="none" />
          </G>
        </G>
      </SvgWrapper>
      {children}
    </View>
  );
};
