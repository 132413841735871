/* eslint-disable react-native/no-inline-styles */
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import { TextL, TextS } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { LogoCafeteriaIcon } from '@/svgs';
import { createStyles, openBrowser } from '@/utils';

interface Props {
  menus: string[];
  selectedDate: Date;
  isActive: boolean;
  isExpired: boolean;
}

const { getStyle } = createStyles(({ pixel }) => ({
  cafeteriaMenusContainer: {
    paddingHorizontal: pixel(40),
    paddingVertical: pixel(23),
    borderRadius: pixel(20),
    backgroundColor: '#fff'
  },
  cafeteriaMenuText: {
    color: '#000'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerTitle: {
    fontWeight: 'bold',
    fontSize: 18
  },
  menusContainer: {
    gap: pixel(2)
  }
}));

export const CafeteriaMenuCardDesktop: React.FC<Props> = ({
  menus,
  selectedDate,
  isActive,
  isExpired
}) => {
  const design = useDesign();
  const styles = getStyle(design);
  const { pixel, colors } = design;

  const isTodaySelected =
    format(new Date(), 'yyyyMMdd') === format(selectedDate, 'yyyyMMdd');

  const dateString = isTodaySelected
    ? '本日'
    : format(selectedDate, 'M月d日(E)', { locale: ja });

  const onPressLogo = () => {
    openBrowser({
      url: 'https://www.aim-kenko.jp/0002346',
      openInSameBrowser: true,
      onBrowserClose: () => {}
    });
  };

  return (
    <View
      style={{
        ...styles.cafeteriaMenusContainer,
        backgroundColor: isActive
          ? '#E3D6C1'
          : isExpired
          ? colors.cardBackground.secondary
          : colors.cardBackground.primary
      }}
    >
      <View
        style={{
          ...styles.header
        }}
      >
        <TextL
          style={{
            ...styles.headerTitle,
            color: isActive ? '#645241' : '#fff'
          }}
        >{`${dateString}のメニュー`}</TextL>
        <TouchableOpacity onPress={onPressLogo}>
          <LogoCafeteriaIcon
            size={{ width: pixel(50.31), height: pixel(37.11) }}
            opacity={1}
            color={isActive ? '#645241' : undefined}
          />
        </TouchableOpacity>
      </View>
      <View
        style={{
          ...styles.menusContainer
        }}
      >
        {menus?.map((menu) => (
          <TextS
            key={`menu_${menu}`}
            style={{
              ...styles.cafeteriaMenuText,
              color: isActive ? '#645241' : '#fff'
            }}
          >
            {menu}
          </TextS>
        ))}
      </View>
    </View>
  );
};
