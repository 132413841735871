import { useIsFocused } from '@react-navigation/native';
import { ReactElement, useState } from 'react';
import { View } from 'react-native';

import { QRCodeReaderScreen } from './QRCodeReader.screen';
import { QrReadHistoryScreen } from './QrReadHistory.screen';

import { Header } from '@/components/atomic/header';
import { Screen } from '@/components/atomic/screen';
import { Separator } from '@/components/atomic/separator';
import { TabFilter } from '@/components/atomic/tab-filter';
import { TextL, TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { createStyles, isMobile, platformSpecificStyle } from '@/utils';

const { getStyle } = createStyles(({ pixel }) => ({
  header: {
    width: '100%',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {},
      default: {
        marginBottom: pixel(60)
      }
    })
  },
  headerText: {
    fontWeight: 'bold',

    ...platformSpecificStyle({
      native: {},
      default: {
        fontSize: pixel(20)
      }
    })
  },
  contentContainer: {
    alignItems: 'center',
    flex: 1,
    ...platformSpecificStyle({
      native: {
        marginTop: pixel(-60)
      },
      default: {
        marginTop: pixel(-30)
      }
    })
  },
  tabText: {
    textAlignVertical: 'center',
    color: '#000',
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(22)
      },
      default: {
        fontSize: 11
      }
    })
  }
}));

const ScreenTitle = '学生証読み取り';

enum QrReaderViewType {
  READER = 'reader',
  READ_LIST = 'readList'
}

export const QrScanTabScreen = () => {
  const design = useDesign();
  const styles = getStyle(design);
  const isFocused = useIsFocused();

  const QrReaderViewTabs: Record<QrReaderViewType, ReactElement> = {
    reader: <TextM style={{ ...styles.tabText }}>学生証読み取り</TextM>,
    readList: <TextM style={{ ...styles.tabText }}>読み取り一覧</TextM>
  };

  const [selectedView, setSelectedView] = useState<QrReaderViewType>(
    QrReaderViewType.READER
  );

  return (
    <Screen>
      {isMobile ? (
        <Header title={ScreenTitle} />
      ) : (
        <View style={{ ...styles.header }}>
          <TextL style={styles.headerText}>{ScreenTitle}</TextL>
        </View>
      )}

      <View style={{ ...styles.contentContainer }}>
        <TabFilter
          selectFilterType={(view) => setSelectedView(view)}
          selectedFilterType={selectedView}
          filterTypesIconsMapping={QrReaderViewTabs}
        />
        <Separator size={10} />

        {selectedView === QrReaderViewType.READER && isFocused && (
          <QRCodeReaderScreen />
        )}

        {selectedView === QrReaderViewType.READ_LIST && <QrReadHistoryScreen />}
      </View>
    </Screen>
  );
};
