import React, { ReactElement } from 'react';

import { TabFilter } from '@/components/atomic/tab-filter';
import { TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { NotificationsFilterTypes } from '@/interfaces';
import {
  FilterCampusPlanLabelIcon,
  FilterClassroomLabelIcon,
  FilterGmailLabelIcon
} from '@/svgs';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  selectedFilterType: NotificationsFilterTypes;
  selectFilterType: (filterType: NotificationsFilterTypes) => void;
}

const { getStyle } = createStyles(({ pixel }) => ({
  filterIconText: {
    textAlignVertical: 'center',
    color: '#000',
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(22)
      },
      default: {
        fontSize: pixel(14)
      }
    })
  },
  filterClassroomLabelIcon: {
    ...platformSpecificStyle({
      native: {
        width: pixel(82.61),
        height: pixel(16.654)
      },
      default: {
        width: pixel(82.6) * 0.75,
        height: pixel(16.654) * 0.75
      }
    })
  },
  filterGmailLabelIcon: {
    ...platformSpecificStyle({
      native: {
        width: pixel(45.848),
        height: pixel(16.654)
      },
      default: {
        width: pixel(45.848) * 0.75,
        height: pixel(16.654) * 0.75
      }
    })
  },
  filterCampusPlanLabelIcon: {
    ...platformSpecificStyle({
      android: {
        marginTop: pixel(6), // Cam p us の p が svg だと下に飛び出ている分、alignItems:center 時に、上にズレるため調整
        width: pixel(99.088),
        height: pixel(21.252)
      },
      ios: {
        marginTop: pixel(2), // Cam p us の p が svg だと下に飛び出ている分、alignItems:center 時に、上にズレるため調整
        width: pixel(99.088),
        height: pixel(21.252)
      },
      default: {
        marginTop: pixel(2),
        width: pixel(99.088) * 0.7,
        height: pixel(21.252) * 0.7
      }
    })
  }
}));

export const NotificationsFilter: React.FC<Props> = ({
  selectFilterType,
  selectedFilterType
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  const FilterTypesIconsMapping: Record<
    NotificationsFilterTypes,
    ReactElement
  > = {
    [NotificationsFilterTypes.ALL]: (
      <TextM style={{ ...styles.filterIconText }}>すべて</TextM>
    ),
    [NotificationsFilterTypes.UNREAD]: (
      <TextM style={{ ...styles.filterIconText }}>未読のみ</TextM>
    ),
    [NotificationsFilterTypes.CAMPUS_PLAN]: (
      <FilterCampusPlanLabelIcon
        style={{ ...styles.filterCampusPlanLabelIcon }}
        size={{
          ...styles.filterCampusPlanLabelIcon
        }}
      />
    ),
    [NotificationsFilterTypes.CLASS_ROOM]: (
      <FilterClassroomLabelIcon
        style={{ ...styles.filterClassroomLabelIcon }}
        size={{
          ...styles.filterClassroomLabelIcon
        }}
      />
    ),
    [NotificationsFilterTypes.GMAIL]: (
      <FilterGmailLabelIcon
        style={{ ...styles.filterGmailLabelIcon }}
        size={{
          ...styles.filterGmailLabelIcon
        }}
      />
    )
  };

  return (
    <TabFilter
      selectFilterType={selectFilterType}
      selectedFilterType={selectedFilterType}
      filterTypesIconsMapping={FilterTypesIconsMapping}
    />
  );
};
