import { api } from '..';

import { AuthTokens, LogoutResponse } from '@/interfaces/Auth.interface';
import { REFRESH_TOKEN, getStorage } from '@/utils/storage.util';

export const refreshTokens = async () => {
  const previousRefreshToken = await getStorage(REFRESH_TOKEN);

  const { data } = await api.post<AuthTokens>('/auth/refresh', {
    refreshToken: previousRefreshToken
  });

  return data;
};

export const logout = async () => {
  const { data } = await api.get<LogoutResponse>('auth/logout');

  return data;
};
