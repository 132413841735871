import * as React from 'react';
import Svg, { G, Path, SvgProps } from 'react-native-svg';

/* SVGR has dropped some elements not supported by react-native-svg: style */

export const DummyIdCardProfile: React.FC<SvgProps> = ({ style, ...props }) => {
  return (
    <Svg
      width="122.000000"
      height="144.000000"
      viewBox="0 0 122.000000 144.000000"
      style={[{ backgroundColor: '#fff' }, style]}
      {...props}
    >
      <G fill="#000" stroke="#000">
        <Path
          d="M5 1428c-3-7-4-330-3-718L5 5h1210v1430l-603 3c-477 2-604 0-607-10zm1205-708V10H10v1420h1200V720z"
          transform="matrix(.1 0 0 -.1 0 144)"
        />
        <Path
          d="M545 1106c-44-19-103-89-111-131-19-104 20-196 101-235 122-59 255 34 255 178 0 64-13 98-53 141-55 58-127 76-192 47zM460 599c-144-37-207-100-217-219l-6-60h746l-6 60c-11 120-73 182-221 220-99 25-198 25-296-1z"
          transform="matrix(.1 0 0 -.1 0 144)"
        />
      </G>
    </Svg>
  );
};
