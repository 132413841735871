import { ViewStyle } from '@expo/html-elements/build/primitives/View';
import { format } from 'date-fns';
import React from 'react';
import { ImageSourcePropType, StyleProp, View } from 'react-native';

import { Image } from '@/components/atomic/Image/Image.atomic';
import { TextL, TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { UserProfile } from '@/interfaces';
import { DummyIdCardProfile } from '@/svgs';
import { createStyles, platformSpecificStyle } from '@/utils';

export type ReadProfileCardProps = {
  profile: UserProfile;
  image?: ImageSourcePropType | null;
  federationId?: string;
  readAt?: Date;
  style?: StyleProp<ViewStyle>;
};

const { getStyle } = createStyles(({ colors, pixel }) => ({
  userCard: {
    backgroundColor: '#fff',
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    padding: pixel(20)
  },
  userInfo: {
    flex: 1,
    paddingLeft: pixel(20)
  },
  userHeaderInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  profileImage: {
    ...platformSpecificStyle({
      native: { width: pixel(186) },
      tablet: { width: pixel(186) / 2 },
      default: { width: pixel(186) / 2 }
    })
  },
  imageContainer: {
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {
        height: pixel(186),
        width: pixel(140)
      },
      tablet: {
        height: pixel(186) / 2,
        width: pixel(140) / 2
      },
      default: {
        height: pixel(186) / 2,
        width: pixel(140) / 2
      }
    })
  },
  text: { color: colors.textPrimaryInverted }
}));

export const ReadProfileCard: React.FC<ReadProfileCardProps> = ({
  profile,
  image,
  federationId,
  readAt,
  style
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <View style={[styles.userCard, style]}>
      <View style={styles.imageContainer}>
        {image && (
          <Image size={{ width: styles.profileImage.width }} src={image} />
        )}
        {!image && <DummyIdCardProfile width={styles.profileImage.width} />}
      </View>
      <View style={styles.userInfo}>
        <View style={styles.userHeaderInfo}>
          <TextM style={styles.text}>{federationId}</TextM>
          <TextM style={styles.text}>
            {(readAt && format(new Date(readAt), 'yyyy/MM/dd HH:mm')) || ''}
          </TextM>
        </View>
        <TextM style={styles.text}>{profile.faculty}</TextM>
        <TextM style={styles.text}>{profile.department}</TextM>
        <TextL style={styles.text}>{profile.name}</TextL>
      </View>
    </View>
  );
};
