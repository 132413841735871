import React from 'react';
import { Code } from 'react-native-vision-camera';

type Props = {
  handleQrCodeScanned?: (params: Code) => void;
};

export const CameraFrameView: React.FC<Props> = (_props) => {
  return <></>;
};
