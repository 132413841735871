import { useFocusEffect, useNavigation } from '@react-navigation/native';
import React, { useCallback, useRef } from 'react';
import { RefreshControl, RefreshControlProps, ScrollView } from 'react-native';

import { useDesign } from '@/hooks/useDesign';
import { RootTabParamList, RootTabScreenProps } from '@/navigation/types';
import { createStyles, isMobile, platformSpecificStyle } from '@/utils';

const { getStyle } = createStyles(({ pixel }) => ({
  content: {
    ...platformSpecificStyle({
      native: {
        paddingBottom: pixel(90)
      },
      default: {
        flex: 1
      }
    })
  }
}));

export const PullToRefreshWrapper: React.FC<RefreshControlProps> = ({
  children,
  refreshing,
  onRefresh
}) => {
  const design = useDesign();
  const styles = getStyle(design);
  const navigation =
    useNavigation<RootTabScreenProps<keyof RootTabParamList>['navigation']>();
  const scrollRef = useRef<ScrollView>(null);

  useFocusEffect(
    useCallback(() => {
      const unsubscribe = navigation.addListener('tabPress', () => {
        scrollRef.current?.scrollTo({
          y: 0,
          animated: true
        });
      });

      return unsubscribe;
    }, [navigation])
  );

  return (
    <ScrollView
      ref={scrollRef}
      showsHorizontalScrollIndicator={false}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{ ...styles.content }}
      refreshControl={
        isMobile && refreshing !== undefined && onRefresh ? (
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        ) : undefined
      }
    >
      {children}
    </ScrollView>
  );
};
