import { useFocusEffect } from '@react-navigation/native';
import { format } from 'date-fns';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { FlatList, View } from 'react-native';

import { AttendanceProfileCard } from './components/AttendanceProfileCard.component';

import { exportAttendanceHistory } from '@/api/calls/teacher.api';
import { OptionButton } from '@/components/atomic/button';
import { ConfirmDialog } from '@/components/atomic/dialog';
import { Header } from '@/components/atomic/header';
import { Loader } from '@/components/atomic/loader';
import { Screen } from '@/components/atomic/screen';
import { Separator } from '@/components/atomic/separator';
import { TabFilter } from '@/components/atomic/tab-filter';
import { TextL, TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { useFocus } from '@/hooks/useFocus';
import { useLoading } from '@/hooks/useLoading';
import { AttendanceStatus } from '@/interfaces/Teacher.interface';
import {
  TeacherHomeStackParamList,
  TeacherHomeStackScreenProps
} from '@/navigation/types';
import {
  fetchAndStoreStudentProfileImage,
  getClassAttendance,
  useAppDispatch,
  useAppSelector
} from '@/store';
import { CsvExportIcon } from '@/svgs';
import { createStyles, localizedFormat, platformSpecificStyle } from '@/utils';

const { getStyle } = createStyles(({ pixel }) => ({
  screenContainer: {
    ...platformSpecificStyle({
      native: {},
      default: {
        padding: 0
      }
    })
  },
  header: {
    width: '100%',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {},
      default: {
        marginBottom: pixel(60)
      }
    })
  },
  headerText: {
    fontWeight: 'bold',

    ...platformSpecificStyle({
      native: {},
      default: {
        fontSize: pixel(20)
      }
    })
  },
  className: {
    width: '100%',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {
        marginTop: pixel(-110),
        marginBottom: pixel(120)
      },
      default: {
        marginTop: pixel(-50),
        marginBottom: pixel(60)
      }
    })
  },
  contentContainer: {
    alignItems: 'center',
    flex: 1,
    ...platformSpecificStyle({
      native: {
        marginTop: pixel(-60)
      },
      default: {
        marginTop: pixel(-30)
      }
    })
  },
  tabText: {
    textAlignVertical: 'center',
    color: '#000',
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(22)
      },
      default: {
        fontSize: 11
      }
    })
  },
  loader: {
    flex: 1
  },
  buttonsWrapper: {
    marginBottom: pixel(10),
    marginTop: pixel(-10),
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-end'
  },
  profileList: {
    flex: 1,
    width: '100%'
  },
  card: {
    marginBottom: 6
  },
  noDataMessage: {
    textAlign: 'center',
    fontSize: pixel(28),
    lineHeight: pixel(44),
    marginTop: pixel(90)
  }
}));

enum AttendanceViewType {
  COMPACT_VIEW = 'compactView',
  DETAILED_VIEW = 'detailedView'
}

export const ClassAttendanceScreen = ({
  navigation,
  route
}: TeacherHomeStackScreenProps<keyof TeacherHomeStackParamList>) => {
  const design = useDesign();
  const styles = getStyle(design);
  const { colors } = design;
  const dispatch = useAppDispatch();
  const {
    classId = '',
    className = '',
    period = '',
    date = new Date().toISOString()
  } = route.params || {};

  const QrReaderViewTabs: Record<AttendanceViewType, ReactElement> = {
    compactView: <TextM style={{ ...styles.tabText }}>コンパクト表示</TextM>,
    detailedView: <TextM style={{ ...styles.tabText }}>詳細表示</TextM>
  };

  const { profileImages } = useAppSelector((state) => state.teacherData);
  const { email } = useAppSelector((state) => state.account.profile);

  const [selectedView, setSelectedView] = useState<AttendanceViewType>(
    AttendanceViewType.COMPACT_VIEW
  );
  const [attendanceList, setAttendanceList] = useState<AttendanceStatus[]>([]);
  const [displayedAttendanceList, setDisplayedAttendanceList] = useState<
    AttendanceStatus[]
  >([]);
  const [isMailModalVisible, setIsMailModalVisible] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const { isLoading, refresh, isPtrLoading, pullToRefresh } = useLoading(() => {
    if (!classId || !period || isLoading || isPtrLoading) return [];

    return [fetchData()];
  });

  const fetchData = async () => {
    const { payload } = await dispatch(
      getClassAttendance({
        date: format(new Date(date), 'yyyy/MM/dd'),
        classId,
        period: Number(period)
      })
    );

    if (payload && !('error' in payload)) {
      setAttendanceList(payload);
      setDisplayedAttendanceList(payload.slice(0, 20));
    } else {
      setAttendanceList([]);
    }
  };
  useEffect(() => {
    // プロファイル画像を取得する
    if (
      selectedView !== AttendanceViewType.COMPACT_VIEW &&
      attendanceList &&
      attendanceList.length > 0
    ) {
      const readIds = attendanceList
        .map((item) => item.profile?.gakusekiNo)
        .filter((id): id is string => Boolean(id));

      const uniqueIds = Array.from(new Set(readIds));

      dispatch(fetchAndStoreStudentProfileImage(uniqueIds));
    }
  }, [selectedView, displayedAttendanceList]);

  const { isFocused, subscribe, unsubscribe } = useFocus();

  const dateHeader = `${localizedFormat(
    new Date(date),
    'MMMdo (EEEEE)'
  )} ${Number(period)}限`;

  // fetch resource when tab is focused
  useFocusEffect(
    useCallback(() => {
      subscribe();
      refresh();

      return () => {
        unsubscribe();
      };
    }, [])
  );

  // fetch resource browser window or app is focused
  useEffect(() => {
    if (isFocused) {
      refresh();
    }
  }, [isFocused]);

  const onEndReachedHandler = () => {
    if (displayedAttendanceList.length === attendanceList.length) return;

    setDisplayedAttendanceList((state) =>
      attendanceList.slice(0, state.length + 20)
    );
  };

  const handleCsvExport = async () => {
    try {
      setIsModalLoading(true);

      const res = await exportAttendanceHistory({
        date: format(new Date(date), 'yyyy/MM/dd'),
        classId,
        period: Number(period)
      });

      if (res.status !== 'success') {
        throw new Error('CSV出力に失敗しました!');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsModalLoading(false);
      setIsMailModalVisible(false);
    }
  };

  return (
    <Screen style={{ ...styles.screenContainer }}>
      <Header
        title={dateHeader}
        onBackPress={() => {
          navigation.goBack();
        }}
      />
      <View style={{ ...styles.className }}>
        <TextL style={styles.headerText}>{className}</TextL>
      </View>

      <View style={{ ...styles.contentContainer }}>
        <TabFilter
          selectFilterType={(view) => setSelectedView(view)}
          selectedFilterType={selectedView}
          filterTypesIconsMapping={QrReaderViewTabs}
        />
        <Separator size={10} />

        <View style={styles.buttonsWrapper}>
          <OptionButton
            text={`受講生一覧（${attendanceList.length}名）を出力`}
            icon={CsvExportIcon}
            disabled={!attendanceList || !attendanceList.length}
            onPress={() => {
              setIsMailModalVisible(true);
            }}
          />
        </View>

        {isLoading && (
          <Loader style={styles.loader} color={colors.secondary} size="large" />
        )}

        {!isLoading && !attendanceList.length && (
          <TextM style={styles.noDataMessage}>
            表示できるデータがありません。
          </TextM>
        )}

        {!isLoading && (
          <FlatList
            data={displayedAttendanceList}
            renderItem={({ item }) => (
              <AttendanceProfileCard
                key={item.federationId}
                {...item}
                statusConfirmed={
                  item.statusMiddle === 'confirmed' &&
                  item.statusLast === 'confirmed'
                }
                image={profileImages[item.profile.gakusekiNo]}
                compact={selectedView === AttendanceViewType.COMPACT_VIEW}
                style={styles.card}
              />
            )}
            keyExtractor={(item, index) => `card-${item.federationId}-${index}`}
            style={styles.profileList}
            refreshing={isPtrLoading}
            onRefresh={() => pullToRefresh()}
            onEndReached={onEndReachedHandler}
            onEndReachedThreshold={0.6}
          />
        )}
      </View>

      <ConfirmDialog
        isModalOpen={isMailModalVisible}
        isLoading={isModalLoading}
        title="出席一覧（CSV）をメールで送信します"
        description={`CSVファイルに「${className}」の出席一覧を ”${email}”宛に送信します。\nよろしいですか？\n\n※書き出したCSVファイルは端末には保存されません。`}
        confirmBtnText="メール送信"
        confirmBtnStyle={{ color: '#0066CC' }}
        onPressClose={() => {
          setIsMailModalVisible(false);
        }}
        onPressConfirm={handleCsvExport}
      />
    </Screen>
  );
};
