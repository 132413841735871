import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import React, { useState } from 'react';
import { Modal, TouchableOpacity, View } from 'react-native';

import { Button } from '@/components/atomic/button';
import { ModalBackgroundOverlay } from '@/components/atomic/modal/ModalBackgroundOverlay.atomic';
import { TextL, TextM, TextS } from '@/components/atomic/text';
import {
  CAFETERIA_MENU_CARD_BUTTON_HEIGHT_MOBILE,
  CAFETERIA_MENU_CARD_BUTTON_HEIGHT_WEB
} from '@/constants/Home';
import { useDesign } from '@/hooks/useDesign';
import { CafeteriaMenusCardSvg, LogoCafeteriaIcon } from '@/svgs';
import { createStyles, openBrowser, platformSpecificStyle } from '@/utils';

interface Props {
  menus: string[];
  selectedDate: Date;
  isActive: boolean;
  isExpired: boolean;
}

const { getStyle } = createStyles(({ pixel, colors }) => ({
  logoTextWrapper: {
    width: '100%',
    height: '80%',
    gap: pixel(24),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: pixel(44)
  },
  eventCard: {
    width: '100%',
    ...platformSpecificStyle({
      native: {
        height: '100%'
      },
      default: {
        height: pixel(90)
      }
    })
  },
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  circleHalfHole: {
    backgroundColor: colors.cardBackground.secondary,

    ...platformSpecificStyle({
      native: {
        height: (pixel(CAFETERIA_MENU_CARD_BUTTON_HEIGHT_MOBILE) * 2) / 5,
        width: pixel(CAFETERIA_MENU_CARD_BUTTON_HEIGHT_MOBILE) / 4,
        borderBottomRightRadius: 50,
        borderTopRightRadius: 50
      },
      default: {
        height: (pixel(CAFETERIA_MENU_CARD_BUTTON_HEIGHT_WEB) * 2) / 5,
        width: pixel(CAFETERIA_MENU_CARD_BUTTON_HEIGHT_WEB) / 4,
        borderBottomRightRadius: 50,
        borderTopRightRadius: 50
      }
    })
  },
  // Dialog
  dialogOverlay1stLayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    backgroundColor: '#E57373'
  },
  cafeteriaMenusContainer: {
    width: '90%',
    height: '68%',
    paddingHorizontal: pixel(80),
    paddingBottom: pixel(100),
    paddingTop: pixel(90),
    borderRadius: pixel(20),
    opacity: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    backgroundColor: '#fff',
    zIndex: 11
  },
  cafeteriaMenusWrap: {
    width: '100%'
  },
  cafeteriaMenuHeader: {
    width: '100%',
    height: pixel(80),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logo: {
    backgroundColor: '#ff0000',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    flexDirection: 'row',
    zIndex: 100,
    width: pixel(64),
    ...platformSpecificStyle({
      default: {
        height: 60
      },
      native: {
        height: pixel(120)
      }
    })
  },
  cafeteriaMenuTitle: {
    color: '#000',
    fontWeight: 'bold',
    fontSize: pixel(38)
  },
  cafeteriaMenuTextsWrap: {
    marginTop: pixel(70),
    fontSize: pixel(30)
  },
  cafeteriaMenuText: {
    color: '#000',
    lineHeight: pixel(42)
  }
}));

export const CafeteriaMenuCardButtonWithDialog: React.FC<Props> = ({
  menus,
  selectedDate,
  isActive,
  isExpired
}) => {
  const design = useDesign();
  const styles = getStyle(design);
  const { pixel, colors } = design;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const onPressButton = () => setIsModalOpen(true);
  const onPressClose = () => setIsModalOpen(false);
  const isTodaySelected =
    format(new Date(), 'yyyyMMdd') === format(selectedDate, 'yyyyMMdd');

  const dateString = isTodaySelected
    ? '本日'
    : format(selectedDate, 'M月d日(E)', { locale: ja });
  const onPressLogo = () => {
    openBrowser({
      url: 'https://www.aim-kenko.jp/0002346',
      openInSameBrowser: true,
      onBrowserClose: () => {}
    });
  };

  return (
    <>
      {menus && menus.length > 0 && (
        <View>
          <CafeteriaMenusCardSvg
            color={
              isActive
                ? '#E3D6C1'
                : isExpired
                ? colors.cardBackground.secondary
                : colors.cardBackground.primary
            }
            size={{
              ...styles.eventCard
            }}
          />
          <View
            style={{
              ...styles.container
            }}
          >
            <TouchableOpacity
              style={{ ...styles.logoTextWrapper }}
              onPress={onPressButton}
            >
              <LogoCafeteriaIcon
                size={{ width: pixel(64.65), height: pixel(47.68) }}
                opacity={1}
                color={isActive ? '#645241' : undefined}
              />
              <TextM
                // eslint-disable-next-line react-native/no-inline-styles
                style={{
                  color: isActive ? '#645241' : '#fff'
                }}
              >
                {dateString}
                のメニュー
              </TextM>
            </TouchableOpacity>
          </View>
        </View>
      )}
      <Modal visible={isModalOpen}>
        <ModalBackgroundOverlay>
          <View style={{ ...styles.cafeteriaMenusContainer }}>
            <View style={{ ...styles.cafeteriaMenusWrap }}>
              <View
                style={{
                  ...styles.cafeteriaMenuHeader
                }}
              >
                <TextL
                  selectable
                  style={{ ...styles.cafeteriaMenuTitle }}
                >{`${dateString}のメニュー`}</TextL>
                <TouchableOpacity onPress={onPressLogo}>
                  <LogoCafeteriaIcon
                    size={{ width: pixel(64.65), height: pixel(47.68) }}
                    opacity={1}
                    color="#000"
                  />
                </TouchableOpacity>
              </View>
              <View style={{ ...styles.cafeteriaMenuTextsWrap }}>
                {menus?.map((menu) => (
                  <TextS
                    selectable
                    key={`menu_${menu}`}
                    style={{ ...styles.cafeteriaMenuText }}
                  >
                    {menu}
                  </TextS>
                ))}
              </View>
            </View>
            <Button
              modal
              fullWidth
              labelStyle={{ fontWeight: 'normal' }}
              label="閉じる"
              onPress={onPressClose}
            />
          </View>
        </ModalBackgroundOverlay>
      </Modal>
    </>
  );
};
