import { Image } from 'react-native';

export type Source = {
  uri?: string | undefined;
  headers?: { [key: string]: string } | undefined;
  priority?: 'low' | 'normal' | 'high' | undefined;
  cache?: Cache | undefined;
};

export default Image;
