import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';

import { fetchCalendarList } from '@/api/calls/calendar.api';
import {
  CalendarClass,
  CalendarEntriesResponse,
  CalendarEvent
} from '@/interfaces/Calendar.interface';
import { RootState } from '@/store/store';
import { convertTimeStringToDate } from '@/utils';

interface State {
  list: CalendarEntriesResponse;
  isLoading: boolean;
}

const initialState: State = {
  list: {},
  isLoading: false
};

export const selectCalendarEvent = (
  { calendar: { list } }: RootState,
  selectedDate: Date
): CalendarEvent[] => {
  return list[format(selectedDate, 'yyyyMMdd')]?.events ?? [];
};

export const selectCalendarSchedule = (
  { calendar }: RootState,
  selectedDate: Date
): CalendarClass[] =>
  [
    ...(calendar.list[format(selectedDate, 'yyyyMMdd')]?.schedule?.map(
      (schedule) => ({
        ...schedule,
        period: {
          from: convertTimeStringToDate({
            timeString: schedule.period.from,
            selectedDate
          }),
          to: convertTimeStringToDate({
            timeString: schedule.period.to,
            selectedDate
          })
        }
      })
    ) ?? [])
  ].sort((a, b) => Number(a.period.from) - Number(b.period.from));

export const selectCalendarCafeteriaMenus = (
  { calendar }: RootState,
  selectedDate: Date
) => calendar.list[format(selectedDate, 'yyyyMMdd')]?.cafeteria.menus;

export const getCalendarList = createAsyncThunk<
  { from: string; to?: string; list: CalendarEntriesResponse } | undefined,
  { from: Date; to?: Date },
  { state: RootState }
>('calendar/getList', async ({ from, to }, { dispatch }) => {
  dispatch(calendarAction.setIsLoading({ isLoading: true }));

  const fromParam = format(from, 'yyyy/MM/dd');
  const toParam = to ? format(to, 'yyyy/MM/dd') : undefined;
  const calendarList = await fetchCalendarList(fromParam, toParam);

  return {
    from: fromParam,
    to: toParam,
    list: calendarList
  };
});

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setIsLoading: (
      state,
      { payload }: PayloadAction<{ isLoading: boolean }>
    ) => {
      state.isLoading = payload.isLoading;
    }
  },
  extraReducers(builder) {
    builder.addCase(getCalendarList.fulfilled, (state, { payload }) => {
      if (payload?.list) {
        const { from, to, list } = payload;
        state.list =
          to && from !== to ? list : Object.assign({}, state.list, list);
      }
      state.isLoading = false;
    });
    builder.addCase(getCalendarList.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const calendarAction = calendarSlice.actions;
export const calendarReducer = calendarSlice.reducer;
