import QrErrorWhite from 'assets/images/icons/id-card/qr-error-white.png';
import QrError from 'assets/images/icons/id-card/qr-error.png';
import React, { useCallback, useMemo } from 'react';
import { ImageSourcePropType, View } from 'react-native';

import { Button } from '../atomic/button';

import { Image } from '@/components/atomic/Image/Image.atomic';
import { Loader } from '@/components/atomic/loader';
import { TextL } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { QrCountdownCircle } from '@/svgs/qr/QrCountdownCircle.svg';
import { ErrorCodes, createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  timelimitAlerting: boolean;
  qrImageData: ImageSourcePropType;
  secondsRemaining: number;
  secondsRemainingForDisplay: number;
  onRetryCallback: () => Promise<void>;
  errorCode: string | undefined;
  forStudent: boolean;
}

const { getStyle } = createStyles(
  ({ pixel, windowDimensions, isLandscape }) => ({
    qrErrorImage: {
      ...platformSpecificStyle({
        ios: {
          width:
            windowDimensions.height < 800
              ? pixel(200)
              : windowDimensions.height < 900
              ? pixel(246)
              : pixel(270)
        },
        android: {
          width:
            windowDimensions.height < 800
              ? pixel(200)
              : windowDimensions.height < 900
              ? pixel(246)
              : pixel(250)
        },
        tablet: {
          width: isLandscape
            ? windowDimensions.height < 768
              ? pixel(160) / 2
              : pixel(200) / 2
            : pixel(300) / 2
        },
        default: {
          width: pixel(300) / 2
        }
      })
    },
    remainingTimeText: {
      color: '#3376D0',
      textAlign: 'left',
      ...platformSpecificStyle({
        native: {
          fontSize: pixel(34)
        },
        tablet: {
          fontSize: pixel(34) / 2
        },
        default: {
          fontSize: pixel(34) / 2
        }
      })
    },
    alertingTextColor: {
      color: '#C54134'
    },
    qrPageBody: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      ...platformSpecificStyle({
        native: {
          marginTop: pixel(100)
        },
        tablet: {
          marginTop: isLandscape ? pixel(100) / 2 : pixel(100)
        },
        default: {
          marginTop: pixel(100) / 2
        }
      })
    },
    qrModalTimer: {
      width: 70,
      height: 70,
      backgroundColor: '#fff',
      borderRadius: 35,
      padding: 7.5,
      ...platformSpecificStyle({
        native: {
          marginTop: pixel(32)
        },
        tablet: {
          marginTop: pixel(22) / 2
        },
        default: {
          marginTop: pixel(32) / 2
        }
      })
    },
    errorMessagesWrap: {
      marginTop: pixel(28),
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '80%'
    },
    errorMessage: {
      color: '#000',
      fontWeight: 'bold',
      textAlign: 'center',
      ...platformSpecificStyle({
        native: {
          fontSize: pixel(26)
        },
        tablet: {
          fontSize: pixel(26) / 2
        },
        default: {
          fontSize: pixel(26) / 2
        }
      })
    },
    forStudent: {
      color: '#000'
    },
    forTeacher: {
      color: '#fff'
    },
    errorCodeMessage: {
      color: '#000',
      fontWeight: 'bold',
      ...platformSpecificStyle({
        native: {
          fontSize: pixel(22)
        },
        tablet: {
          fontSize: pixel(22) / 2
        },
        default: {
          fontSize: pixel(22) / 2
        }
      })
    },
    errorMessageSub: {
      color: '#000',
      textAlign: 'center',
      marginTop: pixel(30),
      ...platformSpecificStyle({
        native: {
          fontSize: pixel(26)
        },
        tablet: {
          fontSize: pixel(26) / 2
        },
        default: {
          fontSize: pixel(26) / 2
        }
      })
    },
    retryButtonContainer: {
      backgroundColor: '#F2F2F2',
      alignSelf: 'center',
      ...platformSpecificStyle({
        native: {
          marginTop: pixel(40),
          width: pixel(212),
          height: pixel(80)
        },
        tablet: {
          marginTop: pixel(40) / 2,
          width: pixel(212) / 2,
          height: pixel(80) / 2
        },
        default: {
          marginTop: pixel(40) / 2,
          width: pixel(212) / 2,
          height: pixel(80) / 2
        }
      })
    },
    retryButtonLabel: {
      color: '#0066CC',
      ...platformSpecificStyle({
        native: {
          fontSize: pixel(30)
        },
        tablet: {
          fontSize: pixel(30) / 2
        },
        default: {
          fontSize: pixel(30) / 2
        }
      })
    },
    qrImageDetail: {
      ...platformSpecificStyle({
        native: { width: pixel(400) },
        tablet: { width: isLandscape ? pixel(300) : pixel(400) },
        default: { width: pixel(400) / 2 }
      })
    }
  })
);

export const QrPageBody: React.FC<Props> = ({
  qrImageData,
  secondsRemaining,
  secondsRemainingForDisplay,
  onRetryCallback,
  errorCode,
  timelimitAlerting,
  forStudent
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  const retry = useCallback(async () => {
    await onRetryCallback();
  }, []);

  const errorMessage = useMemo(() => {
    if (errorCode?.startsWith('4')) {
      switch (errorCode) {
        case ErrorCodes.TimeoutError:
          return {
            text: 'リクエストがタイムアウトしました。',
            subText: 'インターネット接続環境を確認してください。'
          };
        default:
          return {
            text: 'QRコードの生成に失敗しました。',
            subText:
              'お手数をおかけしますが、しばらくたってからアクセスしてください。'
          };
      }
    }
    if (errorCode?.startsWith('5')) {
      return {
        text: 'サーバーの問題でQRコードを表示できません。',
        subText:
          'お手数をおかけしますが、しばらくたってからアクセスしてください。'
      };
    }

    if (errorCode === ErrorCodes.NetworkError) {
      return {
        text: 'インターネット接続がオフラインのため\nQRコードを表示できません。',
        subText: 'インターネット接続環境を\n確認してください。'
      };
    }

    return {
      text: 'QRコードの生成に失敗しました。',
      subText:
        'お手数をおかけしますが、しばらくたってからアクセスしてください。'
    };
  }, [errorCode]);

  const qrImage = useMemo(() => {
    if (errorCode || !qrImageData) {
      return forStudent ? QrError : QrErrorWhite;
    }

    return qrImageData;
  }, [qrImageData, errorCode, forStudent]);

  return (
    <View style={{ ...styles.qrPageBody }}>
      {!qrImageData && !errorCode ? (
        <Loader color="#444" size="large" />
      ) : (
        <>
          <Image
            size={{
              width: errorCode
                ? styles.qrErrorImage.width
                : styles.qrImageDetail.width
            }}
            src={qrImage}
          />
          {errorCode ? (
            <View style={{ ...styles.errorMessagesWrap }}>
              <TextL
                style={{
                  ...styles.errorMessage,
                  ...(!forStudent && styles.forTeacher)
                }}
              >{`${errorMessage.text}`}</TextL>
              {!isNaN(Number(errorCode)) && (
                <TextL
                  style={{
                    ...styles.errorCodeMessage,
                    ...(!forStudent && styles.forTeacher)
                  }}
                >{` (エラーコード: ${errorCode}）`}</TextL>
              )}

              <TextL
                numberOfLines={3}
                style={{
                  ...styles.errorMessageSub,
                  ...(!forStudent && styles.forTeacher)
                }}
              >{`${errorMessage.subText}`}</TextL>
              <Button
                modal
                fullWidth
                containerStyle={{ ...styles.retryButtonContainer }}
                labelStyle={{ ...styles.retryButtonLabel }}
                label="リトライ"
                onPress={retry}
              />
            </View>
          ) : (
            <View style={{ ...styles.qrModalTimer }}>
              <QrCountdownCircle
                size={{ width: 52, height: 52 }}
                progress={Math.max(secondsRemaining / 30000, 0)}
                timelimitAlerting={timelimitAlerting}
              >
                <TextL
                  style={{
                    ...styles.remainingTimeText,
                    ...(timelimitAlerting && {
                      ...styles.alertingTextColor
                    })
                  }}
                >{` ${Math.max(secondsRemainingForDisplay, 0)}`}</TextL>
              </QrCountdownCircle>
            </View>
          )}
        </>
      )}
    </View>
  );
};
