import React from 'react';
import { View } from 'react-native';

import { useDesign } from '@/hooks/useDesign';
import { IdCardUniversityLogo } from '@/svgs';
import { createStyles, platformSpecificStyle } from '@/utils';

const { getStyle } = createStyles(({ pixel }) => ({
  idCardHeaderTop: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...platformSpecificStyle({
      native: {
        marginTop: pixel(150),
        height: pixel(100)
      },
      tablet: {
        marginTop: pixel(150) / 2,
        height: pixel(100) / 2
      },
      default: {
        marginTop: pixel(150) / 2,
        height: pixel(100) / 2
      }
    })
  },
  idCardHeaderTopLeft: {
    flexBasis: '15%'
  },
  idCardHeaderTopCenter: {
    flexBasis: '70%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  univLogo: {
    ...platformSpecificStyle({
      native: {
        marginTop: pixel(29),
        width: pixel(360),
        height: pixel(79.51)
      },
      tablet: {
        marginTop: pixel(29) / 2,
        width: pixel(360) / 2,
        height: pixel(79.51) / 2
      },
      default: {
        marginTop: pixel(29) / 2,
        width: pixel(360) / 2,
        height: pixel(79.51) / 2
      }
    })
  },
  idCardHeaderTopRight: {
    flexBasis: '15%',
    height: pixel(130)
  },
  closeIcon: {
    ...platformSpecificStyle({
      native: { width: pixel(88) },
      tablet: { width: pixel(88) / 2 },
      default: { width: pixel(88) / 2 }
    })
  },
  idCardHeaderText: {
    color: '#000',
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(40),
        marginVertical: pixel(24)
      },
      tablet: {
        fontSize: pixel(40) / 2,
        marginVertical: pixel(24) / 2
      },
      default: {
        fontSize: pixel(40) / 2,
        marginVertical: pixel(24) / 2
      }
    })
  }
}));

export const QrPageHeader: React.FC = () => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <View style={{ ...styles.idCardHeaderTop }}>
      <View style={{ ...styles.idCardHeaderTopLeft }} />
      <View style={{ ...styles.idCardHeaderTopCenter }}>
        <IdCardUniversityLogo style={{ ...styles.univLogo }} />
      </View>
      <View style={{ ...styles.idCardHeaderTopRight }} />
    </View>
  );
};
