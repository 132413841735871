import { View } from 'react-native';

import { TextM } from './atomic/text';

import { useDesign } from '@/hooks/useDesign';
import { createStyles, getTime, platformSpecificStyle } from '@/utils';

interface Props {
  period: { from: Date; to: Date };
  color?: string;
}

const { getStyle } = createStyles(({ pixel, colors }) => ({
  intervalContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  line: {
    backgroundColor: colors.textPrimary,
    ...platformSpecificStyle({
      native: {
        height: pixel(20),
        width: pixel(1),
        marginBottom: pixel(2),
        marginTop: pixel(4)
      },
      default: {
        height: pixel(10),
        width: 1,
        marginBottom: 3,
        marginTop: 3
      }
    })
  },
  classFromTo: {
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(24)
      },
      default: {
        fontSize: 13
      }
    })
  }
}));

export const PeriodLabel: React.FC<Props> = ({ period, color }) => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <View style={{ ...styles.intervalContainer }}>
      <TextM
        style={{
          ...(color && { color }),
          ...styles.classFromTo
        }}
      >
        {period.from && getTime(period.from)}
      </TextM>
      <View
        style={{
          ...styles.line,
          ...(color && { backgroundColor: color })
        }}
      />
      <TextM style={{ ...(color && { color }), ...styles.classFromTo }}>
        {getTime(period.to)}
      </TextM>
    </View>
  );
};
