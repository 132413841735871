import { LinearGradient } from 'expo-linear-gradient';
import React, { PropsWithChildren } from 'react';
import { ViewProps } from 'react-native';

import { useDesign } from '@/hooks/useDesign';
import { createStyles } from '@/utils';

const { getStyle } = createStyles(({ pixel }) => ({
  container: {
    width: pixel(80),
    height: pixel(80),
    borderRadius: pixel(80),
    alignItems: 'center',
    justifyContent: 'center'
  },
  innerContainer: {
    width: pixel(120),
    height: pixel(120),
    borderRadius: pixel(120),
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const BrightContainer: React.FC<PropsWithChildren<ViewProps>> = ({
  ...props
}) => {
  return (
    <LinearGradient
      colors={['#E60C16', '#E60C16']}
      locations={[0, 1]}
      {...props}
    />
  );
};

export const MessageIconContainer: React.FC<PropsWithChildren> = ({
  children
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <BrightContainer style={{ ...styles.container }}>
      {children}
    </BrightContainer>
  );
};
