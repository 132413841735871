import React, { PropsWithChildren } from 'react';
import { View } from 'react-native';

import { useDesign } from '@/hooks/useDesign';
import { createStyles } from '@/utils';

const { getStyle } = createStyles(() => ({
  // Dialog
  dialogOverlay1stLayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    backgroundColor: '#E57373'
  }
}));

export const ModalBackgroundOverlay: React.FC<PropsWithChildren> = ({
  children
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  return <View style={{ ...styles.dialogOverlay1stLayer }}>{children}</View>;
};
