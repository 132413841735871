import { ViewStyle } from '@expo/html-elements/build/primitives/View';
import React from 'react';
import { ImageSourcePropType, StyleProp, View } from 'react-native';

import { Image } from '@/components/atomic/Image/Image.atomic';
import { TextL, TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { UserProfile } from '@/interfaces';
import { AttendanceStatus } from '@/interfaces/Teacher.interface';
import { CheckCircleIcon, DummyIdCardProfile } from '@/svgs';
import { createStyles, platformSpecificStyle } from '@/utils';

const { getStyle } = createStyles(({ colors, pixel }) => ({
  userCard: {
    backgroundColor: '#fff',
    borderRadius: 10,
    flexDirection: 'row',
    alignItems: 'center',
    padding: pixel(20)
  },
  userInfo: {
    flex: 1,
    paddingLeft: pixel(20)
  },
  userHeaderInfo: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  spaceBetween: {
    justifyContent: 'space-between'
  },
  gapBetween: {
    gap: pixel(20)
  },
  profileImage: {
    ...platformSpecificStyle({
      native: { width: pixel(186) },
      tablet: { width: pixel(186) / 2 },
      default: { width: pixel(186) / 2 }
    })
  },
  imageContainer: {
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {
        height: pixel(186),
        width: pixel(140)
      },
      tablet: {
        height: pixel(186) / 2,
        width: pixel(140) / 2
      },
      default: {
        height: pixel(186) / 2,
        width: pixel(140) / 2
      }
    })
  },
  text: {
    color: colors.textPrimaryInverted
  },
  checkIcon: {
    width: pixel(28),
    marginLeft: pixel(5)
  }
}));

const StatusText = {
  attend: '出席',
  late: '遅刻',
  other: 'その他',
  absent: '欠席'
};

const StatusColor = {
  attend: '#018A0F',
  late: '#949494',
  other: '#949494',
  absent: '#949494',
  transparent: 'transparent'
};

export type AttendanceProfileCardProps = {
  profile: UserProfile;
  image?: ImageSourcePropType | null;
  federationId?: string;
  status: AttendanceStatus['status'];
  statusConfirmed?: boolean;
  compact?: boolean;
  style?: StyleProp<ViewStyle>;
};

export const AttendanceProfileCard: React.FC<AttendanceProfileCardProps> = ({
  profile,
  image,
  federationId,
  status,
  statusConfirmed = false,
  compact,
  style
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <View style={[styles.userCard, style]}>
      {!compact && (
        <View style={styles.imageContainer}>
          {image && (
            <Image size={{ width: styles.profileImage.width }} src={image} />
          )}
          {!image && <DummyIdCardProfile width={styles.profileImage.width} />}
        </View>
      )}
      <View style={styles.userInfo}>
        <View style={{ ...styles.userHeaderInfo, ...styles.spaceBetween }}>
          <View style={{ ...styles.userHeaderInfo, ...styles.gapBetween }}>
            <TextM style={styles.text}>{federationId}</TextM>
            {compact && <TextM style={styles.text}>{profile.department}</TextM>}
          </View>
          <View style={styles.userHeaderInfo}>
            <TextM style={styles.text}>
              {status ? StatusText[status] : StatusText.absent}
            </TextM>
            <CheckCircleIcon
              size={styles.checkIcon.width}
              color={status ? StatusColor[status] : StatusColor.transparent}
              strokeColor={status ? StatusColor[status] : StatusColor.absent}
              checked={statusConfirmed}
              style={styles.checkIcon}
            />
          </View>
        </View>
        {!compact && <TextM style={styles.text}>{profile.faculty}</TextM>}
        {!compact && <TextM style={styles.text}>{profile.department}</TextM>}
        <TextL style={styles.text}>{profile.name}</TextL>
      </View>
    </View>
  );
};
