/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */
import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { AccountSubRoutes, NaviParamList, RootStackParamList } from './types';

const naviParamParser: Record<keyof NaviParamList, (value: string) => any> = {
  rootId: Number,
  subId: Number
};

export enum RootScreens {
  HOME = 'Home',
  ACCOUNT = 'AccountRoot',
  NAVI = 'NaviRoot',
  NOTIFICATION = 'Notification',
  TIMETABLE = 'Timetable'
}

export enum Paths {
  HOME = '',
  LOGIN = 'login',
  ACCOUNT = 'account',
  NAVI = 'navi',
  SUB_NAVI = 'menu',
  NOTIFICATION = 'notification',
  NOTIFICATION_SETTINGS = 'notification-settings',
  TIMETABLE = 'timetable',
  MODAL = 'modal',
  CALLBACK = 'callback',
  CRITICAL_ERROR = 'critical-error',
  CATCH = '[!expo-development-client]*'
}

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Root: {
        screens: {
          [RootScreens.HOME]: {},
          [RootScreens.ACCOUNT]: {
            screens: {
              Account: {
                path: `${Paths.ACCOUNT}/:subRoute?`,
                parse: {
                  subRoute: (value: string) => value as AccountSubRoutes
                }
              },
              NotificationSettings: Paths.NOTIFICATION_SETTINGS
            }
          },
          [RootScreens.NAVI]: {
            screens: {
              Navi: {
                path: `${Paths.NAVI}/:rootId?/:subId?`,
                parse: {
                  ...naviParamParser
                }
              },
              SubNavi: {
                path: `${Paths.SUB_NAVI}/:rootId?/:subId?`,
                parse: {
                  ...naviParamParser
                }
              }
            }
          },
          [RootScreens.NOTIFICATION]: Paths.NOTIFICATION,
          [RootScreens.TIMETABLE]: Paths.TIMETABLE
        }
      },
      Login: Paths.LOGIN,
      Modal: Paths.MODAL,
      Callback: Paths.CALLBACK,
      NotFound: Paths.CATCH,
      CriticalError: Paths.CRITICAL_ERROR
    }
  }
};

export default linking;
