import React from 'react';
import {
  ActivityIndicator,
  ActivityIndicatorProps,
  ViewStyle
} from 'react-native';

import { useDesign } from '@/hooks/useDesign';

interface Props extends ActivityIndicatorProps {
  containerStyle?: ViewStyle;
}

export const Loader: React.FC<Props> = ({
  color,
  containerStyle,
  animating,
  ...rest
}: Props) => {
  const design = useDesign();
  const { colors } = design;

  return (
    <ActivityIndicator
      style={containerStyle}
      color={color || colors.primary}
      animating={animating}
      {...rest}
    />
  );
};

Loader.defaultProps = {
  containerStyle: {}
};
