import * as React from 'react';
import { Path } from 'react-native-svg';

import { SvgProps, SvgWrapper, usePrimaryIconColor } from '@/wrappers/svg';

export const EllipsisIcon: React.FC<SvgProps> = ({ color, ...props }) => {
  const primaryIconColor = usePrimaryIconColor();

  return (
    <SvgWrapper viewBox={{ width: 4, height: 20 }} {...props}>
      <Path
        fillRule="evenodd"
        fill={color || primaryIconColor}
        d="M2.003,-0.001 C3.111,-0.001 4.007,0.897 4.007,2.004 C4.007,3.111 3.111,4.008 2.003,4.008 C0.897,4.008 -0.000,3.111 -0.000,2.004 C-0.000,0.897 0.897,-0.001 2.003,-0.001 Z"
      />
      <Path
        fillRule="evenodd"
        fill={color || primaryIconColor}
        d="M2.003,7.999 C3.111,7.999 4.007,8.896 4.007,10.004 C4.007,11.111 3.111,12.008 2.003,12.008 C0.897,12.008 -0.000,11.111 -0.000,10.004 C-0.000,8.896 0.897,7.999 2.003,7.999 Z"
      />
      <Path
        fillRule="evenodd"
        fill={color || primaryIconColor}
        d="M2.003,15.1000 C3.111,15.1000 4.007,16.897 4.007,18.004 C4.007,19.111 3.111,20.008 2.003,20.008 C0.897,20.008 -0.000,19.111 -0.000,18.004 C-0.000,16.897 0.897,15.1000 2.003,15.1000 Z"
      />
    </SvgWrapper>
  );
};
