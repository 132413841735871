import { useFocusEffect } from '@react-navigation/native';
import { isWithinInterval } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AppState, Linking, Platform } from 'react-native';

import { postBeaconEvent } from '@/api/calls/beacons.api';
import {
  ATTENDANCE_2ND_TIME_END_OFFSET,
  ATTENDANCE_2ND_TIME_START_OFFSET,
  ATTENDANCE_3RD_TIME_END_OFFSET,
  ATTENDANCE_3RD_TIME_START_OFFSET,
  ATTENDANCE_TIMEOUT,
  BEFORE_ATTENDANCE_START_TIME
} from '@/constants/Attendance';
import { useBeaconDetection } from '@/hooks/useBeaconDetection';
import { useDeviceUniqueId } from '@/hooks/useDeviceUniqueId';
import { useFocus } from '@/hooks/useFocus';
import { CalendarClass } from '@/interfaces';
import { Beacon, BeaconDidRangeEvent } from '@/interfaces/Beacons.interface';

// ビーコンのUUIDのリストを定義
const rangingUuids = ['93DFF63B-FE13-444F-8C10-4F85B62DA234'];
const monitoringUuids = ['98AF1249-B53D-4A4A-A92B-D4D25EBE418F'];

const API_ATTENDED_CASE_MAX_INTERVAL = 300;
const API_RANDOM_MAX_INTERVAL = 30;
const API_RANDOM_MIN_INTERVAL = 5;
// ランダムなインターバルを取得する関数
const getRandomInterval = () => {
  return Math.floor(
    Math.random() * (API_RANDOM_MAX_INTERVAL - API_RANDOM_MIN_INTERVAL + 1) +
      API_RANDOM_MIN_INTERVAL
  );
};

// useAttendanceBeaconRanging フックの定義
export const useAttendanceBeaconRanging = ({
  currentTime,
  activeSchedule
}: {
  currentTime: Date;
  activeSchedule: CalendarClass;
}) => {
  // ビーコンのデータを保持するための状態
  const [beaconsData, setBeaconsData] = useState<Beacon[]>([]);
  const isWithinAttendanceCheckingPeriod = useMemo(
    () =>
      activeSchedule &&
      (isWithinInterval(currentTime, {
        start: new Date(
          activeSchedule.period.from.getTime() - BEFORE_ATTENDANCE_START_TIME
        ),
        end: new Date(
          activeSchedule.period.from.getTime() +
            ATTENDANCE_TIMEOUT +
            ATTENDANCE_TIMEOUT // 15分以内かどうかを確認
        )
      }) ||
        isWithinInterval(currentTime, {
          start: new Date(
            activeSchedule.period.from.getTime() +
              ATTENDANCE_2ND_TIME_START_OFFSET
          ),
          end: new Date(
            activeSchedule.period.from.getTime() +
              ATTENDANCE_2ND_TIME_END_OFFSET // 60-65分の間かどうかを確認
          )
        }) ||
        isWithinInterval(currentTime, {
          start: new Date(
            activeSchedule.period.from.getTime() +
              ATTENDANCE_3RD_TIME_START_OFFSET
          ),
          end: new Date(
            activeSchedule.period.from.getTime() +
              ATTENDANCE_3RD_TIME_END_OFFSET // 80-85分の間かどうかを確認
          )
        })),
    [currentTime, activeSchedule]
  );

  const { deviceUniqueId } = useDeviceUniqueId();

  // ビーコンイベントハンドラ
  const handleBeaconDidRangeEvent = useCallback(
    async (event: BeaconDidRangeEvent) => {
      const { beacons } = event;
      if (beacons.length > 0) {
        setBeaconsData((preBeaconsData) => {
          const filteredBeacons = beacons.reduce(
            (acc: Beacon[], beacon: Beacon) => {
              if (
                !acc.find(
                  (b) =>
                    b.uuid === beacon.uuid &&
                    b.major === beacon.major &&
                    b.minor === beacon.minor
                )
              ) {
                acc.push({
                  uuid: beacon.uuid,
                  major: String(beacon.major),
                  minor: String(beacon.minor),
                  rssi: String(beacon.rssi),
                  distance: String(beacon.distance),
                  proximity: beacon.proximity,
                  accuracy: String(beacon.accuracy),
                  received_at: new Date(),
                  device_id: deviceUniqueId,
                  app_mode: 'fg_ranging'
                });
              }
              return acc;
            },
            [] as Beacon[]
          );
          return [...preBeaconsData, ...filteredBeacons];
        });
      }
    },
    [deviceUniqueId]
  );

  // Beaconの検知のuseHookの呼び出し
  const {
    requestPermissions,
    canStartBeaconRanging,
    isLocationPermissionsGranted,
    isCurrentlyBeaconRanging,
    startRangingBeacons,
    stopRangingBeacons,
    startNotifeeForegroundServiceForAndroid
  } = useBeaconDetection(
    rangingUuids,
    monitoringUuids,
    handleBeaconDidRangeEvent
  );

  const [
    isLocationPermissionsRequestedOnce,
    setIsLocationPermissionRequestedOnce
  ] = useState<boolean>(false);
  const [
    isLocationPermissionsRejectedOnce,
    setIsLocationPermissionsRejectedOnce
  ] = useState<boolean>(false);

  const { isFocused, subscribe, unsubscribe } = useFocus();

  // app state goes on an infinite loop while listening for changes with appState on android
  // 位置情報のパーミッション要求時に AppState が activeであっても、backgraoundになってしまう問題への対応
  // https://github.com/facebook/react-native/issues/30206#issuecomment-1698972226
  const isPermissionFetching = useRef(false);

  // マウント時にパーミッションをリクエスト
  useFocusEffect(
    useCallback(() => {
      subscribe();
      (async () => {
        setIsLocationPermissionsRejectedOnce(false);
        isPermissionFetching.current = true;
        await requestPermissions();
        isPermissionFetching.current = false;
        setIsLocationPermissionRequestedOnce(true);
      })();
      return () => {
        unsubscribe();
        setIsLocationPermissionRequestedOnce(false);
      };
    }, [])
  );
  // fetch resource browser window or app is focused
  useEffect(() => {
    (async () => {
      if (isFocused && !isPermissionFetching.current) {
        setIsLocationPermissionsRejectedOnce(false);
        isPermissionFetching.current = true;
        await requestPermissions();
        isPermissionFetching.current = false;
        setIsLocationPermissionRequestedOnce(true);
      }
    })();
  }, [isFocused, isPermissionFetching]);

  useEffect(() => {
    if (!canStartBeaconRanging) return;
    if (!isWithinAttendanceCheckingPeriod) {
      isCurrentlyBeaconRanging && stopRangingBeacons();
    } else {
      !isCurrentlyBeaconRanging &&
        startRangingBeacons(BEFORE_ATTENDANCE_START_TIME + ATTENDANCE_TIMEOUT); //自動的に止まるため、最長で起動する時間を設定
    }
  }, [
    canStartBeaconRanging,
    isCurrentlyBeaconRanging,
    isWithinAttendanceCheckingPeriod
  ]);

  // 立ち上げ時にフォアグラウンドサービスを開始
  useEffect(() => {
    startNotifeeForegroundServiceForAndroid();
  }, []);

  //
  const [apiIntervalTimerState, setApiIntervalTimerState] = useState<number>(0);
  const [apiIntervalTimeLimit, setApiIntervalTimeLimit] = useState<number>(
    getRandomInterval()
  );

  useEffect(() => {
    const id = setInterval(() => {
      setApiIntervalTimerState((pre) => {
        return (Number(pre || 0) + 1) % (API_ATTENDED_CASE_MAX_INTERVAL + 1);
      });
    }, 1000);
    return () => {
      clearInterval(id);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (
        apiIntervalTimerState !== 0 &&
        apiIntervalTimerState % apiIntervalTimeLimit === 0
      ) {
        setApiIntervalTimerState(0);
        setApiIntervalTimeLimit(getRandomInterval());
        // 定期的にビーコンのデータをサーバーに送信
        if (deviceUniqueId && beaconsData.length > 0) {
          try {
            const result = await postBeaconEvent(
              beaconsData.map((beacon) => {
                // setInterval内の関数で更新できないStateを補完
                beacon.device_id = deviceUniqueId;
                return beacon;
              })
            );
            if (result.status === 'attended') {
              setApiIntervalTimerState(0);
              setApiIntervalTimeLimit(API_ATTENDED_CASE_MAX_INTERVAL);
            }
          } catch (error) {
            console.warn(error);
          }
          // ビーコンデータのリセット
          setBeaconsData([]);
        }
      }
    })();
  }, [
    deviceUniqueId,
    beaconsData,
    apiIntervalTimerState,
    apiIntervalTimeLimit
  ]);

  const onRequestPermissions = useCallback(async () => {
    switch (Platform.OS) {
      case 'ios':
        Linking.openURL('app-settings:');
        break;
      case 'android':
        Linking.openSettings();
        break;
    }
  }, []);

  const onRejectLocationPermissionSetting = useCallback(async () => {
    setIsLocationPermissionsRejectedOnce(true);
    isPermissionFetching.current = true;
    await requestPermissions();
    isPermissionFetching.current = false;
  }, []);

  useEffect(() => {
    const appStateListener = AppState.addEventListener(
      'change',
      async (nextAppState) => {
        if (nextAppState === 'active') {
          await startNotifeeForegroundServiceForAndroid();
        }
      }
    );
    return () => {
      appStateListener?.remove();
    };
  }, []);

  return {
    onRequestPermissions,
    onRejectLocationPermissionSetting,
    isRequestingLocationPermissions: isPermissionFetching.current,
    isLocationPermissionsRequestedOnce,
    isLocationPermissionsGranted,
    isLocationPermissionsRejectedOnce,
    isWithinAttendanceCheckingPeriod
  };
};
