import React from 'react';
import { TextStyle, TouchableOpacity, ViewStyle } from 'react-native';

import { Loader } from '../loader';
import { TextM } from '../text';

import { useDesign } from '@/hooks/useDesign';
import { createStyles, platformSpecificStyle } from '@/utils';

export interface Props {
  onPress: () => void;
  label: string;
  labelStyle?: TextStyle;
  containerStyle?: ViewStyle;
  isLoading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  secondary?: boolean;
  outline?: boolean;
  modal?: boolean;
}

const { getStyle } = createStyles(({ colors, pixel }) => ({
  buttonContainer: {
    width: pixel(600),
    backgroundColor: colors.button.background,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: pixel(60),
    alignSelf: 'flex-start',
    flexDirection: 'row',
    ...platformSpecificStyle({
      default: {
        height: 60
      },
      native: {
        height: pixel(120)
      }
    })
  },
  buttonContainerSecondary: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: colors.button.borderSecondary
  },
  buttonContainerOutline: {
    borderColor: colors.floatingButton.border,
    borderWidth: 1
  },
  buttonContainerModal: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: colors.button.borderModal
  },
  buttonLabel: {
    color: colors.button.text,
    fontWeight: 'bold'
  },
  buttonLabelSecondary: { color: colors.textPrimary },
  disabled: {
    opacity: 0.5
  },
  loaderStyle: {
    position: 'absolute',
    right: 10
  }
}));

export const Button: React.FC<Props> = ({
  label,
  containerStyle,
  labelStyle,
  onPress,
  secondary,
  modal,
  fullWidth,
  disabled,
  isLoading,
  outline
}) => {
  const design = useDesign();
  const styles = getStyle(design);
  const { colors } = design;

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        ...styles.buttonContainer,
        ...(secondary && styles.buttonContainerSecondary),
        ...(outline && styles.buttonContainerOutline),
        ...(modal && styles.buttonContainerModal),
        ...(disabled || isLoading ? styles.disabled : {}),
        ...(fullWidth ? { width: '100%' } : undefined),
        ...(containerStyle || {})
      }}
      disabled={disabled}
    >
      <TextM
        style={{
          ...styles.buttonLabel,
          ...(secondary && styles.buttonLabelSecondary),
          ...labelStyle
        }}
      >
        {label}
      </TextM>
      {isLoading && (
        <Loader
          color={secondary ? colors.textPrimary : undefined}
          containerStyle={{
            ...styles.loaderStyle
          }}
        />
      )}
    </TouchableOpacity>
  );
};
