import { isToday } from 'date-fns';
import Constants from 'expo-constants';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import React, { useCallback, useMemo, useState } from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';
import ReactNativeBlobUtil from 'react-native-blob-util';
import Collapsible from 'react-native-collapsible';
import Hyperlink from 'react-native-hyperlink';
import * as mime from 'react-native-mime-types';

import { fetchNotificationAttachment } from '@/api/calls/notifiaction.api';
import { Loader } from '@/components/atomic/loader';
import { TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import {
  MESSAGE_TYPE_MAPPING,
  Notification,
  NotificationTypes
} from '@/interfaces';
import { useAppSelector } from '@/store';
import { OpenIcon } from '@/svgs';
import {
  createStyles,
  getDateWithoutYear,
  getTime,
  isMobile,
  isWeb,
  openBrowser,
  openGmailApp,
  platformSpecificStyle
} from '@/utils';

interface Props {
  message: Notification;
  isRead?: boolean;
  refresh: () => void;
  dispatchScrollToSection: () => void;
  setNotificationRead: (id: string, type: NotificationTypes) => Promise<void>;
}

const { getStyle } = createStyles(({ colors, pixel }) => ({
  container: {
    borderRadius: pixel(20),
    backgroundColor: '#fff',
    overflow: 'hidden'
  },
  containerRead: {
    backgroundColor: colors.messageBackgroundRead
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    ...platformSpecificStyle({
      native: {
        paddingTop: pixel(30),
        paddingHorizontal: pixel(32)
      },
      default: {
        paddingTop: pixel(30),
        paddingHorizontal: pixel(32)
      }
    })
  },
  headerContainerRead: {
    backgroundColor: colors.messageBackgroundRead
  },
  headerTextContainer: {
    paddingRight: 0,
    flex: 1
  },
  headerText: {
    color: '#000',
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(30)
      },
      default: {
        fontSize: 15
      }
    })
  },
  headerTextRead: {
    color: '#4C4C4C'
  },
  body: {
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    paddingHorizontal: pixel(32),
    ...platformSpecificStyle({
      native: {
        paddingTop: pixel(30),
        paddingBottom: pixel(26)
      },
      default: {
        paddingTop: pixel(8),
        paddingBottom: pixel(16)
      }
    })
  },
  bodyRead: {
    backgroundColor: colors.messageBackgroundRead
  },
  bodyText: {
    color: '#4C4C4C',
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(28)
      },
      default: {
        fontSize: 14
      }
    })
  },
  linkInsideBodyContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    ...platformSpecificStyle({
      native: {},
      default: {}
    })
  },
  linkInsideBody: {
    color: '#0066CC'
  },
  linkInsideBodyRead: {
    color: '#00366C'
  },
  footer: {
    paddingHorizontal: pixel(32),
    paddingBottom: pixel(26),
    flexDirection: 'row',
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  footerRead: {
    backgroundColor: colors.messageBackgroundRead
  },
  messageInfo: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  messageTypeContainer: {
    backgroundColor: '#F2F2F2',
    borderRadius: pixel(38),
    paddingVertical: pixel(4),
    paddingHorizontal: pixel(14),
    ...platformSpecificStyle({
      native: { marginRight: pixel(15) },
      default: { marginRight: pixel(5) }
    })
  },
  messageTypeText: {
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(24)
      },
      default: {
        fontSize: 12
      }
    })
  },
  dateText: {
    color: '#4C4C4C',
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(24)
      },
      default: {
        fontSize: 12
      }
    })
  },
  actionButtonText: {
    color: '#4C4C4C',
    ...platformSpecificStyle({
      native: {
        textAlignVertical: 'top',
        fontSize: pixel(26)
      },
      default: {
        textAlignVertical: 'center',
        fontSize: 13
      }
    })
  },
  actionButtonContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    ...platformSpecificStyle({
      android: {
        height: pixel(60)
      },
      default: {}
    })
  },
  openIcon: {
    textAlignVertical: 'center',
    color: colors.navigation.backgroundInverted,
    backgroundColor: colors.icon.primary,
    ...platformSpecificStyle({
      android: {
        marginRight: pixel(15),
        marginLeft: pixel(8),
        height: pixel(30),
        paddingTop: pixel(4)
      },
      ios: {
        marginRight: pixel(15),
        marginLeft: pixel(8),
        height: pixel(30)
      },
      default: {
        marginRight: pixel(5),
        marginLeft: pixel(4),
        height: pixel(15)
      }
    })
  },
  attachmentsBlock: {
    marginTop: pixel(40)
  },
  attachmentBody: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  attachmentLoader: {
    marginLeft: pixel(10),
    height: pixel(20)
  }
}));

const GMAIL_WEB_LINK: string = Constants.expoConfig?.extra?.GMAIL_WEB_LINK;

const openMessage = ({
  message,
  email,
  refresh
}: {
  message: Notification;
  email?: string;
  refresh: () => void;
}) => {
  const gmailMessageLink = email
    ? `${GMAIL_WEB_LINK}/mail?authuser=${email}#all/${message.id}`
    : GMAIL_WEB_LINK;
  if (message.type === NotificationTypes.GMAIL && !isWeb) {
    openGmailApp(GMAIL_WEB_LINK);
  } else {
    const url =
      message.type === NotificationTypes.GMAIL ? gmailMessageLink : message.url;

    openBrowser({
      url,
      openInSameBrowser: message.type !== NotificationTypes.CAMPUS_PLAN,
      onBrowserClose: refresh
    });
  }
};

export const MessageElement: React.FC<Props> = ({
  message,
  isRead,
  refresh,
  dispatchScrollToSection,
  setNotificationRead
}) => {
  const design = useDesign();
  const styles = getStyle(design);
  const email = useAppSelector((state) => state.account.profile.email);
  const { pixel } = design;
  const isCampusPlan = message.type === NotificationTypes.CAMPUS_PLAN;

  const [isOpen, setIsOpen] = useState(false);
  const [isClosedOnce, setIsClosedOnce] = useState(false);
  const [hasOpenedGmail, setHasOpenedGmail] = useState(false);
  const isReadOrClosedOnce = useMemo(
    () => isRead || isClosedOnce || hasOpenedGmail,
    [isRead, isClosedOnce, hasOpenedGmail]
  );

  // 添付ファイルのローディング
  const [isLoadingAttachment, setIsLoadingAttachment] = useState<
    string | undefined
  >();

  const canOpenMessage = message.type === NotificationTypes.GMAIL;

  const toggleMessageBody = useCallback(
    (message: Notification) => {
      if (isOpen) {
        setIsClosedOnce(true);
        dispatchScrollToSection();
        setTimeout(() => setIsOpen(!isOpen), 200);
      } else {
        !isReadOrClosedOnce && setNotificationRead(message.id, message.type);
        setIsOpen(!isOpen);
      }
    },
    [isOpen, isReadOrClosedOnce]
  );

  const onOpenLink = () => {
    canOpenMessage && openMessage({ message, email, refresh });
    if (!isCampusPlan && !isRead) {
      setNotificationRead(message.id, message.type);
    }
  };

  const onTapActionButton = (message: Notification) => {
    if (message.type === NotificationTypes.GMAIL) {
      onOpenLink();
      setHasOpenedGmail(true);
    } else {
      toggleMessageBody(message);
    }
  };
  const getColorFromMessageType = (message: Notification) =>
    message.type && MESSAGE_TYPE_MAPPING.hasOwnProperty(message.type)
      ? MESSAGE_TYPE_MAPPING[message.type].labelColor
      : '';

  const openAttachmentApiLink = useCallback(
    async (attachment: {
      id?: string | undefined;
      filename?: string | undefined;
    }) => {
      if (!attachment.id || !attachment.filename) return;

      const filename = attachment.filename;
      const attachmentId = attachment.id;
      const { id, type } = message;
      setIsLoadingAttachment(attachment.filename);
      const attachmentFile = await fetchNotificationAttachment({
        id,
        type,
        attachmentId
      });
      // // attachmentFile の保存処理
      const blobToBase64 = (blob: Blob): Promise<string> => {
        console.log('blobToBase64');
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      };
      const getDownloadedDocsDirectoryUri = (relativePath: string) => {
        return `${FileSystem.cacheDirectory}${relativePath}`;
      };
      const base64DataUrl = await blobToBase64(attachmentFile);
      const base64AttachmentFile = base64DataUrl.split(',')[1]; // データURLからBase64エンコードされたデータだけを取り出す

      const sharePdfLink = async () => {
        const uri = getDownloadedDocsDirectoryUri(filename);
        await FileSystem.writeAsStringAsync(uri, base64AttachmentFile, {
          encoding: FileSystem.EncodingType.Base64
        });

        const mimeType = mime.lookup(uri);

        if (Platform.OS === 'android' && mimeType) {
          const cUri = await FileSystem.getContentUriAsync(uri);
          ReactNativeBlobUtil.android.actionViewIntent(cUri, mimeType);
        } else if (Platform.OS === 'ios') {
          ReactNativeBlobUtil.ios.previewDocument(uri);
        } else {
          await Sharing.shareAsync(uri);
        }
      };

      setIsLoadingAttachment(undefined);

      // openBrowser
      if (isWeb) {
        // ここで attachmentFile がバイナリデータであることを確認する
        const blob = new Blob([attachmentFile], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        openBrowser({
          url,
          openInSameBrowser: true,
          onBrowserClose: () => {}
        });
        window.URL.revokeObjectURL(url); // 使用後のURLを解放
      } else if (Platform.OS === 'android') {
        // expo-file-system の StorageAccessFramework を使用する実装例があるが
        // Android11 以降の場合 Downloadディレクトリに保存ができない
        //  => 代わりに Sharing.shareAsync を使用する
        // const permissions =
        //   await FileSystem.StorageAccessFramework.requestDirectoryPermissionsAsync(
        //     //'content://com.android.externalstorage.documents/'
        //   );
        // if (permissions.granted) {
        //   try {
        //     const uri = await FileSystem.StorageAccessFramework.createFileAsync(
        //       permissions.directoryUri,
        //       filename,
        //       attachmentFile.type
        //     );
        //     await FileSystem.writeAsStringAsync(uri, base64AttachmentFile, {
        //       encoding: FileSystem.EncodingType.Base64
        //     });
        //   } catch (e) {
        //     sharePdfLink();
        //     console.log(e);
        //   }
        // } else {
        //   sharePdfLink();
        // }
        sharePdfLink();
      } else if (Platform.OS === 'ios') {
        sharePdfLink();
      }
    },
    [message]
  );

  return (
    <>
      <View
        style={{
          ...styles.container,
          ...(isReadOrClosedOnce && styles.containerRead)
        }}
      >
        <View
          style={{
            ...styles.headerContainer,
            ...(isReadOrClosedOnce && styles.headerContainerRead)
          }}
        >
          <View style={{ ...styles.headerTextContainer }}>
            <TextM
              style={{
                ...styles.headerText,
                ...(isReadOrClosedOnce && styles.headerTextRead)
              }}
              ellipsizeMode={isOpen ? undefined : 'tail'}
              numberOfLines={isOpen ? undefined : 1}
            >
              {message.body}
            </TextM>
          </View>
        </View>
        <View>
          <View
            style={{
              ...styles.body,
              ...(isReadOrClosedOnce && styles.bodyRead)
            }}
          >
            <Collapsible
              collapsed={!isOpen}
              collapsedHeight={pixel(isMobile ? pixel(160) : pixel(30))}
            >
              <Hyperlink
                linkStyle={{
                  ...styles.linkInsideBody,
                  ...(isReadOrClosedOnce && { ...styles.linkInsideBodyRead })
                }}
                onPress={(url) => {
                  openBrowser({ url, openInSameBrowser: true });
                }}
              >
                <TextM
                  style={{
                    ...styles.bodyText
                  }}
                  ellipsizeMode={isOpen ? undefined : 'tail'}
                  numberOfLines={isOpen ? undefined : isMobile ? 2 : 1}
                >
                  {message.fullBody}
                </TextM>
              </Hyperlink>
              {isOpen &&
                message.attachments &&
                message.attachments.length > 0 && (
                  <View
                    style={{
                      ...styles.attachmentsBlock
                    }}
                  >
                    <TextM
                      style={{
                        ...styles.bodyText
                      }}
                    >
                      添付ファイル
                    </TextM>
                    {isOpen &&
                      message.attachments.map((attachment) => (
                        <View
                          style={{
                            ...styles.linkInsideBodyContainer
                          }}
                        >
                          <TouchableOpacity
                            key={`file_${attachment.id}`}
                            onPress={() => openAttachmentApiLink(attachment)}
                          >
                            <View style={styles.attachmentBody}>
                              <TextM
                                style={{
                                  ...styles.linkInsideBody,
                                  ...(isReadOrClosedOnce && {
                                    ...styles.linkInsideBodyRead
                                  })
                                }}
                              >
                                {attachment.filename}
                              </TextM>
                              {isLoadingAttachment &&
                                isLoadingAttachment === attachment.filename && (
                                  <Loader
                                    style={styles.attachmentLoader}
                                    color="#444"
                                  />
                                )}
                            </View>
                          </TouchableOpacity>
                        </View>
                      ))}
                  </View>
                )}
            </Collapsible>
          </View>
          <View
            style={{
              ...styles.footer,
              ...(isReadOrClosedOnce && styles.footerRead)
            }}
          >
            <View style={{ ...styles.messageInfo }}>
              <View
                style={{
                  ...styles.messageTypeContainer
                }}
              >
                <TextM
                  style={{
                    ...styles.messageTypeText,
                    color: getColorFromMessageType(message)
                  }}
                >
                  {MESSAGE_TYPE_MAPPING[message.type].label}
                </TextM>
              </View>
              <TextM
                style={{
                  ...styles.dateText
                }}
              >
                {isToday(message.date)
                  ? getTime(message.date)
                  : getDateWithoutYear(message.date, {
                      showDayOfWeek: true
                    })}
              </TextM>
            </View>
            <TouchableOpacity
              style={
                {
                  // ...styles.actionButtonContainer
                }
              }
              onPress={() => onTapActionButton(message)}
            >
              <View
                style={{
                  ...styles.actionButtonContainer
                }}
              >
                <TextM style={{ ...styles.actionButtonText }}>
                  {canOpenMessage
                    ? `${MESSAGE_TYPE_MAPPING[message.type].label}で開く`
                    : isOpen
                    ? '閉じる'
                    : 'もっと見る'}
                </TextM>
                <OpenIcon
                  style={{
                    ...(canOpenMessage && {
                      transform: [{ rotate: '-90deg' }]
                    }),
                    ...(isOpen && { transform: [{ rotate: '180deg' }] }),
                    marginLeft: styles.openIcon.marginLeft,
                    ...(styles.openIcon.paddingTop && {
                      paddingTop: styles.openIcon.paddingTop
                    })
                  }}
                  background={styles.openIcon.backgroundColor}
                  color={styles.openIcon.color}
                  size={styles.openIcon.height}
                />
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </>
  );
};
