import { api } from '..';

import { ConfigurationResponse, UniversityInfo } from '@/interfaces';

export const fetchConfiguration = async () => {
  const { data } = await api.get<ConfigurationResponse>('/configuration');

  return data;
};

export const featchUniversityInfo = async () => {
  const { data } = await api.get<UniversityInfo>(
    '/configuration/university-info'
  );

  return data;
};
