import { A } from '@expo/html-elements';
import { RouteProp, useRoute } from '@react-navigation/native';
import Constants from 'expo-constants';
import React, { useContext, useState } from 'react';
import { View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { RootStackParamList } from '../../navigation/types';
import { AppDownloadSection } from './components/AppDownloadSection.component';

import { Button } from '@/components/atomic/button';
import { Screen } from '@/components/atomic/screen';
import { Separator } from '@/components/atomic/separator';
import { TextM } from '@/components/atomic/text';
import { TextXXL } from '@/components/atomic/text/TextXXL.atomic';
import { useAuthentication } from '@/hooks/useAuthentication';
import { useDesign } from '@/hooks/useDesign';
import { OpenInNewIcon, UniversityLogo } from '@/svgs';
import { createStyles, platformSpecificStyle } from '@/utils';
import { DeviceInfoContext } from '@/wrappers/device-info';

const { getStyle } = createStyles(({ pixel, colors }) => ({
  screen: {
    paddingLeft: pixel(40)
  },
  content: {
    flex: 1,
    ...platformSpecificStyle({
      native: {},
      default: {
        justifyContent: 'center'
      }
    })
  },
  header: {
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {
        marginBottom: pixel(129)
      },
      default: {
        marginBottom: 100
      }
    })
  },
  mainTitle: {
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(73),
        marginTop: pixel(35)
      },
      default: {}
    }),
    fontWeight: 'bold'
  },
  loginBody: {
    ...platformSpecificStyle({
      native: {},
      default: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center'
      }
    })
  },
  loginSection: {
    ...platformSpecificStyle({
      native: {
        paddingHorizontal: pixel(40),
        width: '100%'
      },
      default: {
        width: pixel(300),
        marginRight: pixel(67)
      }
    })
  },
  loginButtonContainer: {
    marginTop: pixel(35),
    ...platformSpecificStyle({
      native: {
        marginBottom: pixel(67)
      },
      default: {
        marginBottom: pixel(34)
      }
    })
  },
  loginButtonLabel: {
    fontWeight: 'bold',
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(38)
      },
      default: {
        fontSize: pixel(19)
      }
    })
  },
  errorMessage: {
    alignSelf: 'center',
    color: colors.textPrimary,
    fontWeight: 'bold'
  },
  forgetPasswordContainer: {
    alignSelf: 'flex-end',
    ...platformSpecificStyle({
      native: {
        marginTop: pixel(100)
      },
      default: {
        marginTop: pixel(50)
      }
    })
  },
  forgetPasswordButton: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  forgetPasswordText: {
    paddingVertical: pixel(5),
    borderBottomWidth: pixel(1),
    borderBottomColor: 'white'
  },
  openInNewIcon: {
    ...platformSpecificStyle({
      native: {
        width: pixel(30),
        height: pixel(22)
      },
      default: {
        width: pixel(15),
        height: pixel(11)
      }
    })
  },
  logoContainer: {
    position: 'absolute',
    ...platformSpecificStyle({
      native: {
        position: 'relative',
        left: -20
      },
      default: {
        right: 6,
        top: 0
      }
    })
  },
  universityLogo: {
    position: 'absolute',
    alignSelf: 'center',
    ...platformSpecificStyle({
      native: {
        bottom: 0,
        width: pixel(372),
        height: pixel(110)
      },
      default: {
        top: 0,
        right: 0,
        width: pixel(155),
        height: pixel(46)
      }
    })
  }
}));

const APP_NAME = Constants.expoConfig?.extra?.APP_NAME;
type LoginRouteProp = RouteProp<RootStackParamList, 'Login'>;

export const LoginScreen: React.FC = () => {
  const { bottom } = useSafeAreaInsets();
  const design = useDesign();
  const styles = getStyle(design);
  const { pixel } = design;
  const { login } = useAuthentication();
  const [errorMessage, setErrorMessage] = useState('');
  // additionalMessage を取得
  const route = useRoute<LoginRouteProp>();
  const additionalMessage = route.params?.additionalMessage;

  const doLogin = async () => {
    const errorMessage = await login();
    if (errorMessage) {
      setErrorMessage(errorMessage);
    }
  };
  const onPressLoginButton = async () => {
    return doLogin();
  };

  const { isBrowser } = useContext(DeviceInfoContext);

  return (
    <Screen style={{ ...styles.screen, paddingBottom: bottom }}>
      <View style={{ ...styles.content }}>
        <View style={{ ...styles.header }}>
          <TextM>学生生活・学修支援ポータルサイト</TextM>
          <TextXXL style={styles.mainTitle}>{APP_NAME}</TextXXL>
        </View>
        <View style={{ ...styles.loginBody }}>
          <View style={{ ...styles.loginSection }}>
            <TextM style={{ ...styles.errorMessage }}>
              {errorMessage || additionalMessage}
            </TextM>
            <View style={{ ...styles.loginButtonContainer }}>
              <Button
                fullWidth
                label="ログイン"
                labelStyle={{ ...styles.loginButtonLabel }}
                onPress={onPressLoginButton}
              />
            </View>
            <TextM>このシステムの利用にはYamato IDが必要です。</TextM>
            <A
              target="_blank"
              style={{ ...styles.forgetPasswordContainer }}
              href={Constants.expoConfig?.extra?.FORGET_PASSWORD_LINK}
            >
              <View style={{ ...styles.forgetPasswordButton }}>
                <View style={{ ...styles.forgetPasswordText }}>
                  <TextM>パスワードを忘れた方はこちらから</TextM>
                </View>
                <Separator vertical size={pixel(9)} />
                <OpenInNewIcon size={{ ...styles.openInNewIcon }} />
              </View>
            </A>
          </View>
          {isBrowser && <AppDownloadSection />}
        </View>
      </View>
      <View style={{ ...styles.logoContainer }}>
        <UniversityLogo style={{ ...styles.universityLogo }} />
      </View>
    </Screen>
  );
};
