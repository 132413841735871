/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer } from '@react-navigation/native';
import {
  CardStyleInterpolators,
  createStackNavigator
} from '@react-navigation/stack';
import CalendarIconWhite from 'assets/images/tab-bar-icons/calendar-icon-white.png';
import CalendarIcon from 'assets/images/tab-bar-icons/calendar-icon.png';
import CheckListIconWhite from 'assets/images/tab-bar-icons/check-list-icon-white.png';
import CheckListIcon from 'assets/images/tab-bar-icons/check-list-icon.png';
import GearIconWhite from 'assets/images/tab-bar-icons/gear-icon-white.png';
import GearIcon from 'assets/images/tab-bar-icons/gear-icon.png';
import HomeIconWhite from 'assets/images/tab-bar-icons/home-icon-white.png';
import HomeIcon from 'assets/images/tab-bar-icons/home-icon.png';
import MessageIconWhite from 'assets/images/tab-bar-icons/message-icon-white.png';
import MessageIcon from 'assets/images/tab-bar-icons/message-icon.png';
import QRCodeReadRedTabbarIcon from 'assets/images/tab-bar-icons/qr-code-read-red.png';
import QRCodeReadWhiteTabbarIcon from 'assets/images/tab-bar-icons/qr-code-read-white.png';
import Constants from 'expo-constants';
import React from 'react';
import { Platform } from 'react-native';

import { ModalScreen } from '../screens/ModalScreen';
import { NotFoundScreen } from '../screens/NotFoundScreen';
import { HomeScreen } from '../screens/home/Home.screen';
import { LoginScreen } from '../screens/login/Login.screen';
import { ForcusableTabBarIcon } from './ForcusableTabBarIcon';
import LinkingConfiguration from './LinkingConfiguration';
import { TabBarIcon } from './TabBarIcon';
import {
  AccountStackParamList,
  HomeStackParamList,
  NaviStackParamList,
  RootStackParamList,
  RootTabParamList,
  TeacherHomeStackParamList,
  TeacherRootTabParamList,
  TeacherStudentIdReaderStackParamList
} from './types';

import { Menu } from '@/components/atomic/menu';
import { Screen } from '@/components/atomic/screen';
import { useDesign } from '@/hooks/useDesign';
import { AccountScreen, NotificationSettingsScreen } from '@/screens/account';
import { CriticalErrorScreen } from '@/screens/critical-error';
import { CallbackScreen } from '@/screens/login';
import { NaviScreen } from '@/screens/navi';
import { SubNaviScreen } from '@/screens/navi/SubNavi.screen';
import { NotificationsScreen } from '@/screens/notifications';
import {
  ClassAttendanceScreen,
  TeacherHomeScreen
} from '@/screens/teachers/home';
import { QrScanTabScreen } from '@/screens/teachers/student-id-reader';
import { TimetableScreen } from '@/screens/timetable';
import { hasToken, selectUnreadCount, useAppSelector } from '@/store';
import { createStyles, getErrorMessageWithKey, isMobile } from '@/utils';
import { ErrorRedirectWrapper } from '@/wrappers/error-redirect/ErrorRedirect.wrapper';

const APP_NAME = Constants.expoConfig?.extra?.APP_NAME;

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */

const { getStyle } = createStyles(({ pixel }) => ({
  common: {
    overflow: 'hidden'
  },
  tabIcon: {
    width: pixel(20),
    height: pixel(20)
  },
  nestedNavigation: {
    flex: 1
  }
}));

const NaviStack = createStackNavigator<NaviStackParamList>();

const NaviStackScreen = () => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <NaviStack.Navigator
      id="NaviNavigator"
      screenOptions={{
        cardStyle: { ...styles.common, ...styles.nestedNavigation },
        headerShown: false,
        animationEnabled: false,
        gestureDirection: 'horizontal',
        gestureEnabled: true,
        gestureVelocityImpact: 0.5,
        cardOverlay: () => {
          return <Screen />;
        }
      }}
    >
      <NaviStack.Screen
        name="Navi"
        component={NaviScreen}
        options={{ title: `${APP_NAME} 大学ナビ` }}
      />
      <NaviStack.Screen name="SubNavi" component={SubNaviScreen} />
    </NaviStack.Navigator>
  );
};

const AccountStack = createStackNavigator<AccountStackParamList>();

const AccountStackScreen = () => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <AccountStack.Navigator
      screenOptions={{
        cardStyle: { ...styles.common, ...styles.nestedNavigation },
        headerShown: false,
        animationEnabled: false,
        gestureDirection: 'horizontal',
        gestureEnabled: true,
        gestureVelocityImpact: 0.5,
        cardOverlay: () => {
          return <Screen />;
        }
      }}
    >
      <AccountStack.Screen
        name="Account"
        initialParams={{
          ...(!isMobile && { subRoute: 'notification-settings' })
        }}
        options={{ title: `${APP_NAME} アカウント` }}
        component={AccountScreen}
      />
      <AccountStack.Screen
        name="NotificationSettings"
        component={NotificationSettingsScreen}
      />
    </AccountStack.Navigator>
  );
};

const HomeTabStack = createStackNavigator<HomeStackParamList>();

const HomeTabStackScreen = () => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <HomeTabStack.Navigator
      screenOptions={{
        cardStyle: { ...styles.common, ...styles.nestedNavigation },
        headerShown: false,
        animationEnabled: false,
        gestureDirection: 'horizontal',
        gestureEnabled: true,
        gestureVelocityImpact: 0.5,
        cardOverlay: () => {
          return <Screen />;
        }
      }}
    >
      <HomeTabStack.Screen
        name="Home"
        options={{
          title: `${APP_NAME} - 大和大学の学生生活・学修支援ポータル`
        }}
        component={HomeScreen}
      />
    </HomeTabStack.Navigator>
  );
};

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

const BottomTabNavigator: React.FC = () => {
  const design = useDesign();
  const styles = getStyle(design);
  const messages = useAppSelector(selectUnreadCount);

  return (
    <BottomTab.Navigator
      id="TabNavigator"
      initialRouteName="HomeTab"
      sceneContainerStyle={{ ...styles.common }}
      screenOptions={{
        headerShown: false
      }}
      backBehavior="none"
      tabBar={(tabBarProps) => <Menu {...tabBarProps} />}
    >
      <BottomTab.Screen
        name="HomeTab"
        component={HomeTabStackScreen}
        options={() => ({
          tabBarLabel: 'トップ',
          tabBarIcon: (props) =>
            isMobile ? (
              <TabBarIcon {...props} src={HomeIcon} />
            ) : (
              <ForcusableTabBarIcon
                {...props}
                src={HomeIcon}
                srcOnFocused={HomeIconWhite}
              />
            )
        })}
      />
      <BottomTab.Screen
        name="Timetable"
        component={TimetableScreen}
        options={{
          title: `${APP_NAME} - 時間割`,
          tabBarLabel: '時間割',
          tabBarIcon: (props) =>
            isMobile ? (
              <TabBarIcon {...props} src={CalendarIcon} />
            ) : (
              <ForcusableTabBarIcon
                {...props}
                src={CalendarIcon}
                srcOnFocused={CalendarIconWhite}
              />
            )
        }}
      />
      <BottomTab.Screen
        name="Notification"
        component={NotificationsScreen}
        options={{
          title: `${APP_NAME} - お知らせ`,
          tabBarLabel: 'お知らせ',
          tabBarIcon: (props) =>
            isMobile ? (
              <TabBarIcon {...props} src={MessageIconWhite} />
            ) : (
              <ForcusableTabBarIcon
                {...props}
                src={MessageIcon}
                srcOnFocused={MessageIconWhite}
              />
            ),
          ...(messages && { tabBarBadge: '!' })
        }}
      />
      <BottomTab.Screen
        name="NaviRoot"
        component={NaviStackScreen}
        options={{
          title: `${APP_NAME} 大学ナビ`,
          tabBarLabel: '大学ナビ',
          tabBarIcon: (props) =>
            isMobile ? (
              <TabBarIcon {...props} src={CheckListIcon} />
            ) : (
              <ForcusableTabBarIcon
                {...props}
                src={CheckListIcon}
                srcOnFocused={CheckListIconWhite}
              />
            )
        }}
      />
      <BottomTab.Screen
        name="AccountRoot"
        component={AccountStackScreen}
        options={{
          title: `${APP_NAME} アカウント`,
          tabBarLabel: 'アカウント',
          tabBarIcon: (props) =>
            isMobile ? (
              <TabBarIcon {...props} src={GearIcon} />
            ) : (
              <ForcusableTabBarIcon
                {...props}
                src={GearIcon}
                srcOnFocused={GearIconWhite}
              />
            )
        }}
      />
    </BottomTab.Navigator>
  );
};

// 教員用画面Stack
const TeacherHomeTabStack = createStackNavigator<TeacherHomeStackParamList>();

const TeacherHomeTabStackScreen = () => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <TeacherHomeTabStack.Navigator
      screenOptions={{
        cardStyle: { ...styles.common, ...styles.nestedNavigation },
        headerShown: false,
        animationEnabled: false,
        gestureDirection: 'horizontal',
        gestureEnabled: true,
        gestureVelocityImpact: 0.5,
        cardOverlay: () => {
          return <Screen />;
        }
      }}
    >
      <TeacherHomeTabStack.Screen
        name="Home"
        options={{
          title: `${APP_NAME} - 大和大学の学生生活・学修支援ポータル`
        }}
        component={TeacherHomeScreen}
      />
      <TeacherHomeTabStack.Screen
        name="ClassAttendanceScreen"
        options={{
          title: `${APP_NAME} - 出席詳細画面`
        }}
        component={ClassAttendanceScreen}
      />
    </TeacherHomeTabStack.Navigator>
  );
};

// 教員用画面Stack
const TeacherStudentIdReaderStack =
  createStackNavigator<TeacherStudentIdReaderStackParamList>();

const TeacherStudentIdReaderStackScreen = () => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <TeacherStudentIdReaderStack.Navigator
      screenOptions={{
        cardStyle: { ...styles.common, ...styles.nestedNavigation },
        headerShown: false,
        animationEnabled: false,
        gestureDirection: 'horizontal',
        gestureEnabled: true,
        gestureVelocityImpact: 0.5,
        cardOverlay: () => {
          return <Screen />;
        }
      }}
    >
      <TeacherStudentIdReaderStack.Screen
        name="QrScanTabScreen"
        options={{
          title: `学生証読み取り`
        }}
        component={QrScanTabScreen}
      />
    </TeacherStudentIdReaderStack.Navigator>
  );
};

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const TeacherBottomTab = createBottomTabNavigator<TeacherRootTabParamList>();

const TeacherBottomTabNavigator: React.FC = () => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <TeacherBottomTab.Navigator
      id="TabNavigator"
      initialRouteName="HomeTab"
      sceneContainerStyle={{ ...styles.common }}
      screenOptions={{
        headerShown: false
      }}
      backBehavior="none"
      tabBar={(tabBarProps) => <Menu {...tabBarProps} />}
    >
      <TeacherBottomTab.Screen
        name="HomeTab"
        component={TeacherHomeTabStackScreen}
        options={() => ({
          tabBarLabel: 'トップ',
          tabBarIcon: (props) =>
            isMobile ? (
              <TabBarIcon {...props} src={HomeIcon} />
            ) : (
              <ForcusableTabBarIcon
                {...props}
                src={HomeIcon}
                srcOnFocused={HomeIconWhite}
              />
            )
        })}
      />
      <TeacherBottomTab.Screen
        name="StudentIdReaderTab"
        component={TeacherStudentIdReaderStackScreen}
        options={() => ({
          tabBarLabel: '学生証読み取り',
          tabBarIcon: (props) =>
            isMobile ? (
              <TabBarIcon {...props} src={QRCodeReadRedTabbarIcon} />
            ) : (
              <ForcusableTabBarIcon
                {...props}
                src={QRCodeReadRedTabbarIcon}
                srcOnFocused={QRCodeReadWhiteTabbarIcon}
              />
            )
        })}
      />
    </TeacherBottomTab.Navigator>
  );
};
/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createStackNavigator<RootStackParamList>();

const RootNavigator: React.FC = () => {
  const design = useDesign();
  const style = getStyle(design);
  const isLoggedIn = useAppSelector(hasToken);
  const isStudent = useAppSelector((state) => state.account.profile.isStudent);

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        animationEnabled: true,
        cardOverlay: () => {
          return <Screen />;
        },
        cardStyleInterpolator: CardStyleInterpolators.forFadeFromCenter,
        cardStyle: {
          ...style.common
        }
      }}
    >
      {isLoggedIn ? (
        <>
          {isStudent ? (
            <Stack.Group>
              <Stack.Screen name="Root" component={BottomTabNavigator} />
            </Stack.Group>
          ) : (
            <>
              {Platform.OS === 'web' ? (
                <Stack.Group>
                  <Stack.Screen
                    name="LoginWithTeacherLoginEnabledOnlyAppMessage"
                    component={LoginScreen}
                    options={{
                      title: `${APP_NAME} - 大和大学の学生生活・学修支援ポータル`
                    }}
                    initialParams={{
                      additionalMessage: getErrorMessageWithKey(
                        'teacherLoginEnabledOnlyApp'
                      )
                    }}
                  />
                  <Stack.Screen
                    name="CallbackWithTeacherLoginEnabledOnlyAppMessage"
                    component={CallbackScreen}
                  />
                </Stack.Group>
              ) : (
                <Stack.Group>
                  <Stack.Screen
                    name="Root"
                    component={TeacherBottomTabNavigator}
                  />
                </Stack.Group>
              )}
            </>
          )}
        </>
      ) : (
        <Stack.Group>
          <Stack.Screen
            name="Login"
            component={LoginScreen}
            options={{
              title: `${APP_NAME} - 大和大学の学生生活・学修支援ポータル`
            }}
          />
          <Stack.Screen name="Callback" component={CallbackScreen} />
        </Stack.Group>
      )}
      <Stack.Group navigationKey={isLoggedIn ? 'user' : 'guest'}>
        <Stack.Screen
          name="NotFound"
          component={NotFoundScreen}
          options={{ title: 'Oops!' }}
        />
        <Stack.Screen
          name="Modal"
          options={{
            presentation: 'transparentModal'
          }}
          component={ModalScreen}
        />
        <Stack.Screen name="CriticalError" component={CriticalErrorScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
};

export const Navigation: React.FC = () => {
  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <ErrorRedirectWrapper>
        <RootNavigator />
      </ErrorRedirectWrapper>
    </NavigationContainer>
  );
};
