import React from 'react';
import { View } from 'react-native';

import { CafeteriaMenuCardDesktop } from './CafeteriaMenuCardDesktop.component';
import { EventCardAllDay } from './EventCardAllDay.component';

import { Separator } from '@/components/atomic/separator';
import { useDesign } from '@/hooks/useDesign';
import { CalendarEvent } from '@/interfaces/Calendar.interface';
import { useCafeteriaStatus } from '@/screens/home/hooks/useCafeteriaStatus';
import { createStyles, isMobile } from '@/utils';

interface Props {
  selectedDate: Date;
  events: CalendarEvent[];
  cafeteriaMenus: string[];
}

const { getStyle } = createStyles(() => ({
  scrollableBody: {
    flexDirection: 'row'
  },
  listContainer: {
    flex: 1
  }
}));

export const EventListAllDay: React.FC<Props> = ({
  selectedDate,
  events,
  cafeteriaMenus
}) => {
  const design = useDesign();
  const styles = getStyle(design);
  const { pixel } = design;
  const { isActiveCafeteria, isExpiredCafeteria } = useCafeteriaStatus({
    currentTime: new Date(),
    selectedDate
  });

  return (
    <View style={{ ...styles.scrollableBody }}>
      <View style={{ ...styles.listContainer }}>
        {events.map((event, index) => (
          <React.Fragment key={event.name}>
            <EventCardAllDay eventInfo={event} />
            {index < events.length - 1 && (
              <Separator size={isMobile ? pixel(20) : 16} />
            )}
          </React.Fragment>
        ))}
        {cafeteriaMenus && cafeteriaMenus.length > 0 && (
          <>
            {events.length > 0 && (
              <Separator size={isMobile ? pixel(20) : 16} />
            )}
            <CafeteriaMenuCardDesktop
              menus={cafeteriaMenus}
              selectedDate={selectedDate}
              isActive={isActiveCafeteria}
              isExpired={isExpiredCafeteria}
            />
          </>
        )}
      </View>
    </View>
  );
};
