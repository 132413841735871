import { PropsWithChildren } from 'react';
import { TextStyle, TouchableOpacity, View } from 'react-native';

import { TextM } from '../text';

import { useDesign } from '@/hooks/useDesign';
import { RowType } from '@/interfaces/List.interface';
import { ChevronIcon, OpenInNewIcon, PDFIcon } from '@/svgs';
import { createStyles, platformSpecificStyle } from '@/utils';

export interface Row {
  key: string;
  keyStyle?: TextStyle;
  type?: RowType;
  value?: string | JSX.Element;
  onPress?: () => void;
}

interface Props {
  row: Row;
  isActive?: boolean;
}

const { getStyle } = createStyles(({ pixel, colors }) => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.button.background
  },
  activeContainer: {
    backgroundColor: 'transparent'
  },
  cell: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...platformSpecificStyle({
      native: {
        paddingVertical: pixel(30),
        paddingHorizontal: pixel(40)
      },
      default: {
        paddingVertical: pixel(16),
        paddingHorizontal: pixel(20)
      }
    })
  },
  cellAction: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {
        paddingVertical: pixel(30),
        paddingHorizontal: pixel(40)
      },
      default: {
        paddingVertical: pixel(16),
        paddingHorizontal: pixel(20)
      }
    })
  },
  openInNew: {
    ...platformSpecificStyle({
      native: {
        width: pixel(30),
        height: pixel(22)
      },
      default: {
        width: pixel(15),
        height: pixel(11)
      }
    })
  },
  pdf: {
    ...platformSpecificStyle({
      native: {
        width: pixel(48),
        height: pixel(24)
      },
      default: {
        width: pixel(24),
        height: pixel(12)
      }
    })
  },
  chevron: {
    ...platformSpecificStyle({
      native: {
        width: pixel(24),
        height: pixel(32)
      },
      default: {
        width: pixel(12),
        height: pixel(16)
      }
    })
  },
  text: {
    fontSize: 13,
    color: colors.textPrimaryInverted
  },
  textActive: {
    color: colors.textPrimary
  },
  label: {
    color: colors.list.label,
    fontWeight: 'normal'
  }
}));

export const ListRow: React.FC<PropsWithChildren<Props>> = ({
  row,
  isActive,
  children
}) => {
  const design = useDesign();
  const styles = getStyle(design);
  const { colors } = design;

  const content = children || (
    <>
      <View style={{ ...styles.cell }}>
        <TextM
          style={{
            ...styles.text,
            ...(row.type === 'data' && styles.label),
            ...row.keyStyle,
            ...(isActive && styles.textActive)
          }}
        >
          {row.key}
        </TextM>
      </View>
      {!isActive && (row.type || row.value) && (
        <View style={{ ...styles.cellAction }}>
          {(row.type === 'data' && (
            <TextM style={{ ...styles.text }}>{row.value}</TextM>
          )) ||
            (row.type === 'open' &&
              typeof row.value === 'string' &&
              row.value.endsWith('.pdf') && (
                <PDFIcon size={{ ...styles.pdf }} />
              )) ||
            (row.type === 'open' && (
              <OpenInNewIcon
                color={colors.primary}
                borderColor={colors.list.openInNew}
                size={{ ...styles.openInNew }}
              />
            )) ||
            (row.type === 'navigate' && (
              <ChevronIcon
                color={colors.list.chevron}
                size={{ ...styles.chevron }}
                direction="right"
              />
            )) ||
            row.value}
        </View>
      )}
    </>
  );

  return row.onPress ? (
    <TouchableOpacity
      onPress={row.onPress}
      style={{
        ...styles.container,
        ...(isActive && styles.activeContainer)
      }}
    >
      {content}
    </TouchableOpacity>
  ) : (
    <View
      style={{
        ...styles.container,
        ...(isActive && styles.activeContainer)
      }}
    >
      {content}
    </View>
  );
};
