import { Path } from 'react-native-svg';

import { SvgProps, SvgWrapper, usePrimaryIconColor } from '@/wrappers/svg';

export type Direction = 'left' | 'down' | 'right' | 'up';

const directionRotation: Record<Direction, number> = {
  right: 0,
  down: 90,
  left: 180,
  up: 270
};

interface Props extends SvgProps {
  direction?: Direction;
}

export const ChevronIcon: React.FC<Props> = ({
  direction,
  color,
  ...props
}) => {
  const primaryIconColor = usePrimaryIconColor();
  const rotate = direction ? `${directionRotation[direction]}deg` : '180deg';

  return (
    <SvgWrapper
      viewBox={{ width: 25, height: 38 }}
      {...props}
      style={{ ...props.style, transform: [{ rotate }] }}
    >
      <Path
        fillRule="evenodd"
        stroke={color || primaryIconColor}
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="miter"
        fill="none"
        d="M3.1000,32.000 L18.000,18.000 L3.1000,4.000 "
      />
    </SvgWrapper>
  );
};
