import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const CsvExportIcon: React.FC<SvgProps> = ({
  color = '#fff',
  ...props
}) => {
  return (
    <Svg width={33} height={33} viewBox="0 0 33 33" {...props}>
      <Path data-name="\u9577\u65B9\u5F62 496" fill="none" d="M0 0H33V33H0z" />
      <Path
        data-name="\u30D1\u30B9 364"
        d="M26.75 4h-22A2.758 2.758 0 002 6.75v16.5A2.758 2.758 0 004.75 26h12.375v-2.75H4.75V9.5l11 6.875 11-6.875v6.875h2.75V6.75A2.758 2.758 0 0026.75 4zm-11 9.625l-11-6.875h22zm9.625 5.5l5.5 5.5-5.5 5.5V26h-5.5v-2.75h5.5z"
        transform="translate(.75 1.5)"
        fill={color}
      />
    </Svg>
  );
};
