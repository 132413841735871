import React from 'react';
import { ImageSourcePropType, ImageStyle } from 'react-native';

import FastImage from '@/components/fast-image/FastImage';

interface Props {
  color?: string;
  src: ImageSourcePropType;
  size: {
    width: number;
    height?: number;
  };
  style?: ImageStyle;
}

export const Image: React.FC<Props> = React.memo(
  ({ color, src, size, style }) => {
    const overrideStyle = {
      ...style,
      width: size.width,
      height: size.height || size.width
    };
    color && (overrideStyle.tintColor = color);
    return (
      <FastImage resizeMode="contain" source={src} style={overrideStyle} />
    );
  },
  (prevProps, nextProps) => {
    // we can assume that keys are always in the same order
    const arePropsEqual =
      JSON.stringify(prevProps) === JSON.stringify(nextProps);

    return arePropsEqual;
  }
);
