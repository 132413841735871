export enum NotificationTypes {
  GMAIL = 'gmail',
  CLASS_ROOM = 'classroom',
  CAMPUS_PLAN = 'campusPlan'
}
export enum NotificationsFilterTypes {
  ALL = 'all',
  UNREAD = 'unread',
  GMAIL = 'gmail',
  CLASS_ROOM = 'classroom',
  CAMPUS_PLAN = 'campusPlan'
}

export enum NotificationReadTypes {
  READ = 'read',
  UNREAD = 'unread'
}

export const MESSAGE_TYPE_MAPPING: Record<
  NotificationTypes,
  {
    label: string;
    labelColor: string;
  }
> = {
  [NotificationTypes.GMAIL]: {
    label: 'Gmail',
    labelColor: '#C42323'
  },
  [NotificationTypes.CLASS_ROOM]: {
    label: 'Classroom',
    labelColor: '#096ED1'
  },
  [NotificationTypes.CAMPUS_PLAN]: {
    label: 'CampusPlan',
    labelColor: '#248000'
  }
};

export interface Notification {
  id: string;
  type: NotificationTypes;
  date: Date;
  title: string;
  body: string;
  fullBody: string;
  url?: string;
  sender?: string;
  category_name?: string;
  isRead?: boolean;
  attachments?: { id?: string; filename?: string }[];
}

export interface NotificationList {
  notifications: Notification[];
  total: number;
  current: number;
}

export type NotificationApi = Omit<Notification, 'date'> & { date: string };

export type NotificationListApi = Omit<NotificationList, 'notifications'> & {
  notifications: NotificationApi[];
};

export interface NotificationSection {
  header: JSX.Element;
  data: Notification[];
  isRead?: boolean;
  isLast?: boolean;
}
