import { View } from 'react-native';

import { TextS } from '../text';

import { useDesign } from '@/hooks/useDesign';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  badge: number | string;
}

const { getStyle } = createStyles(({ pixel, colors }) => ({
  badgeContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.icon.primaryInverted,
    ...platformSpecificStyle({
      native: {
        width: pixel(32),
        height: pixel(32),
        borderRadius: pixel(32),
        right: pixel(24),
        top: pixel(24)
      },
      default: {
        width: pixel(16),
        height: pixel(16),
        borderRadius: pixel(16),
        right: -pixel(8),
        top: -pixel(4)
      }
    })
  },
  badge: {
    fontWeight: 'bold',
    ...platformSpecificStyle({
      native: {},
      default: {
        fontSize: pixel(12)
      }
    })
  }
}));

export const BadgeContainer: React.FC<Props> = ({ badge }) => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <View
      style={{
        ...styles.badgeContainer
      }}
    >
      <TextS style={{ ...styles.badge }}>{badge}</TextS>
    </View>
  );
};
