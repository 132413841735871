import React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const DeleteAllIcon: React.FC<SvgProps> = ({
  color = '#fff',
  ...props
}) => {
  return (
    <Svg width={33} height={33} viewBox="0 0 33 33" {...props}>
      <Path data-name="\u30D1\u30B9 353" d="M0 0h33v33H0z" fill="none" />
      <Path
        data-name="\u30D1\u30B9 354"
        d="M19.983 14.2L16.5 17.385 13 14.2l-2.316 2.12 3.5 3.18-3.484 3.18 2.316 2.115 3.483-3.18 3.483 3.18L22.3 22.68l-3.484-3.18 3.484-3.18zm2.267-9.7L20.607 3h-8.214L10.75 4.5H5v3h23v-3zM6.643 27a3.162 3.162 0 003.286 3h13.142a3.162 3.162 0 003.286-3V9H6.643zm3.286-15h13.142v15H9.929z"
        fill={color}
      />
    </Svg>
  );
};
