import { A } from '@expo/html-elements';
import AppIcon from 'assets/icon.png';
import AppStoreBadgeHQ from 'assets/images/store-info/app-store-badge.native.png';
import AppStoreBadge from 'assets/images/store-info/app-store-badge.png';
import AppStoreQrCode from 'assets/images/store-info/app-store-qr-code.png';
import PlayStoreBadgeIconHQ from 'assets/images/store-info/play-store-badge.native.png';
import PlayStoreBadgeIcon from 'assets/images/store-info/play-store-badge.png';
import PlayStoreQrCode from 'assets/images/store-info/play-store-qr-code.png';
import Constants from 'expo-constants';
import { DeviceType } from 'expo-device';
import { useContext } from 'react';
import { ImageBackground, View } from 'react-native';

import { Separator } from '@/components/atomic/separator';
import { TextM } from '@/components/atomic/text';
import FastImage from '@/components/fast-image/FastImage';
import { useDesign } from '@/hooks/useDesign';
import { createStyles, isMobile, platformSpecificStyle } from '@/utils';
import { DeviceInfoContext, DeviceOS } from '@/wrappers/device-info';

const { getStyle } = createStyles(({ colors, pixel }) => ({
  appIcon: {
    borderWidth: pixel(2),
    borderColor: colors.button.borderModal,
    borderStyle: 'solid',
    marginRight: pixel(20),
    ...platformSpecificStyle({
      native: {
        borderRadius: pixel(26),
        width: pixel(116),
        height: pixel(116)
      },
      default: {
        borderRadius: 13,
        width: pixel(58),
        height: pixel(58)
      }
    })
  },
  appStore: {
    height: pixel(380),
    backgroundColor: 'rgba(255,255,255,0.161)',
    borderRadius: pixel(20),
    marginBottom: pixel(60),
    overflow: 'hidden',
    ...platformSpecificStyle({
      native: {
        marginTop: pixel(85),
        width: '100%'
      },
      default: {
        width: pixel(400)
      }
    })
  },
  appStoreHeader: {
    backgroundColor: colors.textPrimary,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {
        height: pixel(170)
      },
      default: {
        height: pixel(86)
      }
    })
  },
  tooltipArrow: {
    width: 0,
    height: 0,
    position: 'absolute',
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    ...platformSpecificStyle({
      native: {
        bottom: -pixel(18),
        borderTopWidth: pixel(24),
        borderRightWidth: pixel(18),
        borderLeftWidth: pixel(18)
      },
      default: {
        bottom: -9,
        borderTopWidth: 12,
        borderRightWidth: 9,
        borderLeftWidth: 9
      }
    }),
    borderBottomWidth: 0,
    borderTopColor: colors.textPrimary,
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: 'transparent'
  },
  appStoreText: {
    color: colors.textPrimaryInverted,
    fontWeight: 'bold'
  },
  appStoreBody: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  appStoreBadeContainer: {
    alignItems: 'center',
    width: '100%'
  },
  appStoreQrCodeContainer: {
    width: pixel(150),
    height: pixel(150),
    marginBottom: pixel(14)
  },
  appStoreQrCode: {
    opacity: 0.7,
    contentFit: 'cover'
  },
  appStoreBadgeIcon: {
    ...platformSpecificStyle({
      native: {
        width: pixel(300),
        height: pixel(110)
      },
      default: {
        width: pixel(109),
        height: pixel(40)
      }
    })
  },
  googlePlayBadgeIcon: {
    ...platformSpecificStyle({
      native: {
        contentFit: 'stretch',
        width: pixel(364),
        height: pixel(108)
      },
      default: {
        contentFit: 'cover',
        width: pixel(135),
        height: pixel(40)
      }
    })
  }
}));

const APP_NAME = Constants.expoConfig?.extra?.APP_NAME;

export const AppDownloadSection: React.FC = () => {
  const design = useDesign();
  const styles = getStyle(design);
  const { pixel } = design;
  const { deviceType, deviceOS } = useContext(DeviceInfoContext);

  return (
    <View style={{ ...styles.appStore }}>
      <View style={{ ...styles.appStoreHeader }}>
        <FastImage
          style={{
            ...styles.appIcon
          }}
          source={AppIcon}
        />
        <View>
          <TextM style={{ ...styles.appStoreText }}>
            公式アプリでさらに便利に！
          </TextM>
          <TextM style={{ ...styles.appStoreText }}>{`${APP_NAME} App`}</TextM>
        </View>
        <View style={{ ...styles.tooltipArrow }} />
      </View>
      <View style={{ ...styles.appStoreBody }}>
        <A target="_blank" href={Constants.expoConfig?.extra?.APP_STORE_LINK}>
          <View style={{ ...styles.appStoreBadeContainer }}>
            {deviceType === DeviceType.DESKTOP && (
              <ImageBackground
                style={{
                  ...styles.appStoreQrCodeContainer
                }}
                imageStyle={{
                  ...styles.appStoreQrCode
                }}
                source={AppStoreQrCode}
              />
            )}
            {deviceOS !== DeviceOS.Android && (
              <FastImage
                style={styles.appStoreBadgeIcon}
                source={isMobile ? AppStoreBadgeHQ : AppStoreBadge}
              />
            )}
          </View>
        </A>
        {deviceType === DeviceType.DESKTOP && (
          <Separator size={pixel(20)} vertical />
        )}
        <A target="_blank" href={Constants.expoConfig?.extra?.PLAY_STORE_LINK}>
          <View style={{ ...styles.appStoreBadeContainer }}>
            {deviceType === DeviceType.DESKTOP && (
              <ImageBackground
                style={{ ...styles.appStoreQrCodeContainer }}
                imageStyle={{
                  ...styles.appStoreQrCode
                }}
                source={PlayStoreQrCode}
              />
            )}
            {![DeviceOS.IOS, DeviceOS.IPadOS].includes(deviceOS) && (
              <FastImage
                style={{ ...styles.googlePlayBadgeIcon }}
                source={isMobile ? PlayStoreBadgeIconHQ : PlayStoreBadgeIcon}
              />
            )}
          </View>
        </A>
      </View>
    </View>
  );
};
