import Notifee, {
  AndroidImportance,
  AndroidVisibility,
  Event,
  EventType
} from '@notifee/react-native';
import { isWithinInterval } from 'date-fns';
import * as Location from 'expo-location';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  NativeEventEmitter,
  NativeModules,
  PermissionsAndroid,
  Platform
} from 'react-native';

import Beacons from '@/components/beacons/Beacons';
import { BeaconDidRangeEvent } from '@/interfaces/Beacons.interface';
import { convertTimeStringToDate } from '@/utils';
import { DeviceInfoContext } from '@/wrappers/device-info';

export const requestIosLocationPermissionsForBeaconDetection = async () => {
  const permissionForgroundLocation =
    await Location.requestForegroundPermissionsAsync();
  // AppDelegateでのアプリ未起動時のmonitoringに「常に」位置情報は必須
  const permissionBackgroundLocation =
    await Location.requestBackgroundPermissionsAsync();

  return {
    permissionForgroundLocationGranted: permissionForgroundLocation.granted,
    permissionBackgroundLocationGranted: permissionBackgroundLocation.granted
  };
};
export const requestAndroidLocationPermissionsForBeaconDetection = async () => {
  if (Platform.OS !== 'android') return false;
  const granted =
    (await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.BLUETOOTH_SCAN
    )) &&
    // 以下のドキュメントの通り、ACCESS_FINE_LOCATION と ACCESS_COARSE_LOCATION の両方のパーミッションを同時にリクエストする
    // > アプリが ACCESS_FINE_LOCATION と ACCESS_COARSE_LOCATION の両方をリクエストした場合、システム権限ダイアログには次のオプションが表示されます。正確: アプリに正確な位置情報の取得を許可します。おおよそ: アプリにおおよその位置情報のみの取得を許可します。
    // > ACCESS_FINE_LOCATION 権限と ACCESS_COARSE_LOCATION 権限をもう一度リクエストします。ユーザーはすでにアプリにおおよその位置情報へのアクセス権を付与することをシステムに許可しているため、システム ダイアログの内容は前と異なります。図 4 と図 5 をご覧ください。
    // https://developer.android.com/develop/sensors-and-location/location/permissions?hl=ja#upgrade-to-precise
    (await PermissionsAndroid.requestMultiple([
      PermissionsAndroid.PERMISSIONS.ACCESS_COARSE_LOCATION,
      PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION
    ]));
  return (
    granted &&
    granted[PermissionsAndroid.PERMISSIONS.ACCESS_COARSE_LOCATION] &&
    granted[PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION] === 'granted'
  );
};

export const registerChannel = async (): Promise<void> => {
  await Notifee.createChannel({
    id: 'foreground-service',
    name: '出席管理確認',
    lights: false,
    vibration: true,
    importance: AndroidImportance.MIN,
    visibility: AndroidVisibility.PUBLIC
  });
};

export const registerForegroundServiceBeaconRanging = () => {
  // Called from App.tsx
  console.log('Running foreground service registration');

  Notifee.registerForegroundService(() => {
    return new Promise((resolve) => {
      let isCurrentlyBeaconRanging = false;

      const stopService = async (id?: string): Promise<void> => {
        console.warn('Stopping service, using notification id: ' + id);
        if (id) {
          await Notifee.cancelNotification(id);
        }
        return resolve();
      };

      const handleActionEvent = async (event: Event): Promise<void> => {
        if (
          event.type === EventType.ACTION_PRESS &&
          event.detail.pressAction?.id === 'stopForegroundService'
        ) {
          await stopService(event.detail.notification?.id);
        }
      };
      Notifee.onBackgroundEvent(handleActionEvent);
      Notifee.onForegroundEvent(handleActionEvent);

      const foregroundServiceBeaconRangingRegion = {
        identifier: 'foregroundServiceBeaconRangingRegion',
        uuid: '93DFF63B-FE13-444F-8C10-4F85B62DA234'
      };
      const fgNotificationSchedules = { from: '07:00', to: '18:10' };
      const rangingSchedules: { from: string; to: string }[] = [
        { from: '09:00', to: '09:25' }, // 1限 09:10開始
        { from: '10:10', to: '10:15' },
        { from: '10:30', to: '10:35' },

        { from: '10:45', to: '11:10' }, // 2限 10:55開始
        { from: '11:55', to: '12:00' },
        { from: '12:15', to: '12:20' },

        { from: '13:05', to: '13:25' }, // 3限 13:15開始
        { from: '14:15', to: '14:20' },
        { from: '14:35', to: '14:40' },

        { from: '14:50', to: '15:15' }, // 4限 15:00開始
        { from: '16:00', to: '16:05' },
        { from: '16:20', to: '16:25' },

        { from: '16:30', to: '16:55' }, // 5限 16:40開始
        { from: '17:40', to: '17:45' },
        { from: '18:00', to: '18:05' }
      ];

      const checkSchedule = () => {
        // 現在時刻が通知スケジュール外の場合はフォアグラウンドサービスを停止
        if (
          !isWithinInterval(new Date(), {
            start: convertTimeStringToDate({
              timeString: fgNotificationSchedules.from
            }),
            end: convertTimeStringToDate({
              timeString: fgNotificationSchedules.to
            })
          })
        ) {
          console.log(
            `Stopping foreground service because end time is reached.`
          );
          return stopService();
        }

        // 現在時刻がレンジングスケジュール外の場合はレンジングを停止
        const currentSchedule = rangingSchedules.find((schedule) => {
          return isWithinInterval(new Date(), {
            start: convertTimeStringToDate({ timeString: schedule.from }),
            end: convertTimeStringToDate({ timeString: schedule.to })
          });
        });
        if (!currentSchedule) {
          if (isCurrentlyBeaconRanging) {
            console.log(
              '[ForegroundService] out of schedules, stop ranging beacons.'
            );
            isCurrentlyBeaconRanging = false;
            Beacons.stopRangingBeaconsInRegion(
              foregroundServiceBeaconRangingRegion
            );
          } else {
            console.log('[ForegroundService] Waiting for next ranging.');
          }
        } else {
          if (!isCurrentlyBeaconRanging) {
            console.log('[ForegroundService] Start ranging beacons.');
            isCurrentlyBeaconRanging = true;
            Beacons.startRangingBeaconsInRegion(
              foregroundServiceBeaconRangingRegion
            );
          } else {
            console.log('[ForegroundService] Continue ranging beacons.');
          }
        }
        setTimeout(checkSchedule, 1000 * 30 * 1);
      };
      checkSchedule();
    });
  });
};

// useBeaconRanging フックの定義
export const useBeaconDetection = (
  rangingUuids: string[],
  monitoringUuids: string[],
  handleBeaconDidRangeEvent: (event: BeaconDidRangeEvent) => void
) => {
  const { isBrowser } = useContext(DeviceInfoContext);

  const [isLocationPermissionsGranted, setIsLocationPermissionsGranted] =
    useState<boolean>(false);

  /**
   * パーミッションをリクエストする
   */
  const requestPermissions = useCallback(async () => {
    if (isBrowser) {
      setIsLocationPermissionsGranted(true);
      return;
    }
    if (Platform.OS === 'ios') {
      const {
        permissionBackgroundLocationGranted,
        permissionForgroundLocationGranted
      } = await requestIosLocationPermissionsForBeaconDetection();
      setIsLocationPermissionsGranted(
        permissionBackgroundLocationGranted &&
          permissionForgroundLocationGranted
      );
    } else if (Platform.OS === 'android') {
      const isAndroidLocationAccessPermissionsGranted =
        await requestAndroidLocationPermissionsForBeaconDetection();
      setIsLocationPermissionsGranted(
        isAndroidLocationAccessPermissionsGranted
      );
    }
  }, []);

  const [isCurrentlyBeaconRanging, setIsCurrentlyBeaconRanging] =
    useState<boolean>(false);

  const canStartBeaconRanging = useMemo(() => {
    return !isBrowser && isLocationPermissionsGranted;
  }, [isBrowser, isLocationPermissionsGranted]);

  const startRangingBeacons = useCallback(
    async (rangingTimeout: number) => {
      if (isBrowser) return;
      if (Platform.OS === 'ios') {
        // for iOS
        Beacons.startUpdatingLocation();
        try {
          rangingUuids.forEach((uuid: string) => {
            Beacons.startRangingBeaconsInRegion({
              identifier: `Region-${uuid}`,
              uuid
            });
          });
          setIsCurrentlyBeaconRanging(true);
        } catch (e) {
          console.log(e);
        }

        // 15分後にレンジングを停止するタイマーを設定
        setTimeout(() => {
          stopRangingBeacons();
        }, rangingTimeout);
      } else if (Platform.OS === 'android') {
        for (const uuid of rangingUuids) {
          const region = {
            identifier: `Region-${uuid}`,
            uuid
          };
          Beacons.detectIBeacons();
          await Beacons.startRangingBeaconsInRegion(region);
        }
        setIsCurrentlyBeaconRanging(true);

        // 15分後にレンジングを停止するタイマーを設定
        setTimeout(() => {
          stopRangingBeacons();
        }, rangingTimeout);
      }
    },
    [canStartBeaconRanging, rangingUuids]
  );

  // Beaconのレンジングを停止する関数
  const stopRangingBeacons = useCallback(async () => {
    // Beaconのレンジング停止処理をここに記述
    rangingUuids.forEach((uuid) => {
      Beacons.stopRangingBeaconsInRegion({
        identifier: `Region-${uuid}`,
        uuid
      });
      console.log('Stop ranging', uuid);
    });
    setIsCurrentlyBeaconRanging(false);
  }, [canStartBeaconRanging, rangingUuids]);

  const startNotifeeForegroundServiceForAndroid = async () => {
    if (isBrowser || Platform.OS !== 'android') return;

    console.log('Starting foreground service');

    await Notifee.displayNotification({
      title: `出席確認を行っています…`,
      body: `最終講義の終了時刻後に自動的に終了します。`,
      android: {
        ongoing: true,
        channelId: 'foreground-service',
        asForegroundService: true,
        actions: [
          {
            title: '終了する',
            pressAction: {
              id: 'stopForegroundService'
            }
          }
        ],
        //color: '#de2332',
        //colorized: true,
        smallIcon: 'notification_icon',
        largeIcon: 'notification_icon',
        importance: AndroidImportance.DEFAULT,
        visibility: AndroidVisibility.PUBLIC
      }
    });
  };

  useEffect(() => {
    const eventEmitter = new NativeEventEmitter(NativeModules.RNiBeacon);
    const listener = eventEmitter.addListener(
      'beaconsDidRange',
      handleBeaconDidRangeEvent
    );
    return () => {
      listener.remove();
    };
  }, []);

  return {
    requestPermissions,
    isLocationPermissionsGranted,
    isCurrentlyBeaconRanging,
    canStartBeaconRanging,
    startNotifeeForegroundServiceForAndroid,
    startRangingBeacons,
    stopRangingBeacons
  };
};
