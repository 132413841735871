import { Header } from '@/components/atomic/header';
import { TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { NavigationMenuItemInfo } from '@/interfaces';
import { createStyles, isMobile, platformSpecificStyle } from '@/utils';

interface Props {
  menuItem: NavigationMenuItemInfo;
  onBackPress?: () => void;
  isContent?: boolean;
}

const { getStyle } = createStyles(() => ({
  subNaviHeaderTitle: {
    ...platformSpecificStyle({
      native: {},
      default: {
        fontSize: 17
      }
    })
  }
}));

export const SubNaviHeader: React.FC<Props> = ({
  onBackPress,
  menuItem,
  isContent = false
}) => {
  const design = useDesign();
  const styles = getStyle(design);
  const { pixel } = design;

  const headerTitle = isMobile ? (
    menuItem.item.title
  ) : (
    <TextM style={{ ...styles.subNaviHeaderTitle }}>
      {menuItem.path[menuItem.path.length - 2]}
    </TextM>
  );

  return (
    <Header
      isLeftAligned={!isMobile}
      title={headerTitle}
      onBackPress={onBackPress}
      marginBottom={!isMobile ? pixel(20) : isContent ? pixel(30) : undefined}
    />
  );
};
