import { useEffect, useState } from 'react';

//import { BleManager, State as BluetoothState } from 'react-native-ble-plx';

// import NfcManager from 'react-native-nfc-manager';

//const bleManager = new BleManager();

export const useHardwareAvailability = () => {
  const [isBluetoothAvailable] = useState<boolean>(true);
  const [isNfcAvailable] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      //const state = await bleManager.state();
    })();
  }, []);

  return {
    isBluetoothAvailable,
    isNfcAvailable
  };
};
