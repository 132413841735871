import * as React from 'react';
import { Path } from 'react-native-svg';

import { SvgProps, SvgWrapper, usePrimaryIconColor } from '@/wrappers/svg';

interface Props extends SvgProps {
  borderColor?: string;
}

export const OpenInNewIcon: React.FC<Props> = ({
  color,
  borderColor,
  ...props
}) => {
  const primaryIconColor = usePrimaryIconColor();

  return (
    <SvgWrapper viewBox={{ width: 30, height: 22 }} {...props}>
      <Path
        fillRule="evenodd"
        stroke={borderColor || primaryIconColor}
        d="M1,5 L23,5 L23,20 L1,20 L1,5Z M2,6 L22,6 L22,19 L2,19 L2,6 Z"
      />
      <Path
        fillRule="evenodd"
        fill={color || primaryIconColor}
        d="M6.000,0.000 L29.1000,0.000 L29.1000,15.1000 L6.000,15.1000 L6.000,0.000 Z"
      />
    </SvgWrapper>
  );
};
