import { getItemAsync, setItemAsync } from 'expo-secure-store';

import { isWeb } from './platform-specific-style.util';

export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';

export const setStorage = async (key: string, value: any) => {
  if (isWeb) {
    localStorage.setItem(key, value);
  } else {
    await setItemAsync(key, value);
  }
};

export const getStorage = async (key: string) => {
  const item = isWeb ? localStorage.getItem(key) : await getItemAsync(key);
  return item ?? '';
};
