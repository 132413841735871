import {
  createAsyncThunk,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';

import { fetchNaviContent, fetchNaviMenu } from '@/api/calls/navi.api';
import {
  NavigationMenuItem,
  NavigationMenuItemInfo,
  NavigationPageContent
} from '@/interfaces/Navi.interface';
import { RootState } from '@/store';

interface NaviState {
  menu: NavigationMenuItem[];
  contents: NavigationPageContent[];
  contentsLastUpdated: Date | null;
}

const initialState: NaviState = {
  menu: [],
  contents: [],
  contentsLastUpdated: null
};

export const getNaviMenu = createAsyncThunk('navi/getNaviMenu', () => {
  return fetchNaviMenu();
});

export const getNaviContent = createAsyncThunk('', async (id: number) => {
  const response = await fetchNaviContent(id);

  return {
    pageId: id,
    ...response
  };
});

export const naviSlice = createSlice({
  name: 'navi',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getNaviMenu.fulfilled, (state, { payload }) => {
      state.menu = payload;
    });

    builder.addCase(getNaviContent.fulfilled, (state, { payload }) => {
      state.contents = [payload];
    });
  }
});

const selectPageContents = (state: RootState) => state.navi.contents;
const selectPageContentsId = (_state: RootState, id?: number) => id;

export const selectNavigationMenuRootItems = (state: RootState) =>
  state.navi.menu;

export const selectActivePageContent = createSelector(
  [selectPageContents, selectPageContentsId],
  (contents, id) => {
    if (id !== undefined) {
      return contents.find((content) => content.pageId === id);
    }
  }
);

const treeTraversal = (
  subtree: NavigationMenuItem[],
  id: number
): NavigationMenuItemInfo | undefined => {
  for (const treeNode of subtree) {
    if (treeNode.id === id) {
      return {
        item: treeNode,
        path: [treeNode.title]
      };
    } else if (treeNode.children?.length) {
      const searchResult = treeTraversal(treeNode.children, id);
      if (searchResult) {
        return {
          ...searchResult,
          path: [treeNode.title, ...searchResult.path]
        };
      }
    }
  }

  return undefined;
};

export const selectNode = createSelector(
  (
    _state: RootState,
    navigationMenuRootItems: NavigationMenuItem[],
    rootId?: number,
    subId?: number
  ) => ({
    navigationMenuRootItems,
    rootId,
    subId
  }),
  ({
    navigationMenuRootItems,
    rootId,
    subId
  }): NavigationMenuItemInfo | undefined => {
    if (rootId === undefined) {
      return;
    }

    const rootItem = navigationMenuRootItems.find(
      (rootItem) => rootItem.id === rootId
    );

    if (!rootItem) {
      return;
    }

    if (rootItem?.children?.length && subId !== undefined) {
      const node = treeTraversal(rootItem?.children, subId);
      if (!node) {
        return;
      }

      return {
        ...node,
        path: [rootItem.title, ...node.path]
      };
    }

    return {
      item: rootItem,
      path: [rootItem.title]
    };
  }
);

export const naviReducer = naviSlice.reducer;
export const naviAction = naviSlice.actions;
