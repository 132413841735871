import React from 'react';
import Svg, { ClipPath, Defs, G, Path, SvgProps } from 'react-native-svg';

export const IconExclamationError: React.FC<SvgProps> = ({
  color,
  ...props
}) => {
  return (
    <Svg width={66} height={66} viewBox="0 0 66 66" {...props}>
      <Defs>
        <ClipPath id="a">
          <Path
            data-name="\u9577\u65B9\u5F62 392"
            fill="none"
            d="M0 0H66V66H0z"
          />
        </ClipPath>
      </Defs>
      <G clipPath="url(#a)" fill={color || '#c7c014'}>
        <Path
          data-name="\u30D1\u30B9 288"
          d="M4.061 60.621a2.99 2.99 0 01-2.594-4.483L30.222 6.446a2.992 2.992 0 015.177 0l29.668 51.263a1.833 1.833 0 01-3.173 1.836L32.809 9.294 5.231 56.955h46.387a1.841 1.841 0 010 3.667H4.061"
          transform="translate(.237 1.101)"
        />
        <Path
          data-name="\u30D1\u30B9 289"
          d="M27.444 40.556A2.444 2.444 0 0125 38.111V23.444a2.444 2.444 0 014.889 0v14.667a2.444 2.444 0 01-2.444 2.444"
          transform="translate(5.556 4.667)"
        />
        <Path
          data-name="\u30D1\u30B9 290"
          d="M30.611 42.056A3.056 3.056 0 1127.556 39a3.055 3.055 0 013.056 3.056"
          transform="translate(5.444 8.667)"
        />
      </G>
    </Svg>
  );
};
