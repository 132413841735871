import { PropsWithChildren, useEffect, useState } from 'react';
import { Modal, View } from 'react-native';

import { Button } from '@/components/atomic/button';
import { TextL, TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { useAppDispatch, useAppSelector } from '@/store';
import { errorAction } from '@/store/reducers/error/error.slice';
import { createStyles } from '@/utils';

const { getStyle } = createStyles(({ pixel }) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modal: {
    padding: 32,
    paddingBottom: 40,
    borderRadius: 16,
    backgroundColor: 'white',
    width: pixel(600),
    position: 'relative'
  },
  title: {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 16
  },
  message: {
    color: 'black',
    fontWeight: 'bold',
    marginBottom: 24
  }
}));

export const ModalsWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { backendErrors } = useAppSelector((state) => state.error);
  const [isVisible, setIsVisible] = useState(false);

  const design = useDesign();
  const styles = getStyle(design);

  useEffect(() => {
    if (backendErrors.length > 0) {
      setIsVisible(true);
    }
  }, [backendErrors]);

  return (
    <>
      {backendErrors.length > 0 && (
        <Modal visible={isVisible} animationType="fade" transparent>
          <View style={styles.container}>
            <View style={styles.modal}>
              <TextL style={styles.title}>{backendErrors[0].label}</TextL>
              <TextM style={styles.message}>{backendErrors[0].message}</TextM>
              <Button
                fullWidth
                outline
                label="閉じる"
                onPress={() => {
                  setIsVisible(false);
                  setTimeout(() => {
                    dispatch(errorAction.pop());
                  }, 500);
                }}
              />
            </View>
          </View>
        </Modal>
      )}
      {children}
    </>
  );
};
