import { TouchableOpacity, View } from 'react-native';

import { Separator } from '../separator';
import { TextM, TextXL } from '../text';

import { useDesign } from '@/hooks/useDesign';
import { ChevronIcon } from '@/svgs';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  title: string | JSX.Element;
  onBackPress?: () => void;
  isLeftAligned?: boolean;
  marginBottom?: number;
}

const { getStyle } = createStyles(({ pixel }) => ({
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {
        alignSelf: 'center',
        marginBottom: pixel(120)
      },
      default: {
        marginBottom: pixel(60)
      }
    })
  },
  headerLeftAligned: {
    justifyContent: 'flex-start'
  },
  navigationContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    ...platformSpecificStyle({
      native: {
        marginRight: pixel(20)
      },
      default: {
        marginRight: pixel(10)
      }
    })
  },
  navigationContainerRelative: {
    position: 'relative'
  },
  chevron: {
    ...platformSpecificStyle({
      native: {
        width: pixel(30),
        height: pixel(40)
      },
      default: {
        width: pixel(13),
        height: pixel(20)
      }
    })
  },
  navigationSeparator: {
    ...platformSpecificStyle({
      native: {
        width: pixel(23)
      },
      default: { width: pixel(10) }
    })
  },
  title: {
    fontWeight: 'bold',
    ...platformSpecificStyle({
      native: {
        maxWidth: '66%'
      },
      default: {}
    })
  }
}));

export const Header: React.FC<Props> = ({
  title,
  onBackPress,
  isLeftAligned,
  marginBottom
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <View
      style={{
        ...styles.header,
        ...(isLeftAligned && styles.headerLeftAligned),
        marginBottom: marginBottom ?? styles.header.marginBottom
      }}
    >
      {onBackPress && (
        <TouchableOpacity
          style={{
            ...styles.navigationContainer,
            ...(isLeftAligned && styles.navigationContainerRelative)
          }}
          onPress={() => {
            onBackPress();
          }}
        >
          <ChevronIcon size={{ ...styles.chevron }} />
          <Separator size={styles.navigationSeparator.width} vertical />
          {typeof title === 'object' ? title : <TextM>戻る</TextM>}
        </TouchableOpacity>
      )}
      {typeof title === 'string' && (
        <TextXL numberOfLines={1} style={{ ...styles.title }}>
          {title}
        </TextXL>
      )}
    </View>
  );
};
