import React, { useRef } from 'react';
import { Platform, RefreshControl, SectionList, View } from 'react-native';

import { MessageElement } from './MessageElement.component';

import { Loader } from '@/components/atomic/loader';
import { Separator } from '@/components/atomic/separator';
import { TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import {
  Notification,
  NotificationSection,
  NotificationTypes
} from '@/interfaces';
import { createStyles } from '@/utils';

interface Props {
  sections: {
    header: JSX.Element;
    data: Notification[];
    isRead?: boolean;
    isLast?: boolean;
  }[];
  onEndReached?: () => void;
  isLoading?: boolean;
  refresh: () => void;
  refreshing?: boolean;
  onPullToRefresh?: () => void;
  setNotificationRead: (id: string, type: NotificationTypes) => Promise<void>;
}

const { getStyle } = createStyles(() => ({
  list: {
    flex: 1
  },
  empty: {
    alignItems: 'center'
  },
  listFooter: {
    flex: 1
  },
  loaderBg: {
    width: 42,
    height: 42,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 21
  },
  footerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export const MessageList: React.FC<Props> = ({
  sections,
  onEndReached,
  isLoading,
  refresh,
  refreshing,
  onPullToRefresh,
  setNotificationRead
}) => {
  const design = useDesign();
  const sectionListRef = useRef<SectionList>(null);
  const styles = getStyle(design);
  const { pixel } = design;

  const listEmpty = (
    <View style={{ ...styles.empty }}>
      <TextM>表示できるデータがありません。</TextM>
      <TextM>他の絞り込み項目を選択してください。</TextM>
    </View>
  );
  const loader =
    Platform.OS === 'ios' ? (
      <Loader color="#444" size="large" />
    ) : (
      <View style={{ ...styles.loaderBg }}>
        <Loader color="#444" size={25} />
      </View>
    );

  const renderFooter = (section: NotificationSection) => {
    return (
      <>
        <View
          style={{
            ...styles.footerContainer,
            ...(!section.data.length && {
              marginVertical: '25%'
            }),
            ...(section.data.length && {
              marginBottom: pixel(100)
            })
          }}
        >
          {isLoading && !refreshing && loader}
          {!(isLoading || refreshing) && !section.data.length && listEmpty}
        </View>
      </>
    );
  };
  const scrollToListItemIndex: (index: number) => void = (index) => {
    sectionListRef?.current?.scrollToLocation({
      itemIndex: index + 1,
      sectionIndex: 0
    });
  };

  return (
    <SectionList
      ref={sectionListRef}
      style={{ ...styles.list }}
      sections={sections}
      refreshControl={
        refreshing !== undefined && onPullToRefresh ? (
          <RefreshControl refreshing={refreshing} onRefresh={onPullToRefresh} />
        ) : undefined
      }
      stickySectionHeadersEnabled={false}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      keyExtractor={(item) => `${item.type}-${item.id}`}
      renderItem={({ item, index }) => (
        <MessageElement
          message={item}
          isRead={item.isRead}
          refresh={refresh}
          dispatchScrollToSection={() => {
            scrollToListItemIndex(index);
          }}
          setNotificationRead={setNotificationRead}
        />
      )}
      ItemSeparatorComponent={() => <Separator size={pixel(30)} />}
      renderSectionFooter={({ section }) =>
        renderFooter(section as NotificationSection)
      }
      ListFooterComponentStyle={{
        ...styles.listFooter
      }}
      onEndReachedThreshold={0.1}
      onEndReached={() => {
        if (onEndReached) {
          onEndReached();
        }
      }}
    />
  );
};
