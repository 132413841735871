import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addDays, format } from 'date-fns';
import { ImageURISource } from 'react-native';

import { fetchAccountProfileImage } from '@/api/calls/account.api';
import {
  fetchClassAttendance,
  fetchTeacherCalendarClassList
} from '@/api/calls/teacher.api';
import {
  AttendanceStatus,
  ClassAttendanceIndex,
  TeacherCalendarClassesAndPeriod,
  TeacherCalendarIndex
} from '@/interfaces/Teacher.interface';
import { AppDispatch, RootState } from '@/store/store';
import { convertTimeStringToDate, getStartOfWeek } from '@/utils';

export interface TeacherSchedule {
  date: Date;
  classes: TeacherCalendarClassesAndPeriod[];
}

export const selectTeacherSchedule = (
  { teacherData }: RootState,
  selectedDate: Date
): TeacherSchedule[] => {
  const firstDayOfWeek = getStartOfWeek(selectedDate);
  const list = Array.from({ length: 7 }, (_, index) => {
    // return format(addDays(firstDayOfWeek, index), 'yyyy/MM/dd');
    const date = addDays(firstDayOfWeek, index);
    const classes = [
      ...(teacherData.classList[format(date, 'yyyyMMdd')]?.map((item) => ({
        ...item,
        period: {
          from: convertTimeStringToDate({
            timeString: item.period.from,
            selectedDate
          }),
          to: convertTimeStringToDate({
            timeString: item.period.to,
            selectedDate
          })
        }
      })) ?? [])
    ].sort((a, b) => Number(a.period.from) - Number(b.period.from));

    return { date, classes };
  });

  return list;
};

export const getTeacherCalendar = createAsyncThunk<
  TeacherCalendarIndex | undefined,
  { date: Date },
  { state: RootState }
>('teacher/getClassList', async ({ date }, { dispatch }) => {
  dispatch(teacherDataAction.setIsLoading({ isLoading: true }));

  const firstDayOfWeek = getStartOfWeek(date);
  const from = format(firstDayOfWeek, 'yyyy/MM/dd');
  const to = format(addDays(firstDayOfWeek, 6), 'yyyy/MM/dd');

  return await fetchTeacherCalendarClassList({ from, to });
});

export const getClassAttendance = createAsyncThunk<
  AttendanceStatus[],
  {
    date: string;
    classId: string;
    period: number;
  },
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: { error: unknown };
  }
>('teacher/getClassAttendance', async (params, thunk) => {
  try {
    const results = await fetchClassAttendance(params);

    // // プロファイル画像を取得する
    // const readIds = results
    //   .map((item) => item.profile?.gakusekiNo)
    //   .filter((id): id is string => Boolean(id));

    // const uniqueIds = Array.from(new Set(readIds));
    // const state = thunk.getState();

    // uniqueIds.forEach((id) => {
    //   if (!state.qrCode.profileImages[id]) {
    //     thunk.dispatch(fetchAndStoreStudentProfileImage(id));
    //   }
    // });

    return results;
  } catch (error) {
    return thunk.rejectWithValue({ error });
  }
});

export const fetchAndStoreStudentProfileImage = createAsyncThunk<
  { federationId: string; image: ImageURISource | undefined }[],
  string[],
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: { error: unknown };
  }
>('teacher/fetchAndStoreStudentProfileImage', async (federationIds, thunk) => {
  try {
    const state = thunk.getState();
    const { profileImages } = state.teacherData;
    const promises: Promise<string | undefined>[] = [];

    const fetchIds = federationIds.filter((id) => !profileImages[id]);

    fetchIds.forEach((id) => {
      promises.push(fetchAccountProfileImage(id));
    });

    const results = await Promise.allSettled(promises);

    const images = results
      .filter(
        (res): res is PromiseFulfilledResult<string | undefined> =>
          res.status === 'fulfilled'
      )
      .map((res) => res.value);

    return images.map((image, index) => {
      return {
        federationId: fetchIds[index],
        image: image ? { uri: image } : undefined
      };
    });
  } catch (error) {
    return thunk.rejectWithValue({ error });
  }
});

interface State {
  classList: TeacherCalendarIndex;
  classAttendance: ClassAttendanceIndex;
  isLoading: boolean;
  profileImages: Record<string, ImageURISource | undefined>;
}

const initialState: State = {
  classList: {},
  classAttendance: {},
  isLoading: false,
  profileImages: {}
};

const teacherDataSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setIsLoading: (
      state,
      { payload }: PayloadAction<{ isLoading: boolean }>
    ) => {
      state.isLoading = payload.isLoading;
    }
  },
  extraReducers(builder) {
    builder.addCase(getTeacherCalendar.fulfilled, (state, { payload }) => {
      if (payload) {
        Object.entries(payload).forEach(([key, list]) => {
          state.classList[key] = list;
        });
      }
      state.isLoading = false;
    });
    builder.addCase(
      fetchAndStoreStudentProfileImage.fulfilled,
      (state, action) => {
        const { payload } = action;
        payload.forEach((item) => {
          if (item.image) {
            state.profileImages[item.federationId] = item.image;
          }
        });
      }
    );
    builder.addCase(getTeacherCalendar.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const teacherDataAction = teacherDataSlice.actions;
export const teacherDataReducer = teacherDataSlice.reducer;
