import { useState } from 'react';
import { Pressable, View } from 'react-native';

import { TimetableModal } from './TimetableModal.component';

import { TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { TimetableClass } from '@/interfaces';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  event?: TimetableClass;
}

const { getStyle } = createStyles(({ pixel, colors, windowDimensions }) => ({
  container: {
    backgroundColor: colors.icon.primary,
    borderRadius: pixel(20),
    margin: pixel(2),
    ...platformSpecificStyle({
      native: {
        padding: pixel(10),
        width: pixel(124),
        height: pixel(200)
      },
      tablet: {
        padding: pixel(10),
        width: windowDimensions.width >= 1024 ? pixel(120) : pixel(95),
        height: pixel(80)
      },
      default: {
        padding: pixel(10),
        width: pixel(165),
        height: pixel(90)
      }
    })
  },
  containerEmpty: {
    backgroundColor: colors.cardBackground.primary,
    borderRadius: 0
  },
  text: {
    color: colors.textPrimaryInverted
  }
}));

export const TimetableCell: React.FC<Props> = ({ event }) => {
  const design = useDesign();
  const styles = getStyle(design);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <Pressable
      disabled={!event}
      onPress={() => {
        setIsModalOpen(true);
        setIsModalVisible(true);
      }}
    >
      <View
        style={{ ...styles.container, ...(!event && styles.containerEmpty) }}
      >
        {Boolean(event) && isModalOpen && (
          <TimetableModal
            onClose={() => {
              setIsModalVisible(false);
              setTimeout(() => {
                setIsModalOpen(false);
              }, 300);
            }}
            event={event!}
            visible={isModalVisible}
          />
        )}

        <TextM numberOfLines={3} style={{ ...styles.text }}>
          {event?.name}
        </TextM>
      </View>
    </Pressable>
  );
};
