import { NavigationMenuItemType, RowType } from '@/interfaces';

export const NAVIGATION_MENU_ITEM_TYPE_MAPPING: Record<
  NavigationMenuItemType,
  RowType
> = {
  [NavigationMenuItemType.CATEGORY]: 'navigate',
  [NavigationMenuItemType.PAGE]: 'custom',
  [NavigationMenuItemType.CUSTOM]: 'open'
};
