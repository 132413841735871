import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { BadgeContainer } from '../BadgeContainer.component';
import { MessageIconContainer } from './MessageIconContainer.component';

import { Separator } from '@/components/atomic/separator';
import { TextS } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { createStyles } from '@/utils';

const { getStyle } = createStyles(({ pixel, colors }) => ({
  menuContainer: {
    width: '100%',
    height: pixel(180),
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingHorizontal: pixel(10),
    paddingBottom: pixel(30)
  },
  buttonContainer: {
    paddingTop: pixel(30),
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden'
  },
  defaultIconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: pixel(80),
    height: pixel(80)
  },
  messageButtonContainer: {
    overflow: 'visible'
  },
  label: {
    color: colors.textPrimaryInverted
  },
  activeIndicator: {
    position: 'absolute',
    alignSelf: 'center',
    top: pixel(11),
    width: pixel(15),
    height: pixel(15),
    borderRadius: pixel(15),
    backgroundColor: colors.primary
  }
}));

export const MenuMobile: React.FC<BottomTabBarProps> = ({
  state,
  navigation,
  descriptors
}) => {
  const design = useDesign();
  const styles = getStyle(design);
  const { pixel, colors } = design;
  const { bottom } = useSafeAreaInsets();
  return (
    <View style={{ ...styles.menuContainer, marginBottom: bottom }}>
      {state.routes.map((route, index) => {
        const { options } = descriptors[route.key];
        const label =
          options.tabBarLabel !== undefined
            ? options.tabBarLabel
            : options.title !== undefined
            ? options.title
            : route.name;

        const isFocused = state.index === index;
        const isMessageTab = index === 2;

        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true
          });

          if (!isFocused && !event.defaultPrevented) {
            // The `merge: true` option makes sure that the params inside the tab screen are preserved
            navigation.navigate({ name: route.name, merge: true, params: {} });
          }
        };

        const tabBarIconContent = options?.tabBarIcon && (
          <>
            {options.tabBarIcon({
              focused: isFocused,
              color: isMessageTab
                ? colors.navigation.iconColorActive
                : colors.navigation.iconColor,
              size: pixel(45)
            })}
          </>
        );

        const tabBarIcon = options?.tabBarIcon && (
          <>
            {isMessageTab ? (
              <MessageIconContainer>{tabBarIconContent}</MessageIconContainer>
            ) : (
              <View style={{ ...styles.defaultIconContainer }}>
                {tabBarIconContent}
              </View>
            )}
            {options.tabBarBadge && (
              <BadgeContainer badge={options.tabBarBadge} />
            )}
          </>
        );

        return (
          <TouchableOpacity
            style={{ width: `${100 / state.routes.length}%` }}
            key={label as string}
            accessibilityRole="button"
            accessibilityState={isFocused ? { selected: true } : {}}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            testID={options.tabBarTestID}
            onPress={onPress}
          >
            <View
              style={{
                ...styles.buttonContainer,
                ...(isMessageTab && styles.messageButtonContainer)
              }}
            >
              {tabBarIcon}
              <Separator size={pixel(2)} />
              <TextS
                style={{
                  ...styles.label
                }}
              >
                {label as string}
              </TextS>
              {isFocused && <View style={{ ...styles.activeIndicator }} />}
            </View>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};
