import { Image } from 'react-native';

import { api } from '..';

import {
  NavigationMenuItem,
  NavigationMenuRootItemResponse,
  NavigationPageContentResponse
} from '@/interfaces/Navi.interface';

export const fetchNaviMenu = async () => {
  const { data } = await api.get<NavigationMenuRootItemResponse[]>(
    '/navi/menu'
  );

  return Promise.all(data.map(mapNavigationMenuRootItemFromApi));
};

export const fetchNaviContent = async (id: number) => {
  const { data } = await api.get<NavigationPageContentResponse>(
    `/navi/contents/${id}`
  );

  return data;
};

const mapNavigationMenuRootItemFromApi = async (
  navigationMenuRootItemResponse: NavigationMenuRootItemResponse
): Promise<NavigationMenuItem> => {
  const baseMapping = {
    id: navigationMenuRootItemResponse.id,
    title: navigationMenuRootItemResponse.title,
    children: navigationMenuRootItemResponse.children
  };

  if (navigationMenuRootItemResponse.imageUrl) {
    try {
      await Image.prefetch(navigationMenuRootItemResponse.imageUrl);
      return {
        ...baseMapping,
        imageUrl: navigationMenuRootItemResponse.imageUrl
      };
    } catch (e) {
      // We do not want the mapping to fail if the url is incorrect
    }
  }

  return { ...baseMapping };
};
