import React from 'react';
import { Modal, Platform, View } from 'react-native';

import { Button } from '@/components/atomic/button';
import { TextL, TextS } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { createStyles } from '@/utils';

interface Props {
  isOpen: boolean;
  isRequestingLocationPermissions: boolean;
  onRequestPermissions?: () => void;
  onRejectLocationPermissionSetting?: () => void;
}

const { getStyle } = createStyles(({ pixel }) => ({
  // Dialog
  dialogOverlay1stLayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    backgroundColor: '#E57373'
  },
  locationPermissionRequestDialogsContainer: {
    width: '90%',
    height: '68%',
    paddingHorizontal: pixel(80),
    paddingBottom: pixel(100),
    paddingTop: pixel(90),
    borderRadius: pixel(20),
    opacity: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    backgroundColor: '#fff',
    zIndex: 11
  },
  locationPermissionRequestDialogsWrap: {
    width: '100%'
  },
  locationPermissionRequestDialogHeader: {
    width: '100%',
    height: pixel(80),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  locationPermissionRequestDialogTitle: {
    color: '#000',
    fontWeight: 'bold',
    fontSize: pixel(38)
  },
  locationPermissionRequestDialogTextsWrap: {
    marginTop: pixel(70),
    fontSize: pixel(30)
  },
  locationPermissionRequestDialogText: {
    color: '#000',
    lineHeight: pixel(42)
  },
  buttonsContainer: {
    width: '100%',
    gap: pixel(20)
  }
}));

export const LocationPermissionsRequestDialog: React.FC<Props> = ({
  isOpen,
  isRequestingLocationPermissions,
  onRequestPermissions,
  onRejectLocationPermissionSetting
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  const onPressRequestPermissions = () => {
    console.log('onPressRequestPermissions');
    onRequestPermissions && onRequestPermissions();
  };

  const onPressRejectLocationPermissionSetting = () => {
    console.log('onPressRejectLocationPermissionSetting');
    onRejectLocationPermissionSetting && onRejectLocationPermissionSetting();
  };

  return (
    <Modal visible={isOpen}>
      <View style={{ ...styles.dialogOverlay1stLayer }}>
        <View style={{ ...styles.locationPermissionRequestDialogsContainer }}>
          <View style={{ ...styles.locationPermissionRequestDialogsWrap }}>
            <View
              style={{
                ...styles.locationPermissionRequestDialogHeader
              }}
            >
              <TextL
                selectable
                style={{ ...styles.locationPermissionRequestDialogTitle }}
              >
                位置情報をONにしてください
              </TextL>
            </View>
            <View
              style={{ ...styles.locationPermissionRequestDialogTextsWrap }}
            >
              {Platform.OS === 'android' && (
                <TextS
                  selectable
                  style={{ ...styles.locationPermissionRequestDialogText }}
                >
                  正確な位置情報が許可されていないため出席確認機能が利用できません。
                  {'\n'}
                  下記のボタンから「権限」→「位置情報」を開き、「アプリの使用中のみ許可」をONにした後、「正確な位置情報を使用」を許可してください。
                  {'\n'}
                  位置情報の確認は講義時間中にのみ行われます。
                </TextS>
              )}
              {Platform.OS === 'ios' && (
                <TextS
                  selectable
                  style={{ ...styles.locationPermissionRequestDialogText }}
                >
                  位置情報が許可されていないため、出席確認機能が利用できません。
                  {'\n'}
                  下記のボタンをタップして、位置情報を「常に許可」に設定してください。
                  {'\n'}
                  位置情報の確認は講義時間中にのみ行われます。
                </TextS>
              )}
            </View>
          </View>
          <View style={{ ...styles.buttonsContainer }}>
            <Button
              modal
              fullWidth
              disabled={isRequestingLocationPermissions}
              labelStyle={{ fontWeight: 'normal' }}
              label="位置情報の設定を変更する"
              onPress={onPressRequestPermissions}
            />
            <Button
              modal
              fullWidth
              disabled={isRequestingLocationPermissions}
              labelStyle={{ fontWeight: 'normal' }}
              label="位置情報の設定をそのままにする"
              onPress={onPressRejectLocationPermissionSetting}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};
