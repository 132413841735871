import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createURL } from 'expo-linking';
import { Platform } from 'expo-modules-core';
import { openAuthSessionAsync } from 'expo-web-browser';

import { updateDeviceToken } from '@/api/calls/account.api';
import { logout } from '@/api/calls/auth.api';
import { AuthTokens, LogoutResponse } from '@/interfaces/Auth.interface';
import { RootState } from '@/store';
import { isWeb } from '@/utils';
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  getStorage,
  setStorage
} from '@/utils/storage.util';
import { DeviceOS } from '@/wrappers/device-info';

interface AuthState {
  tokens: Partial<AuthTokens>;
}

const initialState: AuthState = {
  tokens: {}
};

export const setTokens = createAsyncThunk(
  'auth/setTokens',
  async ({ accessToken, refreshToken }: Partial<AuthTokens>) => {
    await Promise.all([
      setStorage(ACCESS_TOKEN, accessToken ?? ''),
      setStorage(REFRESH_TOKEN, refreshToken ?? '')
    ]);

    return { accessToken, refreshToken };
  }
);

export const getTokensFromStorage = createAsyncThunk(
  'auth/getTokensFromStorage',
  async () => {
    const [accessToken, refreshToken] = await Promise.all([
      getStorage(ACCESS_TOKEN),
      getStorage(REFRESH_TOKEN)
    ]);

    return { accessToken, refreshToken };
  }
);

export const logoutThunk = createAsyncThunk(
  'auth/logout',
  async (
    {
      deviceOs,
      fcmDeviceToken
    }: { deviceOs: DeviceOS; fcmDeviceToken: string },
    { dispatch }
  ) => {
    try {
      const promises = isWeb
        ? [logout()]
        : [
            updateDeviceToken({ deviceInfo: null, deviceOs, fcmDeviceToken }),
            logout()
          ];

      const responses = await Promise.all(promises);
      const logoutResponse = responses[responses.length - 1] as LogoutResponse;

      if (logoutResponse.logoutUrl) {
        if (isWeb) {
          window.location.href = logoutResponse.logoutUrl;
        } else {
          const callbackUri = createURL('login');
          const url = logoutResponse.logoutUrl.replace(
            /post_logout_redirect_uri=[^&]*/,
            `post_logout_redirect_uri=${callbackUri}`
          );
          const result = openAuthSessionAsync(url, callbackUri);

          if (Platform.OS !== 'android') {
            await result;
          }
        }
      }
      dispatch(setTokens({}));
    } catch (error) {
      console.log('error');
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        getTokensFromStorage.fulfilled,
        (state, { payload }: { payload: AuthTokens }) => {
          if (payload) {
            state.tokens = payload;
          }
        }
      )
      .addCase(
        setTokens.fulfilled,
        (state, { payload }: { payload: Partial<AuthTokens> | undefined }) => {
          if (payload) {
            state.tokens = payload;
          }
        }
      );
  }
});

export const hasToken = ({ auth: { tokens } }: RootState) => {
  return tokens.accessToken && tokens.refreshToken;
};

export const authReducer = authSlice.reducer;
export const authAction = authSlice.actions;
