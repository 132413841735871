export enum NavigationMenuItemType {
  CATEGORY = 'category',
  PAGE = 'page',
  CUSTOM = 'custom'
}

export interface NavigationMenuItemInfo {
  item: NavigationMenuItem;
  path: string[];
}

export interface NavigationMenuItem {
  id: number;
  title: string;
  object?: NavigationMenuItemType;
  url?: string;
  imageUrl?: string;
  parentId?: number;
  children?: NavigationMenuItem[];
}

export interface NavigationMenuRootItemResponse {
  id: number;
  imageUrl: string;
  children: NavigationMenuItem[];
  title: string;
}

export interface NavigationPageContentResponse {
  content: {
    rendered: string;
  };
}

export interface NavigationPageContent extends NavigationPageContentResponse {
  pageId: number;
}
