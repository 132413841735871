import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { SvgProps } from 'react-native-svg';

import { TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  text: string;
  disabled?: boolean;
  icon?: React.FC<SvgProps>;
  onPress: () => void;
}

const { getStyle } = createStyles(({ pixel }) => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginHorizontal: pixel(10),
    ...platformSpecificStyle({
      native: {
        marginBottom: pixel(12)
      },
      default: {
        // height: 26,
        marginBottom: pixel(6)
      }
    })
  },
  textWrapper: {
    gap: pixel(10),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  buttonText: {
    color: '#fff'
  },
  buttonTextDisabled: {
    opacity: 0.5
  },
  buttonIcon: {
    height: pixel(33),
    width: pixel(33)
  },
  buttonIconDisabled: {
    opacity: 0.5
  }
}));

export const OptionButton: React.FC<Props> = ({
  text,
  disabled = false,
  icon,
  onPress
}) => {
  const design = useDesign();
  const styles = getStyle(design);
  const Icon = icon;

  return (
    <View
      style={{
        ...styles.container
      }}
    >
      <TouchableOpacity
        style={{ ...styles.textWrapper }}
        onPress={onPress}
        disabled={disabled}
      >
        {Icon && (
          <Icon
            height={styles.buttonIcon.height}
            width={styles.buttonIcon.width}
            style={{
              ...styles.buttonIcon,
              ...(disabled && { ...styles.buttonIconDisabled })
            }}
          />
        )}
        <TextM
          style={{
            ...styles.buttonText,
            ...(disabled && { ...styles.buttonTextDisabled })
          }}
        >
          {text}
        </TextM>
      </TouchableOpacity>
    </View>
  );
};
