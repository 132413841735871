import { useEffect } from 'react';
import { View } from 'react-native';

import { TimetableCell } from './components/TimetableCell.component';

import { PeriodLabel } from '@/components/PeriodLabel.component';
import { Header } from '@/components/atomic/header';
import { Loader } from '@/components/atomic/loader/Loader.atomic';
import { Screen } from '@/components/atomic/screen';
import { Separator } from '@/components/atomic/separator';
import { TextL, TextM } from '@/components/atomic/text';
import { X_AXIS } from '@/constants/Timetable';
import { useDesign } from '@/hooks/useDesign';
import { useLoading } from '@/hooks/useLoading';
import {
  getTimeTableList,
  selectPeriods,
  useAppDispatch,
  useAppSelector
} from '@/store';
import {
  createStyles,
  getSemester,
  isMobile,
  platformSpecificStyle
} from '@/utils';

const { getStyle } = createStyles(({ pixel, colors }) => ({
  container: {
    justifyContent: 'center',
    paddingHorizontal: 0
  },
  tableWrapper: {
    alignItems: 'center'
  },
  tableHeader: {
    borderBottomWidth: pixel(1),
    borderBottomColor: colors.cardBackground.primary,
    ...platformSpecificStyle({
      native: {
        padding: pixel(25)
      },
      default: {
        paddingTop: pixel(10),
        paddingBottom: pixel(10)
      }
    })
  },
  tableBody: {
    flexDirection: 'row',
    alignSelf: 'center'
  },
  column: {
    alignItems: 'center'
  },
  axisCell: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  xAxisCell: {
    ...platformSpecificStyle({
      native: {
        height: pixel(140)
      },
      default: {
        height: pixel(80)
      }
    })
  },
  yAxisCell: {
    flex: 1
  },
  boldText: {
    fontWeight: '600'
  },
  primaryText: {
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(38)
      },
      default: {
        fontSize: 18
      }
    }),
    color: colors.textPrimary
  },
  loader: {
    flex: 1
  },
  mobilePadding: {
    paddingLeft: 0,
    paddingRight: 0
  },
  classNumberLabel: {
    fontWeight: 'bold',
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(30),
        marginBottom: pixel(12)
      },
      default: {
        fontSize: 18,
        marginBottom: 8
      }
    })
  },
  dayLabel: {
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(24),
        marginBottom: pixel(24)
      },
      default: {
        fontSize: 13,
        marginBottom: 8
      }
    })
  },
  localizedDayLabel: {
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(30)
      },
      default: {
        fontSize: 17
      }
    })
  }
}));

export const TimetableScreen = () => {
  const dispatch = useAppDispatch();
  const design = useDesign();
  const styles = getStyle(design);
  const { pixel, colors } = design;

  const semesterLabel = getSemester(new Date()) === 1 ? '前期' : '後期';
  const activeSemester = useAppSelector((state) => state.timetable.semester);
  const periods = useAppSelector((state) => selectPeriods(state));

  const { isLoading, refresh, isPtrLoading, pullToRefresh } = useLoading(() => {
    return [dispatch(getTimeTableList())];
  });

  // fetch resource when component did mount
  useEffect(() => {
    refresh();
  }, []);

  return (
    <Screen
      style={{ ...styles.container, ...(isMobile ? styles.mobilePadding : {}) }}
      isLoading={isLoading}
      refreshing={isPtrLoading}
      onRefresh={() => pullToRefresh()}
    >
      <Header title="時間割" />
      {isLoading ? (
        <Loader
          style={{ ...styles.loader }}
          color={colors.secondary}
          size="large"
        />
      ) : (
        activeSemester && (
          <View
            style={{
              ...styles.tableWrapper
            }}
          >
            <View>
              <View style={{ ...styles.tableHeader }}>
                <TextM style={{ ...styles.primaryText, ...styles.boldText }}>
                  {`${new Date().getFullYear()} ${semesterLabel}`}
                </TextM>
              </View>
              <View style={{ ...styles.tableBody }}>
                <View style={{ ...styles.column }}>
                  <View style={{ ...styles.axisCell, ...styles.xAxisCell }} />
                  {Object.values(periods).map((yTick, index) => (
                    <View
                      key={yTick.from.toString()}
                      style={{ ...styles.axisCell, ...styles.yAxisCell }}
                    >
                      <TextL style={{ ...styles.classNumberLabel }}>
                        {index + 1}
                      </TextL>
                      <PeriodLabel period={yTick} />
                    </View>
                  ))}
                </View>
                <Separator size={pixel(20)} vertical />
                {X_AXIS.map((xTick, xIndex) => {
                  const currentDay = activeSemester[xIndex + 1];
                  return (
                    <View key={xTick.day} style={{ ...styles.column }}>
                      <View style={{ ...styles.axisCell, ...styles.xAxisCell }}>
                        <TextM style={{ ...styles.dayLabel }}>
                          {xTick.day}
                        </TextM>
                        <TextM
                          style={{
                            ...styles.boldText,
                            ...styles.localizedDayLabel
                          }}
                        >
                          {xTick.localizedDay}
                        </TextM>
                      </View>
                      {Object.values(periods).map((yTick, yIndex) => {
                        const cellData = currentDay?.find(
                          (event) => Number(event.jigen) === yIndex + 1
                        );
                        return (
                          <TimetableCell
                            key={xTick.day + yTick.from.toString()}
                            event={cellData}
                          />
                        );
                      })}
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        )
      )}
    </Screen>
  );
};
