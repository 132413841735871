import { useFocusEffect, useNavigation } from '@react-navigation/native';
import React, { useCallback, useMemo } from 'react';
import { View } from 'react-native';

import { MessageList } from './components/MessageList.component';
import { NotificationsFilter } from './components/NotificationsFilter.component';
import { SetAllMessagesReadButtonWithDialog } from './components/SetAllMessagesReadButtonWithDialog.component';
import { useNotificationFilter } from './hooks/useNotificationFilter';

import { Header } from '@/components/atomic/header';
import { Screen } from '@/components/atomic/screen';
import { useDesign } from '@/hooks/useDesign';
import { NotificationSection } from '@/interfaces';
import { RootTabParamList, RootTabScreenProps } from '@/navigation/types';
import { createStyles, isMobile, platformSpecificStyle } from '@/utils';

const { getStyle } = createStyles(({ pixel, colors }) => ({
  body: {
    flex: 1,
    ...platformSpecificStyle({
      native: {
        paddingBottom: pixel(0)
      },
      default: {
        flexDirection: 'row',
        justifyContent: 'center',
        paddingBottom: pixel(24)
      }
    })
  },
  listTitle: {
    flex: 1
  },
  separator: {
    backgroundColor: colors.icon.primaryDisabled,
    ...platformSpecificStyle({
      native: {
        height: pixel(2),
        marginVertical: pixel(60)
      },
      default: {
        width: pixel(2),
        marginHorizontal: pixel(30)
      }
    })
  },
  loader: {
    flex: 1
  }
}));

export const NotificationsScreen: React.FC = () => {
  const design = useDesign();
  const { pixel } = design;
  const styles = getStyle(design);

  const {
    filteredNotifications,
    onEndReached,
    isLoading,
    isSetAllReadEnabledFilterTypeSelected,
    canCallSetAllRead,
    refresh,
    isPtrLoading,
    pullToRefresh,
    selectFilterType,
    selectedFilterType,
    isFilterChanging,
    isApiCalling,
    callSetAllMessagesRead,
    setNotificationRead
  } = useNotificationFilter();

  const filterSection = useMemo((): NotificationSection => {
    return {
      header: <></>,
      data: filteredNotifications,
      isLast: true
    };
  }, [isLoading, isPtrLoading, filteredNotifications]);
  const navigation =
    useNavigation<RootTabScreenProps<keyof RootTabParamList>['navigation']>();

  // fetch resource when tab is focused
  useFocusEffect(
    useCallback(() => {
      refresh();
      const unsubscribeTabPress = navigation.addListener('tabPress', () => {
        refresh();
      });
      return () => {
        unsubscribeTabPress();
      };
    }, [navigation])
  );

  return (
    <Screen isLoading={isLoading}>
      <Header
        title="お知らせ一覧"
        marginBottom={isMobile ? pixel(40) : undefined}
      />
      <NotificationsFilter
        selectedFilterType={selectedFilterType}
        selectFilterType={selectFilterType}
      />
      {isSetAllReadEnabledFilterTypeSelected && (
        <SetAllMessagesReadButtonWithDialog
          isLoading={isApiCalling}
          disabled={!canCallSetAllRead}
          callSetAllMessagesRead={callSetAllMessagesRead}
          onCompleted={async () => await refresh()}
        />
      )}
      <View style={{ ...styles.body }}>
        <MessageList
          sections={[filterSection]}
          onEndReached={onEndReached}
          isLoading={isLoading || isFilterChanging}
          refresh={refresh}
          refreshing={isPtrLoading}
          onPullToRefresh={() => pullToRefresh()}
          setNotificationRead={setNotificationRead}
        />
      </View>
    </Screen>
  );
};
