import { format, parse } from 'date-fns';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ScrollView, View } from 'react-native';

import { NotificationSettingsScreen } from './NotificationSettings.screen';

import { Button } from '@/components/atomic/button';
import { Header } from '@/components/atomic/header';
import { List } from '@/components/atomic/list';
import { Row } from '@/components/atomic/list/ListRow.component';
import { Loader } from '@/components/atomic/loader/Loader.atomic';
import { NavigationView } from '@/components/atomic/navigation-view';
import { Screen } from '@/components/atomic/screen';
import { Separator } from '@/components/atomic/separator';
import { TextL, TextM, TextS } from '@/components/atomic/text';
import { IdCardPageDialog } from '@/components/id-card/IdCardPageDialog.component';
import { useDesign } from '@/hooks/useDesign';
import { useLoading } from '@/hooks/useLoading';
import { RowType } from '@/interfaces';
import {
  AccountStackParamList,
  AccountStackScreenProps
} from '@/navigation/types';
import {
  checkIfCanShowStudentIdCard,
  getMyAccount,
  getNotificationPreference,
  logoutThunk,
  useAppDispatch,
  useAppSelector
} from '@/store';
import {
  createStyles,
  isMobile,
  openBrowser,
  platformSpecificStyle
} from '@/utils';
import { NotificationContext } from '@/wrappers/PushNotification.wrapper';
import { DeviceInfoContext } from '@/wrappers/device-info';

interface Section {
  title: string;
  groups: {
    rows: Row[];
    isActive?: boolean;
  }[];
}

const { getStyle } = createStyles(({ pixel, colors }) => ({
  layout: {
    flex: 1,
    flexDirection: 'row',
    width: '100%'
  },
  subtitle: {
    alignSelf: 'flex-start',
    ...platformSpecificStyle({
      native: {
        marginBottom: pixel(30)
      },
      default: {
        marginBottom: pixel(20)
      }
    })
  },
  separator: {
    backgroundColor: colors.icon.primaryDisabled,
    height: pixel(2)
  },
  userName: {
    marginBottom: pixel(20),
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(40)
      },
      default: {
        fontSize: pixel(20)
      }
    })
  },
  gakusekiNumber: {
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(26)
      },
      default: {}
    })
  },
  sectionSeparator: {
    ...platformSpecificStyle({
      native: {
        height: pixel(40)
      },
      default: {
        height: pixel(30)
      }
    })
  },
  groupSeparator: {
    ...platformSpecificStyle({
      native: {
        height: pixel(20)
      },
      default: {
        height: pixel(20)
      }
    })
  },
  buttonContainer: {
    alignSelf: 'center',
    ...platformSpecificStyle({
      native: {
        width: '70%',
        marginVertical: pixel(80)
      },
      default: {
        width: '50%',
        marginVertical: pixel(65)
      }
    })
  },
  activeScreen: {
    flex: 1
  },
  baseMenu: {
    flex: 1
  },
  navigationViewNoPadding: {
    padding: 0
  },
  loader: {
    flex: 1
  },
  emailKey: {
    fontWeight: 'normal'
  },
  emailUsername: {
    fontWeight: 'bold'
  },
  dataText: {
    color: colors.textPrimaryInverted
  }
}));

export const AccountScreen = ({
  navigation,
  route
}: AccountStackScreenProps<keyof AccountStackParamList>) => {
  const dispatch = useAppDispatch();
  const design = useDesign();
  const styles = getStyle(design);
  const { colors } = design;
  const profile = useAppSelector((state) => state.account.profile);
  const canShowStudentIdCard = useAppSelector(checkIfCanShowStudentIdCard);
  const { deviceToken } = useContext(NotificationContext);
  const { deviceOS } = useContext(DeviceInfoContext);
  const [isStudentIdModalOpen, setIsStudentIdModalOpen] =
    useState<boolean>(false);

  const onPressStudentIdNavigation = () => setIsStudentIdModalOpen(true);
  const onPressCloseStudentId = () => setIsStudentIdModalOpen(false);

  const emailParts = profile.email?.split('@');
  const emailComponents = emailParts && (
    <>
      <TextM style={{ ...styles.emailUsername, ...styles.dataText }}>
        {emailParts[0]}
      </TextM>
      <TextS
        style={{ ...styles.emailUsername, ...styles.dataText }}
      >{`@${emailParts[1]}`}</TextS>
    </>
  );

  const sections = useMemo<Section[]>(() => {
    return [
      {
        title: 'プロフィール',
        groups: [
          {
            rows: [
              {
                key: '入学年度',
                type: 'data' as RowType,
                value: profile.admissionDate
                  ? format(
                      parse(profile.admissionDate, 'yyyyMMdd', new Date()),
                      'yyyy年'
                    )
                  : ''
              },
              {
                key: '学部',
                type: 'data' as RowType,
                value: profile.department
              },
              {
                key: 'メールアドレス',
                keyStyle: {
                  ...styles.emailKey
                },
                type: 'custom' as RowType,
                value: emailComponents
              },
              ...(canShowStudentIdCard
                ? [
                    {
                      key: '学生証',
                      type: 'navigate' as RowType,
                      onPress: onPressStudentIdNavigation
                    }
                  ]
                : [])
            ]
          }
        ]
      },
      {
        title: '設定変更',
        groups: [
          {
            isActive: route.params?.subRoute === 'notification-settings',
            rows: [
              {
                key: '通知設定',
                type: 'navigate' as RowType,
                onPress: () => {
                  if (isMobile) {
                    navigation.navigate('NotificationSettings');
                  } else {
                    navigation.navigate('Account', {
                      subRoute: 'notification-settings'
                    });
                  }
                }
              }
            ]
          },
          {
            rows: [
              {
                key: 'パスワード変更',
                type: 'open' as RowType,
                onPress: () => {
                  openBrowser({
                    url: 'https://id.yamato-u.ac.jp/realms/yamato-univ/account/password',
                    openInSameBrowser: true
                  });
                }
              }
            ]
          }
        ]
      }
    ];
  }, [profile, route]);

  const { isLoading, refresh, isPtrLoading, pullToRefresh } = useLoading(() => {
    return [dispatch(getMyAccount()), dispatch(getNotificationPreference())];
  });

  // fetch resource when component did mount
  useEffect(() => {
    refresh();
  }, []);

  return (
    <Screen
      isLoading={isLoading}
      refreshing={isPtrLoading}
      onRefresh={() => pullToRefresh()}
    >
      <Header title="アカウント" />

      {isLoading ? (
        <Loader
          style={{ ...styles.loader }}
          color={colors.secondary}
          size="large"
        />
      ) : (
        <View style={{ ...styles.layout }}>
          <ScrollView
            style={{ ...styles.baseMenu }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          >
            <NavigationView style={{ ...styles.navigationViewNoPadding }}>
              <View style={{ ...styles.subtitle }}>
                <TextL style={{ ...styles.userName }}>{profile.name}</TextL>
                <TextM
                  style={styles.gakusekiNumber}
                >{`学籍番号 : ${profile.gakusekiNo}`}</TextM>
              </View>
              <View style={{ ...styles.separator }} />
            </NavigationView>
            {sections.map((section) => (
              <View key={section.title}>
                <NavigationView>
                  <Separator size={styles.sectionSeparator.height} />
                  <TextM>{section.title}</TextM>
                </NavigationView>
                {section.groups.map((group, groupIndex) => (
                  <React.Fragment key={groupIndex}>
                    <NavigationView>
                      <Separator size={styles.groupSeparator.height} />
                    </NavigationView>
                    <NavigationView isActive={group.isActive}>
                      <List rows={group.rows} isActive={group.isActive} />
                    </NavigationView>
                  </React.Fragment>
                ))}
              </View>
            ))}
            <NavigationView>
              <View style={{ ...styles.buttonContainer }}>
                <Button
                  label="ログアウト"
                  secondary
                  fullWidth
                  onPress={() => {
                    dispatch(
                      logoutThunk({
                        deviceOs: deviceOS,
                        fcmDeviceToken: deviceToken
                      })
                    );
                  }}
                />
              </View>
            </NavigationView>
          </ScrollView>
          {!isMobile && (
            <View style={{ ...styles.activeScreen }}>
              {route.params?.subRoute === 'notification-settings' && (
                <NotificationSettingsScreen />
              )}
            </View>
          )}
        </View>
      )}
      <IdCardPageDialog
        isModalOpen={isStudentIdModalOpen}
        onPressClose={onPressCloseStudentId}
      />
    </Screen>
  );
};
