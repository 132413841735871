import { format } from 'date-fns';

import { api } from '..';

import { AttendanceResponse } from '@/interfaces/Attendance.interface';

export const getSchedulesAttendanceList = async (date: Date) => {
  const dateKey = format(date, 'yyyy/MM/dd');
  const { data } = await api.get<AttendanceResponse>('/attendance/status', {
    params: { date: dateKey }
  });

  return data[dateKey].attendance;
};
