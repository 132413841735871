export const EVENT_CONTAINER_HEIGHT_MOBILE = 230;
export const EVENT_CONTAINER_HEIGHT_WEB = 180;
export const EVENT_INFOMATION_HEIGHT_MOBILE = 180;
export const EVENT_INFOMATION_HEIGHT_WEB = 90;
export const CAFETERIA_MENU_CARD_BUTTON_HEIGHT_MOBILE = 100;
export const CAFETERIA_MENU_CARD_BUTTON_HEIGHT_WEB = 45;
export const SEPARATOR_HEIGHT_MOBILE = 5;
export const SEPARATOR_HEIGHT_WEB = 20;

export const CAFETERIA_OPEN_TIME = {
  from: '11:00',
  to: '14:00'
};
