import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchConfiguration } from '@/api/calls/configuration.api';
import { ConfigurationResponse } from '@/interfaces';
import { RootState } from '@/store/store';
import { convertTimeStringToDate } from '@/utils';

const initialState: ConfigurationResponse = {
  periods: {}
};

export const selectPeriods = ({ configuration }: RootState) => {
  return Object.entries({ ...configuration.periods }).reduce<
    Record<string, { from: Date; to: Date }>
  >((prev, [key, value]) => {
    return {
      ...prev,
      [key]: {
        from: convertTimeStringToDate({ timeString: value.from }),
        to: convertTimeStringToDate({ timeString: value.to })
      }
    };
  }, {});
};

export const getConfiguration = createAsyncThunk(
  'configuration/getConfiguration',
  () => {
    return fetchConfiguration();
  }
);

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      getConfiguration.fulfilled,
      (state, { payload }: { payload: ConfigurationResponse }) => {
        state.periods = payload.periods;
      }
    );
  }
});

export const configurationReducer = configurationSlice.reducer;
export const configurationAction = configurationSlice.actions;
