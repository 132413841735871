import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect, useMemo, useRef } from 'react';
import { Animated, Dimensions, View, ViewProps } from 'react-native';

import Background from '../../../../assets/images/background.png';
import { PullToRefreshWrapper } from '../pull-to-refresh/PullToRefresh.atomic';

import FastImage from '@/components/fast-image/FastImage';
import { useDesign } from '@/hooks/useDesign';
import { useAppSelector } from '@/store';
import { createStyles, isMobile, platformSpecificStyle } from '@/utils';
import {
  ScreenContext,
  ScreenContextValue
} from '@/wrappers/screen/Screen.context';

interface Props extends ViewProps {
  isTransparent?: boolean;
  isModal?: boolean;
  isLoading?: boolean;
  refreshing?: boolean;
  onRefresh?: () => void | Promise<void>;
  pageFixedLayerChildren?: React.ReactNode;
}

// Create Animated component to adapt the menu animation
const AnimatedLinearGradient = Animated.createAnimatedComponent(LinearGradient);

const { getStyle } = createStyles(({ pixel, safeArea: { top } }) => ({
  screen: {
    flex: 1,
    paddingLeft: isMobile ? pixel(40) : pixel(300),
    paddingRight: pixel(40)
  },
  childrenWrapper: {
    flex: 1
  },
  safeArea: {
    ...platformSpecificStyle({
      native: {
        paddingTop: top + pixel(100)
      },
      default: {
        paddingTop: pixel(40)
      }
    })
  },
  transparent: {
    paddingLeft: pixel(40),
    flex: 1
  },
  modal: {
    paddingLeft: 0,
    paddingRight: 0
  },
  imageContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center'
  },
  backgroundImage: {
    width: Dimensions.get('window').width,
    maxWidth: 1000,
    aspectRatio: 780 / 423
  },
  closedDesktopMenu: {
    paddingLeft: pixel(160)
  },
  menuOpen: {
    ...platformSpecificStyle({
      default: {
        width: pixel(340)
      },
      tablet: {
        width: pixel(250)
      }
    })
  },
  menuClose: {
    ...platformSpecificStyle({
      default: {
        width: pixel(180)
      }
    })
  }
}));

export const Screen = ({
  style,
  isTransparent,
  isModal,
  isLoading,
  refreshing,
  onRefresh,
  children,
  pageFixedLayerChildren,
  ...otherProps
}: Props) => {
  const design = useDesign();
  const defaultStyle = getStyle(design);
  const { colors } = design;

  const loadingContextValue = useMemo<ScreenContextValue>(
    () => ({
      isLoading: isLoading ?? false
    }),
    [isLoading]
  );

  // --------------------------------------------------------------------------
  // Padding animation of desktop content

  const { isDesktopMenuOpen } = useAppSelector((state) => state.ui);

  const openWidth = defaultStyle.menuOpen.width,
    closedWidth = defaultStyle.menuClose.width;

  const paddingAnimation = useRef(
    new Animated.Value(isDesktopMenuOpen ? openWidth : closedWidth)
  ).current;

  useEffect(() => {
    Animated.timing(paddingAnimation, {
      toValue: isDesktopMenuOpen ? openWidth : closedWidth,
      duration: 300,
      useNativeDriver: false
    }).start(() => {});
  }, [isDesktopMenuOpen]);

  // --------------------------------------------------------------------------

  return (
    <ScreenContext.Provider value={loadingContextValue}>
      {isTransparent ? (
        <>
          {pageFixedLayerChildren && <>{pageFixedLayerChildren}</>}
          <View
            style={[
              {
                ...defaultStyle.screen,
                ...(isTransparent && defaultStyle.transparent),
                ...(isModal && defaultStyle.modal)
              },
              style
            ]}
            {...otherProps}
          >
            {children}
          </View>
        </>
      ) : (
        <>
          {pageFixedLayerChildren && <>{pageFixedLayerChildren}</>}
          <AnimatedLinearGradient
            colors={[
              colors.background.color1,
              colors.background.color2,
              colors.background.color3
            ]}
            locations={[0, 0.7, 1]}
            start={{ x: 0.4, y: 0.4 }}
            end={{ x: 0.8, y: 0.8 }}
            style={[
              {
                ...defaultStyle.screen,
                ...defaultStyle.safeArea,
                ...(!isMobile && { paddingLeft: paddingAnimation })
              },
              style
            ]}
            {...otherProps}
          >
            <View style={defaultStyle.imageContainer}>
              <FastImage
                source={Background}
                style={defaultStyle.backgroundImage}
              />
            </View>
            {isMobile && refreshing !== undefined && onRefresh ? (
              <PullToRefreshWrapper
                refreshing={Boolean(refreshing)}
                onRefresh={onRefresh}
              >
                {children}
              </PullToRefreshWrapper>
            ) : (
              <View style={defaultStyle.childrenWrapper}>{children}</View>
            )}
          </AnimatedLinearGradient>
        </>
      )}
    </ScreenContext.Provider>
  );
};
