import Constants from 'expo-constants';
import { View } from 'react-native';

import { HeaderUnreadMessages } from './HeaderUnreadMessages.component';

import { WelcomeMessage } from '@/components/atomic/menu/desktop/WelcomeMessage.component';
import { Separator } from '@/components/atomic/separator';
import { TextXL } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { createStyles, isMobile, platformSpecificStyle } from '@/utils';

const { getStyle } = createStyles(({ pixel }) => ({
  container: {
    alignSelf: 'flex-start',
    ...platformSpecificStyle({
      native: {
        width: '100%'
      },
      default: {
        marginBottom: 90,
        width: pixel(300)
      }
    })
  },
  appName: {
    fontWeight: 'bold',
    ...platformSpecificStyle({
      native: {
        fontSize: pixel(52)
      },
      default: {}
    })
  }
}));

const APP_NAME = Constants.expoConfig?.extra?.APP_NAME;

export const HeaderHome: React.FC = () => {
  const design = useDesign();
  const styles = getStyle(design);
  const { pixel } = design;

  return (
    <View style={{ ...styles.container }}>
      {isMobile && (
        <>
          <TextXL style={{ ...styles.appName }}>{APP_NAME}</TextXL>
          <Separator size={pixel(40)} />
          <WelcomeMessage />
          <Separator size={pixel(30)} />
        </>
      )}
      <HeaderUnreadMessages />
      <Separator size={pixel(40)} />
    </View>
  );
};
