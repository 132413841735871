import { DeviceType, getDeviceTypeAsync, modelName, osName } from 'expo-device';
import { PropsWithChildren, useEffect, useState } from 'react';
import { PixelRatio, Platform } from 'react-native';

import { DeviceInfoContext, DeviceOS } from './DeviceInfo.context';

import useCachedResources from '@/hooks/useCachedResources';

export const DeviceInfoWrapper: React.FC<PropsWithChildren> = ({
  children
}) => {
  const isLoadingComplete = useCachedResources();
  const [deviceType, setDeviceType] = useState(DeviceType.UNKNOWN);

  useEffect(() => {
    getDeviceTypeAsync().then((deviceType) => setDeviceType(deviceType));
  }, []);

  return (
    <DeviceInfoContext.Provider
      value={{
        deviceType,
        deviceOS: osName as DeviceOS,
        modelName,
        isBrowser: Platform.OS === 'web',
        devicePixelDensity: PixelRatio.get()
      }}
    >
      {isLoadingComplete && children}
    </DeviceInfoContext.Provider>
  );
};
