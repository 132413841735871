import { useCallback } from 'react';

import {
  getConfiguration,
  getMyAccount,
  getUnreadCount,
  useAppDispatch
} from '@/store';

export const usePreload = () => {
  const dispatch = useAppDispatch();

  const preload = useCallback(async () => {
    await dispatch(getMyAccount()).unwrap();
    Promise.allSettled([
      dispatch(getUnreadCount()),
      dispatch(getConfiguration())
    ]);
  }, []);

  return { preload };
};
