/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */
import { Text as DefaultText } from 'react-native';

import { useDesign } from '@/hooks/useDesign';

type ThemeProps = {
  lightColor?: string;
  darkColor?: string;
};

export type TextProps = ThemeProps & DefaultText['props'];

export const Text = (props: TextProps) => {
  const { style, ...otherProps } = props;
  const { colors } = useDesign();
  const color = colors.textPrimary;

  return <DefaultText style={[{ color }, style]} {...otherProps} />;
};
