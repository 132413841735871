import {
  FIRST_SEMESTER_END_MONTH,
  FIRST_SEMESTER_START_MONTH
} from '@/constants/Timetable';

export const getSemester = (date: Date) => {
  const month = date.getMonth() + 1;
  return FIRST_SEMESTER_START_MONTH <= month &&
    month <= FIRST_SEMESTER_END_MONTH
    ? 1
    : 2;
};
