import QrErrorQuatiationMark from 'assets/images/icons/id-card/qr-error-mark.png';
import QrError from 'assets/images/icons/id-card/qr-error.png';
import React, { useState } from 'react';
import { Modal, SafeAreaView, TouchableOpacity, View } from 'react-native';

import { QrPageBody } from './QrPageBody.component';
import { QrPageFooter } from './QrPageFooter.component';
import { QrPageHeader } from './QrPageHeader.component';

import { Image } from '@/components/atomic/Image/Image.atomic';
import { Loader } from '@/components/atomic/loader';
import { TextL } from '@/components/atomic/text';
import { useQrCode } from '@/hooks/qr/useQrCode';
import { useDesign } from '@/hooks/useDesign';
import { QrCountdownCircleSmall } from '@/svgs/qr/QrCountdownCircleSmall.svg';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  enabled: boolean;
}

const { getStyle } = createStyles(
  ({ pixel, windowDimensions, isLandscape }) => ({
    qrModalBackground: {
      width: '100%',
      height: '100%',
      backgroundColor: 'fff',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: 0
    },
    qrModalContainer: {
      width: '100%',
      height: '100%',
      backgroundColor: 'fff',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      marginTop: pixel(20)
    },
    idCardQrSection: {
      marginTop: pixel(30),
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      ...platformSpecificStyle({
        ios: {
          height:
            windowDimensions.height < 768
              ? pixel(140)
              : windowDimensions.height < 800
              ? pixel(180)
              : windowDimensions.height < 900
              ? pixel(226)
              : pixel(280)
        },
        android: {
          height:
            windowDimensions.height < 768
              ? pixel(140)
              : windowDimensions.height < 800
              ? pixel(160)
              : windowDimensions.height < 900
              ? pixel(206)
              : pixel(260)
        },
        tablet: {
          height: isLandscape
            ? windowDimensions.height < 768
              ? pixel(160) / 2
              : windowDimensions.height < 800
              ? pixel(160) / 2
              : windowDimensions.height < 900
              ? pixel(226) / 2
              : pixel(260) / 2
            : pixel(260) / 2
        },
        default: {
          height: pixel(260) / 2
        }
      })
    },
    qrSectionCLeft: {
      flexBasis: '20%',
      flexGrow: 1,
      flexShrink: 1
    },
    qrErrorQuatiationMark: {
      ...platformSpecificStyle({
        ios: {
          width: pixel(50)
        },
        android: {
          width: pixel(50)
        },
        tablet: {
          width: pixel(50) / 2
        },
        default: {
          width: pixel(50) / 2
        }
      })
    },
    qrSectionCenter: {
      flexGrow: 0,
      flexShrink: 0,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      ...platformSpecificStyle({
        ios: {
          flexBasis:
            windowDimensions.height < 768
              ? pixel(140)
              : windowDimensions.height < 800
              ? pixel(180)
              : windowDimensions.height < 900
              ? pixel(226)
              : pixel(280)
        },
        android: {
          flexBasis:
            windowDimensions.height < 768
              ? pixel(140)
              : windowDimensions.height < 800
              ? pixel(160)
              : windowDimensions.height < 900
              ? pixel(206)
              : pixel(260)
        },
        tablet: {
          flexBasis: isLandscape
            ? windowDimensions.height < 768
              ? pixel(160) / 2
              : windowDimensions.height < 800
              ? pixel(180) / 2
              : windowDimensions.height < 900
              ? pixel(226) / 2
              : pixel(260) / 2
            : pixel(260) / 2
        },
        default: {
          flexBasis: pixel(260) / 2
        }
      })
    },
    qrSectionRight: {
      flexBasis: '20%',
      flexGrow: 1,
      flexShrink: 1
    },
    qrImageMedium: {
      ...platformSpecificStyle({
        ios: {
          width:
            windowDimensions.height < 768
              ? pixel(140)
              : windowDimensions.height < 800
              ? pixel(180)
              : windowDimensions.height < 900
              ? pixel(226)
              : pixel(250)
        },
        android: {
          width:
            windowDimensions.height < 768
              ? pixel(140)
              : windowDimensions.height < 800
              ? pixel(160)
              : windowDimensions.height < 900
              ? pixel(206)
              : pixel(210)
        },
        tablet: {
          width: isLandscape
            ? windowDimensions.height < 768
              ? pixel(160) / 2
              : windowDimensions.height < 800
              ? pixel(180) / 2
              : windowDimensions.height < 900
              ? pixel(226) / 2
              : pixel(260) / 2
            : pixel(260) / 2
        },
        default: {
          width: pixel(260) / 2
        }
      })
    },
    remainingTimeTextForSmall: {
      color: '#3376D0',
      textAlign: 'left',
      ...platformSpecificStyle({
        native: {
          fontSize: pixel(28)
        },
        tablet: {
          fontSize: pixel(28) / 2
        },
        default: {
          fontSize: pixel(28) / 2
        }
      })
    },
    remainingTimeText: {
      color: '#3376D0',
      textAlign: 'left',
      ...platformSpecificStyle({
        native: {
          fontSize: pixel(34)
        },
        tablet: {
          fontSize: pixel(34) / 2
        },
        default: {
          fontSize: pixel(34) / 2
        }
      })
    },
    alertingTextColor: {
      color: '#C54134'
    },
    qrModalMiddle: {
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      ...platformSpecificStyle({
        native: {
          marginTop: pixel(100),
          height: pixel(400)
        },
        tablet: {
          marginTop: isLandscape ? pixel(100) / 2 : pixel(100),
          height: isLandscape ? pixel(300) : pixel(400)
        },
        default: {
          marginTop: pixel(100) / 2,
          height: pixel(400) / 2
        }
      })
    },
    qrModalTimer: {},
    errorMessagesWrap: {
      marginTop: pixel(28),
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '80%'
    },
    errorMessage: {
      color: '#000',
      fontWeight: 'bold',
      ...platformSpecificStyle({
        native: {
          fontSize: pixel(26)
        },
        tablet: {
          fontSize: pixel(26) / 2
        },
        default: {
          fontSize: pixel(26) / 2
        }
      })
    },
    errorCodeMessage: {
      color: '#000',
      fontWeight: 'bold',
      marginBottom: pixel(20),
      ...platformSpecificStyle({
        native: {
          fontSize: pixel(22)
        },
        tablet: {
          fontSize: pixel(22) / 2
        },
        default: {
          fontSize: pixel(22) / 2
        }
      })
    },
    errorMessageSub: {
      color: '#000',
      ...platformSpecificStyle({
        native: {
          fontSize: pixel(26)
        },
        tablet: {
          fontSize: pixel(26) / 2
        },
        default: {
          fontSize: pixel(26) / 2
        }
      })
    },
    retryButtonContainer: {
      backgroundColor: '#F2F2F2',
      alignSelf: 'center',
      ...platformSpecificStyle({
        native: {
          marginTop: pixel(40),
          width: pixel(212),
          height: pixel(80)
        },
        tablet: {
          marginTop: pixel(40) / 2,
          width: pixel(212) / 2,
          height: pixel(80) / 2
        },
        default: {
          marginTop: pixel(40) / 2,
          width: pixel(212) / 2,
          height: pixel(80) / 2
        }
      })
    },
    retryButtonLabel: {
      color: '#0066CC',
      ...platformSpecificStyle({
        native: {
          fontSize: pixel(30)
        },
        tablet: {
          fontSize: pixel(30) / 2
        },
        default: {
          fontSize: pixel(30) / 2
        }
      })
    },
    qrImageDetail: {
      ...platformSpecificStyle({
        native: { width: pixel(400) },
        tablet: { width: isLandscape ? pixel(300) : pixel(400) },
        default: { width: pixel(400) / 2 }
      })
    }
  })
);

export const QrCodeTouchableMedium: React.FC<Props> = ({ enabled }) => {
  const design = useDesign();
  const styles = getStyle(design);

  const {
    qrImageData,
    secondsRemaining,
    secondsRemainingForDisplay,
    timelimitAlerting,
    errorCode,
    onRetryCallback
  } = useQrCode({ enabled });

  // Modal
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <View style={{ ...styles.idCardQrSection }}>
        {!qrImageData && !errorCode ? (
          <Loader color="#444" size="large" />
        ) : (
          <>
            <View style={{ ...styles.qrSectionCLeft }} />
            <TouchableOpacity
              style={{ ...styles.qrSectionCenter }}
              onPress={() => setIsModalOpen(true)}
            >
              <Image
                size={{ width: styles.qrImageMedium.width }}
                src={qrImageData && !errorCode ? qrImageData : QrError}
              />
            </TouchableOpacity>
            <View style={{ ...styles.qrSectionRight }}>
              {errorCode ? (
                <Image
                  size={{ width: styles.qrErrorQuatiationMark.width }}
                  src={QrErrorQuatiationMark}
                />
              ) : (
                <QrCountdownCircleSmall
                  size={{ width: 35, height: 35 }}
                  progress={Math.max(secondsRemaining / 30000, 0)}
                  timelimitAlerting={timelimitAlerting}
                >
                  <TextL
                    style={{
                      ...styles.remainingTimeTextForSmall,
                      ...(timelimitAlerting && {
                        ...styles.alertingTextColor
                      })
                    }}
                  >{` ${Math.max(secondsRemainingForDisplay, 0)}`}</TextL>
                </QrCountdownCircleSmall>
              )}
            </View>
          </>
        )}
      </View>

      <Modal animationType="fade" statusBarTranslucent visible={isModalOpen}>
        <View style={{ ...styles.qrModalBackground }}>
          <SafeAreaView style={{ ...styles.qrModalContainer }}>
            <QrPageHeader />
            <QrPageBody
              errorCode={errorCode}
              qrImageData={qrImageData || QrError}
              onRetryCallback={onRetryCallback}
              secondsRemaining={secondsRemaining}
              secondsRemainingForDisplay={secondsRemainingForDisplay}
              timelimitAlerting={timelimitAlerting}
              forStudent
            />
            <QrPageFooter onPressClose={() => setIsModalOpen(false)} />
          </SafeAreaView>
        </View>
      </Modal>
    </>
  );
};
