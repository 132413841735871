import { api } from '..';

import { CalendarEntriesResponse } from '@/interfaces/Calendar.interface';

export const fetchCalendarList = async (from: string, to?: string) => {
  const { data } = await api.get<CalendarEntriesResponse>('/calendar/list', {
    params: { from, to }
  });

  return data;
};
