import {
  PayloadAction,
  createAsyncThunk,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import { compareDesc } from 'date-fns';

import {
  deleteNotification,
  fetchNotificationList,
  fetchUnreadCount,
  updateNotificationReadStatus
} from '@/api/calls/notifiaction.api';
import {
  NotificationList,
  NotificationReadTypes,
  NotificationTypes
} from '@/interfaces';
import type { RootState } from '@/store';

interface LoadingState {
  unreadCount: boolean;
  [NotificationReadTypes.READ]: boolean;
  [NotificationReadTypes.UNREAD]: boolean;
}

interface NotificationsState {
  readNotifications: NotificationList;
  unreadNotifications: NotificationList;
  loadingState: LoadingState;
}

const initialState: NotificationsState = {
  unreadNotifications: { notifications: [], current: 0, total: 0 },
  readNotifications: { notifications: [], current: 0, total: 0 },
  loadingState: {
    unreadCount: false,
    [NotificationReadTypes.READ]: false,
    [NotificationReadTypes.UNREAD]: false
  }
};

export const getNotificationList = createAsyncThunk(
  'notifications/getNotificationList',
  async (
    {
      type,
      page,
      shouldReset
    }: {
      type: NotificationReadTypes;
      page?: number;
      shouldReset?: boolean;
    },
    { dispatch }
  ) => {
    dispatch(
      notificationsAction.setLoadingState({
        loadingState: {
          [type]: true
        }
      })
    );
    const data = await fetchNotificationList({ type, page });

    return { data, type, shouldReset };
  }
);

export const getReadAndUnreadNotifications = createAsyncThunk(
  'notifications/getReadAndUnreadNotifications',
  async (_, { dispatch }) => {
    await dispatch(
      getNotificationList({
        type: NotificationReadTypes.READ,
        page: 1,
        shouldReset: true
      })
    );
    await dispatch(getNotificationList({ type: NotificationReadTypes.UNREAD }));
  }
);

export const getUnreadCount = createAsyncThunk<
  number | undefined,
  void,
  { state: RootState }
>('notifications/getUnreadCount', async (_payload, { dispatch }) => {
  dispatch(
    notificationsAction.setLoadingState({
      loadingState: {
        unreadCount: true
      }
    })
  );
  return fetchUnreadCount();
});

export const setNotificationRead = createAsyncThunk(
  'notifications/setNotificationRead',
  async (
    { id, type }: { id: string; type: NotificationTypes },
    { dispatch }
  ) => {
    await updateNotificationReadStatus({ id, type });

    await dispatch(getReadAndUnreadNotifications());
  }
);

export const removeNotification = createAsyncThunk(
  'notifications/deleteNotification',
  async (
    { id, type }: { id: string; type: NotificationTypes },
    { dispatch }
  ) => {
    deleteNotification({ id, type });

    await dispatch(getReadAndUnreadNotifications());
  }
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setLoadingState: (
      state,
      { payload }: PayloadAction<{ loadingState: Partial<LoadingState> }>
    ) => {
      state.loadingState = { ...state.loadingState, ...payload.loadingState };
    }
  },
  extraReducers(builder) {
    builder
      .addCase(
        getNotificationList.fulfilled,
        (state, { payload: { data, type, shouldReset } }) => {
          if (type === NotificationReadTypes.READ) {
            state.readNotifications = {
              ...data,
              notifications: [
                ...(shouldReset ? [] : state.readNotifications.notifications),
                ...data.notifications
              ]
            };
          }

          if (type === NotificationReadTypes.UNREAD) {
            state.unreadNotifications = {
              ...data,
              total: data.notifications.length
            };
          }

          state.loadingState[type] = false;
        }
      )
      .addCase(getUnreadCount.fulfilled, (state, { payload }) => {
        if (payload !== undefined) {
          state.unreadNotifications.total = payload;
        }
        state.loadingState.unreadCount = false;
      })
      .addCase(getUnreadCount.rejected, (state) => {
        state.loadingState.unreadCount = false;
      });
  }
});

export const selectUnreadMessages = (state: RootState) =>
  state.notifications.unreadNotifications;

export const selectReadMessages = (state: RootState) =>
  state.notifications.readNotifications;

export const selectUnreadMessagesDesc = createSelector(
  selectUnreadMessages,
  (notification) =>
    [...notification.notifications].sort((message1, message2) =>
      compareDesc(message1.date, message2.date)
    )
);

export const selectReadMessagesDesc = createSelector(
  selectReadMessages,
  (notification) => ({
    ...notification,
    notifications: [...notification.notifications].sort((message1, message2) =>
      compareDesc(message1.date, message2.date)
    )
  })
);

export const selectUnreadCount = (state: RootState) =>
  state.notifications.unreadNotifications.total;

export const notificationsAction = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
