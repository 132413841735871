import { api } from '..';

import {
  Notification,
  NotificationApi,
  NotificationListApi,
  NotificationReadTypes,
  NotificationTypes
} from '@/interfaces';

export const fetchNotificationList = async ({
  type,
  page
}: {
  type: NotificationReadTypes;
  page?: number;
}) => {
  const { data } = await api.get<NotificationListApi>('/notification/list', {
    params: { type, page }
  });

  return {
    ...data,
    notifications: data.notifications.map(mapNotificationFromApi)
  };
};

export const fetchUnreadCount = async () => {
  const { data } = await api.get<number>('/notification/unreadCount');

  return data;
};

export const updateNotificationReadStatus = async ({
  id,
  type
}: {
  id: string;
  type: NotificationTypes;
}) => {
  await api.patch(`/notification/${type}/${id}`);
};

export const deleteNotification = async ({
  id,
  type
}: {
  id: string;
  type: NotificationTypes;
}) => {
  await api.delete(`notification/${type}/${id}`);
};

const mapNotificationFromApi = (
  notification: NotificationApi
): Notification => {
  return {
    ...notification,
    date: new Date(notification.date)
  };
};

export const fetchNotificationAttachment = async ({
  id,
  type,
  attachmentId
}: {
  id: string;
  type: NotificationTypes;
  attachmentId: string;
}) => {
  try {
    const response = await api.get(
      `/notification/${type}/${id}/attachment/${attachmentId}`,
      {
        responseType: 'blob'
      }
    );
    return response.data; // レスポンスのデータを返す
  } catch (error) {
    console.error('Error fetching attachment:', error);
    return null; // エラーがあった場合はnullを返す
  }
};

export const updateAllMessagesRead = async () => {
  await api.post(`/notification/setread/all`);
};
