// import { addDays, format, isSameDay, parse } from 'date-fns';
// import ja from 'date-fns/locale/ja';
import { api } from '..';

import {
  AttendanceStatus,
  ExportAttendanceHistoryResponse,
  TeacherCalendarIndex
} from '@/interfaces/Teacher.interface';

export const fetchTeacherCalendarClassList = async (params: {
  from: string;
  to: string;
}): Promise<TeacherCalendarIndex> => {
  const { data } = await api.get<TeacherCalendarIndex>(
    '/calendar/class-schedule',
    {
      params
    }
  );

  // ----------------------------------------
  // Mock API Code
  // ----------------------------------------

  // const generateDataForDate = (date: Date): TeacherCalendarResponseItem[] => {
  //   const numOfClass = (date.getDay() % 2) + 3;

  //   return Array.from({ length: numOfClass }, (_, index) => {
  //     return {
  //       period: {
  //         from: '09:10',
  //         to: '10:40'
  //       },
  //       classes: {
  //         name: `キャリアデザイン${index + 1}`,
  //         classId: '123456',
  //         room: [
  //           {
  //             name: `D10${index + 1}大講義室`,
  //             roomId: '67890'
  //           }
  //         ]
  //       }
  //     };
  //   });
  // };

  // const generateData = (): Promise<TeacherCalendarIndex> =>
  //   new Promise((resolve) => {
  //     setTimeout(() => {
  //       let date = parse(params.from, 'yyyy/MM/dd', new Date(), {
  //         locale: ja
  //       });
  //       const toDate = parse(params.to, 'yyyy/MM/dd', new Date(), {
  //         locale: ja
  //       });

  //       const data: TeacherCalendarIndex = {};

  //       while (!isSameDay(date, toDate)) {
  //         const res = generateDataForDate(date);
  //         data[format(date, 'yyyyMMdd')] = res;
  //         date = addDays(date, 1);
  //       }

  //       resolve(data);
  //     }, 300);
  //   });

  // const data = await generateData();

  return data;
};

export const fetchClassAttendance = async (params: {
  date: string;
  classId: string;
  period: number;
}): Promise<AttendanceStatus[]> => {
  const { data } = await api.get<AttendanceStatus[]>(
    '/attendance/class-status-list',
    {
      params
    }
  );

  return data;

  // ----------------------------------------
  // Mock API Code
  // ----------------------------------------
  //
  // return Array.from({ length: 10 }, (_, index) => {
  //   const status: 'attend' | 'late' | 'other' | null =
  //     index === 2 ? 'late' : index === 4 ? 'other' : index ? 'attend' : null;
  //   return {
  //     federationId: '99921013',
  //     status,
  //     statusMiddle: !status ? null : 'confirmed',
  //     statusLast: !status || status === 'other' ? null : 'confirmed',
  //     profile: {
  //       gakusekiNo: '99921013',
  //       name: '大和 花子',
  //       english_name: null,
  //       admissionDate: '2022/12/12',
  //       department: '保健医療学部',
  //       email: 'test@yamato-u.ac.jp',
  //       gakunen: 1,
  //       birthday: null,
  //       faculty: '保健医療学科',
  //       graduationDate: null
  //     }
  //   };
  // });
};

export const exportAttendanceHistory = async (params: {
  date: string;
  classId: string;
  period: number;
}): Promise<ExportAttendanceHistoryResponse> => {
  const { data } = await api.post<ExportAttendanceHistoryResponse>(
    '/attendance/class-status-list/send',
    params
  );
  return data;
};
