import React from 'react';
import { Modal, TextStyle, View } from 'react-native';

import { Button } from '@/components/atomic/button';
import { Loader } from '@/components/atomic/loader';
import { ModalBackgroundOverlay } from '@/components/atomic/modal/ModalBackgroundOverlay.atomic';
import { TextL, TextS } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { createStyles } from '@/utils';

const { getStyle } = createStyles(({ pixel }) => ({
  dialogContentArea: {
    width: '90%',
    height: '68%',
    maxWidth: 540,
    paddingHorizontal: pixel(80),
    paddingBottom: pixel(100),
    paddingTop: pixel(90),
    borderRadius: pixel(20),
    opacity: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    backgroundColor: '#fff',
    zIndex: 11
  },
  dialogContent: {
    width: '100%'
  },
  dialogHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dialogTitle: {
    color: '#000',
    fontWeight: 'bold',
    fontSize: pixel(44)
  },
  dialogBody: {
    marginTop: pixel(70)
  },
  loader: {
    marginTop: pixel(15)
  },
  dialogMessageText: {
    color: '#000',
    lineHeight: pixel(42),
    fontSize: pixel(28)
  },
  dialogButtonsWrapper: {
    width: '100%',
    display: 'flex',
    gap: pixel(20),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  dialogButton: {
    width: '45%'
  }
}));

interface Props {
  isModalOpen: boolean;
  isLoading: boolean;
  title: string;
  description: string;
  confirmBtnText?: string;
  cancelBtnText?: string;
  confirmBtnStyle?: TextStyle;
  onPressConfirm: () => void;
  onPressClose: () => void;
}

export const ConfirmDialog: React.FC<Props> = ({
  isModalOpen,
  isLoading,
  title,
  description,
  confirmBtnText = 'OK',
  cancelBtnText = 'キャンセル',
  confirmBtnStyle,
  onPressConfirm,
  onPressClose
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <Modal visible={isModalOpen}>
      <ModalBackgroundOverlay>
        <View style={{ ...styles.dialogContentArea }}>
          <View style={{ ...styles.dialogContent }}>
            <View
              style={{
                ...styles.dialogHeader
              }}
            >
              <TextL selectable style={{ ...styles.dialogTitle }}>
                {title}
              </TextL>
            </View>
            <View style={{ ...styles.dialogBody }}>
              <TextS selectable style={{ ...styles.dialogMessageText }}>
                {description}
              </TextS>
              {isLoading && (
                <Loader
                  style={{ ...styles.loader }}
                  color="#444"
                  size="large"
                />
              )}
            </View>
          </View>
          <View style={{ ...styles.dialogButtonsWrapper }}>
            <View style={{ ...styles.dialogButton }}>
              <Button
                modal
                fullWidth
                labelStyle={{ fontWeight: 'normal' }}
                label={cancelBtnText}
                onPress={onPressClose}
              />
            </View>
            <View style={{ ...styles.dialogButton }}>
              <Button
                modal
                fullWidth
                labelStyle={{
                  color: '#DE2634',
                  fontWeight: 'normal',
                  ...confirmBtnStyle
                }}
                label={confirmBtnText}
                onPress={onPressConfirm}
              />
            </View>
          </View>
        </View>
      </ModalBackgroundOverlay>
    </Modal>
  );
};
