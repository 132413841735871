import { createSlice } from '@reduxjs/toolkit';

interface UIState {
  isDesktopMenuOpen: boolean;
}

const initialState: UIState = {
  isDesktopMenuOpen: true
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleDesktopMenu: (state) => {
      state.isDesktopMenuOpen = !state.isDesktopMenuOpen;
    }
  }
});

export const uiReducer = uiSlice.reducer;
export const uiAction = uiSlice.actions;
