import { differenceInSeconds } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import { EventCardProgressBar } from './EventCardProgressBar.component';

import { TextM } from '@/components/atomic/text';
import {
  CAFETERIA_MENU_CARD_BUTTON_HEIGHT_MOBILE,
  EVENT_CONTAINER_HEIGHT_MOBILE,
  EVENT_CONTAINER_HEIGHT_WEB
} from '@/constants/Home';
import { useDesign } from '@/hooks/useDesign';
import { CalendarClass } from '@/interfaces/Calendar.interface';
import { createStyles, isMobile, platformSpecificStyle } from '@/utils';

interface Props {
  activeElement?: {
    index: number;
    event: CalendarClass;
  };
  scheduleLength: number;
  gap: number;
  withCafeteriaMenus?: boolean;
  isActiveCafeteria?: boolean;
  isExpiredCafeteria?: boolean;
}

const { getStyle } = createStyles(({ pixel, colors }) => ({
  container: {
    height: '100%',
    marginRight: pixel(10),
    justifyContent: 'space-between'
  },
  row: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  circle: {
    borderColor: colors.icon.secondary,
    justifyContent: 'center',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {
        width: pixel(50),
        height: pixel(50),
        borderRadius: pixel(50),
        borderWidth: pixel(3)
      },
      default: {
        width: pixel(25),
        height: pixel(25),
        borderRadius: pixel(25),
        borderWidth: pixel(2)
      }
    })
  },
  circleActive: {
    backgroundColor: colors.textPrimary,
    borderWidth: 0
  },
  circleExpired: {
    borderColor: colors.icon.primaryDisabled
  },
  cafeCircle: {
    borderColor: colors.icon.secondary,
    justifyContent: 'center',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {
        width: pixel(24),
        height: pixel(24),
        borderRadius: pixel(24),
        borderWidth: pixel(3)
      },
      default: {
        width: pixel(12),
        height: pixel(12),
        borderRadius: pixel(12),
        borderWidth: pixel(2)
      }
    })
  },
  cafeCircleActive: {
    backgroundColor: '#E3D6C1',
    borderWidth: 0
  },
  cafeCircleExpired: {
    borderColor: colors.icon.primaryDisabled
  },
  textActive: {
    color: colors.textPrimaryInverted
  }
}));

export const EventCardListSideBar: React.FC<Props> = ({
  activeElement,
  scheduleLength,
  withCafeteriaMenus = false,
  isActiveCafeteria = false,
  isExpiredCafeteria = false,
  gap
}) => {
  const design = useDesign();
  const { pixel } = design;
  const styles = getStyle(design);
  const [listHeight, setListHeight] = useState(0);
  useEffect(() => {
    setListHeight(
      scheduleLength *
        (isMobile
          ? pixel(EVENT_CONTAINER_HEIGHT_MOBILE)
          : pixel(EVENT_CONTAINER_HEIGHT_WEB)) +
        (scheduleLength - 1) * gap +
        (isMobile && withCafeteriaMenus
          ? pixel(CAFETERIA_MENU_CARD_BUTTON_HEIGHT_MOBILE)
          : 0)
    );
  }, [isMobile, scheduleLength, withCafeteriaMenus]);

  const containerPadding = useMemo(
    () =>
      scheduleLength
        ? ((listHeight -
            (isMobile && withCafeteriaMenus
              ? pixel(CAFETERIA_MENU_CARD_BUTTON_HEIGHT_MOBILE) + gap
              : 0) -
            (scheduleLength - 1) * gap) /
            scheduleLength -
            styles.circle.height) /
          2
        : 0,
    [scheduleLength, listHeight, styles.circle.height]
  );
  const circleDistance = useMemo(
    () => 2 * containerPadding + gap,
    [containerPadding, gap]
  );
  const cafeCircleDistance = useMemo(() => {
    return isMobile
      ? containerPadding +
          gap +
          (pixel(CAFETERIA_MENU_CARD_BUTTON_HEIGHT_MOBILE) -
            styles.circle.height) /
            2
      : 0;
  }, [
    listHeight,
    containerPadding,
    gap,
    styles.circle.height,
    styles.cafeCircle.height
  ]);

  const activeEventProgress = useMemo(
    () =>
      activeElement
        ? differenceInSeconds(new Date(), activeElement.event.period.from) /
          differenceInSeconds(
            activeElement.event.period.to,
            activeElement.event.period.from
          )
        : undefined,
    [activeElement]
  );

  return (
    <View
      style={{
        ...styles.container,
        paddingVertical: containerPadding,
        height: listHeight
      }}
    >
      {Array.from({ length: scheduleLength }).map((_, index) => (
        <React.Fragment key={index}>
          <View
            style={{
              ...styles.row
            }}
          >
            <View
              style={{
                ...styles.circle,
                ...(activeElement?.index === index && styles.circleActive),
                ...((!activeElement || activeElement?.index) &&
                  styles.circleExpired)
              }}
            >
              <TextM
                style={{
                  ...(index === activeElement?.index && styles.textActive)
                }}
              >
                {index + 1}
              </TextM>
            </View>
            {index < scheduleLength - 1 && (
              <EventCardProgressBar
                height={
                  !withCafeteriaMenus || index !== 1
                    ? circleDistance
                    : cafeCircleDistance
                }
                progress={
                  index === activeElement?.index
                    ? activeEventProgress
                    : undefined
                }
              />
            )}
          </View>
          {withCafeteriaMenus && isMobile && index === 1 && (
            <View
              style={{
                ...styles.row
              }}
            >
              <View
                style={{
                  ...styles.cafeCircle,
                  ...(isActiveCafeteria && styles.cafeCircleActive),
                  ...((!activeElement || isExpiredCafeteria) &&
                    styles.cafeCircleExpired)
                }}
              />
              <EventCardProgressBar height={cafeCircleDistance} />
            </View>
          )}
        </React.Fragment>
      ))}
    </View>
  );
};
