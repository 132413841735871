import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import { Platform } from 'react-native';

import {
  fetchMyAccount,
  fetchNotificationPreference,
  patchNotificationPreferences
} from '@/api/calls/account.api';
import {
  NotificationPreference,
  Profile
} from '@/interfaces/Account.interface';
import { RootState } from '@/store/store';

interface AccountState {
  profile: Partial<Profile>;
  notificationPreference: NotificationPreference;
}

const initialState: AccountState = {
  profile: {},
  notificationPreference: {
    campusPlan: false,
    classroom: false,
    email: false
  }
};

export const getMyAccount = createAsyncThunk('account/getMyAccount', () => {
  return fetchMyAccount();
});

export const getNotificationPreference = createAsyncThunk(
  'account/getNotificationPreference',
  () => {
    return fetchNotificationPreference();
  }
);

const debouncedUpdateNotificationPreferences = debounce(
  async (callback: () => Promise<void>) => {
    callback();
  },
  1000
);

export const updateNotificationPreferences = createAsyncThunk(
  'account/updateNotificationPreferences',
  async (preferences: NotificationPreference, { dispatch }) => {
    dispatch(accountAction.setNotificationPreferences(preferences));

    debouncedUpdateNotificationPreferences(async () => {
      await patchNotificationPreferences(preferences);
      await dispatch(getNotificationPreference());
    });
  }
);

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setNotificationPreferences: (
      state,
      action: PayloadAction<NotificationPreference>
    ) => {
      state.notificationPreference = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getMyAccount.fulfilled, (state, { payload }) => {
        state.profile = payload;
      })
      .addCase(getNotificationPreference.fulfilled, (state, { payload }) => {
        state.notificationPreference = payload;
      });
  }
});

/**
 * 学生証を表示できるアカウントかどうかを判定する
 */
export const checkIfCanShowStudentIdCard = (state: RootState) =>
  // 卒業生には学生証を表示しない
  // web版は学生証を表示しない
  Platform.OS !== 'web' && !state.account.profile.isGraduated;

export const accountReducer = accountSlice.reducer;
export const accountAction = accountSlice.actions;
