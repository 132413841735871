import React from 'react';
import { TouchableOpacity, View } from 'react-native';

import { useDesign } from '@/hooks/useDesign';
import { QRCodeIcon } from '@/svgs/qr/QRCodeIcon.svg';
import { createStyles } from '@/utils';

const { getStyle } = createStyles(({ pixel }) => ({
  fab: {
    zIndex: 100,
    position: 'absolute',
    bottom: pixel(32),
    right: pixel(32)
  },
  buttonWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#740E16',
    height: pixel(112),
    width: pixel(112),
    borderRadius: pixel(112 / 2)
  },
  qrIcon: {
    height: pixel(56),
    width: pixel(56)
  }
}));

interface Props {
  onPressButton: () => void;
}

export const TeacherQrCodeFab: React.FC<Props> = ({ onPressButton }) => {
  const design = useDesign();
  const styles = getStyle(design);
  return (
    <View style={{ ...styles.fab }}>
      <TouchableOpacity onPress={onPressButton}>
        <View style={{ ...styles.buttonWrapper }}>
          <QRCodeIcon
            width={styles.qrIcon.width}
            height={styles.qrIcon.height}
          />
        </View>
      </TouchableOpacity>
    </View>
  );
};
