import { parse } from 'date-fns';
import React from 'react';
import { View } from 'react-native';

import { TextL } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { UserProfile } from '@/interfaces/IdCard.interface';
import {
  calculateAge,
  convertToJapaneseEra,
  createStyles,
  platformSpecificStyle
} from '@/utils';

interface Props {
  userProfile?: UserProfile;
}

const { getStyle } = createStyles(({ pixel, isLandscape }) => ({
  idCardContents: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...platformSpecificStyle({
      native: {
        marginTop: pixel(30)
      },
      tablet: {
        marginTop: pixel(30) / 2
      },
      default: {
        marginTop: pixel(30) / 2
      }
    })
  },
  idCardContentsRow: {
    flexDirection: 'row',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: pixel(6)
      },
      android: {
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: pixel(1)
      },
      tablet: {
        width: isLandscape ? '50%' : '70%',
        justifyContent: 'center',
        marginBottom: pixel(6) / 2
      },
      default: {
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: pixel(6) / 2
      }
    })
  },
  idCardContentsRowHead: {
    color: '#000',
    textAlign: 'right',
    ...platformSpecificStyle({
      native: {
        flexBasis: '30%',
        fontSize: pixel(30),
        marginRight: pixel(20)
      },
      tablet: {
        flexBasis: isLandscape ? '45%' : '40%',
        fontSize: pixel(30) / 2
      },
      default: {
        flexBasis: '40%',
        fontSize: pixel(30) / 2,
        marginRight: pixel(20) / 2
      }
    })
  },
  idCardContentsRowText: {
    color: '#000',
    textAlign: 'left',
    ...platformSpecificStyle({
      native: {
        flexBasis: '70%',
        fontSize: pixel(30)
      },
      tablet: {
        flexBasis: isLandscape ? '55%' : '60%',
        fontSize: pixel(30) / 2
      },
      default: {
        flexBasis: '60%',
        fontSize: pixel(30) / 2
      }
    })
  },
  idCardContentsRowStudentName: {
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'left',
    ...platformSpecificStyle({
      native: {
        flexBasis: '70%',
        fontSize: pixel(40)
      },
      tablet: {
        flexBasis: isLandscape ? '55%' : '60%',
        fontSize: pixel(40) / 2
      },
      default: {
        flexBasis: '60%',
        fontSize: pixel(40) / 2
      }
    })
  }
}));

export const IdCardBody: React.FC<Props> = ({ userProfile }) => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <View style={{ ...styles.idCardContents }}>
      <View style={{ ...styles.idCardContentsRow }}>
        <TextL style={{ ...styles.idCardContentsRowHead }}>学籍番号</TextL>
        <TextL selectable style={{ ...styles.idCardContentsRowText }}>
          {userProfile?.gakusekiNo}
        </TextL>
      </View>
      <View style={{ ...styles.idCardContentsRow }}>
        <TextL style={{ ...styles.idCardContentsRowHead }}>所属</TextL>
        <TextL selectable style={{ ...styles.idCardContentsRowText }}>
          {userProfile?.faculty}
        </TextL>
      </View>
      <View style={{ ...styles.idCardContentsRow }}>
        <TextL style={{ ...styles.idCardContentsRowHead }} />
        <TextL selectable style={{ ...styles.idCardContentsRowText }}>
          {userProfile?.department}
        </TextL>
      </View>
      <View style={{ ...styles.idCardContentsRow }}>
        <TextL style={{ ...styles.idCardContentsRowHead }}>氏名</TextL>
        <TextL selectable style={{ ...styles.idCardContentsRowStudentName }}>
          {userProfile?.name}
        </TextL>
      </View>
      <View style={{ ...styles.idCardContentsRow }}>
        <TextL style={{ ...styles.idCardContentsRowHead }}>生年月日</TextL>
        <TextL selectable style={{ ...styles.idCardContentsRowText }}>
          {`${
            userProfile?.birthday &&
            convertToJapaneseEra(
              parse(userProfile.birthday, 'yyyyMMdd', new Date())
            )
          } （${
            userProfile?.birthday &&
            calculateAge(parse(userProfile.birthday, 'yyyyMMdd', new Date()))
          }歳）`}
        </TextL>
      </View>
      <View style={{ ...styles.idCardContentsRow }}>
        <TextL style={{ ...styles.idCardContentsRowHead }}>発効日</TextL>
        <TextL selectable style={{ ...styles.idCardContentsRowText }}>
          {`${
            userProfile?.admissionDate &&
            convertToJapaneseEra(
              parse(userProfile.admissionDate, 'yyyyMMdd', new Date())
            )
          }`}
        </TextL>
      </View>
      <View style={{ ...styles.idCardContentsRow }}>
        <TextL style={{ ...styles.idCardContentsRowHead }}>有効期限</TextL>
        <TextL selectable style={{ ...styles.idCardContentsRowText }}>
          {`${
            userProfile?.graduationDate &&
            convertToJapaneseEra(
              parse(userProfile.graduationDate, 'yyyyMMdd', new Date())
            )
          }`}
        </TextL>
      </View>
    </View>
  );
};
