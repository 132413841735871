import * as React from 'react';
import { Path } from 'react-native-svg';

import { SvgProps, SvgWrapper } from '@/wrappers/svg';

export const OpenAllMessageIcon: React.FC<SvgProps> = ({ color, ...props }) => {
  return (
    <SvgWrapper viewBox={{ width: 24, height: 33.605 }} {...props}>
      <Path
        id="パス_5"
        data-name="パス 5"
        d="M24,30.9l-.005-.017c0-.025-.007-.051-.013-.081a.79.79,0,0,0-.03-.115.639.639,0,0,0-.03-.077.359.359,0,0,0-.044-.07.327.327,0,0,0-.046-.081l-.036-.04-.014-.01-.028-.028-.018-.025L17.507,25.38H16.294v1.057l5.646,4.51L12,38.889l-9.94-7.941,5.646-4.51V25.38H6.493L.3,30.329l-.04.037-.01.014c-.01.01-.017.017-.014.017h0l-.041.03-.022.029a.23.23,0,0,0-.04.068.6.6,0,0,0-.049.081.718.718,0,0,0-.034.084.814.814,0,0,0-.028.108.28.28,0,0,0-.009.065h0L0,30.912V47.138a.793.793,0,0,0,.791.792H23.209A.793.793,0,0,0,24,47.138V30.948ZM22.417,46.347H1.583V32.593l9.923,7.927a.792.792,0,0,0,.988,0l9.923-7.927Z"
        transform="translate(0 -14.325)"
        fill={color || '#fff'}
      />
      <Path
        id="パス_6"
        data-name="パス 6"
        d="M25.518,11.157,19.691,4.448a.568.568,0,0,0-.858,0l-5.826,6.709a.568.568,0,0,0,.429.94h3.137l.033,14.08a.788.788,0,0,0,.788.788h3.8a.788.788,0,0,0,.788-.788L21.951,12.1h3.137a.568.568,0,0,0,.429-.94"
        transform="translate(-7.262 -2.4)"
        fill={color || '#fff'}
      />
    </SvgWrapper>
  );
};
