import React, { useContext } from 'react';
import { View } from 'react-native';

import { Button } from '@/components/atomic/button/Button.atomic';
import { useDesign } from '@/hooks/useDesign';
import { logoutThunk, useAppDispatch } from '@/store';
import { createStyles, platformSpecificStyle } from '@/utils';
import { NotificationContext } from '@/wrappers/PushNotification.wrapper';
import { DeviceInfoContext } from '@/wrappers/device-info';

const { getStyle } = createStyles(({ pixel }) => ({
  buttonContainer: {
    alignSelf: 'center',
    marginBottom: pixel(20),
    ...platformSpecificStyle({
      native: {
        width: '70%'
        // marginVertical: pixel(80)
      },
      default: {
        width: '50%'
        // marginVertical: pixel(65)
      }
    })
  }
}));

export const LogoutButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const design = useDesign();
  const styles = getStyle(design);

  const { deviceToken } = useContext(NotificationContext);
  const { deviceOS } = useContext(DeviceInfoContext);

  return (
    <View style={{ ...styles.buttonContainer }}>
      <Button
        label="ログアウト"
        secondary
        fullWidth
        onPress={() => {
          dispatch(
            logoutThunk({
              deviceOs: deviceOS,
              fcmDeviceToken: deviceToken
            })
          );
        }}
      />
    </View>
  );
};
