import { useNavigation } from '@react-navigation/native';
import { useContext } from 'react';
import { TouchableOpacity } from 'react-native';

import { Loader } from '@/components/atomic/loader';
import { TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { RootTabParamList, RootTabScreenProps } from '@/navigation/types';
import { selectUnreadCount, useAppSelector } from '@/store';
import { OpenIcon } from '@/svgs';
import { createStyles, platformSpecificStyle } from '@/utils';
import { ScreenContext } from '@/wrappers/screen/Screen.context';

const { getStyle } = createStyles(({ pixel, colors }) => ({
  container: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: pixel(38),
    backgroundColor: colors.icon.primary,

    ...platformSpecificStyle({
      native: {
        paddingVertical: pixel(20),
        paddingHorizontal: pixel(30)
      },
      default: {
        paddingVertical: pixel(10),
        paddingHorizontal: pixel(15)
      }
    })
  },
  text: {
    color: colors.textPrimaryInverted
  },
  openIcon: {
    ...platformSpecificStyle({
      native: {
        height: pixel(34)
      },
      default: {
        height: pixel(15)
      }
    })
  }
}));

export const HeaderUnreadMessages: React.FC = () => {
  const design = useDesign();
  const styles = getStyle(design);
  const unreadNotificationsCount = useAppSelector(selectUnreadCount);
  const navigation =
    useNavigation<RootTabScreenProps<keyof RootTabParamList>['navigation']>();
  const { isLoading } = useContext(ScreenContext);

  const navigate = () => {
    navigation.jumpTo('Notification');
  };

  return unreadNotificationsCount ? (
    <TouchableOpacity onPress={navigate} style={{ ...styles.container }}>
      <TextM style={{ ...styles.text }}>
        {isLoading ? (
          <Loader color="red" size="small" />
        ) : (
          `${unreadNotificationsCount} 件の 未読があります`
        )}
      </TextM>
      <OpenIcon size={styles.openIcon.height} />
    </TouchableOpacity>
  ) : (
    <></>
  );
};
