import { useRef, useState } from 'react';
import { AppState, NativeEventSubscription, Platform } from 'react-native';

export const useFocus = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [appStateSub, setAppStateSub] =
    useState<null | NativeEventSubscription>(null);

  const appState = useRef(AppState.currentState);

  const onFocus = () => setIsFocused(true);
  const onBlur = () => setIsFocused(false);

  const subscribe = () => {
    if (Platform.OS === 'web') {
      window.addEventListener('focus', onFocus);
      window.addEventListener('blur', onBlur);
    } else {
      setAppStateSub(
        AppState.addEventListener('change', function (state) {
          if (state !== appState.current) {
            appState.current = state;
            if (state.match('active')) {
              setIsFocused(true);
            }
            setIsFocused(false);
          }
        })
      );
    }
  };

  const unsubscribe = () => {
    if (Platform.OS === 'web') {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    } else {
      appStateSub?.remove();
    }
  };

  return { isFocused, subscribe, unsubscribe, appState: appState.current };
};
