import { PropsWithChildren, useEffect } from 'react';

import { useAppNavigation } from '@/hooks/useAppNavigation';
import { useAppDispatch, useAppSelector } from '@/store';
import { errorAction } from '@/store/reducers/error/error.slice';

export const ErrorRedirectWrapper: React.FC<PropsWithChildren> = ({
  children
}) => {
  const dispatch = useAppDispatch();
  const { redirect } = useAppSelector((state) => state.error);
  const { navigate } = useAppNavigation();

  useEffect(() => {
    if (redirect.shouldRedirect) {
      dispatch(errorAction.setRedirect());
      navigate('CriticalError');
    }
  }, [redirect]);

  return <>{children}</>;
};
