import React from 'react';
import { View } from 'react-native';

import { Button } from '../atomic/button';

import { useDesign } from '@/hooks/useDesign';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  onPressClose: () => void;
}

const { getStyle } = createStyles(({ pixel }) => ({
  qrPageFooter: {
    position: 'absolute',
    bottom: pixel(158),
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    ...platformSpecificStyle({
      native: {
        height: pixel(100)
      },
      tablet: {
        height: pixel(100) / 2
      },
      default: {
        height: pixel(100) / 2
      }
    })
  },
  qrPageFotterButtonContainer: {
    width: '30%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonContainer: {
    alignSelf: 'center',
    ...platformSpecificStyle({
      native: {
        marginTop: pixel(40),
        width: pixel(212),
        height: pixel(80)
      },
      tablet: {
        marginTop: pixel(40) / 2,
        width: pixel(212) / 2,
        height: pixel(80) / 2
      },
      default: {
        marginTop: pixel(40) / 2,
        width: pixel(212) / 2,
        height: pixel(80) / 2
      }
    })
  }
}));

export const QrPageFooter: React.FC<Props> = ({ onPressClose }) => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <View style={{ ...styles.qrPageFooter }}>
      <View style={{ ...styles.qrPageFotterButtonContainer }}>
        <Button
          fullWidth
          modal
          onPress={onPressClose}
          containerStyle={{ ...styles.buttonContainer }}
          labelStyle={{}}
          label="閉じる"
        />
      </View>
    </View>
  );
};
