import React from 'react';
import { ScrollView, View } from 'react-native';

import { Separator } from '@/components/atomic/separator';
import { useDesign } from '@/hooks/useDesign';
import { createStyles } from '@/utils';

interface Props {
  sections: JSX.Element[];
}

const { getStyle } = createStyles(() => ({
  scrollableContainer: {
    maxHeight: '100%',
    marginBottom: 40,
    flex: 1
  }
}));

export const EventListContainer: React.FC<Props> = ({ sections }) => {
  const design = useDesign();
  const styles = getStyle(design);
  const { pixel } = design;

  return (
    <View
      style={{
        ...styles.scrollableContainer
      }}
    >
      <ScrollView
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        {sections.map((section, index) => (
          <React.Fragment key={index}>
            {section}
            {index !== sections.length - 1 && <Separator size={pixel(60)} />}
          </React.Fragment>
        ))}
      </ScrollView>
    </View>
  );
};
