import { AxiosResponse } from 'axios';

import { api } from '..';

import {
  DeleteQrHistoryResponse,
  ExportQrHistoryResponse,
  QrReadHistoryResponse,
  QrReadRequestBody,
  QrReadResponse,
  QrResponse
} from '@/interfaces';

export const fetchNewQr = async () => {
  const { data } = await api.get<QrResponse>('/qr-api/code?width=300');
  return data;
};

export const verifyReadQrCode = async (params: QrReadRequestBody) => {
  const { data } = await api.post<
    QrReadResponse,
    AxiosResponse<QrReadResponse>,
    QrReadRequestBody
  >('/qr-api/read', params);

  return data;
};

export const readQrHistory = async ({ page }: { page?: number }) => {
  const { data } = await api.get<QrReadHistoryResponse>(
    '/qr-api/read-history',
    {
      params: { page }
    }
  );
  return data;
};

export const deleteQrHistory = async () => {
  const { data } = await api.delete<DeleteQrHistoryResponse>(
    '/qr-api/read-history'
  );
  return data;
};

export const exportQrHistory = async () => {
  const { data } = await api.post<ExportQrHistoryResponse>(
    '/qr-api/read-history/send'
  );
  return data;
};
