import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface BackendError {
  label: string;
  message: string;
}

interface ErrorState {
  backendErrors: { label: string; message: string }[];
  redirect: { shouldRedirect: boolean; error?: BackendError };
}

const initialState: ErrorState = {
  backendErrors: [],
  redirect: { shouldRedirect: false }
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    push: (state, action: PayloadAction<BackendError>) => {
      state.backendErrors.push(action.payload);
    },
    pop: (state) => {
      state.backendErrors = state.backendErrors.slice(0, -1);
    },
    setRedirect: (state, action: PayloadAction<BackendError | undefined>) => {
      state.redirect.shouldRedirect = Boolean(action.payload);
      if (action.payload) {
        state.redirect.error = action.payload;
      }
    }
  }
});

export const errorReducer = errorSlice.reducer;
export const errorAction = errorSlice.actions;
