import { useState } from 'react';
import { ImageURISource, Platform, Image as RNImage, View } from 'react-native';

import { TextM } from '@/components/atomic/text';
import FastImage from '@/components/fast-image/FastImage';
import { useDesign } from '@/hooks/useDesign';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  iconSrc?: ImageURISource;
  title: string;
  isActive?: boolean;
}

const { getStyle } = createStyles(({ pixel, colors }) => ({
  container: {
    alignItems: 'center',
    backgroundColor: colors.secondary,
    ...platformSpecificStyle({
      native: {
        justifyContent: 'center',
        width: pixel(320),
        height: pixel(294)
      },
      default: {
        flex: 1,
        flexDirection: 'row',
        paddingVertical: pixel(10),
        paddingHorizontal: pixel(50)
      },
      tablet: {
        width: '100%',
        height: pixel(60),
        paddingVertical: pixel(10),
        paddingHorizontal: pixel(20)
      }
    })
  },
  containerActive: { backgroundColor: 'transparent' },
  label: {
    color: colors.textPrimaryInverted,
    ...platformSpecificStyle({
      native: {
        marginTop: pixel(38)
      },
      default: {
        marginStart: pixel(24)
      },
      tablet: {
        marginTop: pixel(0)
      }
    })
  },
  labelActive: {
    color: colors.textPrimary
  },
  icon: {
    tintColor: colors.navigation.iconColor,
    ...platformSpecificStyle({
      native: {
        width: pixel(110),
        height: pixel(110)
      },
      default: {
        width: pixel(40),
        height: pixel(40)
      },
      tablet: {
        width: pixel(20),
        height: pixel(20)
      }
    })
  },
  iconActive: {
    ...platformSpecificStyle({
      native: {},
      default: {
        tintColor: colors.navigation.iconColorActive
      }
    })
  },
  iconPlaceholder: {
    backgroundColor: colors.icon.primaryInvertedDisabled,
    justifyContent: 'center',
    alignItems: 'center',
    ...platformSpecificStyle({
      native: {
        borderRadius: pixel(124)
      },
      default: {
        borderRadius: pixel(50)
      }
    })
  },
  iconPlaceholderActive: {
    backgroundColor: colors.icon.primary
  },
  iconPlaceholderTextActive: {
    color: colors.textPrimaryInverted
  }
}));

export const NaviMenuItem: React.FC<Props> = ({ iconSrc, title, isActive }) => {
  const design = useDesign();
  const styles = getStyle(design);
  const [showPlaceholder, setShowPlaceholder] = useState(!iconSrc?.uri);

  const iconPlaceholder = (
    <View
      style={{
        ...styles.icon,
        ...styles.iconPlaceholder,
        ...(isActive && styles.iconPlaceholderActive)
      }}
    >
      <TextM style={{ ...(isActive && styles.iconPlaceholderTextActive) }}>
        Icon
      </TextM>
    </View>
  );

  // we fallback on RNImage on web until tintColor is implemented in Expo's
  // great solution would be to use svgs instead of pngs
  const image =
    Platform.OS === 'web' ? (
      <RNImage
        source={{ ...iconSrc }}
        style={{ ...styles.icon, ...(isActive && styles.iconActive) }}
        onError={() => {
          setShowPlaceholder(true);
        }}
        resizeMode="contain"
      />
    ) : (
      <FastImage
        source={{ ...iconSrc }}
        style={{ ...styles.icon, ...(isActive && styles.iconActive) }}
        onError={() => {
          setShowPlaceholder(true);
        }}
        resizeMode="contain"
      />
    );

  return (
    <View
      style={{ ...styles.container, ...(isActive && styles.containerActive) }}
    >
      {!showPlaceholder ? image : iconPlaceholder}
      <TextM style={{ ...styles.label, ...(isActive && styles.labelActive) }}>
        {title}
      </TextM>
    </View>
  );
};
