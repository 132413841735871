import * as SplashScreen from 'expo-splash-screen';
import { StatusBar } from 'expo-status-bar';
import React, { useEffect } from 'react';
import 'react-native-gesture-handler';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { enableFreeze } from 'react-native-screens';
import { Provider } from 'react-redux';

import {
  registerChannel,
  registerForegroundServiceBeaconRanging
} from '@/hooks/useBeaconDetection';
import { Navigation } from '@/navigation';
import { store } from '@/store';
import { PushNotificationWrapper } from '@/wrappers/PushNotification.wrapper';
import { DeviceInfoWrapper } from '@/wrappers/device-info';
import { ModalsWrapper } from '@/wrappers/modals/Modals.wrapper';

SplashScreen.preventAutoHideAsync();
enableFreeze(true);

export default function App() {
  // Register foreground service
  registerForegroundServiceBeaconRanging();
  useEffect(() => {
    registerChannel().catch(console.error);
  }, []);

  return (
    <Provider store={store}>
      <StatusBar style="light" />
      <SafeAreaProvider>
        <DeviceInfoWrapper>
          <PushNotificationWrapper>
            <ModalsWrapper>
              <Navigation />
            </ModalsWrapper>
          </PushNotificationWrapper>
        </DeviceInfoWrapper>
      </SafeAreaProvider>
    </Provider>
  );
}
