import Constants from 'expo-constants';
import { StyleSheet, View } from 'react-native';

import { RootStackScreenProps } from '../../navigation/types';

import { Button } from '@/components/atomic/button';
import { Screen } from '@/components/atomic/screen';
import { TextL, TextM } from '@/components/atomic/text';
import { useAppSelector } from '@/store';

const APP_NAME = Constants.expoConfig?.extra?.APP_NAME;

export const CriticalErrorScreen = ({
  navigation
}: RootStackScreenProps<'CriticalError'>) => {
  const { error } = useAppSelector((state) => state.error.redirect);

  return (
    <Screen style={styles.container}>
      <View style={styles.content}>
        <TextL style={styles.title}>{error?.label}</TextL>
        <TextM style={styles.message}>{error?.message}</TextM>
        <Button
          fullWidth
          onPress={() =>
            navigation.navigate(navigation.getState().routeNames[0])
          }
          label={`${APP_NAME}トップに戻る`}
        />
      </View>
    </Screen>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 100
  },
  content: {
    minWidth: 300
  },
  title: {
    fontWeight: 'bold',
    marginBottom: 48,
    textAlign: 'center'
  },
  message: { marginBottom: 48, textAlign: 'center' }
});
