import View from '@expo/html-elements/build/primitives/View';
import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import { useRef } from 'react';
import { Animated } from 'react-native';

import { Body } from './Body.component';
import { Header } from './Header.component';

import { useDesign } from '@/hooks/useDesign';
import { uiAction, useAppDispatch, useAppSelector } from '@/store';
import { ChevronIcon } from '@/svgs';
import { createStyles, isTablet, platformSpecificStyle } from '@/utils';

const { getStyle } = createStyles(({ pixel, colors }) => ({
  menuContainer: {
    position: 'absolute',
    backgroundColor: colors.navigation.background,
    left: 0,
    top: 0,
    bottom: 0,
    height: '100%',
    paddingVertical: pixel(30),
    minHeight: pixel(700)
  },
  menuOpen: {
    ...platformSpecificStyle({
      default: {
        width: pixel(270)
      },
      tablet: {
        width: pixel(215)
      }
    })
  },
  menuClose: {
    ...platformSpecificStyle({
      default: {
        width: pixel(73)
      }
    })
  },
  chevron: {
    paddingLeft: pixel(25)
  }
}));

export const MenuDesktop: React.FC<BottomTabBarProps> = (props) => {
  const design = useDesign();
  const styles = getStyle(design);
  const { colors, pixel } = design;

  const { isDesktopMenuOpen } = useAppSelector((state) => state.ui);
  const dispatch = useAppDispatch();

  const openWidth = styles.menuOpen.width,
    closedWidth = styles.menuClose.width;

  const widthAnimation = useRef(
    new Animated.Value(isDesktopMenuOpen ? openWidth : closedWidth)
  ).current;

  const opacityInterpolation = widthAnimation.interpolate<number>({
    inputRange: [closedWidth, (openWidth + closedWidth) / 2, openWidth],
    outputRange: [0, 0, 1]
  });

  return (
    <Animated.View
      style={{
        ...styles.menuContainer,
        width: widthAnimation
      }}
    >
      <View style={styles.chevron}>
        {!isTablet && (
          <ChevronIcon
            color={colors.icon.primaryInverted}
            direction={isDesktopMenuOpen ? 'left' : 'right'}
            size={pixel(25)}
            innerSize={pixel(20)}
            onPress={() => {
              dispatch(uiAction.toggleDesktopMenu());
              Animated.timing(widthAnimation, {
                toValue: isDesktopMenuOpen ? closedWidth : openWidth,
                duration: 300,
                useNativeDriver: false
              }).start(() => {});
            }}
          />
        )}
      </View>
      <Header opacityAnimation={opacityInterpolation} />
      <Body {...props} opacityAnimation={opacityInterpolation} />
    </Animated.View>
  );
};
