import * as React from 'react';
import { Circle, G, Path } from 'react-native-svg';

import { SvgProps, SvgWrapper } from '@/wrappers/svg';

export const AttendedMarkIcon: React.FC<SvgProps> = ({ color, ...props }) => {
  return (
    <SvgWrapper viewBox={{ width: 36, height: 36 }} {...props}>
      <Circle
        id="楕円形_39"
        data-name="楕円形 39"
        cx="18"
        cy="18"
        r="18"
        fill={color === 'white' ? '#fff' : '#dd2232'}
      />
      <G id="Check" transform="translate(-3471.004 -887.002)">
        <Path
          id="線_1"
          data-name="線 1"
          d="M3487,912.907l-.092.091L3478,904.091,3480.09,902l6.91,6.909L3498.909,897,3501,899.09,3487.092,913Z"
          fill={color === 'white' ? '#dd2232' : '#fff'}
        />
      </G>
    </SvgWrapper>
  );
};
