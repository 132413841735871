import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ImageURISource } from 'react-native';

import { fetchAccountProfileImage } from '@/api/calls/account.api';
import { deleteQrHistory, readQrHistory } from '@/api/calls/qr.api';
import {
  DeleteQrHistoryResponse,
  QrCodeRecord,
  QrReadHistoryResponse
} from '@/interfaces';
import { AppDispatch, RootState } from '@/store/store';

interface QrCodeStoreState {
  profiles: QrCodeRecord[];
  profileImages: Record<string, ImageURISource | undefined>;
  lastReadPage: number;
}

export const getReadQrHistory = createAsyncThunk<
  QrReadHistoryResponse,
  { page: number },
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: { error: unknown };
  }
>('qr-code/getReadQrHistory', async (params, thunk) => {
  try {
    const result = await readQrHistory(params);

    // プロファイル画像を取得する
    const readIds = result.histories
      .map((item) => item.profile?.gakusekiNo)
      .filter((id): id is string => Boolean(id));

    const uniqueIds = Array.from(new Set(readIds));
    const state = thunk.getState();

    uniqueIds.forEach((id) => {
      if (!state.qrCode.profileImages[id]) {
        thunk.dispatch(getProfileImage(id));
      }
    });

    return result;
  } catch (error) {
    return thunk.rejectWithValue({ error });
  }
});

export const deleteAllQrHistory = createAsyncThunk<
  DeleteQrHistoryResponse,
  undefined,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: { error: unknown };
  }
>('qr-code/deleteAllQrHistory', async (_params, thunk) => {
  try {
    return await deleteQrHistory();
  } catch (error) {
    return thunk.rejectWithValue({ error });
  }
});

export const getProfileImage = createAsyncThunk<
  ImageURISource | undefined,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: { error: unknown };
  }
>('qr-code/getProfileImage', async (federationId, thunk) => {
  try {
    const profileImage = await fetchAccountProfileImage(federationId);
    return profileImage ? { uri: profileImage } : undefined;
  } catch (error) {
    return thunk.rejectWithValue({ error });
  }
});

const initialState: QrCodeStoreState = {
  profiles: [],
  profileImages: {},
  lastReadPage: 0
};

export const qrCodeSlice = createSlice({
  name: 'qr-code',
  initialState,
  reducers: {
    setProfiles(state, action: PayloadAction<QrCodeStoreState['profiles']>) {
      state.profiles = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getReadQrHistory.fulfilled, (state, { payload }) => {
        state.profiles = [...state.profiles, ...payload.histories];
        state.lastReadPage = payload.page;
      })
      .addCase(getProfileImage.fulfilled, (state, action) => {
        state.profileImages[action.meta.arg] = action.payload;
      })
      .addCase(deleteAllQrHistory.fulfilled, (state) => {
        state.profiles = [];
        state.profileImages = {};
        state.lastReadPage = 0;
      });
  }
});

export const qrCodeReducer = qrCodeSlice.reducer;
export const qrCodeAction = qrCodeSlice.actions;
