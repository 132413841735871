import * as React from 'react';
import { Path, TSpan, Text } from 'react-native-svg';

import { useDesign } from '@/hooks/useDesign';
import { SvgProps, SvgWrapper, usePrimaryIconColor } from '@/wrappers/svg';

export const PDFIcon: React.FC<SvgProps> = ({ ...props }) => {
  const primaryIconColor = usePrimaryIconColor();
  const { colors } = useDesign();

  return (
    <SvgWrapper viewBox={{ width: 48, height: 24 }} {...props}>
      <Path
        fillRule="evenodd"
        fill={colors.primary}
        d="M4.000,0.001 L43.1000,0.001 C46.209,0.001 48.000,1.791 48.000,4.001 L48.000,19.1000 C48.000,22.209 46.209,23.1000 43.1000,23.1000 L4.000,23.1000 C1.791,23.1000 0.000,22.209 0.000,19.1000 L0.000,4.001 C0.000,1.791 1.791,0.001 4.000,0.001 Z"
      />
      <Text
        fontFamily="Kozuka Gothic Pr6N"
        fill="rgb(0, 0, 0)"
        fontSize="20px"
        x="7px"
        y="20px"
      >
        <TSpan
          fontSize="20px"
          fontFamily="Hiragino Sans,Noto Sans CJK JP"
          fill={primaryIconColor}
        >
          PDF
        </TSpan>
      </Text>
    </SvgWrapper>
  );
};
