/**
 * @constant {number} ATTENDANCE_TIMEOUT
 * 授業開始から15分間は出席確認のbeaconのrangingを行う
 * 15分 = 15 * 60秒 * 1000ミリ秒
 */
export const ATTENDANCE_TIMEOUT = 15 * 60 * 1000;
/**
 * @constant {number} ATTENDANCE_2ND_TIME_START_OFFSET
 * 授業開始から60分後に出席確認のbeaconのrangingを開始する
 * 60分 = 60 * 60秒 * 1000ミリ秒
 */
export const ATTENDANCE_2ND_TIME_START_OFFSET = 60 * 60 * 1000;
/**
 * @constant {number} ATTENDANCE_2ND_TIME_END_OFFSET
 * 授業開始から65分後に出席確認のbeaconのrangingを止める
 * 65分まで
 */
export const ATTENDANCE_2ND_TIME_END_OFFSET = 65 * 60 * 1000;
/**
 * @constant {number} ATTENDANCE_2ND_TIME_START_OFFSET
 * 授業開始から80分後に出席確認のbeaconのrangingを開始する
 * 80分 = 80 * 60秒 * 1000ミリ秒
 */
export const ATTENDANCE_3RD_TIME_START_OFFSET = 80 * 60 * 1000;
/**
 * @constant {number} ATTENDANCE_2ND_TIME_END_OFFSET
 * 授業開始から85分後に出席確認のbeaconのrangingを止める
 * 85分まで
 */
export const ATTENDANCE_3RD_TIME_END_OFFSET = 85 * 60 * 1000;
/**
 * @constant {number} BEFORE_ATTENDANCE_START_TIME
 * 授業開始10分前から出席情報の表示を行う
 * 10分 = 10 * 60秒 * 1000ミリ秒
 */
export const BEFORE_ATTENDANCE_START_TIME = 10 * 60 * 1000;

/**
 * @constant {number} ATTENDANCE_API_INTERVAL
 * 出席APIを実行するinterval
 */
export const ATTENDANCE_API_INTERVAL = 3000; //
