import { useCallback } from 'react';
import { useWindowDimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import useColorScheme from './useColorScheme';

import Colors from '@/constants/Colors';
import { isMobile } from '@/utils';

/** ------------------------------------------------------------------------
 * Design specification is created with this width
 */

export const useDesign = () => {
  const theme = useColorScheme();
  const safeArea = useSafeAreaInsets();
  const DESIGN_SPEC_WIDTH = !isMobile ? 1440 : 750;

  // Pixel function that calculates the necessary adjustments for the design spec
  const windowDimensions = useWindowDimensions();
  const { width } = windowDimensions;

  const pixel = useCallback(
    isMobile
      ? (number: number) => number * (width / DESIGN_SPEC_WIDTH)
      : (pixel: number) => pixel,
    []
  );

  return {
    colors: theme === 'light' ? Colors.light : Colors.light,
    pixel,
    safeArea,
    windowDimensions,
    isPortrait: windowDimensions.height > windowDimensions.width,
    isLandscape: windowDimensions.height < windowDimensions.width
  };
};

// Type of the style creator function's parameters
export type GetStylesWithDesignArguments = ReturnType<typeof useDesign>;
