import { useState } from 'react';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Code } from 'react-native-vision-camera';

import {
  CameraFrameView,
  ReadProfileCard,
  ReadProfileCardProps
} from './components/qr-code-reader';

import { fetchAccountInfo } from '@/api/calls/account.api';
import { verifyReadQrCode } from '@/api/calls/qr.api';
import { isNetworkError } from '@/api/index';
import { TextM } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import {
  getProfileImage,
  getReadQrHistory,
  qrCodeAction,
  useAppDispatch
} from '@/store';
import { IconExclamationError } from '@/svgs/IconExclamationError.svg';
import { createStyles, platformSpecificStyle } from '@/utils';

const { getStyle } = createStyles(({ pixel }) => ({
  contentContainer: {
    flex: 1,
    justifyContent: 'center',
    maxWidth: pixel(670)
  },
  cameraContainer: {
    overflow: 'hidden',
    borderRadius: 10,
    marginBottom: 10,
    backgroundColor: '#00000055',
    justifyContent: 'center',
    alignItems: 'center',

    ...platformSpecificStyle({
      native: {
        height: pixel(502),
        width: pixel(670)
      },
      default: {
        height: (pixel(502) * 3) / 4,
        width: (pixel(670) * 3) / 4
      }
    })
  },
  error: {
    flexDirection: 'row',
    backgroundColor: '#17171A',
    borderRadius: 20,
    opacity: 0.8,
    justifyContent: 'center',
    alignItems: 'center',

    ...platformSpecificStyle({
      native: { height: pixel(228) },
      default: { height: pixel(228) / 2 }
    })
  },
  errorIcon: {
    height: pixel(66),
    width: pixel(66),
    marginRight: pixel(20)
  },
  errorMessage: {
    fontWeight: 'bold',

    ...platformSpecificStyle({
      native: { fontSize: pixel(26) },
      default: { fontSize: pixel(20) }
    })
  }
}));

export const QRCodeReaderScreen: React.FC = () => {
  const design = useDesign();
  const styles = getStyle(design);
  const dispatch = useAppDispatch();

  const [qrCodeScanned, setQrCodeScanned] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [lastScannedQR, setLastScannedQR] = useState<string | undefined>();
  const [lastReadUser, setLastReadUser] =
    useState<ReadProfileCardProps | null>();

  const handleQrCodeScanned = async (result: Code) => {
    try {
      if (qrCodeScanned || !result.value || result.value === lastScannedQR)
        return;

      // console.log(`@@@@Barcode scanned!`, JSON.stringify(result, null, 2));

      setQrCodeScanned(true);
      setLastScannedQR(result.value);

      const readTime = new Date();

      const qrResponse = await verifyReadQrCode({
        read_content: result.value,
        memo: '',
        read_at: readTime
      });
      if (!qrResponse.isStudent) {
        setErrorMessage('職員のQRコードは読み取れません。');
        setHasError(true);
      } else if (qrResponse.federationId) {
        const accountInfo = await Promise.all([
          fetchAccountInfo(qrResponse.federationId),
          dispatch(getProfileImage(qrResponse.federationId))
        ]);

        // 画像をThunkのpayloadから取り出す
        const { payload } = accountInfo[1];

        const profileImage =
          payload && !('error' in payload) ? payload : undefined;

        setHasError(false);
        setLastReadUser({
          profile: accountInfo[0],
          image: profileImage,
          federationId: qrResponse.federationId,
          readAt: readTime
        });

        await dispatch(qrCodeAction.setProfiles([]));
        await dispatch(getReadQrHistory({ page: 1 }));
      } else {
        throw Error('No federation Id');
      }
    } catch (err) {
      if (isNetworkError(err)) {
        setErrorMessage(
          '通信に失敗しました。\n後ほどもう一度読み取りを行ってください。'
        );
      } else {
        setErrorMessage(
          'QRコードの読み取りに失敗しました。\nもう一度読み取りを行ってください。'
        );
      }
      setHasError(true);
    } finally {
      setTimeout(() => {
        setQrCodeScanned(false);
      }, 1000);
    }
  };

  return (
    <View style={styles.contentContainer}>
      <View style={styles.cameraContainer}>
        <CameraFrameView handleQrCodeScanned={handleQrCodeScanned} />
      </View>
      <ScrollView>
        {lastReadUser && <ReadProfileCard {...lastReadUser} />}
        {hasError && (
          <View style={styles.error}>
            <IconExclamationError
              style={styles.errorIcon}
              width={styles.errorIcon.width}
              height={styles.errorIcon.height}
            />
            <TextM style={styles.errorMessage}>{errorMessage}</TextM>
          </View>
        )}
      </ScrollView>
    </View>
  );
};
