import { isAfter, isBefore, isWithinInterval } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';

import { useAttendanceBeaconRanging } from '../hooks/useAttendanceBeaconRanging';
import { CafeteriaMenuCardButtonWithDialog } from './CafeteriaMenuCardButtonWithDialog.component';
import { EventCard } from './EventCard.component';
import { EventCardListSideBar } from './EventCardListSideBar.component';
import { LocationPermissionsRequestDialog } from './LocationPermissionsRequestDialog.component';

import { getSchedulesAttendanceList } from '@/api/calls/attendance.api';
import { BEFORE_ATTENDANCE_START_TIME } from '@/constants/Attendance';
import {
  CAFETERIA_MENU_CARD_BUTTON_HEIGHT_MOBILE,
  CAFETERIA_MENU_CARD_BUTTON_HEIGHT_WEB,
  EVENT_CONTAINER_HEIGHT_MOBILE,
  EVENT_CONTAINER_HEIGHT_WEB,
  SEPARATOR_HEIGHT_MOBILE,
  SEPARATOR_HEIGHT_WEB
} from '@/constants/Home';
import { useDesign } from '@/hooks/useDesign';
import { AttendanceData } from '@/interfaces/Attendance.interface';
import { CalendarClass } from '@/interfaces/Calendar.interface';
import { useCafeteriaStatus } from '@/screens/home/hooks/useCafeteriaStatus';
import { createStyles, platformSpecificStyle } from '@/utils';

interface Props {
  selectedDate: Date;
  schedule: CalendarClass[];
  cafeteriaMenus?: string[];
}

const { getStyle } = createStyles(({ pixel }) => ({
  listContainer: {
    ...platformSpecificStyle({
      native: {
        flex: 1,
        gap: pixel(SEPARATOR_HEIGHT_MOBILE)
      },
      default: {
        gap: pixel(SEPARATOR_HEIGHT_WEB)
      }
    })
  },
  scrollableBody: {
    flexDirection: 'row'
  },
  eventContainer: {
    justifyContent: 'center',
    ...platformSpecificStyle({
      native: { height: pixel(EVENT_CONTAINER_HEIGHT_MOBILE), width: '100%' },
      default: { height: pixel(EVENT_CONTAINER_HEIGHT_WEB) }
    })
  },
  cafeteriaMenuCardButtonContainer: {
    justifyContent: 'center',
    ...platformSpecificStyle({
      native: {
        height: pixel(CAFETERIA_MENU_CARD_BUTTON_HEIGHT_MOBILE),
        width: '100%'
      },
      default: { height: pixel(CAFETERIA_MENU_CARD_BUTTON_HEIGHT_WEB) }
    })
  }
}));

export const EventList: React.FC<Props> = ({
  selectedDate,
  schedule,
  cafeteriaMenus
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  const [currentTime, setCurrentTime] = useState<Date>(new Date());
  const [attendanceList, setAttendanceList] = useState<AttendanceData[]>([]);

  const activeElementIndex = useMemo(
    () =>
      schedule.findIndex((e) => {
        return (
          e.name &&
          isWithinInterval(currentTime || new Date(), {
            start: e.period.from,
            end: e.period.to
          })
        );
      }),
    [schedule, currentTime]
  );

  // 出席確認のbeaconのrangingとAPI実行のintervalの監視を開始
  const {
    onRequestPermissions,
    onRejectLocationPermissionSetting,
    isRequestingLocationPermissions,
    isLocationPermissionsRequestedOnce,
    isLocationPermissionsGranted,
    isLocationPermissionsRejectedOnce,
    isWithinAttendanceCheckingPeriod
  } = useAttendanceBeaconRanging({
    currentTime: currentTime || new Date(),
    activeSchedule: schedule[activeElementIndex]
  });

  const apiIntervalSeconds = 10;
  const [apiIntervalTimer, setApiIntervalTimer] = useState<number>(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setApiIntervalTimer((prev) =>
        prev != null && prev < apiIntervalSeconds ? prev + 1 : 0
      );
    }, 1 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    setCurrentTime(new Date());
    if (
      isWithinAttendanceCheckingPeriod && // 出席確認の時間内のみAPIを実行
      apiIntervalTimer > 0 &&
      apiIntervalTimer % apiIntervalSeconds === 0
    ) {
      (async () => {
        const attendances = await getSchedulesAttendanceList(
          currentTime || new Date()
        );
        setAttendanceList(attendances);
      })();
    }
  }, [apiIntervalTimer, isWithinAttendanceCheckingPeriod]);
  const [
    needReopenLocationPermissionsRequestDialog,
    setNeedReopenLocationPermissionsRequestDialog
  ] = useState(false);

  const reopenLocationPermissionsRequestDialog = () => {
    setNeedReopenLocationPermissionsRequestDialog(true);
  };
  const onPressRejectLocationPermissionSetting = () => {
    onRejectLocationPermissionSetting();
    setNeedReopenLocationPermissionsRequestDialog(false);
  };

  const { isActiveCafeteria, isExpiredCafeteria } = useCafeteriaStatus({
    currentTime,
    selectedDate
  });

  if (!schedule?.length) {
    return null;
  }
  const withCafeteriaMenus = cafeteriaMenus && cafeteriaMenus.length > 0;
  return (
    <View style={{ ...styles.scrollableBody }}>
      <EventCardListSideBar
        activeElement={
          activeElementIndex > -1
            ? { event: schedule[activeElementIndex], index: activeElementIndex }
            : undefined
        }
        scheduleLength={schedule.length}
        gap={styles.listContainer.gap}
        withCafeteriaMenus={withCafeteriaMenus}
        isActiveCafeteria={isActiveCafeteria}
        isExpiredCafeteria={isExpiredCafeteria}
      />
      <View style={{ ...styles.listContainer }}>
        {schedule.map((scheduleItem, i) => {
          return (
            <React.Fragment key={scheduleItem.period.from.toString()}>
              <View style={{ ...styles.eventContainer }}>
                <EventCard
                  eventInfo={scheduleItem}
                  isActive={
                    Boolean(scheduleItem.name) &&
                    isWithinInterval(currentTime, {
                      start: scheduleItem.period.from,
                      end: scheduleItem.period.to
                    })
                  }
                  isExpired={
                    isBefore(scheduleItem.period.to, currentTime) ||
                    !scheduleItem.name
                  }
                  isLocationPermissionsRejected={
                    isLocationPermissionsRejectedOnce
                  }
                  onClickPermissionRejectedAlert={
                    reopenLocationPermissionsRequestDialog
                  }
                  attendanceInfo={attendanceList.find(
                    (a) => Number(a.period) === i + 1
                  )}
                  displayAttendanceInfo={
                    Boolean(scheduleItem.name) &&
                    isAfter(
                      currentTime,
                      new Date(
                        scheduleItem.period.from.getTime() -
                          BEFORE_ATTENDANCE_START_TIME
                      )
                    )
                  }
                  isWithinAttendanceCheckingPeriod={
                    isWithinAttendanceCheckingPeriod
                  }
                  isWithinBeforeAttendanceStartTime={isBefore(
                    currentTime,
                    scheduleItem.period.from
                  )}
                />
              </View>
              {i === 1 && withCafeteriaMenus && (
                <View style={{ ...styles.cafeteriaMenuCardButtonContainer }}>
                  <CafeteriaMenuCardButtonWithDialog
                    selectedDate={selectedDate}
                    isActive={isActiveCafeteria}
                    isExpired={isExpiredCafeteria}
                    menus={cafeteriaMenus}
                  />
                </View>
              )}
            </React.Fragment>
          );
        })}
      </View>
      <LocationPermissionsRequestDialog
        isOpen={
          (isLocationPermissionsRequestedOnce &&
            !isLocationPermissionsGranted &&
            !isLocationPermissionsRejectedOnce) ||
          (isLocationPermissionsRejectedOnce &&
            needReopenLocationPermissionsRequestDialog)
        }
        isRequestingLocationPermissions={isRequestingLocationPermissions}
        onRequestPermissions={onRequestPermissions}
        onRejectLocationPermissionSetting={
          onPressRejectLocationPermissionSetting
        }
      />
    </View>
  );
};
