import { configureStore } from '@reduxjs/toolkit';

import {
  accountReducer,
  authReducer,
  calendarReducer,
  configurationReducer,
  errorReducer,
  naviReducer,
  notificationsReducer,
  qrCodeReducer,
  teacherDataReducer,
  timetableReducer,
  uiReducer
} from './reducers';

export const store = configureStore({
  reducer: {
    account: accountReducer,
    auth: authReducer,
    calendar: calendarReducer,
    navi: naviReducer,
    notifications: notificationsReducer,
    timetable: timetableReducer,
    configuration: configurationReducer,
    error: errorReducer,
    qrCode: qrCodeReducer,
    teacherData: teacherDataReducer,
    ui: uiReducer
  },
  // we enable non-serializable objects to the store as we don't use any persistance
  // however, this should be handled if store needs to be persisted
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: false
    });
  }
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
