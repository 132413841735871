import React, { PropsWithChildren } from 'react';
import {
  Animated,
  StyleSheet,
  TouchableOpacity,
  TransformsStyle,
  View,
  ViewStyle
} from 'react-native';
import Svg, { SvgProps as ReactSvgProps } from 'react-native-svg';

import { useDesign } from '@/hooks/useDesign';

export interface SvgProps {
  disabled?: boolean;
  color?: string;
  style?: ViewStyle;
  size: number | string | { width: number | string; height: number | string };
  innerSize?: number | string;
  onPress?: () => void;
}

interface Props extends Omit<SvgProps, 'color'> {
  viewBox: { width: number; height: number };
  animation?: Animated.WithAnimatedObject<TransformsStyle>;
}

const styles = StyleSheet.create({
  baseContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  disabledContainer: {
    opacity: 0.2
  }
});

export const usePrimaryIconColor = () => {
  const {
    colors: {
      icon: { primary: primaryIconColor }
    }
  } = useDesign();
  return primaryIconColor;
};

const AnimatedSvg = Animated.createAnimatedComponent(Svg);

export const SvgWrapper: React.FC<PropsWithChildren<Props>> = ({
  children,
  disabled,
  style,
  size,
  innerSize,
  viewBox,
  animation,
  onPress
}) => {
  const mainContainerStyle: ViewStyle = {
    ...style,
    ...styles.baseContainer,
    ...(disabled && styles.disabledContainer),
    width: typeof size === 'object' ? size.width : size,
    height: typeof size === 'object' ? size.height : size
  };

  const mainSVGProps: ReactSvgProps = {
    width: innerSize || '100%',
    height: innerSize || '100%',
    viewBox: `0 0 ${viewBox.width} ${viewBox.height}`
  };

  const content = animation ? (
    <AnimatedSvg {...mainSVGProps} style={animation}>
      {children}
    </AnimatedSvg>
  ) : (
    <Svg {...mainSVGProps}>{children}</Svg>
  );

  if (onPress)
    return (
      <TouchableOpacity
        style={mainContainerStyle}
        disabled={disabled}
        onPress={onPress}
      >
        {content}
      </TouchableOpacity>
    );

  return <View style={mainContainerStyle}>{content}</View>;
};
