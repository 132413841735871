import Constants from 'expo-constants';
import { Animated, View } from 'react-native';

import { WelcomeMessage } from './WelcomeMessage.component';
import { OpacityAnimatedProps } from './types';

import { TextXL } from '@/components/atomic/text';
import { useDesign } from '@/hooks/useDesign';
import { createStyles, platformSpecificStyle } from '@/utils';

const { getStyle } = createStyles(({ colors, pixel }) => ({
  header: {
    width: '100%',
    height: '40%',
    alignItems: 'center'
  },
  titleContainer: {
    flexDirection: 'row',
    marginVertical: pixel(50)
  },
  text: {
    color: colors.textPrimaryInverted
  },
  title: {
    fontWeight: 'bold'
  },
  titleHighlight: {
    color: colors.navigation.iconColor
  },
  subtitleHighlight: {
    fontWeight: 'bold',
    fontSize: pixel(20)
  },
  subtitleContainer: {
    marginBottom: pixel(80),
    alignSelf: 'flex-start',
    ...platformSpecificStyle({
      default: {
        paddingLeft: pixel(35)
      },
      tablet: {
        paddingLeft: pixel(15)
      }
    })
  }
}));

const APP_NAME: string = Constants.expoConfig?.extra?.APP_NAME;

export const Header: React.FC<OpacityAnimatedProps> = ({
  opacityAnimation
}) => {
  const design = useDesign();
  const styles = getStyle(design);

  return (
    <Animated.View style={{ ...styles.header, opacity: opacityAnimation }}>
      <View style={{ ...styles.titleContainer }}>
        {APP_NAME?.split(' ').map((titlePart, index) => (
          <TextXL
            key={titlePart}
            style={{
              ...styles.text,
              ...styles.title,
              ...(index && styles.titleHighlight)
            }}
          >
            {titlePart}
          </TextXL>
        ))}
      </View>
      <View style={{ ...styles.subtitleContainer }}>
        <WelcomeMessage />
      </View>
    </Animated.View>
  );
};
